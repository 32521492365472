import { gql } from "@apollo/client";

export const CHANGE_PASSWORD_REQUEST = gql`
  mutation changePasswordRequest(
    $changePasswordRequestInput: ChangePasswordRequestInput!
  ) {
    changePasswordRequest(
      changePasswordRequestInput: $changePasswordRequestInput
    )
  }
`;
