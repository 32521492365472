import gql from "graphql-tag";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const ProductionFragment = gql`
  fragment Production on Production {
    id
    type
    name
    sets {
      support
      formatName
      format
      parameters
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
