import { TFunction } from "i18next";

export const constraintFirstName = (t: TFunction) => ({
  firstName: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintLastName = (t: TFunction) => ({
  lastName: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintPhoneNumber = (t: TFunction) => ({
  phoneNumber: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
      maximum: 20,
      tooLong: t("Validator.Message.Max20Char"),
    },
    format: {
      pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      message: t("Validator.Message.PhoneNumber"),
    },
  },
});

export const constraintEMail = (t: TFunction) => ({
  email: {
    email: {
      message: t("Validator.Message.Invalid"),
    },
  },
});

export const constraintPassword = (t: TFunction) => ({
  password: {
    length: {
      minimum: 8,
      tooShort: t("Validator.Message.Min8Char"),
      maximum: 20,
      tooLong: t("Validator.Message.Max16Char"),
    },
    format: {
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[a-zA-Z\d\w\s\S]{8,}$/,
      message: t("Validator.Message.Password"),
    },
  },
});

export const constraintConfirmPassword = (t: TFunction) => ({
  confirmPassword: {
    equality: {
      attribute: "password",
      message: t("Validator.Message.NotSamePassword"),
      comparator: function (v1: string, v2: string) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      },
    },
  },
});
