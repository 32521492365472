import { gql } from "@apollo/client";
import { SafeFragment } from "../fragments/safe";

export const USER_SAFE = gql`
  query getUserSafe($safeId: String!) {
    userSafe(safeId: $safeId) {
      ...Safe
    }
  }
  ${SafeFragment}
`;

export const USER_SAFES = gql`
  query userSafes($userSafesInput: UserSafesInput) {
    userSafes(userSafesInput: $userSafesInput) {
      total
      results {
        ...Safe
      }
    }
  }
  ${SafeFragment}
`;
