import { motion } from "framer-motion";
import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo_SoixanteSeize from "../../../img/svg/Logo_SoixanteSeize.svg";
import BurgerMenu from "../../../img/svg/Logo_SoixanteSeize_IconeAloneColor.svg";
import { useWindowInnerSize } from "../../hooks/useWindowSize";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import { SideBarSuperMenu } from "./SideBarSuperMenu";

export interface ISideBarMenuProps {
  connected: boolean;
  menuOpen: boolean;
  setMenuOpen: (bool: boolean) => void;
}

export const SideBarMenu: FunctionComponent<ISideBarMenuProps> = ({
  connected,
  menuOpen,
  setMenuOpen,
}) => {
  const windowDimensions = useWindowInnerSize();
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [superMenuOpen, setSuperMenuOpen] = useState<string | undefined>(
    undefined
  );

  ////////////////////////////////////////////////////////////////// RENDER

  const variants = {
    menuOpen: {
      width: windowDimensions.width < 1024 ? windowDimensions.width : 350,
    },
    menuClosed: { width: 0 },
  };

  return (
    <MenuTiroir>
      <motion.div
        onClick={() => setMenuOpen(!menuOpen)}
        // onHoverStart={() => {
        //   setMenuOpen(true);
        // }}
        // onHoverEnd={() => {
        //   setMenuOpen(false);
        // }}
        style={{ backgroundColor: COLORS.fakeBlack, height: "100%" }}
        animate={menuOpen ? "menuOpen" : "menuClosed"}
        variants={variants}
        transition={{ ease: "easeOut", duration: 0.1 }}
      >
        {menuOpen && (
          <MenuMobileCloseContainer onClick={() => setMenuOpen(false)}>
            FERMER
          </MenuMobileCloseContainer>
        )}

        <MenuColumn>
          <MenuContainer>
            {menuOpen && <LogoMenuImage src={Logo_SoixanteSeize} />}
            {!menuOpen && <BurgerMenuImage src={BurgerMenu} />}

            <MenuItemsContainer>
              <SideBarSuperMenu
                label="STUDIO"
                subLabel="NOS PROJETS CRÉATIFS ET DIGITAUX"
                destinationPath="https://soixanteseize.com"
                path={location.pathname}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                superMenus={[]}
                superMenuOpen={superMenuOpen}
                setSuperMenuOpen={setSuperMenuOpen}
              />

              {connected && menuOpen && (
                <>
                  <Separator />

                  <SideBarSuperMenu
                    label="NOUVELLE IMAGE"
                    subLabel="GÉNÉRER UNE NOUVELLE IMAGE"
                    destinationPath="/bot/imagery"
                    path={location.pathname}
                    superMenus={[]}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    superMenuOpen={superMenuOpen}
                    setSuperMenuOpen={setSuperMenuOpen}
                  />

                  <Separator />

                  <SideBarSuperMenu
                    label="HISTORIQUE"
                    subLabel="VOS DERNIÈRES DEMANDES"
                    destinationPath="/history"
                    path={location.pathname}
                    superMenus={[]}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  />

                  <SideBarSuperMenu
                    label="COLLECTIONS"
                    subLabel="VOS IMAGES ENREGISTRÉES"
                    destinationPath="/collections"
                    path={location.pathname}
                    superMenus={[]}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    superMenuOpen={superMenuOpen}
                    setSuperMenuOpen={setSuperMenuOpen}
                  />

                  {/* <Separator />

                   <SideBarSuperMenu
                    label="FACTORY"
                    subLabel="FABRIQUEZ VOS MESSAGES"
                    destinationPath="/factory"
                    path={location.pathname}
                    superMenus={[]}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  /> */}
                </>
              )}
            </MenuItemsContainer>
          </MenuContainer>

          {menuOpen && (
            <FooterContainer>
              <motion.div
                animate={menuOpen ? { opacity: 1 } : { opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.1 }}
              >
                {/* <PromoContainer
                  onClick={() => window.open("https://soixanteseize.com")}
                >
                  <ContactLink>STUDIO SOIXANTESEIZE</ContactLink>
                  <Text>
                    Tous nos projets sont sur le https://soixanteseize.com.
                  </Text>
                </PromoContainer> */}

                <Deconnexion onClick={() => logout()}>DÉCONNEXION</Deconnexion>

                <Text>21 rue des Filles du Calvaire</Text>
                <Text style={{ marginBottom: 20 }}>75003 Paris - France</Text>
                <ContactLink
                  onClick={() =>
                    window.open("https://soixanteseize.com/contacts", "_blank")
                  }
                >
                  CONTACTS
                </ContactLink>
                <div>
                  <Legals
                    onClick={() =>
                      window.open("https://soixanteseize.com/legals", "_blank")
                    }
                  >
                    Mentions Légales _ SoixanteSeize ©2023
                  </Legals>
                </div>
              </motion.div>
            </FooterContainer>
          )}
        </MenuColumn>
      </motion.div>
    </MenuTiroir>
  );
};

const MenuTiroir = styled.div`
  position: absolute;
  z-index: 100;
  flex-direction: column;
  background-color: ${COLORS.fakeBlack};
  height: 100%;
  width: 0px;
`;

const MenuMobileCloseContainer = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    position: fixed;
    height: 29px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "HelveticaNeueLTPro-Bd";
    font-size: 10px;
    letter-spacing: 1px;
    z-index: 1000;
    top: 38px;
    right: 30px;
    color: ${COLORS.transparentGrey};
  }
`;

const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: "space-between";
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px 20px 33px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin: 30px 0px 20px 22px;
  }
`;

const MenuItemsContainer = styled.div`
  margin: 20px 0px 0px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const FooterContainer = styled.div`
  position: absolute;
  padding: 40px;
  bottom: 0px;
  width: 200px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    position: fixed;
    padding: 0px 0px 30px 65px;
    bottom: calc(var(--vh, 1vh) * 2.5);
  }
`;

const ContactLink = styled.div`
  font-family: "HelveticaNeueLTPro-Bd";
  text-decoration: unset;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.soixanteseize};
  }
`;

const Deconnexion = styled.div`
  font-family: "HelveticaNeueLTPro-Bd";
  text-decoration: unset;
  font-size: 12px;
  color: ${COLORS.red};
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.fakeWhite};
  }
`;

const Text = styled.div`
  font-family: "HelveticaNeueLTPro-Bd";
  text-decoration: unset;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
`;

const Legals = styled.div`
  font-family: "HelveticaNeueLTPro-lt";
  text-decoration: unset;
  font-size: 10px;
  color: ${COLORS.fakeWhite};
  cursor: pointer;
  &:hover {
    color: ${COLORS.soixanteseize};
  }
`;

const LogoMenuImage = styled.img`
  width: 180px;
`;

const BurgerMenuImage = styled.img`
  width: 180px;
  mix-blend-mode: difference;
`;

const Separator = styled.div`
  border-width: 1px;
  border-color: ${COLORS.soixanteseize};
  border-style: solid;
  width: 50%;
  margin: 3px 0 10px 23px;
`;
