import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";
import { COLORS } from "../../libs/styles/colors";

export interface IIncrementSelectorProps {
  darkMode: boolean;
  plusIcon: any;
  minusIcon: any;
  setPlus: () => void;
  setMinus: () => void;
}

export const IncrementSelector: FunctionComponent<IIncrementSelectorProps> = ({
  darkMode,
  plusIcon,
  minusIcon,
  setPlus,
  setMinus,
}) => {
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    return () => stopCounter();
  }, []);

  const startCounter = (isPlus: boolean) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      isPlus ? setPlus() : setMinus();
    }, 10);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <IconsContainer>
      <Icon
        darkMode={darkMode}
        selected={false}
        onMouseDown={() => startCounter(false)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
      >
        <FontAwesomeIcon size="lg" icon={minusIcon} />
        <IconBg selected={false} />
      </Icon>
      <Icon
        darkMode={darkMode}
        selected={false}
        onMouseDown={() => startCounter(true)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
      >
        <FontAwesomeIcon size="lg" icon={plusIcon} />
        <IconBg selected={false} />
      </Icon>
    </IconsContainer>
  );
};

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-bottom: 2px;
`;

const Icon = styled.div<{ selected: boolean; darkMode: boolean }>`
  position: relative;
  color: ${(props) =>
    props.selected ? COLORS.soixanteseize : COLORS.transparentGrey};
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite)};
  }
`;

const IconBg = styled.div<{ selected: boolean }>`
  position: absolute;
  background-color: ${(props) =>
    props.selected ? COLORS.fakeWhite : COLORS.transparent};
  top: 20%;
  width: 100%;
  height: 60%;
  z-index: -1;
`;
