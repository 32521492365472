import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
export interface IAppSideBarMenuItemProps {
  label: string;
  subLabel?: string;
  destinationPath: string;
  path: string;
  menuOpen: boolean;
  setMenuOpen: (bool: boolean) => void;
  subMenus: { label: string; destinationPath: string; subLabel: string }[];
  topMenu?: boolean;
  superMenuOpen?: string | undefined;
  setSuperMenuOpen?: (bool: string | undefined) => void;
}

export const SideBarMenuItem: FunctionComponent<IAppSideBarMenuItemProps> = ({
  subMenus,
  label,
  subLabel,
  destinationPath,
  path,
  setMenuOpen,
  menuOpen,
  topMenu,
  superMenuOpen,
  setSuperMenuOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  ////////////////////////////////////////////////////////////////// STATE

  // const [menuItemOpen, setMenuItemOpen] = useState<boolean>(false);
  // const [itemHover, setItemHover] = useState<boolean>(false);
  // const [subMenuItemHoverIndex, setSubMenuItemHoverIndex] =
  //   useState<number>(-1);

  ////////////////////////////////////////////////////////////////// STYLE

  const pathMatch = destinationPath === location.pathname;

  ////////////////////////////////////////////////////////////////// MENU BEHAVIOUR

  // useEffect(() => {
  //   if (pathMatch) setMenuItemOpen(true);
  // }, [pathMatch]);

  ////////////////////////////////////////////////////////////////// ANIMATION

  return (
    <motion.div
      className={"menuItem"}
      // onMouseOver={() => setItemHover(true)}
      // onMouseLeave={() => setItemHover(false)}
    >
      {menuOpen && (
        <>
          <ItemContainer
            active={!topMenu && pathMatch}
            style={{ flexDirection: "column", alignItems: "flex-start" }}
            onClick={() => {
              if (topMenu && setSuperMenuOpen)
                setSuperMenuOpen(superMenuOpen !== label ? label : undefined);
              else {
                if (pathMatch) window.location.reload();
                if (destinationPath.startsWith("http")) {
                  setMenuOpen(false);
                  window.open(destinationPath, "_blank");
                } else {
                  setMenuOpen(false);
                  navigate(destinationPath);
                }
              }
            }}
          >
            <ItemLabel active={!topMenu && pathMatch}>{label}</ItemLabel>
            <ItemSubLabel active={!topMenu && pathMatch}>
              {subLabel}
            </ItemSubLabel>
          </ItemContainer>
        </>
      )}
    </motion.div>
  );
};

const ItemContainer = styled.div<{ active: boolean }>`
  --color: ${(props) =>
    props.active ? COLORS.soixanteseize : COLORS.fakeWhite};
  --backgroundColor: ${(props) =>
    props.active ? COLORS.fakeWhite : COLORS.transparent};
  --padding: ${(props) => (props.active ? "2px 0 0px 5px" : "unset")};
  color: var(--color);
  text-decoration: none;
  display: flex;
  height: 30px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--backgroundColor);
  padding: var(--padding);

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ItemLabel = styled.div<{ active: boolean }>`
  --color: ${(props) =>
    props.active ? COLORS.soixanteseize : COLORS.fakeWhite};
  --padding: ${(props) => (props.active ? "2px 0 0px 5px" : "5px 0 0 0")};

  color: var(--color);
  padding: var(--padding);
  font-family: "HelveticaNeueLtPro-Bd";
  font-size: 12px;
  width: 200px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ItemSubLabel = styled.div<{ active: boolean }>`
  --color: ${(props) =>
    props.active ? COLORS.soixanteseize : COLORS.fakeWhite};
  --padding: ${(props) => (props.active ? "0 0 0 5px" : "0 0 0 0")};

  color: var(--color);
  padding: var(--padding);
  font-family: "HelveticaNeueLtPro-Lt";
  font-size: 9px;
  width: 200px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;
