import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS } from "../../styles/colors";
export interface ISelectorProps {
  items: { label: string; onClick?: () => void; active: boolean }[];
}

const Selector: FunctionComponent<ISelectorProps> = ({ items }) => {
  ////////////////////////////////////////////////////////////////// RENDER

  return (
    <SelectorContainer>
      <SelectorItemContainer>
        {items.map((item, i) => (
          <SelectorItem active={item.active} key={i} onClick={item.onClick}>
            {item.label.toUpperCase()}
          </SelectorItem>
        ))}
      </SelectorItemContainer>
    </SelectorContainer>
  );
};

export default Selector;

const SelectorContainer = styled.div`
  height: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "HelveticaNeueLTPro-Bd";
  font-size: 10px;
  letter-spacing: 1px;
  top: 46px;
  right: 40px;
  cursor: pointer;
`;

const SelectorItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${COLORS.translucentDarkBG};
`;

const SelectorItem = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? COLORS.white : COLORS.transparentGrey)};
  background-color: ${(props) =>
    props.active ? COLORS.fakeBlack : COLORS.transparent};
  padding: 10px 20px 9px 20px;
`;
