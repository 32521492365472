import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../..";
import {
  Account,
  AccountsResponse,
  Production,
} from "../../graphql/generated/graphql";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { PRODUCTIONS } from "../../graphql/queries/productions";
import Button from "../../libs/components/button/Button";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { Modal } from "../../libs/components/modal/modal";
import { PageHeaderLayout } from "../../libs/components/pageHeaderLayout/PageHeaderLayout";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { RemoveProduction } from "../modals/removeProduction";

export interface IImageryDeliveryProps {
  darkMode: boolean;
}

export const DisplayAd: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();

  const context = useContext(AppContext);
  const { state, setState } = context;

  /**********************************************************
   *** MODAL ***
   **********************************************************/

  const [removeModal, setRemoveModal] = useState<
    | {
        open: boolean;
        productionId: string;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onCompleted: (data: { userAccounts: AccountsResponse }) => {
      if (data.userAccounts?.results && data.userAccounts?.results.length > 0) {
        setAccount(data.userAccounts?.results[0]);
      }
    },
    onError: (error: ApolloError) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
    },
  });

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [getProductions, { data: productionsData }] = useLazyQuery(PRODUCTIONS);
  useEffect(() => {
    getProductions({
      variables: {
        productionsFilterInput: {
          type: "DISPLAY_AD",
          skip: 0,
          limit: 100,
          order: ["createdAt:DESC"],
        },
      },
      onError: (error: ApolloError) => {
        console.log("PRODUCTIONS ERROR:", JSON.parse(JSON.stringify(error)));
      },
    });
  }, [getProductions]);
  const productions = productionsData?.productions?.results;

  if (!account) return null;

  return (
    <>
      <Layout darkMode={darkMode}>
        <Screen>
          <PageHeaderLayout
            title={t("Page.DisplayAd.Title")}
            subtitle={t("Page.DisplayAd.Subtitle") ?? ""}
            buttons={
              state.factory.displayAd
                ? [
                    <Button
                      onClick={() => {
                        navigate("/factory/display-ad/config");
                      }}
                      type={"classic"}
                    >
                      {t("Button.ReloadProjectInProgress")}
                    </Button>,
                    <Button
                      onClick={() => {
                        setState((prevstate: any) => {
                          prevstate.factory.displayAd = undefined;
                          return { ...prevstate };
                        });
                        navigate("/factory/display-ad/config");
                      }}
                      type={"classic"}
                    >
                      {t("Button.CreateNewProject")}
                    </Button>,
                  ]
                : [
                    <Button
                      onClick={() => {
                        setState((prevstate: any) => {
                          prevstate.factory.displayAd = undefined;
                          return { ...prevstate };
                        });
                        navigate("/factory/display-ad/config");
                      }}
                      type={"classic"}
                    >
                      {t("Button.CreateNewProject")}
                    </Button>,
                  ]
            }
            id={`${account.users[0].id}`}
          />

          {productions?.length > 0 && (
            <Content>
              <Separator />

              <ProjectTitle darkMode={darkMode}>
                {t("Page.DisplayAd.ProjectTitle")}
              </ProjectTitle>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  gap: 20,
                }}
              >
                {productions.map((p: Production, i: number) => {
                  const destination =
                    "/factory/display-ad/config?production=" + p.id;
                  return (
                    <ProjectContainer key={i}>
                      <ImageCardContainer
                        onClick={() => {
                          navigate(destination);
                        }}
                      >
                        <ImageCard>
                          <Image
                            src={
                              p.sets[0].parameters.image.asset
                                ? `https://ucarecdn.com/${
                                    p.sets[0].parameters.image.asset.cdnId ??
                                    "826a2deb-0db9-4345-ad19-15f430defea1"
                                  }/-/scale_crop/100x20/smart/`
                                : "https://ucarecdn.com/826a2deb-0db9-4345-ad19-15f430defea1/-/scale_crop/100x20/smart/"
                            }
                            alt="Alt"
                          />
                        </ImageCard>
                      </ImageCardContainer>

                      <OnLabelRow>
                        <OnLabel
                          onClick={() => {
                            navigate(destination);
                          }}
                        >
                          {DateTime.fromISO(p.updatedAt).toLocaleString(
                            DateTime.DATE_FULL
                          )}
                        </OnLabel>
                        <IconContainer
                          onClick={() => {
                            setRemoveModal({
                              open: true,
                              productionId: p.id,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} size="xs" />
                        </IconContainer>
                      </OnLabelRow>
                      <Label
                        onClick={() => {
                          navigate(destination);
                        }}
                      >
                        {p.name}
                      </Label>
                    </ProjectContainer>
                  );
                })}
              </div>
            </Content>
          )}

          <Footer darkMode={darkMode} />
        </Screen>
      </Layout>

      <Modal
        open={removeModal?.open ? true : false}
        title={`Remove asset`}
        onClose={() => {
          setRemoveModal(undefined);
        }}
      >
        <RemoveProduction
          onClose={() => {
            setRemoveModal(undefined);
            window.location.reload();
          }}
          darkMode={darkMode}
          productionId={removeModal?.productionId ?? ""}
        />
      </Modal>
    </>
  );
};

const Screen = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 100px 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const Separator = styled.div`
  width: 100%;
  max-width: 1080px;
  height: 1px;
  background-color: ${COLORS.soixanteseize};
  margin: 60px 0 60px 0;
`;
const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const ImageCardContainer = styled.div`
  cursor: pointer;
`;
const ImageCard = styled.div`
  height: 65px;
  background-color: ${COLORS.translucentDarkBG};
  border-radius: 10px;
  padding: 5px 5px 7px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 110px;
  max-height: 65px;
  object-fit: contain;
  /* border-radius: 10px; */

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const OnLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Label = styled.div`
  max-width: 1080px;
  font-size: 18px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  margin-left: 5px;
`;
const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${COLORS.fakeWhite};
  margin-top: -3px;
  margin-right: 5px;
`;

const OnLabel = styled.div`
  cursor: pointer;
  max-width: 1080px;
  font-size: 9px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  margin-bottom: -5px;
  margin-left: 5px;
`;

const ProjectTitle = styled.div<{ darkMode: boolean }>`
  cursor: pointer;
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeBlack)};
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 24px;
  }
`;
