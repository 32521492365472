import { useMutation } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { REMOVE_ASSET } from "../../graphql/mutations/removeAsset";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";

export interface IRemoveAssetProps {
  darkMode?: boolean;
  image?: string;
  safeId: string;
  assetId: string;
  onClose: () => void;
}

export const RemoveAsset: FunctionComponent<IRemoveAssetProps> = ({
  darkMode,
  image,
  safeId,
  assetId,
  onClose,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);

  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  /**********************************************************
   *** CREATE SAFE ***
   **********************************************************/

  const [removeAsset, { loading }] = useMutation(REMOVE_ASSET, {
    onCompleted: (data: any) => {
      setSuccess(`${t("Success.Message.AssetRemoved")} `);
      onClose();
    },
    onError: (error: any) => {
      console.log("ASSET REMOVE ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (confirmation) {
      removeAsset({
        variables: {
          removeAssetInput: {
            safeId,
            assetId,
          },
        },
      });
    }
  }, [assetId, confirmation, removeAsset, safeId]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        images: image ? [image] : undefined,
        q: t("RemoveAsset.Question.0"),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("RemoveAsset.Answer.No"),
            label: t("RemoveAsset.Answer.No"),
            type: "secondary",
          },
          {
            value: t("RemoveAsset.Answer.Yes"),
            label: t("RemoveAsset.Answer.Yes"),
            type: "alert",
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("RemoveAsset.Answer.Yes")) {
            setConfirmation(true);
          } else {
            onClose();
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [image, onClose, resQuestion, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={loading ? t("Loading.Message.Waiting") : undefined}
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
