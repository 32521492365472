import React, { useEffect, useRef } from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components/macro";
import { COLORS } from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts/fonts";

interface IBotTextField {
  darkMode?: boolean;
}

export const BotTextField: React.FC<
  IBotTextField & FieldRenderProps<string, any>
> = ({
  input,
  meta,
  editable,
  disabled = false,
  secure,
  placeholder,
  darkMode = false,
  style,
  multiline,
  secured,
  ...rest
}) => {
  const textAreaRef = useRef<
    HTMLTextAreaElement & HTMLCollectionOf<HTMLElement>
  >(null);

  // This effect will run every time the input value changes to modify the height of the textarea
  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, input.value]);

  return (
    <Container style={style}>
      <InputContainer editable={editable} darkMode={darkMode}>
        {multiline ? (
          <TextArea
            ref={textAreaRef}
            darkMode={darkMode}
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            security={secure}
            required
            // cols={40}
            rows={multiline}
            {...input}
            {...rest}
            disabled={disabled}
            editable={editable}
          />
        ) : (
          <Input
            darkMode={darkMode}
            type={secured ? "password" : "text"}
            placeholder={placeholder}
            autoComplete="off"
            security={secure}
            required
            {...input}
            {...rest}
            disabled={disabled}
            editable={editable}
          />
        )}
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const InputContainer = styled.div<{
  editable?: boolean;
  darkMode?: boolean;
}>`
  /* --bgColor: ${(props) => {
    if (props.editable)
      return `${props.darkMode ? COLORS.fakeBlack : COLORS.fakeWhite}`;
    return "transparent";
  }}; */
  background: transparent;
  padding: 20px;
`;

const Input = styled.input<{
  darkMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
}>`
  --color: ${(props) => {
    if (props.disabled && !props.editable) return COLORS.lightgray;
    if (props.disabled && props.editable)
      return props.darkMode ? COLORS.darkgray : COLORS.lightgray;
    return props.darkMode ? COLORS.white : COLORS.black;
  }};

  display: flex;
  width: 100%;
  color: var(--color);
  font-size: 24px;
  font-family: ${FONTS.bold};
  letter-spacing: 0.5px;
  line-height: 1.1;
  padding: 10px 0px 5px 20px;
  background-color: transparent;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 1; /* Firefox */
  }
`;
const TextArea = styled.textarea<{
  darkMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
}>`
  display: flex;
  resize: none;
  width: 100%;
  --color: ${(props) => {
    if (props.disabled && !props.editable) return COLORS.lightgray;
    if (props.disabled && props.editable)
      return props.darkMode ? COLORS.darkgray : COLORS.lightgray;
    return props.darkMode ? COLORS.white : COLORS.black;
  }};
  color: var(--color);
  font-size: 24px;
  font-family: ${FONTS.bold};
  letter-spacing: 0.5px;
  line-height: 1.1;
  /* padding: 10px 0px 5px 20px; */
  background-color: transparent;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 0.5; /* Firefox */
  }
`;
