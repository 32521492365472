import { gql } from "@apollo/client";
import { SafeFragment } from "../fragments/safe";

export const CREATE_SAFE = gql`
  mutation createSafe($createSafeInput: CreateSafeInput!) {
    createSafe(createSafeInput: $createSafeInput) {
      ...Safe
    }
  }
  ${SafeFragment}
`;
