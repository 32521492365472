import { TFunction } from "i18next";

export const constraintSector = (t: TFunction) => ({
  sector: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintPosition = (t: TFunction) => ({
  position: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintCompany = (t: TFunction) => ({
  company: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintCountry = (t: TFunction) => ({
  country: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintCity = (t: TFunction) => ({
  city: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintName = (t: TFunction) => ({
  name: {
    length: {
      minimum: 1,
      tooShort: t("Validator.Message.Required"),
    },
  },
});
