import { gql } from "@apollo/client";
import { AccountFragment } from "../fragments/account";

export const CREATE_ACCOUNT = gql`
  mutation createAccount($createAccountInput: CreateAccountInput!) {
    createAccount(createAccountInput: $createAccountInput) {
      ...Account
    }
  }
  ${AccountFragment}
`;
