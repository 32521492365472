import { gql } from "@apollo/client";
import { MoodboardFragment } from "../fragments/moodboard";

export const MOODBOARD = gql`
  query moodboard($moodboardInput: MoodboardInput!) {
    moodboard(moodboardInput: $moodboardInput) {
      ...Moodboard
    }
  }
  ${MoodboardFragment}
`;
