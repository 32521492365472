import gql from "graphql-tag";
import { AssetFragment } from "./asset";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const SafeFragment = gql`
  fragment Safe on Safe {
    id
    type
    name
    user
    assets {
      ...Asset
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  ${AssetFragment}
`;
