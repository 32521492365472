import { TFunction } from "i18next";
import {
  ArtMediumTypes,
  ArtTypes,
  ArtistsStudiosTypes,
  CinemaGenreTypes,
  FxTypes,
  IllustrationArtistTypes,
  IllustrationGenreTypes,
  ImageTypes,
  LightingTypes,
  PhotographyTypes,
  SectorTypes,
  ShotTypes,
} from "./general";

export const sectorOptions = (t: TFunction) => {
  return [
    {
      value: SectorTypes.COMMUNICATION,
      label: t("Option.Sector.COMMUNICATION"),
    },
    {
      value: SectorTypes.CONSUMER_STAPLES,
      label: t("Option.Sector.CONSUMER_STAPLES"),
    },
    { value: SectorTypes.ENERGY, label: t("Option.Sector.ENERGY") },
    { value: SectorTypes.FINANCIAL, label: t("Option.Sector.FINANCIAL") },
    { value: SectorTypes.HEALTHCARE, label: t("Option.Sector.HEALTHCARE") },
    { value: SectorTypes.INDUSTRIALS, label: t("Option.Sector.INDUSTRIALS") },
    { value: SectorTypes.LUXURY, label: t("Option.Sector.LUXURY") },
    { value: SectorTypes.MATERIALS, label: t("Option.Sector.MATERIALS") },
    { value: SectorTypes.REAL_ESTATE, label: t("Option.Sector.REAL_ESTATE") },
    { value: SectorTypes.TECHNOLOGY, label: t("Option.Sector.TECHNOLOGY") },
    { value: SectorTypes.UTILITIES, label: t("Option.Sector.UTILITIES") },
  ];
};

/// IMAGES

export const imageTypeOptions = (t: TFunction) => {
  return [
    {
      value: ImageTypes.PHOTOGRAPHY,
      label: t("Option.PHOTOGRAPHY"),
      description: t("Option.PHOTOGRAPHY.Description"),
    },
    {
      value: ImageTypes.ILLUSTRATION,
      label: t("Option.ILLUSTRATION"),
      description: t("Option.ILLUSTRATION.Description"),
    },
    /* {
      value: ImageTypes.PAINTING,
      label: t("Option.PAINTING"),
      description: t("Option.PAINTING.Description"),
    },
    {
      value: ImageTypes.DRAWING,
      label: t("Option.DRAWING"),
      description: t("Option.DRAWING.Description"),
    },
    {
      value: ImageTypes.SKETCH,
      label: t("Option.SKETCH"),
      description: t("Option.SKETCH.Description"),
    }, */
  ];
};

export const photoTypeOptions = (t: TFunction) => {
  return [
    {
      value: "None",
      label: t("Option.NONE"),
      description: t("Option.NONE.Description"),
    },
    {
      value: PhotographyTypes.PORTRAIT,
      label: t("Option.PORTRAIT"),
      description: t("Option.PORTRAIT.Description"),
    },
    {
      value: PhotographyTypes.PACKSHOT,
      label: t("Option.PACKSHOT"),
      description: t("Option.PACKSHOT.Description"),
    },
    {
      value: PhotographyTypes.STILL_LIFE,
      label: t("Option.STILL_LIFE"),
      description: t("Option.STILL_LIFE.Description"),
    },

    {
      value: PhotographyTypes.FASHION,
      label: t("Option.FASHION"),
      description: t("Option.FASHION.Description"),
    },
    {
      value: PhotographyTypes.STREET,
      label: t("Option.STREET"),
      description: t("Option.STREET.Description"),
    },
    {
      value: PhotographyTypes.ARCHITECTURE,
      label: t("Option.ARCHITECTURE"),
      description: t("Option.ARCHITECTURE.Description"),
    },
    {
      value: PhotographyTypes.CITYSCAPE,
      label: t("Option.CITYSCAPE"),
      description: t("Option.CITYSCAPE.Description"),
    },
    {
      value: PhotographyTypes.SEASCAPE,
      label: t("Option.SEASCAPE"),
      description: t("Option.SEASCAPE.Description"),
    },
    {
      value: PhotographyTypes.LANDSCAPE,
      label: t("Option.LANDSCAPE"),
      description: t("Option.LANDSCAPE.Description"),
    },
    {
      value: PhotographyTypes.NATURE,
      label: t("Option.NATURE"),
      description: t("Option.NATURE.Description"),
    },
    {
      value: PhotographyTypes.WILDLIFE,
      label: t("Option.WILDLIFE"),
      description: t("Option.WILDLIFE.Description"),
    },
    {
      value: PhotographyTypes.DOCUMENTARY,
      label: t("Option.DOCUMENTARY"),
      description: t("Option.DOCUMENTARY.Description"),
    },
    {
      value: PhotographyTypes.UNDERWATER,
      label: t("Option.UNDERWATER"),
      description: t("Option.UNDERWATER.Description"),
    },
    {
      value: PhotographyTypes.PANORAMIC,
      label: t("Option.PANORAMIC"),
      description: t("Option.PANORAMIC.Description"),
    },
    {
      value: PhotographyTypes.TIME_LAPSE,
      label: t("Option.TIME_LAPSE"),
      description: t("Option.TIME_LAPSE.Description"),
    },
    {
      value: PhotographyTypes.ASTROPHOTOGRAPHY,
      label: t("Option.ASTROPHOTOGRAPHY"),
      description: t("Option.ASTROPHOTOGRAPHY.Description"),
    },
    {
      value: PhotographyTypes.MACRO,
      label: t("Option.MACRO"),
      description: t("Option.MACRO.Description"),
    },
    {
      value: PhotographyTypes.MICRO,
      label: t("Option.MICRO"),
      description: t("Option.MICRO.Description"),
    },

    {
      value: PhotographyTypes.ACTION,
      label: t("Option.ACTION"),
      description: t("Option.ACTION.Description"),
    },
    {
      value: PhotographyTypes.AERIAL,
      label: t("Option.AERIAL"),
      description: t("Option.AERIAL.Description"),
    },
    /* {
      value: FxTypes.BLACK_AND_WHITE,
      label: t("Option.BLACK_AND_WHITE"),
      description: t("Option.BLACK_AND_WHITE.Description"),
    },
    {
      value: FxTypes.CYANOTYPE,
      label: t("Option.CYANOTYPE"),
      description: t("Option.CYANOTYPE.Description"),
    },
    {
      value: FxTypes.LOMOGRAPHY,
      label: t("Option.LOMOGRAPHY"),
      description: t("Option.LOMOGRAPHY.Description"),
    },

    {
      value: FxTypes.POLAROID,
      label: t("Option.POLAROID"),
      description: t("Option.POLAROID.Description"),
    },
    {
      value: FxTypes.INFRARED,
      label: t("Option.INFRARED"),
      description: t("Option.INFRARED.Description"),
    }, */
    /*     {
      value: PhotographyTypes.HOLGA,
      label: t("Option.HOLGA"),
      description: t("Option.HOLGA.Description"),
    }, 
    {
      value: PhotographyTypes.PINHOLE,
      label: t("Option.PINHOLE"),
      description: t("Option.PINHOLE.Description"),
    },*/
  ];
};

export const cinemaGenreOptions = (t: TFunction) => {
  return [
    {
      value: CinemaGenreTypes.ACTION,
      label: t("Option.GENRE_ACTION"),
      description: t("Option.GENRE_ACTION.Description"),
    },
    {
      value: CinemaGenreTypes.ADVENTURE,
      label: t("Option.GENRE_ADVENTURE"),
      description: t("Option.GENRE_ADVENTURE.Description"),
    },
    {
      value: CinemaGenreTypes.ANIMATED,
      label: t("Option.GENRE_ANIMATED"),
      description: t("Option.GENRE_ANIMATED.Description"),
    },
    {
      value: CinemaGenreTypes.COMEDY,
      label: t("Option.GENRE_COMEDY"),
      description: t("Option.GENRE_COMEDY.Description"),
    },
    {
      value: CinemaGenreTypes.DRAMA,
      label: t("Option.GENRE_DRAMA"),
      description: t("Option.GENRE_DRAMA.Description"),
    },
    {
      value: CinemaGenreTypes.FANTASY,
      label: t("Option.GENRE_FANTASY"),
      description: t("Option.GENRE_FANTASY.Description"),
    },
    {
      value: CinemaGenreTypes.HISTORICAL,
      label: t("Option.GENRE_HISTORICAL"),
      description: t("Option.GENRE_HISTORICAL.Description"),
    },
    {
      value: CinemaGenreTypes.HORROR,
      label: t("Option.GENRE_HORROR"),
      description: t("Option.GENRE_HORROR.Description"),
    },
    {
      value: CinemaGenreTypes.MUSICAL,
      label: t("Option.GENRE_MUSICAL"),
      description: t("Option.GENRE_MUSICAL.Description"),
    },
    {
      value: CinemaGenreTypes.NOIR,
      label: t("Option.GENRE_NOIR"),
      description: t("Option.GENRE_NOIR.Description"),
    },
    {
      value: CinemaGenreTypes.ROMANCE,
      label: t("Option.GENRE_ROMANCE"),
      description: t("Option.GENRE_ROMANCE.Description"),
    },
    {
      value: CinemaGenreTypes.SCIFI,
      label: t("Option.GENRE_SCIFI"),
      description: t("Option.GENRE_SCIFI.Description"),
    },
    {
      value: CinemaGenreTypes.THRILLER,
      label: t("Option.GENRE_THRILLER"),
      description: t("Option.GENRE_THRILLER.Description"),
    },
    {
      value: CinemaGenreTypes.WESTERN,
      label: t("Option.GENRE_WESTERN"),
      description: t("Option.GENRE_WESTERN.Description"),
    },
  ];
};

export const artTypeOptions = (t: TFunction) => {
  return [
    {
      value: ArtTypes.HYPER_REALISM,
      label: t("Option.HYPER_REALISM"),
      description: t("Option.HYPER_REALISM.Description"),
    },
    {
      value: ArtTypes.POST_MODERNISM,
      label: t("Option.POST_MODERNISM"),
      description: t("Option.POST_MODERNISM.Description"),
    },
    {
      value: ArtTypes.POP_ART,
      label: t("Option.POP_ART"),
      description: t("Option.POP_ART.Description"),
    },
    {
      value: ArtTypes.CONSTRUCTIVISM,
      label: t("Option.CONSTRUCTIVISM"),
      description: t("Option.CONSTRUCTIVISM.Description"),
    },
    {
      value: ArtTypes.CONCEPTUAL_ART,
      label: t("Option.CONCEPTUAL_ART"),
      description: t("Option.CONCEPTUAL_ART.Description"),
    },
    {
      value: ArtTypes.MINIMALISM,
      label: t("Option.MINIMALISM"),
      description: t("Option.MINIMALISM.Description"),
    },
    {
      value: ArtTypes.ABSTRACT,
      label: t("Option.ABSTRACT"),
      description: t("Option.ABSTRACT.Description"),
    },
    {
      value: ArtTypes.REALISM,
      label: t("Option.REALISM"),
      description: t("Option.REALISM.Description"),
    },
    {
      value: ArtTypes.SURREALISM,
      label: t("Option.SURREALISM"),
      description: t("Option.SURREALISM.Description"),
    },
    {
      value: ArtTypes.IMPRESSIONISM,
      label: t("Option.IMPRESSIONISM"),
      description: t("Option.IMPRESSIONISM.Description"),
    },
    {
      value: ArtTypes.EXPRESSIONISM,
      label: t("Option.EXPRESSIONISM"),
      description: t("Option.EXPRESSIONISM.Description"),
    },
    {
      value: ArtTypes.ART_DECO,
      label: t("Option.ART_DECO"),
      description: t("Option.ART_DECO.Description"),
    },
    {
      value: ArtTypes.ART_NOUVEAU,
      label: t("Option.ART_NOUVEAU"),
      description: t("Option.ART_NOUVEAU.Description"),
    },
    {
      value: ArtTypes.FAUVISM,
      label: t("Option.FAUVISM"),
      description: t("Option.FAUVISM.Description"),
    },
    {
      value: ArtTypes.FUTURISM,
      label: t("Option.FUTURISM"),
      description: t("Option.FUTURISM.Description"),
    },
    {
      value: ArtTypes.CUBISM,
      label: t("Option.CUBISM"),
      description: t("Option.CUBISM.Description"),
    },
    {
      value: ArtTypes.DADA,
      label: t("Option.DADA"),
      description: t("Option.DADA.Description"),
    },
  ];
};

export const artMediumOptions = (t: TFunction) => {
  return [
    {
      value: ArtMediumTypes.DIGITAL_ART,
      label: t("Option.DIGITAL_ART"),
      description: t("Option.DIGITAL_ART.Description"),
    },
    {
      value: ArtMediumTypes.DIGITAL_PAINTING,
      label: t("Option.DIGITAL_PAINTING"),
      description: t("Option.DIGITAL_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.DIGITAL_DRAWING,
      label: t("Option.DIGITAL_DRAWING"),
      description: t("Option.DIGITAL_DRAWING.Description"),
    },
    {
      value: ArtMediumTypes.WATERCOLOR_ART,
      label: t("Option.WATERCOLOR_ART"),
      description: t("Option.WATERCOLOR_ART.Description"),
    },
    {
      value: ArtMediumTypes.OIL_PAINTING,
      label: t("Option.OIL_PAINTING"),
      description: t("Option.OIL_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.ACRYLIC_PAINTING,
      label: t("Option.ACRYLIC_PAINTING"),
      description: t("Option.ACRYLIC_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.TEMPERA_PAINTING,
      label: t("Option.TEMPERA_PAINTING"),
      description: t("Option.TEMPERA_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.ENCAUSTIC_ART,
      label: t("Option.ENCAUSTIC_ART"),
      description: t("Option.ENCAUSTIC_ART.Description"),
    },
    {
      value: ArtMediumTypes.FRESCO_PAINTING,
      label: t("Option.FRESCO_PAINTING"),
      description: t("Option.FRESCO_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.INK_DRAWING,
      label: t("Option.INK_DRAWING"),
      description: t("Option.INK_DRAWING.Description"),
    },
    {
      value: ArtMediumTypes.PASTEL_ART,
      label: t("Option.PASTEL_ART"),
      description: t("Option.PASTEL_ART.Description"),
    },
    {
      value: ArtMediumTypes.CHARCOAL_DRAWING,
      label: t("Option.CHARCOAL_DRAWING"),
      description: t("Option.CHARCOAL_DRAWING.Description"),
    },
    {
      value: ArtMediumTypes.PENCIL_DRAWING,
      label: t("Option.PENCIL_DRAWING"),
      description: t("Option.PENCIL_DRAWING.Description"),
    },
    {
      value: ArtMediumTypes.POINTILLISM_PAINTING,
      label: t("Option.POINTILLISM_PAINTING"),
      description: t("Option.POINTILLISM_PAINTING.Description"),
    },
    {
      value: ArtMediumTypes.DIVISIONISM_PAINTING,
      label: t("Option.DIVISIONISM_PAINTING"),
      description: t("Option.DIVISIONISM_PAINTING.Description"),
    },
  ];
};

export const illustrationStyleOptions = (t: TFunction) => {
  return [
    {
      value: IllustrationGenreTypes.FLAT_DESIGN,
      label: t("Option.FLAT_DESIGN"),
      description: t("Option.FLAT_DESIGN.Description"),
    },
    {
      value: IllustrationGenreTypes.ANIME,
      label: t("Option.ANIME"),
      description: t("Option.ANIME.Description"),
    },
    {
      value: IllustrationGenreTypes.MANGA,
      label: t("Option.MANGA"),
      description: t("Option.MANGA.Description"),
    },
    {
      value: IllustrationGenreTypes.CARTOON,
      label: t("Option.CARTOON"),
      description: t("Option.CARTOON.Description"),
    },
    {
      value: IllustrationGenreTypes.COMIC_BOOK,
      label: t("Option.COMIC_BOOK"),
      description: t("Option.COMIC_BOOK.Description"),
    },
    // {
    //   value: IllustrationGenreTypes.FANTASY,
    //   label: t("Option.FANTASY"),
    //   description: t("Option.FANTASY.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.HORROR,
    //   label: t("Option.HORROR"),
    //   description: t("Option.HORROR.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.SCI_FI,
    //   label: t("Option.SCI_FI"),
    //   description: t("Option.SCI_FI.Description"),
    // },
    {
      value: IllustrationGenreTypes.PIXEL_ART,
      label: t("Option.PIXEL_ART"),
      description: t("Option.PIXEL_ART.Description"),
    },
    {
      value: IllustrationGenreTypes.LOW_POLY,
      label: t("Option.LOW_POLY"),
      description: t("Option.LOW_POLY.Description"),
    },
    {
      value: IllustrationGenreTypes.VAPORWAVE,
      label: t("Option.VAPORWAVE"),
      description: t("Option.VAPORWAVE.Description"),
    },
    {
      value: IllustrationGenreTypes.RETRO_FUTURISM,
      label: t("Option.RETRO_FUTURISM"),
      description: t("Option.RETRO_FUTURISM.Description"),
    },
    // {
    //   value: IllustrationGenreTypes.CYBERPUNK,
    //   label: t("Option.CYBERPUNK"),
    //   description: t("Option.CYBERPUNK.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.STEAMPUNK,
    //   label: t("Option.STEAMPUNK"),
    //   description: t("Option.STEAMPUNK.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.URBAN,
    //   label: t("Option.URBAN"),
    //   description: t("Option.URBAN.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.SPRAYCAN,
    //   label: t("Option.GRAFFITI"),
    //   description: t("Option.GRAFFITI.Description"),
    // },
    // {
    //   value: IllustrationGenreTypes.STREET_ART,
    //   label: t("Option.STREET_ART"),
    //   description: t("Option.STREET_ART.Description"),
    // },
    {
      value: IllustrationGenreTypes.GLITCH_ART,
      label: t("Option.GLITCH_ART"),
      description: t("Option.GLITCH_ART.Description"),
    },
    {
      value: IllustrationGenreTypes.UKIYOE,
      label: t("Option.UKIYOE"),
      description: t("Option.UKIYOE.Description"),
    },
  ];
};

export const illustrationArtistOptions = (t: TFunction) => {
  return [
    {
      value: IllustrationArtistTypes.SHEPARD_FAIREY,
      label: "Shepard Fairey",
      description: t("Option.SHEPARD_FAIREY.Description"),
    },
    {
      value: IllustrationArtistTypes.MOEBIUS,
      label: "Moebius",
      description: t("Option.MOEBIUS.Description"),
    },
    {
      value: IllustrationArtistTypes.HAYAO_MIYAZAKI,
      label: "Hayao Miyazaki",
      description: t("Option.HAYAO_MIYAZAKI.Description"),
    },
    {
      value: IllustrationArtistTypes.ENKI_BILAL,
      label: "Enki Bilal",
      description: t("Option.ENKI_BILAL.Description"),
    },
    {
      value: IllustrationArtistTypes.KATSUHIRO_OTOMO,
      label: "Katsuhiro Otomo",
      description: t("Option.KATSUHIRO_OTOMO.Description"),
    },
    {
      value: IllustrationArtistTypes.ALFONS_MUCHA,
      label: "Alfons Mucha",
      description: t("Option.ALFONS_MUCHA.Description"),
    },
    {
      value: IllustrationArtistTypes.QUENTIN_BLAKE,
      label: "Quentin Blake",
      description: t("Option.QUENTIN_BLAKE.Description"),
    },
    {
      value: IllustrationArtistTypes.JAMES_JEAN,
      label: "James Jean",
      description: t("Option.JAMES_JEAN.Description"),
    },
    {
      value: IllustrationArtistTypes.J_C_LEYENDECKER,
      label: "J. C. Leyendecker",
      description: t("Option.J_C_LEYENDECKER.Description"),
    },
    {
      value: IllustrationArtistTypes.NORMAN_ROCKWELL,
      label: "Norman Rockwell",
      description: t("Option.NORMAN_ROCKWELL.Description"),
    },
    {
      value: IllustrationArtistTypes.AUBREY_BEARDSLEY,
      label: "Aubrey Beardsley",
      description: t("Option.AUBREY_BEARDSLEY.Description"),
    },

    {
      value: IllustrationArtistTypes.STUDIO_GHIBLI,
      label: "Ghibli Studio",
      description: t("Option.STUDIO_GHIBLI.Description"),
    },
    {
      value: IllustrationArtistTypes.PIXAR,
      label: "Pixar Studio",
      description: t("Option.PIXAR.Description"),
    },
    {
      value: IllustrationArtistTypes.DISNEY_CLASSIC,
      label: "Disney Classic",
      description: t("Option.DISNEY_CLASSIC.Description"),
    },
  ];
};

export const artistStudioOptions = (t: TFunction) => {
  return [
    {
      value: ArtistsStudiosTypes.VINCENT_VAN_GOGH,
      label: t("Option.VINCENT_VAN_GOGH"),
      description: t("Option.VINCENT_VAN_GOGH.Description"),
    },
    {
      value: ArtistsStudiosTypes.PABLO_PICASSO,
      label: t("Option.PABLO_PICASSO"),
      description: t("Option.PABLO_PICASSO.Description"),
    },
    {
      value: ArtistsStudiosTypes.LEONARDO_DA_VINCI,
      label: t("Option.LEONARDO_DA_VINCI"),
      description: t("Option.LEONARDO_DA_VINCI.Description"),
    },
    {
      value: ArtistsStudiosTypes.SALVADOR_DALI,
      label: t("Option.SALVADOR_DALI"),
      description: t("Option.SALVADOR_DALI.Description"),
    },
    {
      value: ArtistsStudiosTypes.ANDY_WARHOL,
      label: t("Option.ANDY_WARHOL"),
      description: t("Option.ANDY_WARHOL.Description"),
    },
    {
      value: ArtistsStudiosTypes.FRIDA_KAHLO,
      label: t("Option.FRIDA_KAHLO"),
      description: t("Option.FRIDA_KAHLO.Description"),
    },
    {
      value: ArtistsStudiosTypes.BANKSY,
      label: t("Option.BANKSY"),
      description: t("Option.BANKSY.Description"),
    },
    {
      value: ArtistsStudiosTypes.JEAN_MICHEL_BASQUIAT,
      label: t("Option.JEAN_MICHEL_BASQUIAT"),
      description: t("Option.JEAN_MICHEL_BASQUIAT.Description"),
    },
    {
      value: ArtistsStudiosTypes.WASSILY_KANDINSKY,
      label: t("Option.WASSILY_KANDINSKY"),
      description: t("Option.WASSILY_KANDINSKY.Description"),
    },
    {
      value: ArtistsStudiosTypes.MAX_ERNST,
      label: t("Option.MAX_ERNST"),
      description: t("Option.MAX_ERNST.Description"),
    },
    {
      value: ArtistsStudiosTypes.NORMAN_ROCKWELL,
      label: t("Option.NORMAN_ROCKWELL"),
      description: t("Option.NORMAN_ROCKWELL.Description"),
    },
    {
      value: ArtistsStudiosTypes.JACKSON_POLLOCK,
      label: t("Option.JACKSON_POLLOCK"),
      description: t("Option.JACKSON_POLLOCK.Description"),
    },
    {
      value: ArtistsStudiosTypes.CLAUDE_MONET,
      label: t("Option.CLAUDE_MONET"),
      description: t("Option.CLAUDE_MONET.Description"),
    },
    {
      value: ArtistsStudiosTypes.HENRI_MATISSE,
      label: t("Option.HENRI_MATISSE"),
      description: t("Option.HENRI_MATISSE.Description"),
    },
    {
      value: ArtistsStudiosTypes.PAUL_KLEE,
      label: t("Option.PAUL_KLEE"),
      description: t("Option.PAUL_KLEE.Description"),
    },
    {
      value: ArtistsStudiosTypes.EDVARD_MUNCH,
      label: t("Option.EDVARD_MUNCH"),
      description: t("Option.EDVARD_MUNCH.Description"),
    },
    {
      value: ArtistsStudiosTypes.EDGAR_DEGAS,
      label: t("Option.EDGAR_DEGAS"),
      description: t("Option.EDGAR_DEGAS.Description"),
    },
    {
      value: ArtistsStudiosTypes.JOAN_MIRO,
      label: t("Option.JOAN_MIRO"),
      description: t("Option.JOAN_MIRO.Description"),
    },
    {
      value: ArtistsStudiosTypes.RENE_MAGRITTE,
      label: t("Option.RENE_MAGRITTE"),
      description: t("Option.RENE_MAGRITTE.Description"),
    },
    {
      value: ArtistsStudiosTypes.PAUL_GAUGUIN,
      label: t("Option.PAUL_GAUGUIN"),
      description: t("Option.PAUL_GAUGUIN.Description"),
    },
    {
      value: ArtistsStudiosTypes.HENRI_ROUSSEAU,
      label: t("Option.HENRI_ROUSSEAU"),
      description: t("Option.HENRI_ROUSSEAU.Description"),
    },
    {
      value: ArtistsStudiosTypes.GEORGIA_O_KEEFFE,
      label: t("Option.GEORGIA_O_KEEFFE"),
      description: t("Option.GEORGIA_O_KEEFFE.Description"),
    },
    {
      value: ArtistsStudiosTypes.EDWARD_HOPPER,
      label: t("Option.EDWARD_HOPPER"),
      description: t("Option.EDWARD_HOPPER.Description"),
    },
    {
      value: ArtistsStudiosTypes.MARK_ROTHKO,
      label: t("Option.MARK_ROTHKO"),
      description: t("Option.MARK_ROTHKO.Description"),
    },
    {
      value: ArtistsStudiosTypes.FRANCIS_BACON,
      label: t("Option.FRANCIS_BACON"),
      description: t("Option.FRANCIS_BACON.Description"),
    },
  ];
};

export const shotTypeOptions = (t: TFunction) => {
  return [
    {
      value: ShotTypes.CLOSE_UP,
      label: t("Option.CLOSE_UP"),
      description: t("Option.CLOSE_UP.Description"),
    },
    {
      value: ShotTypes.MEDIUM_SHOT,
      label: t("Option.MEDIUM_SHOT"),
      description: t("Option.MEDIUM_SHOT.Description"),
    },
    {
      value: ShotTypes.LONG_SHOT,
      label: t("Option.LONG_SHOT"),
      description: t("Option.LONG_SHOT.Description"),
    },
    {
      value: ShotTypes.EXTREME_LONG_SHOT,
      label: t("Option.EXTREME_LONG_SHOT"),
      description: t("Option.EXTREME_LONG_SHOT.Description"),
    },
    {
      value: ShotTypes.EXTREME_CLOSE_UP,
      label: t("Option.EXTREME_CLOSE_UP"),
      description: t("Option.EXTREME_CLOSE_UP.Description"),
    },
    {
      value: ShotTypes.BIRD_S_EYE_VIEW,
      label: t("Option.BIRD_S_EYE_VIEW"),
      description: t("Option.BIRD_S_EYE_VIEW.Description"),
    },
    {
      value: ShotTypes.HIGH_ANGLE_SHOT,
      label: t("Option.HIGH_ANGLE_SHOT"),
      description: t("Option.HIGH_ANGLE_SHOT.Description"),
    },
    {
      value: ShotTypes.LOW_ANGLE_SHOT,
      label: t("Option.LOW_ANGLE_SHOT"),
      description: t("Option.LOW_ANGLE_SHOT.Description"),
    },
    {
      value: ShotTypes.AERIAL_SHOT,
      label: t("Option.AERIAL_SHOT"),
      description: t("Option.AERIAL_SHOT.Description"),
    },
    {
      value: ShotTypes.DUTCH_ANGLE_SHOT,
      label: t("Option.DUTCH_ANGLE_SHOT"),
      description: t("Option.DUTCH_ANGLE_SHOT.Description"),
    },
    {
      value: ShotTypes.OVER_THE_SHOULDER_SHOT,
      label: t("Option.OVER_THE_SHOULDER_SHOT"),
      description: t("Option.OVER_THE_SHOULDER_SHOT.Description"),
    },
    {
      value: ShotTypes.POINT_OF_VIEW_SHOT,
      label: t("Option.POINT_OF_VIEW_SHOT"),
      description: t("Option.POINT_OF_VIEW_SHOT.Description"),
    },
    {
      value: ShotTypes.WIDE_SHOT,
      label: t("Option.WIDE_SHOT"),
      description: t("Option.WIDE_SHOT.Description"),
    },
    {
      value: ShotTypes.FULL_BODY_SHOT,
      label: t("Option.FULL_BODY_SHOT"),
      description: t("Option.FULL_BODY_SHOT.Description"),
    },
    {
      value: ShotTypes.GROUP_SHOT,
      label: t("Option.GROUP_SHOT"),
      description: t("Option.GROUP_SHOT.Description"),
    },
    {
      value: ShotTypes.PORTRAIT_SHOT,
      label: t("Option.PORTRAIT_SHOT"),
      description: t("Option.PORTRAIT_SHOT.Description"),
    },
    {
      value: ShotTypes.PANORAMIC_SHOT,
      label: t("Option.PANORAMIC_SHOT"),
      description: t("Option.PANORAMIC_SHOT.Description"),
    },
    {
      value: ShotTypes.TILT_SHOT,
      label: t("Option.TILT_SHOT"),
      description: t("Option.TILT_SHOT.Description"),
    },
  ];
};

export const lightingTypeOptions = (t: TFunction) => {
  return [
    {
      value: LightingTypes.NATURAL_LIGHTING,
      label: t("Option.NATURAL_LIGHTING"),
      description: t("Option.NATURAL_LIGHTING.Description"),
    },
    {
      value: LightingTypes.SUNLIGHT,
      label: t("Option.SUNLIGHT"),
      description: t("Option.SUNLIGHT.Description"),
    },
    {
      value: LightingTypes.MOONLIGHT,
      label: t("Option.MOONLIGHT"),
      description: t("Option.MOONLIGHT.Description"),
    },
    {
      value: LightingTypes.GOLDEN_HOUR,
      label: t("Option.GOLDEN_HOUR"),
      description: t("Option.GOLDEN_HOUR.Description"),
    },
    {
      value: LightingTypes.NORDIC_LIGHT,
      label: t("Option.NORDIC_LIGHT"),
      description: t("Option.NORDIC_LIGHT.Description"),
    },
    {
      value: LightingTypes.MORNING_LIGHT,
      label: t("Option.MORNING_LIGHT"),
      description: t("Option.MORNING_LIGHT.Description"),
    },
    {
      value: LightingTypes.NEON_LIGHT,
      label: t("Option.NEON_LIGHT"),
      description: t("Option.NEON_LIGHT.Description"),
    },
    {
      value: LightingTypes.VOLUMETRIC_LIGHTING,
      label: t("Option.VOLUMETRIC_LIGHTING"),
      description: t("Option.VOLUMETRIC_LIGHTING.Description"),
    },
    {
      value: LightingTypes.STUDIO_LIGHTING,
      label: t("Option.STUDIO_LIGHTING"),
      description: t("Option.STUDIO_LIGHTING.Description"),
    },
    {
      value: LightingTypes.HIGH_KEY_LIGHTING,
      label: t("Option.HIGH_KEY_LIGHTING"),
      description: t("Option.HIGH_KEY_LIGHTING.Description"),
    },
    {
      value: LightingTypes.LOW_KEY_LIGHTING,
      label: t("Option.LOW_KEY_LIGHTING"),
      description: t("Option.LOW_KEY_LIGHTING.Description"),
    },
    {
      value: LightingTypes.THREE_POINT_LIGHTING,
      label: t("Option.THREE_POINT_LIGHTING"),
      description: t("Option.THREE_POINT_LIGHTING.Description"),
    },
    {
      value: LightingTypes.REMBRANDT_LIGHTING,
      label: t("Option.REMBRANDT_LIGHTING"),
      description: t("Option.REMBRANDT_LIGHTING.Description"),
    },
    {
      value: LightingTypes.BUTTERFLY_LIGHTING,
      label: t("Option.BUTTERFLY_LIGHTING"),
      description: t("Option.BUTTERFLY_LIGHTING.Description"),
    },
    {
      value: LightingTypes.SPLIT_LIGHTING,
      label: t("Option.SPLIT_LIGHTING"),
      description: t("Option.SPLIT_LIGHTING.Description"),
    },
    {
      value: LightingTypes.LOOP_LIGHTING,
      label: t("Option.LOOP_LIGHTING"),
      description: t("Option.LOOP_LIGHTING.Description"),
    },
    {
      value: LightingTypes.SILHOUETTE_LIGHTING,
      label: t("Option.SILHOUETTE_LIGHTING"),
      description: t("Option.SILHOUETTE_LIGHTING.Description"),
    },
    {
      value: LightingTypes.RIM_LIGHTING,
      label: t("Option.RIM_LIGHTING"),
      description: t("Option.RIM_LIGHTING.Description"),
    },
    {
      value: LightingTypes.BACK_LIGHTING,
      label: t("Option.BACK_LIGHTING"),
      description: t("Option.BACK_LIGHTING.Description"),
    },
    {
      value: LightingTypes.SIDE_LIGHTING,
      label: t("Option.SIDE_LIGHTING"),
      description: t("Option.SIDE_LIGHTING.Description"),
    },
    {
      value: LightingTypes.HARD_LIGHTING,
      label: t("Option.HARD_LIGHTING"),
      description: t("Option.HARD_LIGHTING.Description"),
    },
    {
      value: LightingTypes.SOFT_LIGHTING,
      label: t("Option.SOFT_LIGHTING"),
      description: t("Option.SOFT_LIGHTING.Description"),
    },
  ];
};

export const fxTypeOptions = (t: TFunction) => {
  return [
    {
      value: FxTypes.MOTION_BLUR,
      label: t("Option.MOTION_BLUR"),
      description: t("Option.MOTION_BLUR.Description"),
    },
    {
      value: FxTypes.ZOOM_BLUR,
      label: t("Option.ZOOM_BLUR"),
      description: t("Option.ZOOM_BLUR.Description"),
    },
    {
      value: FxTypes.PANNING,
      label: t("Option.PANNING"),
      description: t("Option.PANNING.Description"),
    },
    {
      value: FxTypes.TILT_SHIFT,
      label: t("Option.TILT_SHIFT"),
      description: t("Option.TILT_SHIFT.Description"),
    },
    {
      value: FxTypes.LONG_EXPOSURE,
      label: t("Option.LONG_EXPOSURE"),
      description: t("Option.LONG_EXPOSURE.Description"),
    },
    {
      value: FxTypes.SHALLOW_DEPTH_OF_FIELD,
      label: t("Option.SHALLOW_DEPTH_OF_FIELD"),
      description: t("Option.SHALLOW_DEPTH_OF_FIELD.Description"),
    },
    {
      value: FxTypes.DEEP_DEPTH_OF_FIELD,
      label: t("Option.DEEP_DEPTH_OF_FIELD"),
      description: t("Option.DEEP_DEPTH_OF_FIELD.Description"),
    },

    {
      value: FxTypes.FISH_EYE,
      label: t("Option.FISH_EYE"),
      description: t("Option.FISH_EYE.Description"),
    },
    /*     {
      value: FxTypes.PHOTOGRAMS,
      label: t("Option.PHOTOGRAMS"),
      description: t("Option.PHOTOGRAMS.Description"),
    }, */
    {
      value: FxTypes.BLACK_AND_WHITE,
      label: t("Option.BLACK_AND_WHITE"),
      description: t("Option.BLACK_AND_WHITE.Description"),
    },
    {
      value: FxTypes.CYANOTYPE,
      label: t("Option.CYANOTYPE"),
      description: t("Option.CYANOTYPE.Description"),
    },
    {
      value: FxTypes.LOMOGRAPHY,
      label: t("Option.LOMOGRAPHY"),
      description: t("Option.LOMOGRAPHY.Description"),
    },

    {
      value: FxTypes.POLAROID,
      label: t("Option.POLAROID"),
      description: t("Option.POLAROID.Description"),
    },
    {
      value: FxTypes.INFRARED,
      label: t("Option.INFRARED"),
      description: t("Option.INFRARED.Description"),
    },
    {
      value: FxTypes.DOUBLE_EXPOSURE,
      label: t("Option.DOUBLE_EXPOSURE"),
      description: t("Option.DOUBLE_EXPOSURE.Description"),
    },
    {
      value: FxTypes.MIXED_MEDIA,
      label: t("Option.MIXED_MEDIA"),
      description: t("Option.MIXED_MEDIA.Description"),
    },
  ];
};
