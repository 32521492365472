export const deepCloneWithoutKey = (
  originalObj: any,
  keyToRemove: string
): any => {
  if (originalObj === null || typeof originalObj !== "object") {
    return originalObj;
  }

  if (Array.isArray(originalObj)) {
    return originalObj.map((item) => deepCloneWithoutKey(item, keyToRemove));
  }

  const newObj: any = {};
  for (const key in originalObj) {
    if (key !== keyToRemove) {
      newObj[key] = deepCloneWithoutKey(originalObj[key], keyToRemove);
    }
  }
  return newObj;
};
