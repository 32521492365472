import { gql } from "@apollo/client";
import { ProductionFragment } from "../fragments/production";

export const CREATE_PRODUCTION = gql`
  mutation createProduction($createProductionInput: CreateProductionInput!) {
    createProduction(createProductionInput: $createProductionInput) {
      ...Production
    }
  }
  ${ProductionFragment}
`;
