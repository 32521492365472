/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  fragment Account on Account {\n    id\n    type\n    status\n    name\n    sector\n    company\n    vatIdentifier\n    country\n    address\n    zip\n    city\n    invoicingEmail\n    users {\n      ...User\n    }\n    credit\n    subscriptionExpiration\n    stripeCustomerId\n    appleSubscriptionReceipt\n  }\n  \n": types.AccountFragmentDoc,
    "\n  fragment Asset on Asset {\n    id\n    type\n    name\n    jobId\n    jobDeliveryIndex\n    content\n    cdnId\n    additionalDetails\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n": types.AssetFragmentDoc,
    "\n  fragment CreditTrack on CreditTrack {\n    id\n    accountId\n    account {\n      ...Account\n    }\n    type\n    deliveredAssetId\n    amount\n    comment\n    createdAt\n    createdBy\n  }\n  \n": types.CreditTrackFragmentDoc,
    "\n  fragment Imagery on Imagery {\n    id\n    type\n    seeds {\n      id\n      delivery\n      asset\n    }\n    user\n    need\n    prompt\n    parametric\n    refUrl\n    progress {\n      percentage\n      description\n    }\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n": types.ImageryFragmentDoc,
    "\n  fragment Moodboard on Moodboard {\n    id\n    user\n    need {\n      subject\n      requirements\n      mood\n    }\n    prompt\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n": types.MoodboardFragmentDoc,
    "\n  fragment Production on Production {\n    id\n    type\n    name\n    sets {\n      support\n      formatName\n      format\n      parameters\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n": types.ProductionFragmentDoc,
    "\n  fragment Safe on Safe {\n    id\n    type\n    name\n    user\n    assets {\n      ...Asset\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n  \n": types.SafeFragmentDoc,
    "\n  fragment User on User {\n    id\n    role\n    # activated\n    email\n    firstName\n    lastName\n    language\n    phoneNumber\n    # optinSystem\n    # optinMarket\n    createdAt\n    updatedAt\n  }\n": types.UserFragmentDoc,
    "\n  mutation changePasswordRequest(\n    $changePasswordRequestInput: ChangePasswordRequestInput!\n  ) {\n    changePasswordRequest(\n      changePasswordRequestInput: $changePasswordRequestInput\n    )\n  }\n": types.ChangePasswordRequestDocument,
    "\n  mutation checkExistingUserEmail(\n    $checkExistingUserEmailInput: CheckExistingUserEmailInput!\n  ) {\n    checkExistingUserEmail(\n      checkExistingUserEmailInput: $checkExistingUserEmailInput\n    )\n  }\n": types.CheckExistingUserEmailDocument,
    "\n  mutation checkUserEmail($checkUserEmailInput: CheckUserEmailInput!) {\n    checkUserEmail(checkUserEmailInput: $checkUserEmailInput)\n  }\n": types.CheckUserEmailDocument,
    "\n  mutation createAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(createAccountInput: $createAccountInput) {\n      ...Account\n    }\n  }\n  \n": types.CreateAccountDocument,
    "\n  mutation createImagery($createImageryInput: CreateImageryInput!) {\n    createImagery(createImageryInput: $createImageryInput) {\n      ...Imagery\n    }\n  }\n  \n": types.CreateImageryDocument,
    "\n  mutation createProduction($createProductionInput: CreateProductionInput!) {\n    createProduction(createProductionInput: $createProductionInput) {\n      ...Production\n    }\n  }\n  \n": types.CreateProductionDocument,
    "\n  mutation createSafe($createSafeInput: CreateSafeInput!) {\n    createSafe(createSafeInput: $createSafeInput) {\n      ...Safe\n    }\n  }\n  \n": types.CreateSafeDocument,
    "\n  mutation createUser($createUserInput: CreateUserInput!) {\n    createUser(createUserInput: $createUserInput) {\n      ...User\n    }\n  }\n  \n": types.CreateUserDocument,
    "\n  mutation debitCredits($createCreditTrackInput: CreateCreditTrackInput!) {\n    debitCredits(createCreditTrackInput: $createCreditTrackInput) {\n      ...CreditTrack\n    }\n  }\n  \n": types.DebitCreditsDocument,
    "\n  mutation deleteProduction($productionId: String!) {\n    deleteProduction(productionId: $productionId)\n  }\n": types.DeleteProductionDocument,
    "\n  mutation deleteSafe($safeId: String!) {\n    deleteSafe(safeId: $safeId)\n  }\n": types.DeleteSafeDocument,
    "\n  mutation generateCatchlines(\n    $generateCatchlinesInput: GenerateCatchlinesInput!\n  ) {\n    generateCatchlines(generateCatchlinesInput: $generateCatchlinesInput) {\n      proposals\n    }\n  }\n": types.GenerateCatchlinesDocument,
    "\n  mutation generateFontFamilies(\n    $generateFontFamiliesInput: GenerateFontFamiliesInput!\n  ) {\n    generateFontFamilies(\n      generateFontFamiliesInput: $generateFontFamiliesInput\n    ) {\n      proposals\n    }\n  }\n": types.GenerateFontFamiliesDocument,
    "\n  mutation launchImagery($imageryInput: ImageryInput!) {\n    launchImagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n": types.LaunchImageryDocument,
    "\n  mutation removeAsset($removeAssetInput: RemoveAssetInput!) {\n    removeAsset(removeAssetInput: $removeAssetInput)\n  }\n": types.RemoveAssetDocument,
    "\n  mutation saveImage($saveImageFromUrlInput: SaveImageFromUrlInput!) {\n    saveImage(saveImageFromUrlInput: $saveImageFromUrlInput) {\n      ...Asset\n    }\n  }\n  \n": types.SaveImageDocument,
    "\n  mutation updateProduction($updateProductionInput: UpdateProductionInput!) {\n    updateProduction(updateProductionInput: $updateProductionInput)\n  }\n": types.UpdateProductionDocument,
    "\n  mutation updateUser($updateUserInput: UpdateUserInput!) {\n    updateUser(updateUserInput: $updateUserInput)\n  }\n": types.UpdateUserDocument,
    "\n  mutation uploadImage($uploadImageInput: UploadImageInput!) {\n    uploadImage(uploadImageInput: $uploadImageInput) {\n      ...Asset\n    }\n  }\n  \n": types.UploadImageDocument,
    "\n  query userAccounts($filter: FiltersAccountsInput!) {\n    userAccounts(filter: $filter) {\n      total\n      results {\n        ...Account\n      }\n    }\n  }\n  \n": types.UserAccountsDocument,
    "\n  query imagery($imageryInput: ImageryInput!) {\n    imagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n": types.ImageryDocument,
    "\n  query moodboard($moodboardInput: MoodboardInput!) {\n    moodboard(moodboardInput: $moodboardInput) {\n      ...Moodboard\n    }\n  }\n  \n": types.MoodboardDocument,
    "\n  query production($productionId: String!) {\n    production(productionId: $productionId) {\n      ...Production\n    }\n  }\n  \n": types.ProductionDocument,
    "\n  query productions($productionsFilterInput: ProductionsFilterInput!) {\n    productions(productionsFilterInput: $productionsFilterInput) {\n      total\n      results {\n        ...Production\n      }\n    }\n  }\n  \n": types.ProductionsDocument,
    "\n  query getUserSafe($safeId: String!) {\n    userSafe(safeId: $safeId) {\n      ...Safe\n    }\n  }\n  \n": types.GetUserSafeDocument,
    "\n  query userSafes($userSafesInput: UserSafesInput) {\n    userSafes(userSafesInput: $userSafesInput) {\n      total\n      results {\n        ...Safe\n      }\n    }\n  }\n  \n": types.UserSafesDocument,
    "\n  query auth($email: String!, $password: String!) {\n    auth(authInput: { email: $email, password: $password }) {\n      accessToken\n    }\n  }\n": types.AuthDocument,
    "\n  query userImagery($filter: FilterUserImageriesInput!) {\n    userImageries(filter: $filter) {\n      total\n      results {\n        ...Imagery\n      }\n    }\n  }\n  \n": types.UserImageryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Account on Account {\n    id\n    type\n    status\n    name\n    sector\n    company\n    vatIdentifier\n    country\n    address\n    zip\n    city\n    invoicingEmail\n    users {\n      ...User\n    }\n    credit\n    subscriptionExpiration\n    stripeCustomerId\n    appleSubscriptionReceipt\n  }\n  \n"): (typeof documents)["\n  fragment Account on Account {\n    id\n    type\n    status\n    name\n    sector\n    company\n    vatIdentifier\n    country\n    address\n    zip\n    city\n    invoicingEmail\n    users {\n      ...User\n    }\n    credit\n    subscriptionExpiration\n    stripeCustomerId\n    appleSubscriptionReceipt\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Asset on Asset {\n    id\n    type\n    name\n    jobId\n    jobDeliveryIndex\n    content\n    cdnId\n    additionalDetails\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"): (typeof documents)["\n  fragment Asset on Asset {\n    id\n    type\n    name\n    jobId\n    jobDeliveryIndex\n    content\n    cdnId\n    additionalDetails\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CreditTrack on CreditTrack {\n    id\n    accountId\n    account {\n      ...Account\n    }\n    type\n    deliveredAssetId\n    amount\n    comment\n    createdAt\n    createdBy\n  }\n  \n"): (typeof documents)["\n  fragment CreditTrack on CreditTrack {\n    id\n    accountId\n    account {\n      ...Account\n    }\n    type\n    deliveredAssetId\n    amount\n    comment\n    createdAt\n    createdBy\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Imagery on Imagery {\n    id\n    type\n    seeds {\n      id\n      delivery\n      asset\n    }\n    user\n    need\n    prompt\n    parametric\n    refUrl\n    progress {\n      percentage\n      description\n    }\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"): (typeof documents)["\n  fragment Imagery on Imagery {\n    id\n    type\n    seeds {\n      id\n      delivery\n      asset\n    }\n    user\n    need\n    prompt\n    parametric\n    refUrl\n    progress {\n      percentage\n      description\n    }\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Moodboard on Moodboard {\n    id\n    user\n    need {\n      subject\n      requirements\n      mood\n    }\n    prompt\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"): (typeof documents)["\n  fragment Moodboard on Moodboard {\n    id\n    user\n    need {\n      subject\n      requirements\n      mood\n    }\n    prompt\n    delivery {\n      type\n      asset\n      additionalDetails\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Production on Production {\n    id\n    type\n    name\n    sets {\n      support\n      formatName\n      format\n      parameters\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"): (typeof documents)["\n  fragment Production on Production {\n    id\n    type\n    name\n    sets {\n      support\n      formatName\n      format\n      parameters\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Safe on Safe {\n    id\n    type\n    name\n    user\n    assets {\n      ...Asset\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n  \n"): (typeof documents)["\n  fragment Safe on Safe {\n    id\n    type\n    name\n    user\n    assets {\n      ...Asset\n    }\n    createdAt\n    createdBy\n    updatedAt\n    updatedBy\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment User on User {\n    id\n    role\n    # activated\n    email\n    firstName\n    lastName\n    language\n    phoneNumber\n    # optinSystem\n    # optinMarket\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment User on User {\n    id\n    role\n    # activated\n    email\n    firstName\n    lastName\n    language\n    phoneNumber\n    # optinSystem\n    # optinMarket\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation changePasswordRequest(\n    $changePasswordRequestInput: ChangePasswordRequestInput!\n  ) {\n    changePasswordRequest(\n      changePasswordRequestInput: $changePasswordRequestInput\n    )\n  }\n"): (typeof documents)["\n  mutation changePasswordRequest(\n    $changePasswordRequestInput: ChangePasswordRequestInput!\n  ) {\n    changePasswordRequest(\n      changePasswordRequestInput: $changePasswordRequestInput\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation checkExistingUserEmail(\n    $checkExistingUserEmailInput: CheckExistingUserEmailInput!\n  ) {\n    checkExistingUserEmail(\n      checkExistingUserEmailInput: $checkExistingUserEmailInput\n    )\n  }\n"): (typeof documents)["\n  mutation checkExistingUserEmail(\n    $checkExistingUserEmailInput: CheckExistingUserEmailInput!\n  ) {\n    checkExistingUserEmail(\n      checkExistingUserEmailInput: $checkExistingUserEmailInput\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation checkUserEmail($checkUserEmailInput: CheckUserEmailInput!) {\n    checkUserEmail(checkUserEmailInput: $checkUserEmailInput)\n  }\n"): (typeof documents)["\n  mutation checkUserEmail($checkUserEmailInput: CheckUserEmailInput!) {\n    checkUserEmail(checkUserEmailInput: $checkUserEmailInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(createAccountInput: $createAccountInput) {\n      ...Account\n    }\n  }\n  \n"): (typeof documents)["\n  mutation createAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(createAccountInput: $createAccountInput) {\n      ...Account\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createImagery($createImageryInput: CreateImageryInput!) {\n    createImagery(createImageryInput: $createImageryInput) {\n      ...Imagery\n    }\n  }\n  \n"): (typeof documents)["\n  mutation createImagery($createImageryInput: CreateImageryInput!) {\n    createImagery(createImageryInput: $createImageryInput) {\n      ...Imagery\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createProduction($createProductionInput: CreateProductionInput!) {\n    createProduction(createProductionInput: $createProductionInput) {\n      ...Production\n    }\n  }\n  \n"): (typeof documents)["\n  mutation createProduction($createProductionInput: CreateProductionInput!) {\n    createProduction(createProductionInput: $createProductionInput) {\n      ...Production\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createSafe($createSafeInput: CreateSafeInput!) {\n    createSafe(createSafeInput: $createSafeInput) {\n      ...Safe\n    }\n  }\n  \n"): (typeof documents)["\n  mutation createSafe($createSafeInput: CreateSafeInput!) {\n    createSafe(createSafeInput: $createSafeInput) {\n      ...Safe\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUser($createUserInput: CreateUserInput!) {\n    createUser(createUserInput: $createUserInput) {\n      ...User\n    }\n  }\n  \n"): (typeof documents)["\n  mutation createUser($createUserInput: CreateUserInput!) {\n    createUser(createUserInput: $createUserInput) {\n      ...User\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation debitCredits($createCreditTrackInput: CreateCreditTrackInput!) {\n    debitCredits(createCreditTrackInput: $createCreditTrackInput) {\n      ...CreditTrack\n    }\n  }\n  \n"): (typeof documents)["\n  mutation debitCredits($createCreditTrackInput: CreateCreditTrackInput!) {\n    debitCredits(createCreditTrackInput: $createCreditTrackInput) {\n      ...CreditTrack\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteProduction($productionId: String!) {\n    deleteProduction(productionId: $productionId)\n  }\n"): (typeof documents)["\n  mutation deleteProduction($productionId: String!) {\n    deleteProduction(productionId: $productionId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteSafe($safeId: String!) {\n    deleteSafe(safeId: $safeId)\n  }\n"): (typeof documents)["\n  mutation deleteSafe($safeId: String!) {\n    deleteSafe(safeId: $safeId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation generateCatchlines(\n    $generateCatchlinesInput: GenerateCatchlinesInput!\n  ) {\n    generateCatchlines(generateCatchlinesInput: $generateCatchlinesInput) {\n      proposals\n    }\n  }\n"): (typeof documents)["\n  mutation generateCatchlines(\n    $generateCatchlinesInput: GenerateCatchlinesInput!\n  ) {\n    generateCatchlines(generateCatchlinesInput: $generateCatchlinesInput) {\n      proposals\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation generateFontFamilies(\n    $generateFontFamiliesInput: GenerateFontFamiliesInput!\n  ) {\n    generateFontFamilies(\n      generateFontFamiliesInput: $generateFontFamiliesInput\n    ) {\n      proposals\n    }\n  }\n"): (typeof documents)["\n  mutation generateFontFamilies(\n    $generateFontFamiliesInput: GenerateFontFamiliesInput!\n  ) {\n    generateFontFamilies(\n      generateFontFamiliesInput: $generateFontFamiliesInput\n    ) {\n      proposals\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation launchImagery($imageryInput: ImageryInput!) {\n    launchImagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n"): (typeof documents)["\n  mutation launchImagery($imageryInput: ImageryInput!) {\n    launchImagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeAsset($removeAssetInput: RemoveAssetInput!) {\n    removeAsset(removeAssetInput: $removeAssetInput)\n  }\n"): (typeof documents)["\n  mutation removeAsset($removeAssetInput: RemoveAssetInput!) {\n    removeAsset(removeAssetInput: $removeAssetInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveImage($saveImageFromUrlInput: SaveImageFromUrlInput!) {\n    saveImage(saveImageFromUrlInput: $saveImageFromUrlInput) {\n      ...Asset\n    }\n  }\n  \n"): (typeof documents)["\n  mutation saveImage($saveImageFromUrlInput: SaveImageFromUrlInput!) {\n    saveImage(saveImageFromUrlInput: $saveImageFromUrlInput) {\n      ...Asset\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProduction($updateProductionInput: UpdateProductionInput!) {\n    updateProduction(updateProductionInput: $updateProductionInput)\n  }\n"): (typeof documents)["\n  mutation updateProduction($updateProductionInput: UpdateProductionInput!) {\n    updateProduction(updateProductionInput: $updateProductionInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUser($updateUserInput: UpdateUserInput!) {\n    updateUser(updateUserInput: $updateUserInput)\n  }\n"): (typeof documents)["\n  mutation updateUser($updateUserInput: UpdateUserInput!) {\n    updateUser(updateUserInput: $updateUserInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadImage($uploadImageInput: UploadImageInput!) {\n    uploadImage(uploadImageInput: $uploadImageInput) {\n      ...Asset\n    }\n  }\n  \n"): (typeof documents)["\n  mutation uploadImage($uploadImageInput: UploadImageInput!) {\n    uploadImage(uploadImageInput: $uploadImageInput) {\n      ...Asset\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userAccounts($filter: FiltersAccountsInput!) {\n    userAccounts(filter: $filter) {\n      total\n      results {\n        ...Account\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query userAccounts($filter: FiltersAccountsInput!) {\n    userAccounts(filter: $filter) {\n      total\n      results {\n        ...Account\n      }\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query imagery($imageryInput: ImageryInput!) {\n    imagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n"): (typeof documents)["\n  query imagery($imageryInput: ImageryInput!) {\n    imagery(imageryInput: $imageryInput) {\n      ...Imagery\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query moodboard($moodboardInput: MoodboardInput!) {\n    moodboard(moodboardInput: $moodboardInput) {\n      ...Moodboard\n    }\n  }\n  \n"): (typeof documents)["\n  query moodboard($moodboardInput: MoodboardInput!) {\n    moodboard(moodboardInput: $moodboardInput) {\n      ...Moodboard\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query production($productionId: String!) {\n    production(productionId: $productionId) {\n      ...Production\n    }\n  }\n  \n"): (typeof documents)["\n  query production($productionId: String!) {\n    production(productionId: $productionId) {\n      ...Production\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query productions($productionsFilterInput: ProductionsFilterInput!) {\n    productions(productionsFilterInput: $productionsFilterInput) {\n      total\n      results {\n        ...Production\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query productions($productionsFilterInput: ProductionsFilterInput!) {\n    productions(productionsFilterInput: $productionsFilterInput) {\n      total\n      results {\n        ...Production\n      }\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserSafe($safeId: String!) {\n    userSafe(safeId: $safeId) {\n      ...Safe\n    }\n  }\n  \n"): (typeof documents)["\n  query getUserSafe($safeId: String!) {\n    userSafe(safeId: $safeId) {\n      ...Safe\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userSafes($userSafesInput: UserSafesInput) {\n    userSafes(userSafesInput: $userSafesInput) {\n      total\n      results {\n        ...Safe\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query userSafes($userSafesInput: UserSafesInput) {\n    userSafes(userSafesInput: $userSafesInput) {\n      total\n      results {\n        ...Safe\n      }\n    }\n  }\n  \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query auth($email: String!, $password: String!) {\n    auth(authInput: { email: $email, password: $password }) {\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  query auth($email: String!, $password: String!) {\n    auth(authInput: { email: $email, password: $password }) {\n      accessToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userImagery($filter: FilterUserImageriesInput!) {\n    userImageries(filter: $filter) {\n      total\n      results {\n        ...Imagery\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query userImagery($filter: FilterUserImageriesInput!) {\n    userImageries(filter: $filter) {\n      total\n      results {\n        ...Imagery\n      }\n    }\n  }\n  \n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;