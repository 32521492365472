import styled from "styled-components";

const colors = {
  black: "#000000",
  color200: "#D3D3D3",
  white: "#FFFFFF",
  blue: "#0000FF",
  color600: "#7C7C7C",
  color50: "#F1F1F1",
};

const DesktopContainer = styled.div`
  background-color: ${colors.white};
  height: 2160px;
  position: relative;
  width: 1440px;
`;

const Footer = styled.div`
  background-color: ${colors.color50};
  height: 320px;
  left: 0;
  position: absolute;
  top: 1840px;
  width: 1440px;
`;

const StackTitleTxtImg = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 167px;
  position: absolute;
  top: 1376px;
`;

const Img = styled.div`
  background-color: ${colors.color50};
  height: 400px;
  position: relative;
  width: 544px;
`;

const TitleTxt = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
`;

const Title = styled.div`
  color: ${colors.blue};
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 544px;
`;

const Txt = styled.p`
  color: ${colors.black};
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 432px;
`;

const Div = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 167px;
  position: absolute;
  top: 944px;
`;

const TextWrapper = styled.p`
  color: ${colors.black};
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 280px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 803px;
  width: 880px;
`;

const OverlapGroup = styled.div`
  height: 740px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
`;

const Header = styled.header`
  background-color: ${colors.color50};
  height: 740px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
`;

const TagTitleTxtBtn = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 168px;
  position: relative;
  top: 298px;
`;

const TagTitleTxt = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
`;

const Tag = styled.div`
  align-items: flex-start;
  border: 1px solid ${colors.color600};
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 4px 12px;
  position: relative;
`;

const Txt2 = styled.div`
  color: ${colors.blue};
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
`;

const Title2 = styled.div`
  color: ${colors.blue};
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 768px;
`;

const P = styled.p`
  color: ${colors.black};
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 768px;
`;

const Btn = styled.button`
  all: unset;
  align-items: center;
  background-color: ${colors.blue};
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
`;

const TextWrapper2 = styled.div`
  color: ${colors.white};
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
`;

const Menu = styled.div`
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
`;

const Logo = styled.div`
  background-color: ${colors.color200};
  border-radius: 8px;
  height: 48px;
  left: 56px;
  position: relative;
  top: 8px;
  width: 208px;
`;

export const LandingPage = () => {
  return (
    <DesktopContainer>
      <Footer />
      <StackTitleTxtImg>
        <Img />
        <TitleTxt>
          <Title>
            Lorem ipsum
            <br />
            dolor simet
          </Title>
          <Txt>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </Txt>
        </TitleTxt>
      </StackTitleTxtImg>
      <Div>
        <TitleTxt>
          <Title>
            Lorem ipsum
            <br />
            dolor simet
          </Title>
          <Txt>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </Txt>
        </TitleTxt>
        <Img />
      </Div>
      <TextWrapper>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt.
      </TextWrapper>
      <OverlapGroup>
        <Header>
          <TagTitleTxtBtn>
            <TagTitleTxt>
              <Tag>
                <Txt2>Lorem ipsum</Txt2>
              </Tag>
              <Title2>
                Lorem ipsum
                <br />
                dolor simet consectetur
              </Title2>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </TagTitleTxt>
            <Btn>
              <TextWrapper2>LABEL</TextWrapper2>
            </Btn>
          </TagTitleTxtBtn>
        </Header>
        <Menu>
          <Logo />
        </Menu>
      </OverlapGroup>
    </DesktopContainer>
  );
};
