import gql from "graphql-tag";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const ImageryFragment = gql`
  fragment Imagery on Imagery {
    id
    type
    seeds {
      id
      delivery
      asset
    }
    user
    need
    prompt
    parametric
    refUrl
    progress {
      percentage
      description
    }
    delivery {
      type
      asset
      additionalDetails
    }

    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
