import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../styles/colors";

interface Props {
  value?: number;
  setValue: (value: number) => void;
  slow?: number;
  decimals?: number;
}

export const DragIncrementer: React.FC<Props> = ({
  value = 0,
  setValue,
  slow = 4,
  decimals = 0,
}) => {
  const [snapshot, setSnapshot] = useState(value);
  const [startVal, setStartVal] = useState(0);

  const onStart = useCallback(
    (event: any) => {
      setStartVal(event.clientX);
      setSnapshot(value);
    },
    [value]
  );

  useEffect(() => {
    const onUpdate = (event: any) => {
      if (startVal) {
        setValue(
          +(snapshot + (event.clientX - startVal) / slow).toFixed(decimals)
        );
      }
    };

    const onEnd = () => {
      setStartVal(0);
    };

    document.addEventListener("mousemove", onUpdate);
    document.addEventListener("mouseup", onEnd);

    return () => {
      document.removeEventListener("mousemove", onUpdate);
      document.removeEventListener("mouseup", onEnd);
    };
  }, [startVal, setValue, snapshot, slow, decimals]);

  return <Container onMouseDown={onStart} />;
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 11px;
  background-color: ${COLORS.darkgray};
  cursor: ew-resize;
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: ${COLORS.lightgray};
  }
`;
