import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../styles/colors";
import { Text } from "../text/Text";

// type TextType = "alert" | "classic" | "disabled" | "validation" | "obsolete";

// export const TextFieldAdapter: React.FC<FieldRenderProps<string>> = () => {
export const TextField: React.FC<any> = ({
  error,
  editable,
  disabled = false,
  secure,
  label,
  placeholder,
  darkMode = false,
  helperText,
  style,
  multiline,
  onChange,
  onFocus,
  initialValue,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (initialValue) setInputValue(initialValue);
  }, [initialValue]);

  return (
    <Container style={style}>
      <Text
        text={label}
        darkMode={darkMode}
        type={disabled ? "disabled" : "classic"}
      />
      <InputContainer editable={editable}>
        {multiline ? (
          <TextArea
            darkMode={darkMode}
            aria-label={label}
            placeholder={placeholder}
            autoComplete="off"
            security={secure}
            required
            cols={40}
            rows={multiline}
            disabled={disabled}
            editable={editable}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={onFocus}
          />
        ) : (
          <Input
            darkMode={darkMode}
            type="text"
            aria-label={label}
            placeholder={placeholder}
            autoComplete="off"
            security={secure}
            required
            disabled={disabled}
            editable={editable}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={onFocus}
          />
        )}
      </InputContainer>
      {/* <Line
        disabled={!editable || disabled}
        darkMode={darkMode}
        type={error ? "alert" : "classic"}
      /> */}
      {(helperText || error) && (
        <Helper>
          {error && (
            <Text
              title={error}
              type={disabled || !editable ? "disabled" : "alert"}
            />
          )}
          <Text
            text={helperText}
            darkMode={darkMode}
            type={disabled || !editable ? "disabled" : "classic"}
          />
        </Helper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const Helper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  padding: 5px 0px;
`;
const InputContainer = styled.div<{
  editable?: boolean;
}>`
  --bgColor: ${(props) => {
    if (props.editable) return `${COLORS.darkgray}`;
    return COLORS.darkgray;
  }};
  --opacity: ${(props) => {
    if (props.editable) return `1`;
    return `0.3`;
  }};
  opacity: var(--opacity);
  background: var(--bgColor);
  padding-right: 15px;
`;

const Input = styled.input<{
  darkMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
}>`
  --color: ${(props) => {
    if (props.disabled && !props.editable) return COLORS.lightgray;
    if (props.disabled && props.editable)
      return props.darkMode ? COLORS.darkgray : COLORS.lightgray;
    return props.darkMode ? COLORS.white : COLORS.black;
  }};

  display: flex;
  width: 100%;
  color: var(--color);
  font-size: 14px;
  font-weight: 900;
  padding: 13px 0 15px 15px;
  background-color: transparent;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  margin-top: 7px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 1; /* Firefox */
  }
`;
const TextArea = styled.textarea<{
  darkMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
}>`
  display: flex;
  width: 100%;
  --color: ${(props) => {
    if (props.disabled && !props.editable) return COLORS.lightgray;
    if (props.disabled && props.editable)
      return props.darkMode ? COLORS.darkgray : COLORS.lightgray;
    return props.darkMode ? COLORS.white : COLORS.black;
  }};
  color: var(--color);
  font-size: 14px;
  font-weight: 900;
  padding: 13px 0 15px 15px;
  background-color: transparent;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  margin-top: 7px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 1; /* Firefox */
  }
`;

// const Line = styled.div<{
//   disabled: boolean;
//   darkMode: boolean;
//   type: TextType;
// }>`
//   --color: ${(props) => {
//     if (props.disabled) return COLORS.mediumgray;
//     return props.type === "alert"
//       ? COLORS.red
//       : props.type === "validation"
//       ? COLORS.green
//       : props.darkMode
//       ? COLORS.white
//       : COLORS.black;
//   }};
//   border-color: var(--color);
//   border-bottom-width: 0.1px;
//   border-style: solid;
// `;
