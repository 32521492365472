export const en = {
  "Welcome.Question.0":
    "Welcome to SoixanteSeize AI Experimentation. An account is required to continue. Do you have one?",
  "Welcome.Question.0.option.0": "Yes, let me log in",
  "Welcome.Question.0.option.1": "No, let me create one",

  "Login.Question.0": "Enter your email",
  "Login.Question.1": "and your password",
  "Login.Question.2": "Congratulations, you're in!",

  // BUTTONS
  "Button.Retry": "Retry",

  // SUCCESS
  "Success.Message.UserCreated":
    "Your credentials have been created. We have sent you an email to confirm your email.",

  // REGISTER
  "Register.Question.0": "What is your first name?",
  "Register.Question.1": "and your last name?",
  "Register.Question.2": "Your phone number, please...",
  "Register.Question.3": "Your email...",
  "Register.Question.4": "And choose a password.",
  "Register.Question.5": "Please confirm your password.",

  // VALIDATORS
  "Validator.Message.Invalid": "This email seems invalid. Please correct.",
  "Validator.Message.NotSamePassword":
    "Passwords are not the same. Please try again.",
  "Validator.Message.PhoneNumber":
    "This phone number seems invalid. Please correct.",
  "Validator.Message.Password":
    "Password must contain at least single digit from 1 to 9, one lowercase letter, one uppercase letter and one special character.",
  "Validator.Message.Min8Char": "Must be at least 8 characters long.",
  "Validator.Message.Max16Char": "Must be at most 16 characters long.",
  "Validator.Message.Max20Char": "Must be at most 20 characters long.",
  "Validator.Message.Required": "This is required.",

  // ERRORS
  "Error.Message.FailedToFetch":
    "A network error occured. Please try again after checking your connection",
  "Error.Message.EmailAlreadyExists": "This email already exists.",
  "Error.Message.Occured": "An error occured.",
};
