import { snakeCase } from "lodash";

export const getPromptObject = (prompt: string) => {
  if (!prompt) return {};
  const formattedPrompt = prompt
    .replace(/\n/g, "")
    .replace(/\./g, "")
    .replace(/"/g, "");
  console.log(formattedPrompt);
  const splitPrompt = formattedPrompt.split(" | ");
  console.log(splitPrompt);
  splitPrompt.splice(0, 1, "SCENE: " + splitPrompt[0]);
  const splitParsed = splitPrompt.map((keyValue: any) => keyValue.split(": "));
  console.log(splitParsed);

  const promptObject = splitParsed.reduce(
    (acc: any, [key, value]: [string, string]) => {
      acc[snakeCase(key).toUpperCase()] = value;
      return acc;
    },
    {}
  );

  return promptObject;
};
