import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { BREAKPOINTS } from "../../../styles/breakpoints";
import { COLORS } from "../../../styles/colors";

// type TextType = "alert" | "classic" | "disabled" | "validation" | "obsolete";

const SelectField: React.FC<any> = ({
  options,
  firstOptionAsDefaultValue = false,
  darkMode = false,
  style,
  editable,
  onChange,
  initialValue,
  defaultOption,
}) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(initialValue || "");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setSelectedValue(initialValue || "");
  }, [initialValue]);

  return (
    <Container style={style}>
      <InputContainer editable={editable}>
        <Select
          darkMode={darkMode}
          onChange={handleSelectChange}
          value={selectedValue}
        >
          {!firstOptionAsDefaultValue && (
            <Option value="">
              {defaultOption ?? t("Option.Default").toUpperCase()}
            </Option>
          )}
          {options.map((option: { value: any; label: string }, key: number) => (
            <Option key={key} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: 100%;
  }
`;
const InputContainer = styled.div<{
  editable?: boolean;
}>`
  --bgColor: ${(props) => {
    if (props.editable) return `${COLORS.white}`;
    return COLORS.soixanteseize;
  }};
  background: var(--bgColor);
  padding: 16px 20px 14px 20px;
  border-radius: 10px;
`;

const Select = styled.select<{
  darkMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
}>`
  display: flex;
  width: 100%;
  --color: ${(props) => {
    if (props.disabled && props.editable)
      return props.darkMode ? COLORS.darkgray : COLORS.lightgray;
    return props.darkMode ? COLORS.white : COLORS.black;
  }};

  color: var(--color);
  background-color: ${COLORS.soixanteseize};
  font-size: 1rem;
  line-height: 1;
  font-family: "HelveticaNeueLTPro-Bd";
  background-color: transparent;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color);
    opacity: 1; /* Firefox */
  }
`;

const Option = styled.option`
  color: var(--color);
  font-size: 1rem;
  font-family: "HelveticaNeueLTPro-Bd";
  line-height: 3;
  padding: 5px 0px 5px 40px;
`;

export default SelectField;
