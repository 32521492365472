import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS } from "../../libs/styles/colors";

export interface IPositionSelectorProps {
  darkMode: boolean;
  position: {
    horizontal: "flex-end" | "flex-start" | "center";
    vertical: "flex-end" | "flex-start" | "center";
    textAlign: "left" | "center" | "right";
  };
  setPosition: (position: {
    horizontal: "flex-end" | "flex-start" | "center";
    vertical: "flex-end" | "flex-start" | "center";
    textAlign: "left" | "center" | "right";
  }) => void;
}

export const PositionSelector: FunctionComponent<IPositionSelectorProps> = ({
  darkMode,
  position,
  setPosition,
}) => {
  const icons = [
    {
      alighPosition: "flex-start",
      selected:
        position.horizontal === "flex-start" &&
        position.vertical === "flex-start",
      onClick: () =>
        setPosition({
          horizontal: "flex-start",
          vertical: "flex-start",
          textAlign: "left",
        }),
    },
    {
      alighPosition: "center",
      selected:
        position.horizontal === "center" && position.vertical === "flex-start",
      onClick: () =>
        setPosition({
          horizontal: "center",
          vertical: "flex-start",
          textAlign: "center",
        }),
    },
    {
      alighPosition: "flex-end",
      selected:
        position.horizontal === "flex-end" &&
        position.vertical === "flex-start",
      onClick: () =>
        setPosition({
          horizontal: "flex-end",
          vertical: "flex-start",
          textAlign: "right",
        }),
    },
    {
      alighPosition: "flex-start",
      selected:
        position.horizontal === "flex-start" && position.vertical === "center",
      onClick: () =>
        setPosition({
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        }),
    },
    {
      alighPosition: "center",
      selected:
        position.horizontal === "center" && position.vertical === "center",
      onClick: () =>
        setPosition({
          horizontal: "center",
          vertical: "center",
          textAlign: "center",
        }),
    },
    {
      alighPosition: "flex-end",
      selected:
        position.horizontal === "flex-end" && position.vertical === "center",
      onClick: () =>
        setPosition({
          horizontal: "flex-end",
          vertical: "center",
          textAlign: "right",
        }),
    },
    {
      alighPosition: "flex-start",
      selected:
        position.horizontal === "flex-start" &&
        position.vertical === "flex-end",
      onClick: () =>
        setPosition({
          horizontal: "flex-start",
          vertical: "flex-end",
          textAlign: "left",
        }),
    },
    {
      alighPosition: "center",
      selected:
        position.horizontal === "center" && position.vertical === "flex-end",
      onClick: () =>
        setPosition({
          horizontal: "center",
          vertical: "flex-end",
          textAlign: "center",
        }),
    },
    {
      alighPosition: "flex-end",
      selected:
        position.horizontal === "flex-end" && position.vertical === "flex-end",
      onClick: () =>
        setPosition({
          horizontal: "flex-end",
          vertical: "flex-end",
          textAlign: "right",
        }),
    },
  ];

  return (
    <Container>
      {icons.map((icon, i) => (
        <Item
          key={i}
          onClick={() => !icon.selected && icon.onClick()}
          selected={icon.selected}
          alignPosition={icon.alighPosition}
        >
          <div>
            <span />
            <span />
            <span />
          </div>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 5px;
  border: 1px solid ${COLORS.darkgray};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
`;

const Item = styled.div<{
  selected: boolean;
  alignPosition: string;
}>`
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    height: 4px;
    width: 4px;
    background-color: ${COLORS.darkgray};
    border-radius: 9999px;
  }

  div {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignPosition};
    row-gap: 4px;
    color: ${(props) => (props.selected ? COLORS.lightgray : "transparent")};

    &:hover {
      color: ${(props) =>
        props.selected ? COLORS.lightgray : COLORS.darkgray};
    }

    span {
      display: block;
      background-color: currentColor;

      &:nth-child(1),
      &:nth-child(3) {
        width: 60%;
        height: 25%;
      }

      &:nth-child(2) {
        width: 100%;
        height: 30%;
      }
    }
  }
`;
