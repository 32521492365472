import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  faEye,
  faMagicWandSparkles,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { find } from "lodash";
import moment from "moment";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Account, User } from "../../graphql/generated/graphql";
import { CREATE_IMAGERY } from "../../graphql/mutations/createImagery";
import { LAUNCH_IMAGERY } from "../../graphql/mutations/launchImagery";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import {
  illustrationStyleOptions,
  imageTypeOptions,
  photoTypeOptions,
} from "../../libs/constants/options";
import { errorMessage } from "../../libs/errors/errorMessage";
import { COLORS } from "../../libs/styles/colors";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";

export interface IBotProps {
  darkMode: boolean;
}

export const BotImagery: FunctionComponent<IBotProps> = ({ darkMode }) => {
  //
  localStorage.removeItem("redirect");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const paramCodeValue = searchParams.get("code");

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);
  const [image, setImage] = useState<string>("");
  const [type, setType] = useState<string | undefined>(undefined);
  // const [inspiration, setInspiration] = useState<string | undefined>(undefined);
  const [style, setStyle] = useState<string | undefined>(undefined);
  const [scene, setScene] = useState<string>("");
  // const [shotType, setShotType] = useState<string>("");

  /**********************************************************
   *** INITIAL IMAGERY ***
   **********************************************************/

  const [initialImagery, setInitialImagery] = useState<any>(undefined);

  const [getInitialImagery] = useLazyQuery(IMAGERY, {
    variables: {
      imageryInput: { imageryId: paramCodeValue },
    },
    onCompleted: (data: any) => {
      setImage(data?.imagery?.type);
      setInitialImagery(data?.imagery);
    },
  });

  useEffect(() => {
    if (paramCodeValue) {
      getInitialImagery();
    }
  }, [getInitialImagery, paramCodeValue]);

  // console.log("initialImagery", initialImagery);

  /**********************************************************
   *** CONVERSATION STATE ***
   **********************************************************/

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** COMPONENTS ***
   **********************************************************/

  const ErrorButtons = [
    <Button
      key={"buttonA0"}
      icon={faMagicWandSparkles}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => window.location.reload()}
      type="classic"
      style={{
        backgroundColor: COLORS.transparent,
        borderColor: COLORS.fakeWhite,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {t("Button.Imagery.ReQuery")}
    </Button>,
    <Button
      key={"buttonA1"}
      icon={faTimes}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => navigate("/bot/start")}
      type="classic"
      style={{
        backgroundColor: COLORS.transparent,
        borderColor: COLORS.fakeWhite,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {t("Button.BackBotStart")}
    </Button>,
  ];

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [user, setUser] = useState<User | undefined>(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      setAccount(account);
      setUser(account?.users[0]);
      if (
        account.credit <= 0 &&
        (!account.subscriptionExpiration ||
          (account.subscriptionExpiration &&
            moment().isAfter(moment(account.subscriptionExpiration))))
      )
        navigate("/subscription");
    },
  });

  /**********************************************************
   *** IMAGERY ***
   **********************************************************/

  const [imageryId, setImageryId] = useState<string | undefined>(undefined);

  const [createImagery, { loading: createImageryLoading }] = useMutation(
    CREATE_IMAGERY,
    {
      onCompleted: (data) => {
        setImageryId(data.createImagery.id);
        launchImagery({
          variables: { imageryInput: { imageryId: data.createImagery.id } },
        });
      },
      onError: (error: any) => {
        console.log("CREATE_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
        setButtons(ErrorButtons);
      },
    }
  );

  const [launchImagery, { loading: launchImageryLoading }] = useMutation(
    LAUNCH_IMAGERY,
    {
      onCompleted: (data) => {
        setSuccess(`${t("Success.Message.ImageryCreated")}`);
      },
      onError: (error: any) => {
        console.log("LAUNCH_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setSuccess(`${t("Error.Message.ManualProcessEngaged")}`);
        setButtons(ErrorButtons);
      },
    }
  );

  /**********************************************************
   *** DELIVERY ***
   **********************************************************/

  const [deliveryOk, setDeliveryOk] = useState<boolean>(false);

  const [getImagery, { data: imageryData }] = useLazyQuery(IMAGERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.imagery?.delivery?.length > 0) {
        setButtons([
          <Button
            key={"buttonB0"}
            icon={faEye}
            iconPosition="left"
            darkMode={darkMode}
            onClick={() =>
              navigate(`/imagery/delivery?code=${imageryData.imagery.id}`)
            }
            type="validation"
          >
            {t("Button.Imagery.SeeResults")}
          </Button>,
        ]);
        setDeliveryOk(true);
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageryId && !deliveryOk) {
        getImagery({ variables: { imageryInput: { imageryId } } });
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    createImageryLoading,
    deliveryOk,
    getImagery,
    imageryId,
    launchImageryLoading,
  ]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(
    (): IQuestion[] => [
      {
        step: 0,
        q: t("BotImagery.Question.1"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  imageTypeOptions(t),
                  (o: any) => o.value === initialImagery.type
                )?.label ?? initialImagery.type,
            })
          : "",
        answerType: AnswerTypes.LIST,
        options: imageTypeOptions(t).map((option, i) => ({
          ...option,
          nextStep: (i + 1) * 10,
        })),
        initialValue: initialImagery?.type,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setImage(answerValue);
          setStep(getNextStep(question, answer));
        },
      },

      /* Photographie */
      {
        step: 10,
        q: t("BotImagery.Question.PHOTOGRAPHY.0"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  photoTypeOptions(t),
                  (o: any) => o.value === initialImagery.need.TYPE
                )?.label ?? initialImagery.need.TYPE,
            })
          : t("BotImagery.Question.PHOTOGRAPHY.0.Help"),
        answerType: AnswerTypes.TEXT_AND_LIST,
        options: photoTypeOptions(t),
        initialValue: initialImagery?.need.TYPE,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setType(answerValue ?? answer);
          setStep(getNextStep(question, answer));
        },
      },

      /* Illustration */
      {
        step: 20,
        q: t("BotImagery.Question.ILLUSTRATION.0"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  illustrationStyleOptions(t),
                  (o: any) => o.value === initialImagery.need.STYLE
                )?.label ?? initialImagery.need.STYLE,
            })
          : t("BotImagery.Question.ILLUSTRATION.0.Help"),
        answerType: AnswerTypes.TEXT_AND_LIST,
        options: illustrationStyleOptions(t),
        initialValue: initialImagery?.need.STYLE,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setStyle(answerValue ?? answer);
          setStep(getNextStep(question, answer));
        },
      },
      // {
      //   step: 21,
      //   q: t("BotImagery.Question.ILLUSTRATION.1"),
      //   help: initialImagery
      //     ? t("BotImagery.Question.1.Help", {
      //         previousAnswer:
      //           find(
      //             illustrationArtistOptions(t),
      //             (o: any) => o.value === initialImagery.need.INSPIRATION
      //           )?.label ?? initialImagery.need.INSPIRATION,
      //       })
      //     : t("BotImagery.Question.ILLUSTRATION.1.Help"),
      //   answerType: AnswerTypes.TEXT_AND_LIST,
      //   options: illustrationArtistOptions(t),
      //   initialValue: initialImagery?.need.INSPIRATION,
      //   nextStep: 100,
      //   onAnswer: (question: IQuestion, answer: string) => {
      //     const answerValue = question.options?.find(
      //       (o) => o.label === answer
      //     )?.value;
      //     setInspiration(answerValue ?? answer);
      //     setStep(getNextStep(question, answer));
      //   },
      // },

      /* FINAL */
      {
        step: 100,
        q: t("BotImagery.Question.2"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer: initialImagery.need.SCENE,
            })
          : t("BotImagery.Question.2.Help"),
        answerType: AnswerTypes.TEXT,
        initialValue: initialImagery?.need.SCENE,
        nextStep: 102,
        onAnswer: (question: IQuestion, answer: string) => {
          setScene(answer);
          setStep(getNextStep(question, answer));
        },
      },
      /*       {
        step: 101,
        q: t("BotImagery.Question.PHOTOGRAPHY.1"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer: initialImagery.need.INSPIRATION,
            })
          : t("BotImagery.Question.PHOTOGRAPHY.1.Help"),
        answerType: AnswerTypes.TEXT,
        initialValue: initialImagery?.need.INSPIRATION,
        nextStep: 102,
        onAnswer: (question: IQuestion, answer: string) => {
          setInspiration(answer);
          setStep(getNextStep(question, answer));
        },
      }, */
      {
        step: 102,
        q: t("BotImagery.Question.LAUNCH"),
        help: t("BotImagery.Question.LAUNCH.Help", {
          credit: account?.credit ?? 0,
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImagery.Question.LAUNCH.Option.1"),
            label: t("BotImagery.Question.LAUNCH.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.LAUNCH.Option.0"),
            label: t("BotImagery.Question.LAUNCH.Option.0"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.LAUNCH.Option.0")) {
            createImagery({
              variables: {
                createImageryInput: {
                  type: image,
                  need: {
                    TYPE: type,
                    SCENE: scene,
                    // INSPIRATION: inspiration,
                    STYLE: style,
                  },
                  parametric: false,
                },
              },
            });
          }
          if (answer === t("BotImagery.Question.4.Option.1")) {
            navigate("/bot/start");
          }
        },
      },
    ],
    [
      t,
      initialImagery,
      account?.credit,
      createImagery,
      image,
      type,
      scene,
      style,
      navigate,
    ]
  );

  if (!user) return null;

  // console.log("ALL QUESTIONS:", allQuestions);

  return (
    <Layout
      darkMode={darkMode}
      processing={createImageryLoading || launchImageryLoading}
      processingProgress={`${
        imageryData?.imagery?.progress?.percentage ?? "0"
      } %`}
    >
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        resetErrors={() => setError(undefined)}
        success={success}
        resetSuccess={() => setSuccess(undefined)}
        processing={createImageryLoading || launchImageryLoading}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
    </Layout>
  );
};
