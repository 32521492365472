import {
  faSquare,
  faSquareCheck,
  faSquareDown,
  faSquareLeft,
  faSquareRight,
  faSquareUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Asset, Safe, Settings } from "../../../graphql/generated/graphql";
import { DragIncrementer } from "../../../libs/components/fields/DragIncrementer";
import Radio from "../../../libs/components/radio/Radio";
import { AssetTypes } from "../../../libs/constants/general";
import { BREAKPOINTS } from "../../../libs/styles/breakpoints";
import { COLORS } from "../../../libs/styles/colors";
import { IncrementSelector } from "../IncrementSelector";
import { MessageContainer } from "./MessageContainer";

export interface IParamImageProps {
  darkMode: boolean;
  displayAdSettings: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  stepValidated: boolean;
  setCreateNewImageryModal: (value: { open: boolean }) => void;
  setSaveImageModal: (value: {
    open: boolean;
    image: any;
    type: AssetTypes;
  }) => void;
  setImageModal: (value: {
    open: boolean;
    image: any;
    jobId: string;
    jobDeliveryIndex: number;
  }) => void;
  safes: Safe[];
}

export const ParamImage: FunctionComponent<IParamImageProps> = ({
  darkMode,
  displayAdSettings,
  setDisplayAdSettings,
  stepValidated,
  setCreateNewImageryModal,
  setSaveImageModal,
  setImageModal,
  safes,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   * STATE
   * *******************************************************/

  const imageParameters = displayAdSettings.parameters.image;

  /**********************************************************
   *** IMAGE ***
   **********************************************************/

  /**********************************************************
   * ImageUpload
   * *******************************************************/

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (image)
      setSaveImageModal({
        open: true,
        image: image,
        type: AssetTypes.IMAGE,
      });
  }, [image, setSaveImageModal]);

  const onImageChange = (imageList: any) => {
    const image = imageList[0];
    setImage(image);
  };

  if (!safes) return null;

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={
          !imageParameters.safe?.id
            ? t("Factory.Message.CollectionImages")
            : !imageParameters.asset?.id
            ? t("Factory.Message.Image")
            : t("Factory.Message.UploadImage")
        }
        buttons={[
          <ModalLink onClick={() => setCreateNewImageryModal({ open: true })}>
            {t("Button.CreateNewImage")}
          </ModalLink>,
          <ImageUploading onChange={onImageChange} maxNumber={1} multiple>
            {({ imageList, onImageUpload }: any) => (
              <div>
                {imageList.map((image: any, index: number) => (
                  <div key={index}>
                    <img src={image.dataURL} alt="" width="100" />
                  </div>
                ))}
                <ModalLink onClick={onImageUpload}>
                  {t("Button.UploadImage").toUpperCase()}
                </ModalLink>
              </div>
            )}
          </ImageUploading>,
        ]}
      />

      <ParamContainer>
        <Param style={{ gap: 10 }}>
          <TitleLabel>{`Image Collections`}</TitleLabel>
          <Radio
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
            items={safes
              .filter((s: Safe) => s.type === "AI")
              .map((safe: Safe) => ({
                label: safe.name,
                onLabel:
                  safe.type === "IMAGE"
                    ? "IMAGE LIBRARY"
                    : safe.type === "LOGO"
                    ? "LOGO  LIBRARY"
                    : "AI GENERATIONS",
                onClick: () =>
                  setDisplayAdSettings((prevstate: Settings) => {
                    prevstate.parameters.image.safe = safe;
                    return { ...prevstate };
                  }),
                active: safe?.id === imageParameters.safe?.id,
              }))}
          />
          <Radio
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
            items={safes
              .filter((s: Safe) => s.type === "IMAGE")
              .map((safe: Safe) => ({
                label: safe.name,
                onLabel:
                  safe.type === "IMAGE"
                    ? "IMAGE LIBRARY"
                    : safe.type === "LOGO"
                    ? "LOGO  LIBRARY"
                    : "AI GENERATIONS",
                onClick: () => {
                  setDisplayAdSettings((prevstate: Settings) => {
                    prevstate.parameters.image.safe = safe;
                    prevstate.parameters.image.asset = {};
                    return { ...prevstate };
                  });
                },
                active: safe === imageParameters.safe,
              }))}
          />

          {imageParameters.safe?.id && (
            <>
              <TitleLabel>{`Images`}</TitleLabel>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  gap: 20,
                }}
              >
                {imageParameters.safe?.assets.map((asset: Asset, i: number) => {
                  return (
                    <div
                      key={i}
                      style={{
                        cursor: "pointer",
                        opacity:
                          imageParameters.asset?.cdnId === asset.cdnId
                            ? 1
                            : 0.25,
                      }}
                      onClick={() => {
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.image.asset = asset;
                          return { ...prevstate };
                        });
                      }}
                    >
                      <ImageCardContainer>
                        <ImageCard>
                          <Image src={asset.content as string} alt="Alt" />
                        </ImageCard>
                        <OptionRow>
                          <FontAwesomeIcon
                            icon={
                              imageParameters.asset?.cdnId === asset.cdnId
                                ? faSquareCheck
                                : faSquare
                            }
                            color={COLORS.fakeWhite}
                            style={{ paddingBottom: 5 }}
                          />
                          <Mentions>{asset.name}</Mentions>
                        </OptionRow>
                      </ImageCardContainer>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {imageParameters.asset?.id &&
            imageParameters.safe?.assets?.includes(imageParameters.asset) && (
              <ModalLink
                onClick={() => {
                  setImageModal({
                    open: true,
                    image: imageParameters.asset?.content,
                    jobId: imageParameters.asset?.jobId,
                    jobDeliveryIndex: imageParameters.asset?.jobDeliveryIndex,
                  });
                }}
              >
                MODIFIER L'IMAGE
              </ModalLink>
            )}
        </Param>

        {imageParameters.safe?.id &&
          imageParameters.asset?.id &&
          imageParameters.safe?.assets.includes(imageParameters.asset) && (
            <>
              <Separator />
              <ParamLine>
                <Param>
                  <TitleLabel>{`Alignment`}</TitleLabel>
                  <TitleRow style={{ paddingTop: 2 }}>
                    <Radio
                      items={[
                        {
                          label: "Automatic",
                          onClick: () =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.automaticAlignment =
                                true;
                              return { ...prevstate };
                            }),
                          active: imageParameters.automaticAlignment,
                        },
                        {
                          label: "Manual",
                          onClick: () =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.automaticAlignment =
                                false;
                              return { ...prevstate };
                            }),
                          active: !imageParameters.automaticAlignment,
                        },
                      ]}
                    />
                  </TitleRow>
                </Param>

                {!imageParameters.automaticAlignment && (
                  <>
                    <Param>
                      <TitleLabel>{`Vertical`}</TitleLabel>
                      <ParamRow>
                        <DragIncrementer
                          value={imageParameters.imageVerticalAlignment}
                          setValue={(value: number) =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageVerticalAlignment =
                                value;
                              return { ...prevstate };
                            })
                          }
                        />
                        <ConfigInput
                          value={imageParameters.imageVerticalAlignment}
                          onChange={(e) => {
                            if (isNaN(Number(e.target.value))) return;
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageVerticalAlignment =
                                Number(e.target.value);
                              return { ...prevstate };
                            });
                          }}
                        />
                        <IncrementSelector
                          plusIcon={faSquareUp}
                          minusIcon={faSquareDown}
                          darkMode={darkMode}
                          setPlus={() =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageVerticalAlignment =
                                imageParameters.imageVerticalAlignment - 1;
                              return { ...prevstate };
                            })
                          }
                          setMinus={() =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageVerticalAlignment =
                                imageParameters.imageVerticalAlignment + 1;
                              return { ...prevstate };
                            })
                          }
                        />
                      </ParamRow>
                    </Param>
                    <Param>
                      <TitleLabel>{`Horizontal`}</TitleLabel>
                      <ParamRow>
                        <DragIncrementer
                          value={imageParameters.imageHorizontalAlignment}
                          setValue={(value: number) =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageHorizontalAlignment =
                                value;
                              return { ...prevstate };
                            })
                          }
                        />
                        <ConfigInput
                          value={imageParameters.imageHorizontalAlignment}
                          onChange={(e) => {
                            if (isNaN(Number(e.target.value))) return;
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageHorizontalAlignment =
                                Number(e.target.value);
                              return { ...prevstate };
                            });
                          }}
                        />
                        <IncrementSelector
                          plusIcon={faSquareRight}
                          minusIcon={faSquareLeft}
                          darkMode={darkMode}
                          setPlus={() =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageHorizontalAlignment =
                                imageParameters.imageHorizontalAlignment + 1;
                              return { ...prevstate };
                            })
                          }
                          setMinus={() =>
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.image.imageHorizontalAlignment =
                                imageParameters.imageHorizontalAlignment - 1;
                              return { ...prevstate };
                            })
                          }
                        />
                      </ParamRow>
                    </Param>
                  </>
                )}
              </ParamLine>
              <ParamLine>
                <Param>
                  <TitleLabel>{`Gamma`}</TitleLabel>
                  <ParamRow>
                    <DragIncrementer
                      value={imageParameters.gamma}
                      setValue={(value: number) =>
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.image.gamma = value;
                          return { ...prevstate };
                        })
                      }
                    />
                    <ConfigInput
                      value={imageParameters.gamma}
                      onChange={(e) => {
                        if (isNaN(Number(e.target.value))) return;
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.image.gamma = Number(
                            e.target.value
                          );
                          return { ...prevstate };
                        });
                      }}
                    />
                    <IncrementSelector
                      plusIcon={faSquareRight}
                      minusIcon={faSquareLeft}
                      darkMode={darkMode}
                      setPlus={() =>
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.image.gamma =
                            imageParameters.gamma + 10;
                          return { ...prevstate };
                        })
                      }
                      setMinus={() =>
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.image.gamma =
                            imageParameters.gamma - 10;
                          return { ...prevstate };
                        })
                      }
                    />
                  </ParamRow>
                </Param>
              </ParamLine>
            </>
          )}
      </ParamContainer>
    </>
  );
};

const ParamContainer = styled.div`
  background-color: ${COLORS.fakeBlack};
  flex: 1;
  padding: 20px;
  border: 20px solid ${COLORS.darkgray};
  border-top: 0px solid ${COLORS.darkgray};
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 0px;
`;

const ParamRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border: 1px solid ${COLORS.darkgray};
  padding: 4px 6px 4px 17px;
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
`;

const Param = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
`;

const TitleLabel = styled.div`
  max-width: 1080px;
  font-size: 14px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ConfigInput = styled.input`
  max-width: 4ch;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${COLORS.fakeWhite};
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    border: none;
    outline: none;
  }
`;

const Mentions = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ModalLink = styled.div`
  font-size: 14px;
  border-radius: 8px;
  padding: 10px 20px 7px 20px;
  color: ${COLORS.mediumgray};
  background-color: ${COLORS.transparent};
  border: 1px solid ${COLORS.mediumgray};
  cursor: pointer;
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: center;
  &:hover {
    color: ${COLORS.mediumgray};
    background-color: ${COLORS.fakeWhite};
    border: 1px solid ${COLORS.fakeWhite};
  }
`;

const ImageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;
const ImageCard = styled.div`
  height: 65px;
  background-color: ${COLORS.translucentDarkBG};
  border-radius: 10px;
  padding: 5px 5px 7px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 110px;
  max-height: 65px;
  object-fit: contain;
  border-radius: 10px;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.transparentGrey};
  margin-top: 20px;
  margin-bottom: 30px;
`;
