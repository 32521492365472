import { gql } from "@apollo/client";
import { AssetFragment } from "../fragments/asset";

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($uploadImageInput: UploadImageInput!) {
    uploadImage(uploadImageInput: $uploadImageInput) {
      ...Asset
    }
  }
  ${AssetFragment}
`;
