import { faSquareMinus, faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, SetStateAction, useState } from "react";
import { ChromePicker } from "react-color";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Settings } from "../../../graphql/generated/graphql";
import { TextField } from "../../../libs/components/fields/TextField";
import Radio from "../../../libs/components/radio/Radio";
import { COLORS } from "../../../libs/styles/colors";
import { IncrementSelector } from "../IncrementSelector";
import { PositionSelector } from "../PositionSelector";
import { MessageContainer } from "./MessageContainer";
import { DragIncrementer } from '../../../libs/components/fields/DragIncrementer'
import { useClickAway } from "@uidotdev/usehooks";

export interface IParamCatchlineCtaProps {
  darkMode: boolean;
  setIsGenerated: (generated: boolean) => void;
  displayAdSettings: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  setGenerateCopyProposalsModal: (modal: { open: boolean }) => void;
  setGenerateFontFamiliesModal: (modal: { open: boolean }) => void;
  stepValidated: boolean;
}

export const ParamCatchlineCta: FunctionComponent<IParamCatchlineCtaProps> = ({
  darkMode,
  setIsGenerated,
  displayAdSettings,
  setDisplayAdSettings,
  setGenerateCopyProposalsModal,
  setGenerateFontFamiliesModal,
  stepValidated,
}) => {
  //
  const { t } = useTranslation();

  const [showCatchlineColorPicker, setShowCatchlineColorPicker] =
    useState<boolean>(false);
  const [showCtaColorPicker, setShowCtaColorPicker] = useState<boolean>(false);

  const catchlineColorPickerRef = useClickAway<any>(() => {
    setShowCatchlineColorPicker(false);
  });

  const ctaColorPickerRef = useClickAway<any>(() => {
    setShowCtaColorPicker(false);
  });

  const catchlineParameters = displayAdSettings.parameters.catchline;
  const ctaParameters = displayAdSettings.parameters.cta;

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={t("Factory.Message.CatchlineCtaFont.Validated")}
        buttons={[
          <ModalLink
            onClick={() => {
              setGenerateCopyProposalsModal({
                open: true,
              });
              setIsGenerated(true);
            }}
          >
            NOUVELLES ACCROCHES
          </ModalLink>,
          <ModalLink
            onClick={() => {
              setGenerateFontFamiliesModal({
                open: true,
              });
            }}
          >
            NOUVELLES FONTES
          </ModalLink>,
        ]}
      />
      <ParamContainer>
        <Param>
          <TitleLabel>{`Block Position`}</TitleLabel>
          <PositionSelector
            position={catchlineParameters.position}
            darkMode={darkMode}
            setPosition={(position) => {
              setDisplayAdSettings((prevstate: Settings) => {
                prevstate.parameters.catchline.position = position;
                return { ...prevstate };
              });
            }}
          />
        </Param>

        <BlocLine>
          <Param style={{ width: "100%" }}>
            <TitleLabel>{`Catchlines`}</TitleLabel>
            {catchlineParameters.copyProposals.map(
              (catchline: string, i: number) => (
                <TextField
                  key={i}
                  style={{ width: 400 }}
                  darkMode={darkMode}
                  onChange={(text: string) => {
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.catchline.copyUpdated = text;
                      return { ...prevstate };
                    });
                  }}
                  onFocus={() => {
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.catchline.copySelectionIndex = i;
                      prevstate.parameters.catchline.copyUpdated = catchline;
                      return { ...prevstate };
                    });
                  }}
                  initialValue={catchline}
                  editable={catchlineParameters.copySelectionIndex === i}
                />
              )
            )}
          </Param>
          <Param style={{ width: "100%" }}>
            <TitleLabel>{`CTA`}</TitleLabel>
            {ctaParameters.copyProposals.map((cta: string, i: number) => (
              <TextField
                key={i}
                style={{ width: 400 }}
                darkMode={darkMode}
                onChange={(text: string) => {
                  setDisplayAdSettings((prevstate: Settings) => {
                    prevstate.parameters.cta.copyUpdated = text;
                    return { ...prevstate };
                  });
                }}
                onFocus={() => {
                  setDisplayAdSettings((prevstate: Settings) => {
                    prevstate.parameters.cta.copySelectionIndex = i;
                    prevstate.parameters.cta.copyUpdated = cta;
                    return { ...prevstate };
                  });
                }}
                initialValue={cta}
                editable={ctaParameters.copySelectionIndex === i}
              />
            ))}
          </Param>
        </BlocLine>

        <BlocLine>
          <ParamColumn>
            <ParamLine>
              <Param>
                <TitleLabel>{`Size`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={catchlineParameters.fontSize}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.fontSize = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={catchlineParameters.fontSize}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.fontSize =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    darkMode={darkMode}
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.fontSize =
                          prevstate.parameters.catchline.fontSize + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.fontSize =
                          prevstate.parameters.catchline.fontSize - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Color`}</TitleLabel>
                <ParamColorRow>
                  <ColorRow ref={catchlineColorPickerRef} onClick={() => setShowCatchlineColorPicker(true)}>
                    <ColorSwatch color={catchlineParameters.color} />
                    <ConfigLabel style={{ color: COLORS.fakeWhite }}>
                      {catchlineParameters.color}
                    </ConfigLabel>
                    {showCatchlineColorPicker && (
                      <ColorPickerContainer>
                        <ChromePicker
                          color={catchlineParameters.color}
                          onChange={(color) => {
                            const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.catchline.color = rgba;
                              return { ...prevstate };
                            });
                          }}
                        />
                      </ColorPickerContainer>
                    )}
                  </ColorRow>
                </ParamColorRow>
              </Param>
            </ParamLine>
            <ParamLine>
              <Param>
                <TitleLabel>{`Font`}</TitleLabel>
                <Radio
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: 5,
                  }}
                  items={catchlineParameters.fontProposals.map((fp: string) => ({
                    label: fp,
                    value: fp,
                    active: catchlineParameters.font === fp,
                    onClick: () =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.font = fp;
                        return { ...prevstate };
                      }),
                  }))}
                />
              </Param>
            </ParamLine>
            <ParamLine>
              <Param>
                <TitleLabel>{`Top Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={catchlineParameters.topPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.topPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={catchlineParameters.topPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.topPadding =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.topPadding =
                          catchlineParameters.topPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.topPadding =
                          catchlineParameters.topPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Right Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={catchlineParameters.rightPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.rightPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={catchlineParameters.rightPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.rightPadding = Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.rightPadding =
                          catchlineParameters.rightPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.rightPadding =
                          catchlineParameters.rightPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Bottom Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={catchlineParameters.bottomPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.bottomPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={catchlineParameters.bottomPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.bottomPadding = Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.bottomPadding =
                          catchlineParameters.bottomPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.bottomPadding =
                          catchlineParameters.bottomPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Left Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={catchlineParameters.leftPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.leftPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={catchlineParameters.leftPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.leftPadding = Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.leftPadding =
                          catchlineParameters.leftPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.catchline.leftPadding =
                          catchlineParameters.leftPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
            </ParamLine>
          </ParamColumn>
          <ParamColumn>
            <ParamLine>
              <Param>
                <TitleLabel>{`Size`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={ctaParameters.fontSize}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.fontSize = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={ctaParameters.fontSize}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.fontSize = Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    darkMode={darkMode}
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.fontSize =
                          ctaParameters.fontSize + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.fontSize =
                          ctaParameters.fontSize - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Color`}</TitleLabel>
                <ParamColorRow>
                  <ColorRow ref={ctaColorPickerRef} onClick={() => setShowCtaColorPicker(true)}>
                    <ColorSwatch color={ctaParameters.color} />
                    <ConfigLabel style={{ color: COLORS.fakeWhite }}>
                      {ctaParameters.color}
                    </ConfigLabel>
                    {showCtaColorPicker && (
                      <ColorPickerContainer>
                        <ChromePicker
                          color={ctaParameters.color}
                          onChange={(color) => {
                            const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            setDisplayAdSettings((prevstate: Settings) => {
                              prevstate.parameters.cta.color = rgba;
                              return { ...prevstate };
                            });
                          }}
                        />
                      </ColorPickerContainer>
                    )}
                  </ColorRow>
                </ParamColorRow>
              </Param>
            </ParamLine>
            <ParamLine>
              <Param>
              <TitleLabel>{`Font`}</TitleLabel>
                <Radio
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: 5,
                  }}
                  items={ctaParameters.fontProposals.map((fp: string) => {
                    return {
                      label: fp,
                      value: fp,
                      active: ctaParameters.font === fp,
                      onClick: () =>
                        setDisplayAdSettings((prevstate: Settings) => {
                          prevstate.parameters.cta.font = fp;
                          return { ...prevstate };
                        }),
                    };
                  })}
                />
              </Param>
            </ParamLine>
            <ParamLine>
              <Param>
                <TitleLabel>{`Top Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={ctaParameters.topPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.topPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={ctaParameters.topPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.topPadding =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.topPadding =
                          ctaParameters.topPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.topPadding =
                          ctaParameters.topPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Right Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={ctaParameters.rightPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.rightPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={ctaParameters.rightPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.rightPadding =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.rightPadding =
                          ctaParameters.rightPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.rightPadding =
                          ctaParameters.rightPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Bottom Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={ctaParameters.bottomPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.bottomPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={ctaParameters.bottomPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.bottomPadding =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.bottomPadding =
                          ctaParameters.bottomPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.bottomPadding =
                          ctaParameters.bottomPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Left Margin`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={ctaParameters.leftPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.leftPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={ctaParameters.leftPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.leftPadding =
                          Number(e.target.value);
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.leftPadding =
                          ctaParameters.leftPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.cta.leftPadding =
                          ctaParameters.leftPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
            </ParamLine>
          </ParamColumn>
        </BlocLine>
      </ParamContainer>
    </>
  );
};

const ParamContainer = styled.div`
  background-color: ${COLORS.fakeBlack};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 20px solid ${COLORS.darkgray};
  border-top: 0px solid ${COLORS.darkgray};
  gap: 20px;
`;

const ParamRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border: 1px solid ${COLORS.darkgray};
  padding: 4px 6px 4px 17px;
`;

const ParamColorRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding-top: 8px;
`;

const ParamColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

const BlocLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

const Param = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const TitleLabel = styled.div`
  max-width: 1080px;
  font-size: 14px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ConfigLabel = styled.div`
  max-width: 1080px;
  font-size: 16px;
  min-width: 4ch;
  color: ${COLORS.transparentGrey};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ConfigInput = styled.input`
  max-width: 4ch;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${COLORS.fakeWhite};
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    border: none;
    outline: none;
  }
`;

const ColorRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -2px;
  cursor: pointer;
`;

const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 25px;
`;

const ColorSwatch = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
`;

const ModalLink = styled.div`
  font-size: 14px;
  border-radius: 8px;
  padding: 10px 20px 7px 20px;
  color: ${COLORS.mediumgray};
  background-color: ${COLORS.transparent};
  border: 1px solid ${COLORS.mediumgray};
  cursor: pointer;
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: center;
  &:hover {
    color: ${COLORS.mediumgray};
    background-color: ${COLORS.fakeWhite};
    border: 1px solid ${COLORS.fakeWhite};
  }
`;
