import { gql } from "@apollo/client";
import { AccountFragment } from "../fragments/account";

export const USER_ACCOUNTS = gql`
  query userAccounts($filter: FiltersAccountsInput!) {
    userAccounts(filter: $filter) {
      total
      results {
        ...Account
      }
    }
  }
  ${AccountFragment}
`;
