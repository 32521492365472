import { TFunction } from "i18next";

export const errorMessage = (error: any, t: TFunction) => {
  if (error.message === "Failed to fetch")
    return t("Error.Message.FailedToFetch");
  if (error.message === "Email already exists")
    return t("Error.Message.EmailAlreadyExists");
  if (error.message === "Invalid credentials")
    return t("Error.Message.InvalidCredentials");
  if (error.message === "The link has expired")
    return t("Error.Message.LinkExpired");
  if (error.message === "The validation url has expired")
    return t("Error.Message.ValidationUrlExpired");
  if (error.message === "Email not verified")
    return t("Error.Message.EmailNotVerified");
  if (error.message === "Manual process engaged")
    return t("Error.Message.ManualProcessEngaged");
  if (error.message === "Middle service error")
    return t("Error.Message.MiddleServiceError");
  else return `${t("Error.Message.Occured")} (${error.message})`;
};
