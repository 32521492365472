import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import he from "he";
import { find, truncate, uniqBy } from "lodash";
import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Typer } from "../../libs/components/Typer";
import { AnswerTypes } from "../../libs/constants/general";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { FONTS } from "../../libs/styles/fonts/fonts";
import { ConversationForm } from "./ConversationForm";
export interface IQuestion {
  step: number;
  q: string;
  type?: "error" | "info" | "success";
  answerType?: AnswerTypes;
  initialValue?: string;
  style?: React.CSSProperties;
  options?: {
    label: string;
    value: any;
    nextStep?: number;
    type?:
      | "alert"
      | "classic"
      | "secondary"
      | "disabled"
      | "validation"
      | "neutral"
      | "outlined"
      | "transparent"
      | string;
  }[];
  nextStep?: number;
  onAnswer?: (question: IQuestion, answer: string) => void;
  noAnswerWaited?: boolean;
  secured?: boolean;
  answerTruncateLength?: number;
  help?: string | null;
  images?: string[];
}

interface IConversationProps {
  darkMode?: boolean;
  allQuestions: IQuestion[];
  error?: string;
  success?: string;
  info?: string | null;
  resetErrors?: () => void;
  resetSuccess?: () => void;
  processing?: boolean;
  step: number;
  setStep: (step: number) => void;
  buttons?: ReactNode[];
  resetButtons?: () => void;
  questionToGetBack?: IQuestion;
  resetQuestionToGetBack?: () => void;
}

export const Conversation: FunctionComponent<IConversationProps> = ({
  darkMode,
  allQuestions,
  error,
  success,
  info,
  resetErrors,
  resetSuccess,
  processing,
  step = 0,
  setStep,
  buttons,
  resetButtons,
  questionToGetBack,
  resetQuestionToGetBack,
}) => {
  //

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [showTypingZone, setShowTypingZone] = useState<boolean>(false);
  const [stepChanged, setStepChanged] = useState<boolean>(false);

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [showQuestion, setShowQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<string[]>([]);

  /**********************************************************
   *** FIRST QUESTION ***
   **********************************************************/

  useEffect(() => {
    if (questions.length === 0) setQuestions([allQuestions[step]]);
  }, [allQuestions, questions, step]);

  /**********************************************************
   *** STEP MANAGEMENT ***
   **********************************************************/

  useEffect(() => {
    // console.log("STEP", step);
    const stepQuestion = find(allQuestions, { step });
    if (stepQuestion) {
      setTimeout(() => {
        setQuestions(uniqBy([...questions, stepQuestion], "step"));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, allQuestions, stepChanged]);

  const backToThisQuestion = useCallback(
    (question: IQuestion) => {
      if (resetErrors) resetErrors();
      if (resetSuccess) resetSuccess();
      const index = questions.findIndex((q) => q.step === question.step);
      const questionsCut = questions.slice(0, index);
      const answerCut = answers.slice(0, index);
      setQuestions(questionsCut);
      setAnswers(answerCut);

      if (step === question.step) setStepChanged(!stepChanged);
      else setStep(question.step);

      if (buttons && buttons.length > 0 && resetButtons) resetButtons();
    },
    [
      answers,
      buttons,
      questions,
      resetButtons,
      resetErrors,
      resetSuccess,
      setStep,
      step,
      stepChanged,
    ]
  );

  useEffect(() => {
    if (questionToGetBack && resetQuestionToGetBack) {
      backToThisQuestion(questionToGetBack);
      resetQuestionToGetBack();
    }
  }, [backToThisQuestion, questionToGetBack, resetQuestionToGetBack]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const botConversationContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (botConversationContainerRef?.current)
      (botConversationContainerRef.current as any).scrollTo({
        top: (botConversationContainerRef.current as any).scrollHeight,
        behavior: "smooth",
      });
  };

  /**********************************************************
   *** ANIMATION ***
   **********************************************************/

  const variants = {
    exit: {
      scaleY: 0,
      transition: { delay: 1.5 }, // delay exit by second
    },
  };
  // console.log("QUESTIONS", questions);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Container ref={botConversationContainerRef}>
        <BotConversationContainer>
          {questions.map((question, i) => {
            if (question && i <= answers.length + 1)
              return (
                <Fragment key={i}>
                  {showQuestion >= i && (
                    <>
                      {question.images && question.images.length > 0 && (
                        <ImagesRow imagesCount={question.images?.length ?? 0}>
                          {question.images.map((i) => (
                            <Image
                              key={`img_${i}`}
                              imagesCount={question.images?.length ?? 0}
                              src={i}
                              alt="Alt"
                            />
                          ))}
                        </ImagesRow>
                      )}

                      <BotTextContainer style={question.style}>
                        <Typer
                          key={i}
                          darkMode={darkMode}
                          color={question.style?.color ?? COLORS.mediumgray}
                          backgroundColor={
                            question.style?.backgroundColor ??
                            (darkMode ? COLORS.fakeBlack : COLORS.fakeWhite)
                          }
                          onTypingDone={() => {
                            if (question.answerType) setShowTypingZone(true);
                          }}
                          onCharacterTyped={() =>
                            setTimeout(() => scrollToBottom(), 500)
                          }
                        >
                          {question.q}
                        </Typer>
                      </BotTextContainer>
                      {question.help && (
                        <AnimatePresence>
                          <BotHelpContainer style={question.style}>
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{
                                ease: "easeInOut",
                                duration: 1,
                                delay: 2,
                              }}
                              style={{
                                color:
                                  question.style?.color ?? COLORS.mediumgray,
                              }}
                            >
                              {question.help.split("\n").map((h, i) => (
                                <div key={`help_${i}`}>{he.decode(h)}</div>
                              ))}
                            </motion.div>
                          </BotHelpContainer>
                        </AnimatePresence>
                      )}
                    </>
                  )}
                  {answers[i] && (
                    <BotTextContainer answer>
                      <Typer
                        darkMode={darkMode}
                        backgroundColor={
                          darkMode ? COLORS.fakeBlack : COLORS.mediumgray
                        }
                        color={COLORS.fakeWhite}
                        onTypingDone={() => setShowQuestion(i + 1)}
                        onCharacterTyped={() => scrollToBottom()}
                      >
                        {question.secured
                          ? answers[i].replaceAll(/[^*]/g, "--")
                          : question.answerTruncateLength
                          ? truncate(answers[i], {
                              length: question.answerTruncateLength,
                            })
                          : answers[i]}
                      </Typer>

                      <AnimatePresence>
                        {!processing && !success && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              ease: "easeInOut",
                              duration: 1,
                              delay: 2,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPencil}
                              style={{
                                fontSize: 14,
                                cursor: "pointer",
                                backgroundColor: darkMode
                                  ? COLORS.soixanteseize
                                  : COLORS.soixanteseize,
                                color: darkMode
                                  ? COLORS.fakeWhite
                                  : COLORS.fakeWhite,
                                padding: "10px",
                                margin: "0 10px",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                backToThisQuestion(question);
                              }}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </BotTextContainer>
                  )}
                </Fragment>
              );
            else return null;
          })}

          {/* processing && <Loader darkMode /> */}

          {error && (
            <BotTextContainer error>
              <Typer
                color={COLORS.red}
                backgroundColor={"transparent"}
                onCharacterTyped={() => scrollToBottom()}
              >
                {error}
              </Typer>
            </BotTextContainer>
          )}

          {success && (
            <BotTextContainer success>
              <Typer
                color={COLORS.green}
                backgroundColor={"transparent"}
                onCharacterTyped={() => scrollToBottom()}
              >
                {success}
              </Typer>
            </BotTextContainer>
          )}

          {info && (
            <BotTextContainer info>
              <Typer
                color={COLORS.mediumgray}
                backgroundColor={"transparent"}
                onCharacterTyped={() => scrollToBottom()}
              >
                {info}
              </Typer>
            </BotTextContainer>
          )}

          {buttons && buttons.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 1, delay: 2 }}
            >
              <ButtonRow>{buttons.map((button) => button)}</ButtonRow>
            </motion.div>
          )}
        </BotConversationContainer>
      </Container>
      <TypingZone>
        <AnimatePresence>
          {showTypingZone && (
            <>
              <motion.div
                style={{ transformOrigin: "50% bottom" }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                }}
              >
                <ConversationForm
                  darkMode={darkMode}
                  initialValues={{
                    answer: find(allQuestions, { step: step })?.initialValue,
                  }}
                  onSubmit={(values: any) => {
                    const question = find(allQuestions, { step: step });

                    if (!question?.noAnswerWaited)
                      setAnswers([...answers, values.answer]);

                    if (question?.onAnswer)
                      question.onAnswer(question, values.answer);
                    setShowTypingZone(false);
                  }}
                  question={find(allQuestions, { step: step })}
                />
              </motion.div>
              {/* <motion.div
                style={{
                  position: "fixed",
                  top: 0,
                  transformOrigin: "50% top",
                  zIndex: -1,
                  backgroundColor: COLORS.soixanteseize,
                  height: "20px",
                  width: "100%",
                }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                exit={{ scaleY: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              />
              <motion.div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  transformOrigin: "right 50%",
                  zIndex: -1,
                  backgroundColor: COLORS.soixanteseize,
                  height: "100%",
                  width: "20px",
                }}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                exit={{ scaleX: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              />
              <motion.div
                style={{
                  position: "fixed",
                  bottom: 0,
                  transformOrigin: "50% bottom",
                  zIndex: -1,
                  backgroundColor: COLORS.soixanteseize,
                  height: "20px",
                  width: "100%",
                }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                exit={{ scaleY: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              />
              <motion.div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  transformOrigin: "left 50%",
                  zIndex: -1,
                  backgroundColor: COLORS.soixanteseize,
                  height: "100%",
                  width: "20px",
                }}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                exit={{ scaleX: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              /> */}
            </>
          )}
        </AnimatePresence>
      </TypingZone>
    </div>
  );
};

/**********************************************************
 *** STYLE ***
 **********************************************************/

const Container = styled.div`
  /* height: calc(var(--vh, 1vh) * 50); */
  overflow-y: scroll;
`;

const BotConversationContainer = styled.div<{
  showTypingZone?: boolean;
}>`
  --height: ${(props) =>
    props.showTypingZone
      ? "calc(var(--vh, 1vh) * 80)"
      : "calc(var(--vh, 1vh) * 100)"};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 100px 35px 175px 100px;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    padding: 100px 30px 175px 32px;
  }
`;

const BotTextContainer = styled.div<{
  answer?: boolean;
  error?: boolean;
  success?: boolean;
  info?: boolean;
}>`
  --font-family: ${(props) => (props.answer ? FONTS.bold : FONTS.bold)};
  --marginBottom: ${(props) => (props.answer ? "40px" : "-0px")};
  --marginTop: ${(props) => (props.answer ? "10px" : "-0px")};
  --font-size: ${(props) =>
    props.error || props.success || props.info ? "24px" : "44px"};
  --font-size-mobile: ${(props) =>
    props.error || props.success || props.info ? "18px" : "36px"};
  --line-height: ${(props) =>
    props.error || props.success || props.info ? "1.5" : "1.35"};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(var(--vw, 1vw) * 80);
  padding: 0px 20px 0px 0px;
  font-size: var(--font-size);
  font-family: var(--font-family);
  letter-spacing: 0.5px;
  line-height: var(--line-height);
  margin-bottom: var(--marginBottom);
  margin-top: var(--marginTop);
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: calc(var(--vw, 1vw) * 80);
    padding: 8px 20px 0px 0px;
    font-size: var(--font-size-mobile);
  }
`;
const BotHelpContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(var(--vw, 1vw) * 80);
  font-size: 24px;
  font-family: ${FONTS.thin};
  letter-spacing: 0.5px;
  line-height: 1.75;
  margin-top: 8px;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: calc(var(--vw, 1vw) * 80);
    font-size: 18px;
  }
`;

const TypingZone = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: calc(var(--vw, 1vw) * 100);
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 5px 0;
  margin-top: 50px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
  }
`;

const ImagesRow = styled.div<{
  imagesCount: number;
}>`
  --imageWidthDesktop: ${(props) =>
    props.imagesCount === 1 ? "600px" : "300px"};
  --imageWidthMobile: ${(props) =>
    props.imagesCount === 1 ? "300px" : "125px"};

  width: 100%;
  display: grid;
  grid-template-columns: var(--imageWidthDesktop) var(--imageWidthDesktop);
  gap: 10px;
  margin-bottom: 50px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    grid-template-columns: var(--imageWidthMobile) var(--imageWidthMobile);
  }
`;

const Image = styled.img<{
  imagesCount: number;
}>`
  --imageWidthDesktop: ${(props) =>
    props.imagesCount === 1 ? "600px" : "300px"};
  --imageWidthMobile: ${(props) =>
    props.imagesCount === 1 ? "300px" : "125px"};

  width: var(--imageWidthDesktop);
  border-radius: 10px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: var(--imageWidthMobile);
  }
`;
