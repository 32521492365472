import { useQuery } from "@apollo/client";
import { capitalize } from "lodash";
import moment from "moment";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { User } from "../../graphql/generated/graphql";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { Conversation, IQuestion } from "../conversation/Conversation";
export interface IBotProps {
  darkMode: boolean;
}

export const BotStart: FunctionComponent<IBotProps> = ({ darkMode }) => {
  //
  localStorage.removeItem("redirect");

  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number>(0);

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [user, setUser] = useState<User | undefined>(undefined);
  // const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    fetchPolicy: "network-only",
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      // setAccount(account);
      setUser(account?.users[0]);
      if (
        account.credit <= 0 &&
        (!account.subscriptionExpiration ||
          (account.subscriptionExpiration &&
            moment().isAfter(moment(account.subscriptionExpiration))))
      )
        navigate("/subscription");
    },
  });

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(
    () => [
      {
        step: 0,
        q: t("BotStart.Question.0", { firstName: capitalize(user?.firstName) }),
        answerType: AnswerTypes.OPTIONS,
        help: t("BotStart.Question.0.Help"),
        options: [
          // {
          //   value: t("BotStart.Question.0.Option.0"),
          //   label: t("BotStart.Question.0.Option.0"),
          // },
          {
            value: t("BotStart.Question.0.Option"),
            label: t("BotStart.Question.0.Option"),
          } /*
          {
            value: t("BotStart.Question.0.Option.2"),
            label: t("BotStart.Question.0.Option.2"),
          },
          {
            value: t("BotStart.Question.0.Option.3"),
            label: t("BotStart.Question.0.Option.3"),
          }, */,
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          // if (answer === t("BotStart.Question.0.Option.0")) {
          //   navigate("/bot/moodboard");
          // }
          if (answer === t("BotStart.Question.0.Option")) {
            navigate("/bot/imagery");
          }
          // if (answer === t("BotStart.Question.0.Option.2")) {
          //   navigate("/bot/email");
          // }
          // if (answer === t("BotStart.Question.0.Option.3")) {
          //   navigate("/bot/branding");
          // }
        },
      },
    ],
    [user?.firstName, navigate, t]
  );

  useEffect(() => localStorage.removeItem("redirect"), []);

  if (!user) return null;

  return (
    <Layout darkMode={darkMode}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step}
        setStep={setStep}
      />
    </Layout>
  );
};
