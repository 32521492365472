import { gql } from "@apollo/client";

export const GENERATE_CATCHLINES = gql`
  mutation generateCatchlines(
    $generateCatchlinesInput: GenerateCatchlinesInput!
  ) {
    generateCatchlines(generateCatchlinesInput: $generateCatchlinesInput) {
      proposals
    }
  }
`;
