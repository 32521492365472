import moment, { Moment } from "moment";

export const getMonthDays = (date: Moment) => {
  const daysInMonth = moment(date).daysInMonth();
  const requestDate = moment(date).startOf("month");

  let monthDatesPickerValues: { value: Moment; label: number }[] = [];
  for (let index = 0; index + 1 <= daysInMonth; index++) {
    const newDay = moment(requestDate).add(index, "days");
    const pickerValue = { value: newDay, label: index + 1 };

    monthDatesPickerValues.push(pickerValue);
  }

  return monthDatesPickerValues;
};
