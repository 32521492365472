/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  address?: Maybe<Scalars['String']>;
  appleSubscriptionReceipt?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  credit: Scalars['Float'];
  id: Scalars['ID'];
  invoicingEmail: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
  sector: Scalars['String'];
  status: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  subscriptionExpiration?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  users: Array<User>;
  vatIdentifier?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AccountsResponse = {
  __typename?: 'AccountsResponse';
  results: Array<Account>;
  total: Scalars['Float'];
};

export type Asset = {
  __typename?: 'Asset';
  additionalDetails: Scalars['String'];
  cdnId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  jobDeliveryIndex?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type Auth = {
  __typename?: 'Auth';
  accessToken: Scalars['String'];
};

export type AuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ChangePasswordRequestInput = {
  email: Scalars['String'];
};

export type CheckExistingUserEmailInput = {
  email: Scalars['String'];
};

export type CheckUserEmailInput = {
  hash: Scalars['String'];
};

export type CreateAccountInput = {
  company: Scalars['String'];
  position: Scalars['String'];
  sector: Scalars['String'];
};

export type CreateCreditTrackInput = {
  accountId: Scalars['String'];
  comment: Scalars['String'];
  deliveredAssetId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateImageryInput = {
  need: Scalars['JSON'];
  parametric: Scalars['Boolean'];
  refUrl?: InputMaybe<Scalars['String']>;
  seeds?: InputMaybe<Array<SeedInput>>;
  type: Scalars['String'];
};

export type CreateMoodboardInput = {
  mood: Scalars['String'];
  requirements: Scalars['String'];
  subject: Scalars['String'];
};

export type CreateProductionInput = {
  name: Scalars['String'];
  sets: Array<SettingsInput>;
  type: Scalars['String'];
};

export type CreateSafeInput = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreditTrack = {
  __typename?: 'CreditTrack';
  account?: Maybe<Account>;
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  comment: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  deliveredAssetId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type: Scalars['String'];
};

export type CreditTracksFilterInput = {
  accountId: Scalars['String'];
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  limit: Scalars['Float'];
  order?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Float'];
};

export type CreditTracksResponse = {
  __typename?: 'CreditTracksResponse';
  results: Array<CreditTrack>;
  total: Scalars['Float'];
};

export type FilterUserImageriesInput = {
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  limit: Scalars['Float'];
  order?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Float'];
};

export type FiltersAccountsInput = {
  limit: Scalars['Float'];
  order?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Float'];
};

export type GenerateCatchlinesInput = {
  demand: Scalars['String'];
  language: Scalars['String'];
};

export type GenerateFontFamiliesInput = {
  inspiration: Scalars['String'];
};

export type GenerateTextResponseType = {
  __typename?: 'GenerateTextResponseType';
  proposals: Array<Scalars['String']>;
};

export type ImageriesResponse = {
  __typename?: 'ImageriesResponse';
  results: Array<Imagery>;
  total: Scalars['Float'];
};

export type Imagery = {
  __typename?: 'Imagery';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  delivery?: Maybe<Array<ImageryDelivery>>;
  id: Scalars['ID'];
  need: Scalars['JSON'];
  parametric?: Maybe<Scalars['Boolean']>;
  progress?: Maybe<Progress>;
  prompt?: Maybe<Scalars['String']>;
  refUrl?: Maybe<Scalars['String']>;
  seeds?: Maybe<Array<Seeds>>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  user: Scalars['String'];
};

export type ImageryDelivery = {
  __typename?: 'ImageryDelivery';
  additionalDetails: Scalars['String'];
  asset?: Maybe<Scalars['String']>;
  operator: Scalars['String'];
  service?: Maybe<Scalars['String']>;
  serviceData?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type ImageryInput = {
  imageryId: Scalars['String'];
};

export type Moodboard = {
  __typename?: 'Moodboard';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  delivery: Array<MoodboardResponse>;
  id: Scalars['ID'];
  need: MoodboardNeed;
  prompt: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  user: Scalars['String'];
};

export type MoodboardInput = {
  moodboardId: Scalars['String'];
};

export type MoodboardNeed = {
  __typename?: 'MoodboardNeed';
  mood: Scalars['String'];
  requirements: Scalars['String'];
  subject: Scalars['String'];
};

export type MoodboardResponse = {
  __typename?: 'MoodboardResponse';
  additionalDetails: Scalars['String'];
  asset: Scalars['String'];
  operator: Scalars['String'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAccountUser: Account;
  changePasswordRequest: Scalars['Boolean'];
  checkExistingUserEmail: Scalars['String'];
  checkUserEmail: Scalars['Boolean'];
  createAccount: Account;
  createImagery: Imagery;
  createMoodboard: Moodboard;
  createProduction: Production;
  createSafe: Safe;
  createUser: User;
  debitCredits: CreditTrack;
  deleteProduction: Scalars['Boolean'];
  deleteSafe: Scalars['Boolean'];
  generateCatchlines: GenerateTextResponseType;
  generateFontFamilies: GenerateTextResponseType;
  launchImagery: Imagery;
  removeAccountUser: Account;
  removeAsset: Scalars['Boolean'];
  saveImage: Asset;
  updateAccount: Account;
  updateProduction: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  uploadImage: Asset;
};


export type MutationAddAccountUserArgs = {
  accountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationChangePasswordRequestArgs = {
  changePasswordRequestInput: ChangePasswordRequestInput;
};


export type MutationCheckExistingUserEmailArgs = {
  checkExistingUserEmailInput: CheckExistingUserEmailInput;
};


export type MutationCheckUserEmailArgs = {
  checkUserEmailInput: CheckUserEmailInput;
};


export type MutationCreateAccountArgs = {
  createAccountInput: CreateAccountInput;
};


export type MutationCreateImageryArgs = {
  createImageryInput: CreateImageryInput;
};


export type MutationCreateMoodboardArgs = {
  createMoodboardInput: CreateMoodboardInput;
};


export type MutationCreateProductionArgs = {
  createProductionInput: CreateProductionInput;
};


export type MutationCreateSafeArgs = {
  createSafeInput: CreateSafeInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDebitCreditsArgs = {
  createCreditTrackInput: CreateCreditTrackInput;
};


export type MutationDeleteProductionArgs = {
  productionId: Scalars['String'];
};


export type MutationDeleteSafeArgs = {
  safeId: Scalars['String'];
};


export type MutationGenerateCatchlinesArgs = {
  generateCatchlinesInput: GenerateCatchlinesInput;
};


export type MutationGenerateFontFamiliesArgs = {
  generateFontFamiliesInput: GenerateFontFamiliesInput;
};


export type MutationLaunchImageryArgs = {
  imageryInput: ImageryInput;
};


export type MutationRemoveAccountUserArgs = {
  accountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveAssetArgs = {
  removeAssetInput: RemoveAssetInput;
};


export type MutationSaveImageArgs = {
  saveImageFromUrlInput: SaveImageFromUrlInput;
};


export type MutationUpdateAccountArgs = {
  accountId: Scalars['String'];
  updateAccountInput: UpdateAccountInput;
};


export type MutationUpdateProductionArgs = {
  updateProductionInput: UpdateProductionInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadImageArgs = {
  uploadImageInput: UploadImageInput;
};

export type Production = {
  __typename?: 'Production';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sets: Array<Settings>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type ProductionsFilterInput = {
  limit: Scalars['Float'];
  order?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Float'];
  type?: InputMaybe<Scalars['String']>;
};

export type ProductionsResponse = {
  __typename?: 'ProductionsResponse';
  results: Array<Production>;
  total: Scalars['Float'];
};

export type Progress = {
  __typename?: 'Progress';
  description?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  auth: Auth;
  creditTracks: CreditTracksResponse;
  imagery: Imagery;
  moodboard: Moodboard;
  production: Production;
  productions: ProductionsResponse;
  userAccounts: AccountsResponse;
  userImageries: ImageriesResponse;
  userSafe: Safe;
  userSafes: SafesResponse;
};


export type QueryAccountArgs = {
  accountId: Scalars['String'];
};


export type QueryAuthArgs = {
  authInput: AuthInput;
};


export type QueryCreditTracksArgs = {
  creditTracksFilterInput: CreditTracksFilterInput;
};


export type QueryImageryArgs = {
  imageryInput: ImageryInput;
};


export type QueryMoodboardArgs = {
  moodboardInput: MoodboardInput;
};


export type QueryProductionArgs = {
  productionId: Scalars['String'];
};


export type QueryProductionsArgs = {
  productionsFilterInput: ProductionsFilterInput;
};


export type QueryUserAccountsArgs = {
  filter: FiltersAccountsInput;
};


export type QueryUserImageriesArgs = {
  filter: FilterUserImageriesInput;
};


export type QueryUserSafeArgs = {
  safeId: Scalars['String'];
};


export type QueryUserSafesArgs = {
  userSafesInput?: InputMaybe<UserSafesInput>;
};

export type RemoveAssetInput = {
  assetId: Scalars['String'];
  safeId: Scalars['String'];
};

export type Safe = {
  __typename?: 'Safe';
  assets?: Maybe<Array<Asset>>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  user: Scalars['String'];
};

export type SafesResponse = {
  __typename?: 'SafesResponse';
  results: Array<Safe>;
  total: Scalars['Float'];
};

export type SaveImageFromUrlInput = {
  filename: Scalars['String'];
  jobDeliveryIndex?: InputMaybe<Scalars['Float']>;
  jobId?: InputMaybe<Scalars['String']>;
  safeId: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type SeedInput = {
  asset: Scalars['String'];
  delivery: Scalars['Float'];
  id: Scalars['String'];
};

export type Seeds = {
  __typename?: 'Seeds';
  asset: Scalars['String'];
  delivery: Scalars['Float'];
  id: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  format: Scalars['String'];
  formatName: Scalars['String'];
  parameters: Scalars['JSON'];
  support: Scalars['String'];
};

export type SettingsInput = {
  format: Scalars['String'];
  formatName: Scalars['String'];
  parameters: Scalars['JSON'];
  support: Scalars['String'];
};

export type UpdateAccountInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  invoicingEmail?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatIdentifier?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateProductionInput = {
  name?: InputMaybe<Scalars['String']>;
  productionId: Scalars['String'];
  sets?: InputMaybe<Array<SettingsInput>>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  optinMarket?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['String']>;
};

export type UploadImageInput = {
  file: Scalars['Upload'];
  filename: Scalars['String'];
  safeId: Scalars['String'];
  type: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  optinMarket: Scalars['Boolean'];
  optinSystem: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type UserSafesInput = {
  limit: Scalars['Float'];
  order?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Float'];
  type?: InputMaybe<Scalars['String']>;
};

export type AccountFragment = { __typename?: 'Account', id: string, type: string, status: string, name: string, sector: string, company?: string | null, vatIdentifier?: string | null, country?: string | null, address?: string | null, zip?: string | null, city?: string | null, invoicingEmail: string, credit: number, subscriptionExpiration?: string | null, stripeCustomerId?: string | null, appleSubscriptionReceipt?: string | null, users: Array<(
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'UserFragment': UserFragment } }
  )> } & { ' $fragmentName'?: 'AccountFragment' };

export type AssetFragment = { __typename?: 'Asset', id: string, type: string, name: string, jobId?: string | null, jobDeliveryIndex?: number | null, content?: string | null, cdnId?: string | null, additionalDetails: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string } & { ' $fragmentName'?: 'AssetFragment' };

export type CreditTrackFragment = { __typename?: 'CreditTrack', id: string, accountId: string, type: string, deliveredAssetId?: string | null, amount: number, comment: string, createdAt: string, createdBy: string, account?: (
    { __typename?: 'Account' }
    & { ' $fragmentRefs'?: { 'AccountFragment': AccountFragment } }
  ) | null } & { ' $fragmentName'?: 'CreditTrackFragment' };

export type ImageryFragment = { __typename?: 'Imagery', id: string, type?: string | null, user: string, need: any, prompt?: string | null, parametric?: boolean | null, refUrl?: string | null, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string, seeds?: Array<{ __typename?: 'Seeds', id: string, delivery: number, asset: string }> | null, progress?: { __typename?: 'Progress', percentage?: number | null, description?: string | null } | null, delivery?: Array<{ __typename?: 'ImageryDelivery', type: string, asset?: string | null, additionalDetails: string }> | null } & { ' $fragmentName'?: 'ImageryFragment' };

export type MoodboardFragment = { __typename?: 'Moodboard', id: string, user: string, prompt: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string, need: { __typename?: 'MoodboardNeed', subject: string, requirements: string, mood: string }, delivery: Array<{ __typename?: 'MoodboardResponse', type: string, asset: string, additionalDetails: string }> } & { ' $fragmentName'?: 'MoodboardFragment' };

export type ProductionFragment = { __typename?: 'Production', id: string, type: string, name: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string, sets: Array<{ __typename?: 'Settings', support: string, formatName: string, format: string, parameters: any }> } & { ' $fragmentName'?: 'ProductionFragment' };

export type SafeFragment = { __typename?: 'Safe', id: string, type: string, name: string, user: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string, assets?: Array<(
    { __typename?: 'Asset' }
    & { ' $fragmentRefs'?: { 'AssetFragment': AssetFragment } }
  )> | null } & { ' $fragmentName'?: 'SafeFragment' };

export type UserFragment = { __typename?: 'User', id: string, role: string, email: string, firstName: string, lastName: string, language: string, phoneNumber: string, createdAt: string, updatedAt: string } & { ' $fragmentName'?: 'UserFragment' };

export type ChangePasswordRequestMutationVariables = Exact<{
  changePasswordRequestInput: ChangePasswordRequestInput;
}>;


export type ChangePasswordRequestMutation = { __typename?: 'Mutation', changePasswordRequest: boolean };

export type CheckExistingUserEmailMutationVariables = Exact<{
  checkExistingUserEmailInput: CheckExistingUserEmailInput;
}>;


export type CheckExistingUserEmailMutation = { __typename?: 'Mutation', checkExistingUserEmail: string };

export type CheckUserEmailMutationVariables = Exact<{
  checkUserEmailInput: CheckUserEmailInput;
}>;


export type CheckUserEmailMutation = { __typename?: 'Mutation', checkUserEmail: boolean };

export type CreateAccountMutationVariables = Exact<{
  createAccountInput: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: (
    { __typename?: 'Account' }
    & { ' $fragmentRefs'?: { 'AccountFragment': AccountFragment } }
  ) };

export type CreateImageryMutationVariables = Exact<{
  createImageryInput: CreateImageryInput;
}>;


export type CreateImageryMutation = { __typename?: 'Mutation', createImagery: (
    { __typename?: 'Imagery' }
    & { ' $fragmentRefs'?: { 'ImageryFragment': ImageryFragment } }
  ) };

export type CreateProductionMutationVariables = Exact<{
  createProductionInput: CreateProductionInput;
}>;


export type CreateProductionMutation = { __typename?: 'Mutation', createProduction: (
    { __typename?: 'Production' }
    & { ' $fragmentRefs'?: { 'ProductionFragment': ProductionFragment } }
  ) };

export type CreateSafeMutationVariables = Exact<{
  createSafeInput: CreateSafeInput;
}>;


export type CreateSafeMutation = { __typename?: 'Mutation', createSafe: (
    { __typename?: 'Safe' }
    & { ' $fragmentRefs'?: { 'SafeFragment': SafeFragment } }
  ) };

export type CreateUserMutationVariables = Exact<{
  createUserInput: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: (
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'UserFragment': UserFragment } }
  ) };

export type DebitCreditsMutationVariables = Exact<{
  createCreditTrackInput: CreateCreditTrackInput;
}>;


export type DebitCreditsMutation = { __typename?: 'Mutation', debitCredits: (
    { __typename?: 'CreditTrack' }
    & { ' $fragmentRefs'?: { 'CreditTrackFragment': CreditTrackFragment } }
  ) };

export type DeleteProductionMutationVariables = Exact<{
  productionId: Scalars['String'];
}>;


export type DeleteProductionMutation = { __typename?: 'Mutation', deleteProduction: boolean };

export type DeleteSafeMutationVariables = Exact<{
  safeId: Scalars['String'];
}>;


export type DeleteSafeMutation = { __typename?: 'Mutation', deleteSafe: boolean };

export type GenerateCatchlinesMutationVariables = Exact<{
  generateCatchlinesInput: GenerateCatchlinesInput;
}>;


export type GenerateCatchlinesMutation = { __typename?: 'Mutation', generateCatchlines: { __typename?: 'GenerateTextResponseType', proposals: Array<string> } };

export type GenerateFontFamiliesMutationVariables = Exact<{
  generateFontFamiliesInput: GenerateFontFamiliesInput;
}>;


export type GenerateFontFamiliesMutation = { __typename?: 'Mutation', generateFontFamilies: { __typename?: 'GenerateTextResponseType', proposals: Array<string> } };

export type LaunchImageryMutationVariables = Exact<{
  imageryInput: ImageryInput;
}>;


export type LaunchImageryMutation = { __typename?: 'Mutation', launchImagery: (
    { __typename?: 'Imagery' }
    & { ' $fragmentRefs'?: { 'ImageryFragment': ImageryFragment } }
  ) };

export type RemoveAssetMutationVariables = Exact<{
  removeAssetInput: RemoveAssetInput;
}>;


export type RemoveAssetMutation = { __typename?: 'Mutation', removeAsset: boolean };

export type SaveImageMutationVariables = Exact<{
  saveImageFromUrlInput: SaveImageFromUrlInput;
}>;


export type SaveImageMutation = { __typename?: 'Mutation', saveImage: (
    { __typename?: 'Asset' }
    & { ' $fragmentRefs'?: { 'AssetFragment': AssetFragment } }
  ) };

export type UpdateProductionMutationVariables = Exact<{
  updateProductionInput: UpdateProductionInput;
}>;


export type UpdateProductionMutation = { __typename?: 'Mutation', updateProduction: boolean };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: boolean };

export type UploadImageMutationVariables = Exact<{
  uploadImageInput: UploadImageInput;
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: (
    { __typename?: 'Asset' }
    & { ' $fragmentRefs'?: { 'AssetFragment': AssetFragment } }
  ) };

export type UserAccountsQueryVariables = Exact<{
  filter: FiltersAccountsInput;
}>;


export type UserAccountsQuery = { __typename?: 'Query', userAccounts: { __typename?: 'AccountsResponse', total: number, results: Array<(
      { __typename?: 'Account' }
      & { ' $fragmentRefs'?: { 'AccountFragment': AccountFragment } }
    )> } };

export type ImageryQueryVariables = Exact<{
  imageryInput: ImageryInput;
}>;


export type ImageryQuery = { __typename?: 'Query', imagery: (
    { __typename?: 'Imagery' }
    & { ' $fragmentRefs'?: { 'ImageryFragment': ImageryFragment } }
  ) };

export type MoodboardQueryVariables = Exact<{
  moodboardInput: MoodboardInput;
}>;


export type MoodboardQuery = { __typename?: 'Query', moodboard: (
    { __typename?: 'Moodboard' }
    & { ' $fragmentRefs'?: { 'MoodboardFragment': MoodboardFragment } }
  ) };

export type ProductionQueryVariables = Exact<{
  productionId: Scalars['String'];
}>;


export type ProductionQuery = { __typename?: 'Query', production: (
    { __typename?: 'Production' }
    & { ' $fragmentRefs'?: { 'ProductionFragment': ProductionFragment } }
  ) };

export type ProductionsQueryVariables = Exact<{
  productionsFilterInput: ProductionsFilterInput;
}>;


export type ProductionsQuery = { __typename?: 'Query', productions: { __typename?: 'ProductionsResponse', total: number, results: Array<(
      { __typename?: 'Production' }
      & { ' $fragmentRefs'?: { 'ProductionFragment': ProductionFragment } }
    )> } };

export type GetUserSafeQueryVariables = Exact<{
  safeId: Scalars['String'];
}>;


export type GetUserSafeQuery = { __typename?: 'Query', userSafe: (
    { __typename?: 'Safe' }
    & { ' $fragmentRefs'?: { 'SafeFragment': SafeFragment } }
  ) };

export type UserSafesQueryVariables = Exact<{
  userSafesInput?: InputMaybe<UserSafesInput>;
}>;


export type UserSafesQuery = { __typename?: 'Query', userSafes: { __typename?: 'SafesResponse', total: number, results: Array<(
      { __typename?: 'Safe' }
      & { ' $fragmentRefs'?: { 'SafeFragment': SafeFragment } }
    )> } };

export type AuthQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthQuery = { __typename?: 'Query', auth: { __typename?: 'Auth', accessToken: string } };

export type UserImageryQueryVariables = Exact<{
  filter: FilterUserImageriesInput;
}>;


export type UserImageryQuery = { __typename?: 'Query', userImageries: { __typename?: 'ImageriesResponse', total: number, results: Array<(
      { __typename?: 'Imagery' }
      & { ' $fragmentRefs'?: { 'ImageryFragment': ImageryFragment } }
    )> } };

export const UserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"language"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode<UserFragment, unknown>;
export const AccountFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Account"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Account"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sector"}},{"kind":"Field","name":{"kind":"Name","value":"company"}},{"kind":"Field","name":{"kind":"Name","value":"vatIdentifier"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"zip"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"invoicingEmail"}},{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"User"}}]}},{"kind":"Field","name":{"kind":"Name","value":"credit"}},{"kind":"Field","name":{"kind":"Name","value":"subscriptionExpiration"}},{"kind":"Field","name":{"kind":"Name","value":"stripeCustomerId"}},{"kind":"Field","name":{"kind":"Name","value":"appleSubscriptionReceipt"}}]}},...UserFragmentDoc.definitions]} as unknown as DocumentNode<AccountFragment, unknown>;
export const CreditTrackFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CreditTrack"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CreditTrack"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"accountId"}},{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Account"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"deliveredAssetId"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}}]}},...AccountFragmentDoc.definitions]} as unknown as DocumentNode<CreditTrackFragment, unknown>;
export const ImageryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Imagery"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Imagery"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"seeds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"delivery"}},{"kind":"Field","name":{"kind":"Name","value":"asset"}}]}},{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"need"}},{"kind":"Field","name":{"kind":"Name","value":"prompt"}},{"kind":"Field","name":{"kind":"Name","value":"parametric"}},{"kind":"Field","name":{"kind":"Name","value":"refUrl"}},{"kind":"Field","name":{"kind":"Name","value":"progress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"percentage"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"delivery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"asset"}},{"kind":"Field","name":{"kind":"Name","value":"additionalDetails"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"}}]}}]} as unknown as DocumentNode<ImageryFragment, unknown>;
export const MoodboardFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Moodboard"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Moodboard"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"need"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subject"}},{"kind":"Field","name":{"kind":"Name","value":"requirements"}},{"kind":"Field","name":{"kind":"Name","value":"mood"}}]}},{"kind":"Field","name":{"kind":"Name","value":"prompt"}},{"kind":"Field","name":{"kind":"Name","value":"delivery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"asset"}},{"kind":"Field","name":{"kind":"Name","value":"additionalDetails"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"}}]}}]} as unknown as DocumentNode<MoodboardFragment, unknown>;
export const ProductionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Production"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Production"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"support"}},{"kind":"Field","name":{"kind":"Name","value":"formatName"}},{"kind":"Field","name":{"kind":"Name","value":"format"}},{"kind":"Field","name":{"kind":"Name","value":"parameters"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"}}]}}]} as unknown as DocumentNode<ProductionFragment, unknown>;
export const AssetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Asset"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Asset"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"jobId"}},{"kind":"Field","name":{"kind":"Name","value":"jobDeliveryIndex"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"cdnId"}},{"kind":"Field","name":{"kind":"Name","value":"additionalDetails"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"}}]}}]} as unknown as DocumentNode<AssetFragment, unknown>;
export const SafeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Safe"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Safe"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"assets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"}}]}},...AssetFragmentDoc.definitions]} as unknown as DocumentNode<SafeFragment, unknown>;
export const ChangePasswordRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"changePasswordRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"changePasswordRequestInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangePasswordRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changePasswordRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"changePasswordRequestInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"changePasswordRequestInput"}}}]}]}}]} as unknown as DocumentNode<ChangePasswordRequestMutation, ChangePasswordRequestMutationVariables>;
export const CheckExistingUserEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"checkExistingUserEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"checkExistingUserEmailInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CheckExistingUserEmailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkExistingUserEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"checkExistingUserEmailInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"checkExistingUserEmailInput"}}}]}]}}]} as unknown as DocumentNode<CheckExistingUserEmailMutation, CheckExistingUserEmailMutationVariables>;
export const CheckUserEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"checkUserEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"checkUserEmailInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CheckUserEmailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkUserEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"checkUserEmailInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"checkUserEmailInput"}}}]}]}}]} as unknown as DocumentNode<CheckUserEmailMutation, CheckUserEmailMutationVariables>;
export const CreateAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createAccountInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createAccountInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createAccountInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Account"}}]}}]}},...AccountFragmentDoc.definitions]} as unknown as DocumentNode<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateImageryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createImagery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createImageryInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateImageryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createImagery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createImageryInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createImageryInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Imagery"}}]}}]}},...ImageryFragmentDoc.definitions]} as unknown as DocumentNode<CreateImageryMutation, CreateImageryMutationVariables>;
export const CreateProductionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createProduction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createProductionInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateProductionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createProduction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createProductionInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createProductionInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Production"}}]}}]}},...ProductionFragmentDoc.definitions]} as unknown as DocumentNode<CreateProductionMutation, CreateProductionMutationVariables>;
export const CreateSafeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createSafe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createSafeInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateSafeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSafe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createSafeInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createSafeInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Safe"}}]}}]}},...SafeFragmentDoc.definitions]} as unknown as DocumentNode<CreateSafeMutation, CreateSafeMutationVariables>;
export const CreateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createUserInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createUserInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createUserInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"User"}}]}}]}},...UserFragmentDoc.definitions]} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const DebitCreditsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"debitCredits"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createCreditTrackInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateCreditTrackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"debitCredits"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createCreditTrackInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createCreditTrackInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CreditTrack"}}]}}]}},...CreditTrackFragmentDoc.definitions]} as unknown as DocumentNode<DebitCreditsMutation, DebitCreditsMutationVariables>;
export const DeleteProductionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteProduction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteProduction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productionId"}}}]}]}}]} as unknown as DocumentNode<DeleteProductionMutation, DeleteProductionMutationVariables>;
export const DeleteSafeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteSafe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"safeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteSafe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"safeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"safeId"}}}]}]}}]} as unknown as DocumentNode<DeleteSafeMutation, DeleteSafeMutationVariables>;
export const GenerateCatchlinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generateCatchlines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"generateCatchlinesInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateCatchlinesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateCatchlines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"generateCatchlinesInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"generateCatchlinesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"proposals"}}]}}]}}]} as unknown as DocumentNode<GenerateCatchlinesMutation, GenerateCatchlinesMutationVariables>;
export const GenerateFontFamiliesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generateFontFamilies"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"generateFontFamiliesInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateFontFamiliesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateFontFamilies"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"generateFontFamiliesInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"generateFontFamiliesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"proposals"}}]}}]}}]} as unknown as DocumentNode<GenerateFontFamiliesMutation, GenerateFontFamiliesMutationVariables>;
export const LaunchImageryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"launchImagery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"imageryInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ImageryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"launchImagery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"imageryInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"imageryInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Imagery"}}]}}]}},...ImageryFragmentDoc.definitions]} as unknown as DocumentNode<LaunchImageryMutation, LaunchImageryMutationVariables>;
export const RemoveAssetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"removeAsset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"removeAssetInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveAssetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeAsset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"removeAssetInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"removeAssetInput"}}}]}]}}]} as unknown as DocumentNode<RemoveAssetMutation, RemoveAssetMutationVariables>;
export const SaveImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"saveImage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"saveImageFromUrlInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveImageFromUrlInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveImage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"saveImageFromUrlInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"saveImageFromUrlInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"}}]}}]}},...AssetFragmentDoc.definitions]} as unknown as DocumentNode<SaveImageMutation, SaveImageMutationVariables>;
export const UpdateProductionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateProduction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateProductionInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateProductionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProduction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateProductionInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateProductionInput"}}}]}]}}]} as unknown as DocumentNode<UpdateProductionMutation, UpdateProductionMutationVariables>;
export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateUserInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateUserInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateUserInput"}}}]}]}}]} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"uploadImage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uploadImageInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UploadImageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadImage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"uploadImageInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uploadImageInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"}}]}}]}},...AssetFragmentDoc.definitions]} as unknown as DocumentNode<UploadImageMutation, UploadImageMutationVariables>;
export const UserAccountsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userAccounts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FiltersAccountsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userAccounts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Account"}}]}}]}}]}},...AccountFragmentDoc.definitions]} as unknown as DocumentNode<UserAccountsQuery, UserAccountsQueryVariables>;
export const ImageryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"imagery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"imageryInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ImageryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"imagery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"imageryInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"imageryInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Imagery"}}]}}]}},...ImageryFragmentDoc.definitions]} as unknown as DocumentNode<ImageryQuery, ImageryQueryVariables>;
export const MoodboardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"moodboard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"moodboardInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MoodboardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moodboard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"moodboardInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"moodboardInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Moodboard"}}]}}]}},...MoodboardFragmentDoc.definitions]} as unknown as DocumentNode<MoodboardQuery, MoodboardQueryVariables>;
export const ProductionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"production"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"production"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Production"}}]}}]}},...ProductionFragmentDoc.definitions]} as unknown as DocumentNode<ProductionQuery, ProductionQueryVariables>;
export const ProductionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productionsFilterInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductionsFilterInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productionsFilterInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productionsFilterInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Production"}}]}}]}}]}},...ProductionFragmentDoc.definitions]} as unknown as DocumentNode<ProductionsQuery, ProductionsQueryVariables>;
export const GetUserSafeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserSafe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"safeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSafe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"safeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"safeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Safe"}}]}}]}},...SafeFragmentDoc.definitions]} as unknown as DocumentNode<GetUserSafeQuery, GetUserSafeQueryVariables>;
export const UserSafesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userSafes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userSafesInput"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UserSafesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSafes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userSafesInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userSafesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Safe"}}]}}]}}]}},...SafeFragmentDoc.definitions]} as unknown as DocumentNode<UserSafesQuery, UserSafesQueryVariables>;
export const AuthDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"auth"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"auth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"authInput"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}}]}}]}}]} as unknown as DocumentNode<AuthQuery, AuthQueryVariables>;
export const UserImageryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userImagery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FilterUserImageriesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userImageries"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Imagery"}}]}}]}}]}},...ImageryFragmentDoc.definitions]} as unknown as DocumentNode<UserImageryQuery, UserImageryQueryVariables>;