import { DateTime } from "luxon";
import { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";

export interface IImageryDeliveryProps {
  darkMode: boolean;
  imagery: any;
}

export const HistoryImage: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
  imagery,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showSeeds, setShowSeeds] = useState<boolean>(false);

  return (
    <PreviousImagesContainer>
      <TitleContainer>
        <Title>
          {DateTime.fromISO(imagery.updatedAt).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
        </Title>

        <ButtonContainer>
          {imagery.seeds && imagery.seeds.length > 0 && (
            <ShowHistoryButton
              onClick={() => {
                setShowSeeds(!showSeeds);
              }}
            >
              {showSeeds ? t("Link.HideHistory") : t("Link.ShowHistory")}
            </ShowHistoryButton>
          )}
        </ButtonContainer>
      </TitleContainer>

      {showSeeds && (
        <>
          {imagery.seeds &&
            imagery.seeds.length > 0 &&
            imagery.seeds.map((seed: any, seedIndex: number) => {
              console.log("SEED", seed);
              return (
                <Fragment key={seedIndex}>
                  <SeedImagesRow>
                    {seedIndex > 0 && (
                      <SeedLinkContainer index={seedIndex}>
                        <SeedLink />
                      </SeedLinkContainer>
                    )}

                    <SeedImageContainer
                      key={seedIndex}
                      onClick={() => {
                        navigate(
                          `/imagery/delivery?code=${seed.id}&delivery=${seed.delivery}`
                        );
                      }}
                    >
                      <SeedImage src={seed.asset} alt="Alt" />
                    </SeedImageContainer>
                  </SeedImagesRow>
                  {seedIndex === imagery.seeds.length - 1 && (
                    <LastSeedLinkContainer seedsLength={imagery.seeds.length}>
                      <LastSeedLink />
                    </LastSeedLinkContainer>
                  )}
                </Fragment>
              );
            })}
        </>
      )}

      <PreviousImagesRow>
        {imagery.delivery &&
          imagery.delivery.length > 0 &&
          imagery.delivery.map((delivery: any, deliveryIndex: number) => (
            <PreviousImageContainer
              key={deliveryIndex}
              onClick={() => {
                navigate(
                  `/imagery/delivery?code=${imagery.id}&delivery=${deliveryIndex}`
                );
              }}
            >
              <PreviousImage src={delivery.asset} alt="Alt" />
            </PreviousImageContainer>
          ))}
      </PreviousImagesRow>

      <TitleContainer>
        <Text>
          {/* imagery.need.ZOOMOUT
            ? `Image extended with: ${imagery.prompt}`
            : imagery.prompt */}
          {/* Object.entries(JSON.parse(JSON.stringify(imagery.need)))
            .map(([key, value]) => `${key.toUpperCase()}: ${value}`)
            .join(" | ") */}
        </Text>
      </TitleContainer>
    </PreviousImagesContainer>
  );
};

const TitleContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.fakeWhite};
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 24px;
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: left;
`;

const ShowHistoryButton = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.soixanteseize};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: right;
  text-decoration: underline;

  cursor: pointer;
  &:hover {
    color: ${COLORS.fakeWhite};
  }
`;
const PreviousImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const PreviousImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 500px;
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    gap: auto;
    margin-bottom: 0px;
  }
`;

const SeedImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PreviousImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;
const PreviousImage = styled.img`
  width: 100%;
  max-width: 260px;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    border-radius: 10px;
  }
`;

const SeedImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

const SeedImage = styled.img`
  width: 100%;
  max-width: 100px;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    border-radius: 10px;
  }
`;

const SeedLinkContainer = styled.div<{
  index: number;
}>`
  --margin-left: ${(props) => (props.index - 1) * 100 + 50}px;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: var(--margin-left);
  margin-right: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin-left: 50px;
  }
`;

const SeedLink = styled.div`
  width: 25px;
  height: 25px;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: ${COLORS.mediumgray};
`;

const LastSeedLinkContainer = styled.div<{
  seedsLength: number;
}>`
  --margin-left: ${(props) => (props.seedsLength - 1) * 100 + 50}px;
  width: 1px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: var(--margin-left);
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin-left: 150px;
  }
`;

const LastSeedLink = styled.div`
  width: 1px;
  height: 50px;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: ${COLORS.mediumgray};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  justify-content: space-between;
`;
