import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { COLORS } from "../styles/colors";
import { FONTS } from "../styles/fonts/fonts";

export const Loader = ({ darkMode }: any) => {
  const { t } = useTranslation();

  const container = {
    hidden: { opacity: 0.5 },
    show: {
      opacity: 1,
    },
  };

  return (
    <LoaderContainer>
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        transition={{
          repeat: Infinity,
          duration: 0.5,
        }}
        style={{
          backgroundColor: `${darkMode ? COLORS.fakeBlack : COLORS.fakeWhite}`,
        }}
      >
        <Text darkMode>{t("Loading.Message.Waiting") ?? "Loading..."}</Text>
      </motion.div>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
`;

const Text = styled.div<{
  darkMode?: boolean;
}>`
  --color: ${(props) =>
    props.darkMode ? COLORS.soixanteseize : COLORS.soixanteseize};

  padding: 10px;
  color: var(--color);
  font-family: ${FONTS.bold};
`;
