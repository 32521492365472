import { useMutation } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import validate from "validate.js";

import { Settings } from "../../graphql/generated/graphql";
import { CREATE_PRODUCTION } from "../../graphql/mutations/createProduction";
import { UPDATE_PRODUCTION } from "../../graphql/mutations/updateProduction";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { constraintName } from "../account/validations/validators";
import { Conversation, IQuestion } from "../conversation/Conversation";

export interface ISaveProductionProps {
  darkMode?: boolean;
  onClose: () => void;
  setsToSave?: Settings[];
  initialProduction?: any;
  type: string;
}

export const SaveProduction: FunctionComponent<ISaveProductionProps> = ({
  darkMode,
  onClose,
  setsToSave,
  initialProduction,
  type,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);
  const [productionName, setProductionName] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  /**********************************************************
   *** CREATE PRODUCTION ***
   **********************************************************/

  const [createProduction, { loading }] = useMutation(CREATE_PRODUCTION, {
    onCompleted: (data: any) => {
      setSuccess(`${t("SaveProduction.Success")} `);
      setTimeout(() => {
        onClose();
      }, 2000);
    },
    onError: (error: any) => {
      console.log(
        "PRODUCTION CREATION ERROR:",
        JSON.parse(JSON.stringify(error))
      );
      setError(errorMessage(error, t));
    },
  });

  const [updateProduction, { loading: loadingUpdateProduction }] = useMutation(
    UPDATE_PRODUCTION,
    {
      onCompleted: (data: any) => {
        setSuccess(`${t("SaveProduction.Success")} `);
        setTimeout(() => {
          onClose();
        }, 2000);
      },
      onError: (error: any) => {
        console.log(
          "PRODUCTION SAVED ERROR:",
          JSON.parse(JSON.stringify(error))
        );
        setError(errorMessage(error, t));
      },
    }
  );

  useEffect(() => {
    if (productionName) {
      createProduction({
        variables: {
          createProductionInput: {
            type,
            name: productionName,
            sets: setsToSave,
          },
        },
      });
    }
  }, [createProduction, productionName, setsToSave, type]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: initialProduction
          ? `${t("SaveProduction.Question.10")}: "${initialProduction.name}" ?`
          : t("SaveProduction.Question.0"),
        answerType: initialProduction ? AnswerTypes.OPTIONS : AnswerTypes.TEXT,
        options: initialProduction
          ? [
              {
                value: t("SaveProduction.Answer.CreateNew"),
                label: t("SaveProduction.Answer.CreateNew"),
                type: "secondary",
              },
              {
                value: t("SaveProduction.Answer.Save"),
                label: t("SaveProduction.Answer.Save"),
                type: "classic",
              },
            ]
          : undefined,
        onAnswer: (question: IQuestion, answer: string) => {
          if (!initialProduction) {
            const errors = validate({ name: answer }, constraintName(t), {
              fullMessages: false,
            });
            if (errors) setError(errors.name[0]);
            else {
              setProductionName(answer);
            }
          } else {
            if (answer === t("SaveProduction.Answer.Save")) {
              updateProduction({
                variables: {
                  updateProductionInput: {
                    productionId: initialProduction.id,
                    sets: setsToSave,
                  },
                },
              });
            } else {
              setStep(10);
            }
          }
        },
      },
      {
        step: 10,
        q: t("SaveProduction.Question.0"),
        answerType: AnswerTypes.TEXT,

        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setProductionName(answer);
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [initialProduction, t, resQuestion, updateProduction, setsToSave]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={
        loading || loadingUpdateProduction
          ? t("Loading.Message.Waiting")
          : undefined
      }
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
