import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { Conversation, IQuestion } from "../conversation/Conversation";

export interface IFuncCompProps {
  darkMode?: boolean;
}

export const Welcome: FunctionComponent<IFuncCompProps> = ({ darkMode }) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string | undefined>(undefined);
  const [resQuestion /* setResQuestion */] = useState<IQuestion | undefined>(
    undefined
  );

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("Welcome.Question.0"),
        help: t("Welcome.Question.0.Help"),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("Welcome.Question.0.option.1"),
            label: t("Welcome.Question.0.option.1"),
            type: "secondary",
          },
          {
            value: t("Welcome.Question.0.option.0"),
            label: t("Welcome.Question.0.option.0"),
          },
        ],
        noAnswerWaited: true,
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("Welcome.Question.0.option.0")) navigate("/login");
          if (answer === t("Welcome.Question.0.option.1"))
            navigate("/register");
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [navigate, resQuestion, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        error={error}
        resetErrors={() => setError(undefined)}
        step={step}
        setStep={setStep}
      />
    </Layout>
  );
};
