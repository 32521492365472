import gql from "graphql-tag";
import { UserFragment } from "./user";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const AccountFragment = gql`
  fragment Account on Account {
    id
    type
    status
    name
    sector
    company
    vatIdentifier
    country
    address
    zip
    city
    invoicingEmail
    users {
      ...User
    }
    credit
    subscriptionExpiration
    stripeCustomerId
    appleSubscriptionReceipt
  }
  ${UserFragment}
`;
