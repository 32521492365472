export const COLORS = {
  red: "hsl(357, 92%, 47%)",
  green: "hsl(151, 100%, 36%)",
  darkgray: "hsl(0, 0%, 20%)",
  mediumgray: "hsl(0, 0%, 50%)",
  lightgray: "hsl(0, 0%, 80%)",
  white: "rgba(250, 250, 250, 1)",
  black: "hsl(0, 0%, 10%)",
  blue: "hsl(199, 92%, 56%)",
  warning: "hsl(36, 100%, 57%)",
  transparent: "transparent",

  fakeBlack: "hsl(0, 0%, 10%)",
  fakeWhite: "rgba(250, 250, 250, 1)",

  light: "rgba(250, 250, 250, 1)",
  transparentDark: "rgba(0, 0, 0, 0.5)",
  transparentGrey: "rgba(125, 125, 125, 0.5)",
  transparentLight: "rgba(250, 250, 250, 0.4)",
  soixanteseize: "#0000FF",

  dark: "#1a1a1a",
  soixanteseizeOld: "rgba(255, 170, 77, 1)",

  translucentDarkBG: "rgba(0, 0, 0, 0.7)",
  translucentLightBG: "rgba(255, 255, 255, 0.95)",
};
