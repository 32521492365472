import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { USER_ACCOUNTS } from "../../../graphql/queries/account";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import { KineticEffect } from "../kineticEffect/KineticEffect";
import Navigation from "../navigation/Navigation";
import { SideBarMenu } from "../sideMenu/SideBarMenu";
import { LoadingScreen } from "./LoadingScreen";

export interface ILayoutProps {
  cursorMessage?: string | null;
  children: React.ReactNode;
  darkMode?: boolean;
  processing?: boolean;
  processingProgress?: string;
}

export const Layout: FunctionComponent<ILayoutProps> = ({
  children,
  cursorMessage,
  darkMode,
  processing,
  processingProgress,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();
  /* 
  const lightRef = useRef();

  const handleMouseMove = (e: any) => {
    const { clientX: x, clientY: y } = e;
    const light: any = lightRef.current;
    if (light) {
      light.style.transform = `translate(${x - light.offsetWidth / 2}px, ${
        y - light.offsetHeight / 2
      }px)`;
    }
  }; */

  ////////////////////////////////////////////////////////////////// QUERY

  const [connected, setConnected] = useState<boolean>(false);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 1 } },
    onCompleted: (data: any) => {
      setConnected(true);
    },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
    },
  });

  ////////////////////////////////////////////////////////////////// MENU STATE

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  // const [message, setMessage] = useState<string | null>("");

  // useEffect(() => {
  //   setMessage(cursorMessage ?? t("Welcome.CursorMessage.Welcome"));
  // }, [cursorMessage, t]);

  ////////////////////////////////////////////////////////////////// RENDER

  const connectedNavigationItems = [
    {
      label: "NOUVELLE IMAGE",
      destination: "/bot/imagery",
      withSuperMenu: false,
    },
    { label: "|", withSuperMenu: false },

    {
      label: "HISTORIQUE",
      destination: "/history",
      withSuperMenu: false,
    },
    {
      label: "COLLECTIONS",
      destination: "/collections",
      withSuperMenu: false,
    },
    /*     { label: "|", withSuperMenu: false },
    {
      label: "FACTORY",
      destination: "/factory",
      withSuperMenu: false,
    }, */
    { label: "|", withSuperMenu: false },
    {
      label: "CONTACTS",
      destination: "https://soixanteseize.com/contacts",
      withSuperMenu: false,
    },
  ];

  return (
    <LayoutContainer
      darkMode={darkMode}
      /* style={{
        backgroundImage: darkMode
          ? `url(${backgroundWht})`
          : `url(${background})`,
        backgroundRepeat: "repeat",
      }} */
    >
      <NavigationContainer>
        <Navigation
          items={
            connected
              ? connectedNavigationItems
              : [
                  {
                    label: "CONTACTS",
                    destination: "https://soixanteseize.com/contacts",
                    withSuperMenu: false,
                  },
                ]
          }
        />
      </NavigationContainer>

      <MenuContainer onClick={() => setMenuOpen(!menuOpen)}>
        <MenuLabel>{!menuOpen ? "MENU" : "FERMER"}</MenuLabel>
      </MenuContainer>

      {/* {!processing && <Cursor cursorMessage={message} darkMode={darkMode} />} */}

      {!processing && (
        <KineticEffectContainer>
          <KineticEffect />
        </KineticEffectContainer>
      )}

      <ChildrenContainer>{children}</ChildrenContainer>

      <SideBarMenu
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        connected={connected}
      />

      {processing && (
        <LoadingScreen
          waitingMessage={t("Loading.Message.MailResults")}
          processingProgress={processingProgress}
          onClose={() => navigate("/bot/start")}
        />
      )}
      {/* <AnimationDiv>
          <LightFollowerContainer onMouseMove={handleMouseMove}>
            <Light ref={lightRef as any} />
            <TextContainer>
              <Text darkMode>{processingProgress}</Text>

              <SmallText darkMode>
                {t("Loading.Message.MailResults") ?? ""}
              </SmallText>
              <ButtonRow>
                {[
                  <Button
                    icon={faTimes}
                    iconPosition="left"
                    key={"button11"}
                    darkMode={darkMode}
                    onClick={() => navigate("/bot/start")}
                    type="secondary"
                  >
                    {t("Button.Close")}
                  </Button>,
                ]}
              </ButtonRow>
            </TextContainer>
          </LightFollowerContainer>
          <KineticEffect />
        </AnimationDiv> 
      )}*/}
    </LayoutContainer>
  );
};

const KineticEffectContainer = styled.div`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  mix-blend-mode: screen;
`;

const MenuContainer = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    position: fixed;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "HelveticaNeueLTPro-Bd";
    font-size: 10px;
    letter-spacing: 1px;
    top: 30px;
    right: 30px;
    background-color: ${COLORS.dark};
    cursor: pointer;
  }
`;

const MenuLabel = styled.div`
  font-family: "HelveticaNeueLtPro-Bd";
  font-size: 10px;
  padding: 0 20px;
  color: ${COLORS.mediumgray};
`;

const NavigationContainer = styled.div`
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    display: none;
  }
`;

const LayoutContainer = styled.div<{
  darkMode?: boolean;
}>`
  --bg-color: ${(props) =>
    props.darkMode ? COLORS.fakeBlack : COLORS.fakeWhite};

  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--bg-color);
`;

const ChildrenContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
`;
