import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import { SideBarMenuItem } from "./SideBarMenuItem";
export interface IAppSideBarSuperMenuProps {
  label: string;
  subLabel?: string;
  destinationPath: string;
  path: string;
  menuOpen: boolean;
  setMenuOpen: (bool: boolean) => void;
  superMenus: {
    label: string;
    subLabel?: string;
    destinationPath: string;
    subMenus: { label: string; destinationPath: string; subLabel: string }[];
  }[];

  superMenuOpen?: string | undefined;
  setSuperMenuOpen?: (string: string | undefined) => void;
}

export const SideBarSuperMenu: FunctionComponent<IAppSideBarSuperMenuProps> = ({
  superMenus,
  label,
  subLabel,
  destinationPath,
  path,
  menuOpen,
  setMenuOpen,
  superMenuOpen,
  setSuperMenuOpen,
}) => {
  ////////////////////////////////////////////////////////////////// PATHMATCH

  return (
    <>
      <div
        style={{
          paddingLeft: 25,
        }}
        className={"menuItem"}
        onClick={() => {
          if (setSuperMenuOpen)
            if (superMenuOpen === label) setSuperMenuOpen(undefined);
            else setSuperMenuOpen(label);
        }}
      >
        <SideBarMenuItem
          label={label}
          subLabel={subLabel}
          destinationPath={destinationPath}
          path={path}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          subMenus={[]}
          topMenu={superMenus.length === 0 ? false : true}
          superMenuOpen={superMenuOpen}
          setSuperMenuOpen={setSuperMenuOpen}
        />
      </div>

      {superMenuOpen === label &&
        superMenus.map((superMenu, i) => {
          return (
            <motion.div
              key={i}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: 0.1 * i }}
              exit={{ opacity: 0, y: 10 }}
              style={{
                paddingBottom: i === superMenus.length - 1 ? 20 : 0,
                paddingLeft: 40,
              }}
            >
              <SideBarMenuItem
                key={i}
                label={superMenu.label}
                subLabel={superMenu.subLabel}
                destinationPath={superMenu.destinationPath}
                path={path}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                subMenus={superMenu.subMenus}
              />
            </motion.div>
          );
        })}
    </>
  );
};
