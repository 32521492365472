import { AnimatePresence, motion } from "framer-motion";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Typer } from "../../../libs/components/Typer";
import { COLORS } from "../../../libs/styles/colors";

export interface IMessageContainerProps {
  stepValidated: boolean;
  message: string;
  buttons: JSX.Element[];
}

export const MessageContainer: FunctionComponent<IMessageContainerProps> = ({
  stepValidated,
  message,
  buttons,
}) => {
  //
  const [render, setRender] = useState<boolean>(true);
  const [validated, setValidated] = useState<boolean | undefined>(undefined);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  useEffect(() => {
    if (stepValidated !== validated) {
      setValidated(stepValidated);
      setRender(false);
    }
    if (!render) setTimeout(() => setRender(true), 500);
  }, [render, stepValidated, validated]);

  return (
    <Container stepValidated={stepValidated}>
      <Message stepValidated={stepValidated}>
        {render && (
          <Typer
            darkMode={true}
            color={stepValidated ? COLORS.fakeWhite : COLORS.red}
            backgroundColor={COLORS.transparent}
            onTypingDone={() => {
              setShowButtons(true);
            }}
          >
            {message}
          </Typer>
        )}
      </Message>
      {showButtons && buttons.length > 0 && (
        <AnimatePresence>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                delay: 0.5,
              }}
            >
              <ButtonContainer>
                {buttons.map((button: JSX.Element, i: number) => (
                  <Fragment key={i}>{button}</Fragment>
                ))}
              </ButtonContainer>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </Container>
  );
};

const Container = styled.div<{ stepValidated: boolean }>`
  background-color: ${COLORS.fakeBlack};
  padding: 15px 20px 20px 20px;
  border: 20px solid ${COLORS.darkgray};
`;
const Message = styled.div<{ stepValidated: boolean }>`
  padding-top: 4px;
  font-size: 20px;
  color: ${({ stepValidated }) => (stepValidated ? COLORS.white : COLORS.red)};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;
const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
`;
