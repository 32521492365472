import { FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Settings } from "../../../graphql/generated/graphql";
import { TextField } from "../../../libs/components/fields/TextField";
import { COLORS } from "../../../libs/styles/colors";
import { MessageContainer } from "./MessageContainer";

export interface IParamDestinationProps {
  darkMode: boolean;
  displayAdSettings: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  stepValidated: boolean;
}

export const ParamDestination: FunctionComponent<IParamDestinationProps> = ({
  darkMode,
  displayAdSettings,
  setDisplayAdSettings,
  stepValidated,
}) => {
  //
  const { t } = useTranslation();

  const destinationParameters = displayAdSettings.parameters.destination;

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={t("Factory.Message.Destination")}
        buttons={[]}
      />

      <ParamContainer>
        <ParamLine
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Param style={{ width: "100%" }}>
            <TitleLabel>{`Destination URL`}</TitleLabel>

            <TextField
              style={{ width: 400 }}
              darkMode={darkMode}
              onChange={(text: string) => {
                setDisplayAdSettings((prevstate: Settings) => {
                  prevstate.parameters.destination.url = text;
                  return { ...prevstate };
                });
              }}
              initialValue={destinationParameters.url}
              editable={true}
            />
          </Param>
        </ParamLine>
      </ParamContainer>
    </>
  );
};

const ParamContainer = styled.div`
  background-color: ${COLORS.fakeBlack};
  flex: 1;
  padding: 20px;
  border: 20px solid ${COLORS.darkgray};
  border-top: 0px solid ${COLORS.darkgray};
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
`;

const Param = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
`;

const TitleLabel = styled.div`
  max-width: 1080px;
  font-size: 14px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;
