import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import { FONTS } from "../../styles/fonts/fonts";
import { Typer } from "../Typer";
import Button from "../button";
import { KineticEffect } from "../kineticEffect/KineticEffect";

export interface ILoadingScreenProps {
  darkMode?: boolean;
  processingProgress?: string;
  onClose: () => void;
  waitingMessage: string;
}

export const LoadingScreen: FunctionComponent<ILoadingScreenProps> = ({
  darkMode,
  processingProgress,
  onClose,
  waitingMessage,
}) => {
  const { t } = useTranslation();

  const lightRef = useRef();

  const handleMouseMove = (e: any) => {
    const { clientX: x, clientY: y } = e;
    const light: any = lightRef.current;
    if (light) {
      light.style.transform = `translate(${x - light.offsetWidth / 2}px, ${
        y - light.offsetHeight / 2
      }px)`;
    }
  };

  return (
    <AnimationDiv>
      <LightFollowerContainer onMouseMove={handleMouseMove}>
        <Light ref={lightRef as any} />
        <TextContainer>
          <Text darkMode>{processingProgress}</Text>

          <SmallText darkMode>
            <Typer
              darkMode
              color={COLORS.fakeWhite}
              backgroundColor={COLORS.transparent}
            >
              {waitingMessage}
            </Typer>
          </SmallText>
          <ButtonRow>
            {[
              <Button
                icon={faTimes}
                iconPosition="left"
                key={"button11"}
                darkMode={darkMode}
                onClick={() => onClose()}
                type="secondary"
              >
                {t("Button.Close")}
              </Button>,
            ]}
          </ButtonRow>
        </TextContainer>
      </LightFollowerContainer>
      <KineticEffect />
    </AnimationDiv>
  );
};

const AnimationDiv = styled.div`
  z-index: 1001;
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 70px;
`;
const LightFollowerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Light = styled.div`
  position: absolute;
  z-index: 1002;
  width: 10000px;
  height: 10000px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    ${COLORS.soixanteseize},
    transparent,
    transparent,
    transparent
  );
  pointer-events: none;
  animation: ${keyframes`
0%, 100% {
opacity: 0.2;
}
50% {
opacity: 0.5;
}
`} 5s ease-in-out infinite; // adjust the duration (3s) as needed
  transition: transform 0.1s ease-in-out;
`;
const Text = styled.div<{
  darkMode?: boolean;
}>`
  z-index: 1003;

  --color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite)};
  color: var(--color);
  font-family: ${FONTS.ultraLight};
  font-size: 120px;
  line-height: 80px;
  padding: 0 100px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    padding: 0 35px;
    font-size: 100px;
    line-height: 60px;
  }
`;

const SmallText = styled.div<{
  darkMode?: boolean;
}>`
  --color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite)};
  color: var(--color);
  font-family: ${FONTS.bold};
  font-size: 18px;
  padding: 20px 100px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    padding: 20px 35px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 100px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    padding: 20px 35px;
  }
`;
