import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { MessageContainer } from "./MessageContainer";

export interface IParamExportationProps {
  darkMode: boolean;
  button: JSX.Element;
  stepValidated: boolean;
}

export const ParamExportation: FunctionComponent<IParamExportationProps> = ({
  darkMode,
  button,
  stepValidated,
}) => {
  //
  const { t } = useTranslation();

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={t("Factory.Message.Exportation")}
        buttons={[button]}
      />
    </>
  );
};
