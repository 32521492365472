import { FunctionComponent } from "react";

import styled from "styled-components";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";

export interface IImageZoomProps {
  image?: string;
}

export const ImageZoom: FunctionComponent<IImageZoomProps> = ({ image }) => {
  //

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <ImageBigContainer>
      <ImageBig src={image} alt="Alt" />
    </ImageBigContainer>
  );
};

const ImageBigContainer = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`;
const ImageBig = styled.img`
  object-fit: contain;
  max-width: 1024px;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    border-radius: 10px;
    max-width: 85%;
    max-height: 80%;
  }
`;
