import { FunctionComponent } from "react";
import styled from "styled-components";
import { Settings } from "../../graphql/generated/graphql";
import Logo_SoixanteSeize from "../../img/svg/Logo_SoixanteSeize.svg";
import { AnimatedDiv } from "../../libs/components/animatedDiv/AnimatedDiv";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";

export interface IDisplayAdRenderProps {
  displayAdSettings: Settings;
}

export const DisplayAdRender: FunctionComponent<IDisplayAdRenderProps> = ({
  displayAdSettings,
}) => {
  const defaultUUID = "826a2deb-0db9-4345-ad19-15f430defea1";

  const width = parseInt(displayAdSettings.format.split("x")[0]);
  const height = parseInt(displayAdSettings.format.split("x")[1]);

  let imageUrl = displayAdSettings.parameters.image?.automaticAlignment
    ? `https://ucarecdn.com/${
        displayAdSettings.parameters.image.asset?.cdnId ?? defaultUUID
      }/-/scale_crop/${displayAdSettings.format}/smart/`
    : `https://ucarecdn.com/${
        displayAdSettings.parameters.image.asset?.cdnId ?? defaultUUID
      }/-/scale_crop/${displayAdSettings.format}/${
        displayAdSettings.parameters.image?.imageHorizontalAlignment
      }p,${displayAdSettings.parameters.image?.imageVerticalAlignment}p/`;

  if (displayAdSettings.parameters.image?.gamma)
    imageUrl = `${imageUrl}-/gamma/${displayAdSettings.parameters.image?.gamma}/`;

  /**********************************************************
   *** IMAGE ANIMATION ***
   **********************************************************/

  return (
    <FormatContainer
      style={{
        width,
        height,
        backgroundColor: displayAdSettings.parameters.image.backgroundColor,
      }}
      onClick={() =>
        window.open(displayAdSettings.parameters.destination.url, "_blank")
      }
    >
      <ImageContainer style={{ width, height }}>
        <AnimatedDiv
          animationSettings={displayAdSettings.parameters.image.animation}
        >
          <ImageCard src={imageUrl} />
        </AnimatedDiv>
      </ImageContainer>

      <AllCopiesContainer
        position={displayAdSettings.parameters.catchline.position}
        style={{
          width,
          height,
        }}
      >
        <CopyContainer
          position={displayAdSettings.parameters.catchline.position}
        >
          <AnimatedDiv
            animationSettings={displayAdSettings.parameters.catchline.animation}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                displayAdSettings.parameters.catchline.position.textAlign ===
                "left"
                  ? "flex-start"
                  : displayAdSettings.parameters.catchline.position
                      .textAlign === "right"
                  ? "flex-end"
                  : "center",
            }}
          >
            <Copy
              fontFamily={displayAdSettings.parameters.catchline.font}
              style={{
                color: displayAdSettings.parameters.catchline.color,
                lineHeight: 1,
                fontSize: displayAdSettings.parameters.catchline.fontSize,
                padding: `${displayAdSettings.parameters.catchline.topPadding}px ${displayAdSettings.parameters.catchline.rightPadding}px ${displayAdSettings.parameters.catchline.bottomPadding}px ${displayAdSettings.parameters.catchline.leftPadding}px`,
                textAlign:
                  displayAdSettings.parameters.catchline.position.textAlign,
              }}
            >
              {displayAdSettings.parameters.catchline.copyUpdated}
            </Copy>
          </AnimatedDiv>
        </CopyContainer>

        <CopyContainer
          position={displayAdSettings.parameters.catchline.position}
        >
          <AnimatedDiv
            animationSettings={displayAdSettings.parameters.cta.animation}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                displayAdSettings.parameters.catchline.position.textAlign ===
                "left"
                  ? "flex-start"
                  : displayAdSettings.parameters.catchline.position
                      .textAlign === "right"
                  ? "flex-end"
                  : "center",
            }}
          >
            <Copy
              fontFamily={displayAdSettings.parameters.cta.font}
              style={{
                color: displayAdSettings.parameters.cta.color,
                lineHeight: 1,
                fontSize: displayAdSettings.parameters.cta.fontSize,
                padding: `${displayAdSettings.parameters.cta.topPadding}px ${displayAdSettings.parameters.cta.rightPadding}px ${displayAdSettings.parameters.cta.bottomPadding}px ${displayAdSettings.parameters.cta.leftPadding}px`,
                textAlign:
                  displayAdSettings.parameters.catchline.position.textAlign,
              }}
            >
              {displayAdSettings.parameters.cta.copyUpdated}
            </Copy>
          </AnimatedDiv>
        </CopyContainer>
      </AllCopiesContainer>

      <AnimatedDiv
        animationSettings={displayAdSettings.parameters.logo.animation}
        style={{
          position: "absolute",
          display: "flex",
          width,
          height,
          alignItems: displayAdSettings.parameters.logo.position.vertical,
          justifyContent: displayAdSettings.parameters.logo.position.horizontal,
        }}
      >
        <Logo
          src={
            displayAdSettings.parameters.logo.asset?.content ??
            Logo_SoixanteSeize
          }
          style={{
            maxWidth: displayAdSettings.parameters.logo.size,
            maxHeight:
              height -
              (displayAdSettings.parameters.logo.topPadding +
                displayAdSettings.parameters.logo.bottomPadding),
            padding: `${displayAdSettings.parameters.logo.topPadding}px ${displayAdSettings.parameters.logo.rightPadding}px ${displayAdSettings.parameters.logo.bottomPadding}px ${displayAdSettings.parameters.logo.leftPadding}px`,
          }}
        />
      </AnimatedDiv>
    </FormatContainer>
  );
};

const FormatContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

const AllCopiesContainer = styled.div<{
  position: { horizontal: string; vertical: string };
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.position.vertical};
  align-items: ${(props) => props.position.horizontal};
`;

const CopyContainer = styled.div<{
  position: { horizontal: string; vertical: string };
}>`
  display: flex;
  justify-content: ${(props) => props.position.horizontal};
  align-items: ${(props) => props.position.vertical};
`;
const Copy = styled.div<{ fontFamily: string }>`
  font-family: ${(props) => props.fontFamily};
`;

const Logo = styled.img``;

const ImageContainer = styled.div``;

const ImageCard = styled.img`
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;
