import { gql } from "@apollo/client";
import { ImageryFragment } from "../fragments/imagery";

export const USER_IMAGERIES = gql`
  query userImagery($filter: FilterUserImageriesInput!) {
    userImageries(filter: $filter) {
      total
      results {
        ...Imagery
      }
    }
  }
  ${ImageryFragment}
`;
