import { gql } from "@apollo/client";
import { ImageryFragment } from "../fragments/imagery";

export const CREATE_IMAGERY = gql`
  mutation createImagery($createImageryInput: CreateImageryInput!) {
    createImagery(createImageryInput: $createImageryInput) {
      ...Imagery
    }
  }
  ${ImageryFragment}
`;
