import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { getMonthDays } from "../../../helpers/getMonthDays";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";

export interface ICalendarProps {
  darkMode: boolean;
  date?: string;
  selectedDates: string[];
  notInThePast?: boolean;
  notInTheFuture?: boolean;
  changeDate: (newDate: Moment) => void;
}

export const Calendar: FunctionComponent<ICalendarProps> = ({
  darkMode,
  date,
  selectedDates,
  notInThePast,
  notInTheFuture,
  changeDate,
}) => {
  const [calendarDate, setCalendarDate] = useState<Moment | undefined>(
    undefined
  );

  const baseColor = darkMode ? COLORS.fakeWhite : COLORS.fakeBlack;

  useEffect(() => {
    setCalendarDate(moment(date));
  }, [date]);

  if (!calendarDate) return null;

  return (
    <Container>
      <Title darkMode>
        {DateTime.fromISO(calendarDate.toISOString()).toLocaleString(
          DateTime.DATE_FULL
        )}
      </Title>
      <DateRow>
        <Link
          color={
            notInThePast &&
            moment(calendarDate).unix() < moment().startOf("day").unix()
              ? COLORS.transparentGrey
              : baseColor
          }
          onClick={() => {
            const selectedDateIndex = selectedDates.findIndex((sd, i) => {
              const sdArray = sd.split("-");
              return (
                parseInt(sdArray[0]) === calendarDate.month() + 1 &&
                parseInt(sdArray[1]) === calendarDate.date()
              );
            });
            const previousDate =
              selectedDates[selectedDateIndex + 1]?.split("-");
            if (previousDate) {
              const newDate = calendarDate
                .clone()
                .set("month", parseInt(previousDate[0]) - 1)
                .set("date", parseInt(previousDate[1]));
              setCalendarDate(newDate);
              changeDate(newDate);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            size="1x"
            style={{ cursor: "pointer" }}
          />
        </Link>

        <Row>
          {getMonthDays(calendarDate).map((monthDay, i) => {
            const monthSelectedDays = selectedDates
              .filter((selectedDate) =>
                selectedDate.startsWith(`${monthDay.value.month() + 1}`)
              )
              .map((sd) => parseInt(sd.split("-")[1]));

            let color: any;
            if (
              moment(monthDay.value).unix() ===
              moment(calendarDate).startOf("day").unix()
            )
              color = COLORS.soixanteseize;
            else
              color =
                notInThePast &&
                moment(monthDay.value).unix() < moment().startOf("day").unix()
                  ? COLORS.transparentGrey
                  : notInTheFuture &&
                    moment(monthDay.value).unix() >
                      moment().startOf("day").unix()
                  ? COLORS.transparentGrey
                  : !monthSelectedDays.includes(monthDay.label)
                  ? COLORS.transparentGrey
                  : baseColor;

            return (
              <Link
                style={{ cursor: color === baseColor ? "pointer" : "default" }}
                key={i}
                color={color}
                onClick={() => {
                  if (color === baseColor) {
                    const newDate = monthDay.value.clone();
                    setCalendarDate(newDate);
                    changeDate(newDate);
                  }
                }}
              >
                {monthDay.label}
              </Link>
            );
          })}
        </Row>

        <Link
          color={
            notInTheFuture &&
            moment(calendarDate).unix() > moment().startOf("day").unix()
              ? COLORS.transparentGrey
              : baseColor
          }
          onClick={() => {
            const selectedDateIndex = selectedDates.findIndex((sd, i) => {
              const sdArray = sd.split("-");
              return (
                parseInt(sdArray[0]) === calendarDate.month() + 1 &&
                parseInt(sdArray[1]) === calendarDate.date()
              );
            });
            const nextDate = selectedDates[selectedDateIndex - 1]?.split("-");
            if (nextDate) {
              const newDate = calendarDate
                .clone()
                .set("month", parseInt(nextDate[0]) - 1)
                .set("date", parseInt(nextDate[1]));
              setCalendarDate(newDate);
              changeDate(newDate);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            size="1x"
            style={{ cursor: "pointer" }}
          />
        </Link>
      </DateRow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const DateRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    display: none;
  }
`;

const Title = styled.div<{ darkMode: boolean }>`
  width: 100%;
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.soixanteseize};
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 24px;
  }
`;

const Link = styled.div<{ color: string }>`
  font-size: 16px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${(props) => props.color};
`;
