import { faWarning } from "@fortawesome/pro-light-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS } from "../../styles/colors";
export interface ISelectorProps {
  items: {
    label: string;
    onClick?: () => void;
    active: boolean;
    stepValidated: boolean;
  }[];
}

export const StepSelector: FunctionComponent<ISelectorProps> = ({ items }) => {
  ////////////////////////////////////////////////////////////////// RENDER

  return (
    <SelectorRow>
      <SelectorContainer>
        {items.map((item, i) => {
          if (i === items.length - 1) return null;
          return (
            <SelectorItemRow
              key={i}
              validated={item.stepValidated}
              active={item.active}
            >
              <FontAwesomeIcon
                icon={item.stepValidated ? faCircleCheck : faWarning}
                color={item.stepValidated ? COLORS.green : COLORS.red}
                style={{ fontSize: 14 }}
              />
              <SelectorItem
                validated={item.stepValidated}
                active={item.active}
                key={i}
                onClick={item.onClick}
              >
                <StepLabel
                  validated={item.stepValidated}
                  active={item.active}
                >{`STEP ${i + 1}`}</StepLabel>
                <div>{item.label.toUpperCase()}</div>
              </SelectorItem>
            </SelectorItemRow>
          );
        })}
      </SelectorContainer>
      <SelectorContainer>
        <SelectorItemRow
          validated={items[items.length - 1].stepValidated}
          active={items[items.length - 1].active}
        >
          <FontAwesomeIcon
            icon={
              items[items.length - 1].stepValidated ? faCircleCheck : faWarning
            }
            color={
              items[items.length - 1].stepValidated ? COLORS.green : COLORS.red
            }
            style={{ fontSize: 14 }}
          />
          <SelectorItem
            validated={items[items.length - 1].stepValidated}
            active={items[items.length - 1].active}
            onClick={items[items.length - 1].onClick}
          >
            <StepLabel
              validated={items[items.length - 1].stepValidated}
              active={items[items.length - 1].active}
            >{`FINAL STEP`}</StepLabel>
            <div>{items[items.length - 1].label.toUpperCase()}</div>
          </SelectorItem>
        </SelectorItemRow>
      </SelectorContainer>
    </SelectorRow>
  );
};

const SelectorRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StepLabel = styled.div<{ validated: boolean; active: boolean }>`
  color: ${({ active, validated }) =>
    validated ? COLORS.green : /* active ? COLORS.fakeWhite : */ COLORS.red};
  font-size: 8px;
`;

const SelectorItemRow = styled.div<{ validated: boolean; active: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ active, validated }) =>
    !active ? COLORS.translucentDarkBG : COLORS.darkgray};
  padding: 5px 20px 6px 20px;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;

  justify-content: center;
  align-items: center;
  font-family: "HelveticaNeueLTPro-Bd";
  font-size: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: ${COLORS.fakeBlack};
`;

const SelectorItem = styled.div<{ active: boolean; validated: boolean }>`
  color: ${({ active, validated }) =>
    active
      ? COLORS.fakeWhite
      : validated
      ? COLORS.fakeWhite
      : COLORS.fakeWhite};
  padding-top: 3px;
`;
