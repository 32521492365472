export const fr = {
  "Languages.fr-FR": "Français",
  "Languages.en": "English",
  "Languages.fr": "Français",

  "Welcome.CursorMessage.Welcome": "Hello. Je suis là pour vous aider :)",

  // WELCOME
  "Welcome.Question.0":
    "Bienvenue dans l'expérimentation SoixanteSeize AI.\nJe suis un robot assistant de production d'image par l'intelligence artificielle.",
  "Welcome.Question.0.Help":
    "La phase Beta est gratuite.\nSi vous avez des suggestions ou des remarques, n'hésitez pas à nous contacter.",
  "Welcome.Question.0.option.0": "Se connecter",
  "Welcome.Question.0.option.1": "Créer un compte",

  // PAGE
  "Page.History.Title": "Historique",
  "Page.History.Subtitle":
    "Cliquez sur les images pour retourner sur la page de résultats.",
  "Page.History.NoHistory": "Aucun requête n'a été formulée à cette date.",
  "Page.Collections.Title": "Collections",
  "Page.Collections.Subtitle":
    "Retrouvez ici toutes vos images et organisez-le par projet.",
  "Page.Collections.Message.NoCollection":
    "Vous n'avez pas encore de collection.",
  "Page.Collections.Message.NoAssets":
    "Vous n'avez pas encore d'éléments enregistrés.",
  "Page.Collections.Message.AI":
    "Vos images générées par l'IA. Cliquez sur les images pour les agrandir et accéder à de nouvelles fonctionnalités.",
  "Page.Collections.Message.IMAGE":
    "Votre bibliothèque d'images uploadées dans la Factory.",
  "Page.Collections.Message.LOGO": "Tous vos logos uploadées dans la Factory.",
  "Page.Delivery.Title": "Résultats",
  "Page.Delivery.Subtitle.1":
    "L'IA a traduit et enrichi votre demande initiale. Si l'interprétation est trop éloignée de votre attente, vous pouvez reformuler votre demande.",
  "Page.Delivery.Subtitle.2":
    "L'IA a agrandi votre image. Si le résultat ne vous convient pas, vous pouvez repartir des résultats précédents.",
  "Page.Delivery.PreviousResults": "Résultat(s) précédent(s) :",
  "Page.Delivery.PreviousResultsDetails":
    "Vous pouvez repartir d'une précédente image en cliquant dessus",
  "Page.DisplayAd.Title": "Display Ad",
  "Page.DisplayAd.Subtitle":
    "Générez votre prochaine campagne Display assisté par l'IA.",
  "Page.DisplayAd.ProjectSubtitle": "Dernière sauvegarde : {{savedate}}.",
  "Page.DisplayAd.ProjectSubtitleTemporary":
    "Le nom de votre projet est temporaire jusqu'à ce que vous le sauvegardiez.",
  "Page.DisplayAd.ProjectTitle": "Vos projets sauvegardés",
  // MODAL
  "GenerateCopyProposals.Question.0": "Dans quelle langue dois-je travailler ?",
  "GenerateCopyProposals.Help.0":
    "La version Beta n'est disponible que pour l'anglais et le français.",
  "GenerateCopyProposals.Question.1":
    "Je vais maintenant générer vos accroches.\nVeuillez me briefer pour que je puisse vous faire des propositions.",
  "GenerateCopyProposals.Help.1":
    "Votre brief peut se concentrer sur le sujet de votre campagne, sur le message que vous souhaitez faire passer ou sur les valeurs que vous souhaitez véhiculer.",
  "GenerateFontFamilies.Question.0":
    "Je dois maintenant travailler la typographie de votre annonce.\nDe quoi les fontes doivent-elle s'inspirer ?",
  "GenerateFontFamilies.Help.0":
    "Vous pouvez choisir un thème, un mot-clé, un nom ou un style de typographie.",
  "ChooseSafe.Question.0":
    "Nous allons maintenant choisir l'image de fond de votre annonce.\nVeuillez choisir la Collection dans laquelle vous avez enregistré l'image souhaitée.",
  "ChooseSafe.Help.0":
    "Pour pouvoir utiliser une image, vous devez l'avoir préalablement enregistrée dans une de vos Collections.",

  // FACTORY
  "Factory.Welcome.Question.0":
    "Bienvenue dans la Factory SoixanteSeize AI.\nIci, vous pourrez automatiser la création de vos outils de communications tout en étant assisté par l'IA.\n \nQue souhaitez-vous générer ?",
  "Factory.Welcome.Question.0.option.1": "Display Ad",
  "Factory.ConfigLabel.Formats": "Veuillez choisir un format :",

  "Factory.ConfigLabel.Language": "Langues :",
  "Factory.Message.Language": "Veuillez choisir une langue.",
  "Factory.ConfigLabel.Catchline": "Accroches :",
  "Factory.Message.Catchline": "Veuillez générer des accroches.",
  "Factory.ConfigLabel.Font": "Fontes :",
  "Factory.Message.Font": "Veuillez générer une sélection de fontes.",
  "Factory.ConfigLabel.Logo": "Logo :",
  "Factory.Message.CollectionLogos":
    "Pour changer le logo,\nveuillez choisir une de vos collections ou uploadez un logo.",
  "Factory.ConfigLabel.Images": "Images :",

  "Factory.Message.CollectionImages":
    "L'image de fond actuelle est temporaire.\nPour la changer, vous avez 3 options :\n1_Générer une nouvelle image par l'IA\n2_Uploader une de vos images dans une collection\n3_Sélectionner une image déjà enregistrées dans vos collections.",
  "Factory.Message.Image": "Veuillez choisir une image.",
  "Factory.Message.Logo": "Veuillez choisir un logo.",
  "Factory.Message.UploadImage":
    "Vous pouvez maintenant ajuster votre image.\nSi vous souhaitez uploader votre propre image, cliquez sur le bouton ci-contre.",
  "Factory.Message.UploadLogo":
    "Vous pouvez maintenant ajuster votre logo.\nSi vous souhaitez uploader votre logo, cliquez sur le bouton ci-contre.",
  "Factory.Message.CatchlineCtaFont.Validated":
    "Vos accroches et CTA ont été générés. Vous pouvez maintenant les sélectionner et/ou les modifier.\nSi vous souhaitez de nouvelles proposition, cliquez sur les boutons ci-contre pour relancer des générations.",

  "Factory.Message.Animation":
    "Vous pouvez maintenant choisir un set et paramétrer l'animation de chacun de vos élément.",
  "Factory.Message.Destination": "Veuillez entrer l'url de votre campagne.",

  // LOGIN
  "Login.Question.0":
    "Si vous avez déjà un compte,\nveuillez entrer votre email",
  "Login.Question.1": "Et votre mot de passe",
  "Login.Question.2":
    "Félicitations, vous êtes connecté! Veuillez patienter...",

  // CHECK EMAIL
  "Check.Message.Waiting": "Vérification...",

  // LOADING
  "Loading.Message.Waiting": "Veuillez patienter...",
  "Loading.Message.MailResults":
    "Si vous avez plus important à faire que de rester devant un écran bleu,\nun email vous sera envoyé dès que les images seront prêtes.",
  "Loading.Message.Modal":
    "Veuillez patienter pendant que je génère vos images.\nSi vous fermez cette modale, la génération de l'image ne sera pas interrompue.\nVous recevrez un email dès que vos images seront prêtes.",
  "Loading.Message.Preparation": "Préparation...",

  // BUTTONS
  "Button.Retry": "Réessayer",
  "Button.Close": "Fermer",
  "Button.Restart": "Recommencer",
  "Button.Reload": "Ré-essayer",
  "Button.ZoomOut": "Étendre",
  "Button.SaveImage": "Enregistrer",
  "Button.Start": "Commencer",
  "Button.Generate": "Générer",
  "Button.Choose": "Choisir",
  "Button.Upload": "Uploader",
  "Button.UploadImage": "Uploader une image",
  "Button.UploadLogo": "Uploader un logo",
  "Button.GoToHistory": "Historique",
  "Button.ExportFormat": "Exporter ce format",
  "Button.BackBotStart": "Terminer",
  "Button.CreateNewProject": "Nouveau Projet",
  "Button.ReloadProjectInProgress": "Reprendre le projet en cours",
  "Button.Back": "Retour",
  "Button.ReQuery": "Reformuler",
  "Button.Moodboard.ReQuery": "Nouveau moodboard",
  "Button.Imagery.ReQuery": "Nouvelle image",
  "Button.Imagery.SeeResults": "Voir les résultats",
  "Button.Contacts": "Contacts",
  "Button.AdjustQuery": "Repartir de cette image",
  "Button.ChangeEmail": "Choisir un autre email",
  "Button.ConnectWithEmail": "Se connecter avec cet email",
  "Button.Save": "Sauvegarder",
  "Button.RefreshResult": "Rejouer l'animation",
  "Button.Reset": "Réinitialiser",

  // LINKS
  "Link.HideHistory": "Masquer l'historique",
  "Link.ShowHistory": "Afficher l'historique",

  // SUCCESS
  "Success.Message.UserCreated":
    "Vos identifiants ont été créés. Nous vous avons envoyé un email. Veuillez cliquer sur le lien transmis pour confirmer votre compte.",
  "Success.Message.EmailChecked":
    "Votre email a été vérifié. Veuillez patienter, vous aller pouvoir vous connecter.",
  "Success.Message.AccountCreated":
    "Merci. Vous pouvez maintenant utiliser SoixanteSeize AI.",
  "Success.Message.MoodboardCreated": "Votre moodboard est lancé.",
  "Success.Message.ImageryCreated":
    "Vos images sont prêtes. Vous pouvez cliquer sur le lien ci dessous.\nUn e-mail avec un lien vers les résultats vous a également été envoyé.",
  "Success.Message.ChangePasswordRequest":
    "Un email de réinitialisation vient de vous être envoyé.",
  "Success.Message.PasswordChanged":
    "Votre nouveau mot de passe est enregistré. Veuillez patienter, vous allez pouvoir vous connecter...",
  "Success.Message.SafeCreated": "Votre collection est créée.",
  "Success.Message.ImageSaved": "Votre image est ajoutée à votre collection.",
  "Success.Message.AssetRemoved":
    "Votre image est supprimée de votre collection.",
  "Success.Message.FontFamiliesGenerated": "Vos fontes sont générées.",
  "Success.Message.CatchlinesGenerated": "Vos accroches sont générées.",

  // REGISTER
  "Register.CursorMessage.Welcome":
    "Ici, nous affinons votre profil pour notre IA.",
  "Register.CursorMessage.CreatingUser": "C'est parti, veuillez patienter.",
  "Register.CursorMessage.UserCreated": "C'est bon! Allez checker vos emails.",
  "Register.CursorMessage.Error": "Ooooops! Regardez, il y a une erreur.",
  "Register.Question.0":
    "Nous allons créer votre compte ensemble.\n \nQuel est votre prénom ?",
  "Register.Question.1": "Votre nom de famille ?",
  "Register.Question.2": "Votre numéro de téléphone, s'il vous plaît",
  "Register.Question.3": "Votre email",
  "Register.Question.4": "Et choisissez un mot de passe",
  "Register.Question.5": "Veuillez confirmer votre mot de passe",

  // ACCOUNT
  "CreateAccount.CursorMessage.Welcome":
    "Quelques informations supplémentaires.",
  "CreateAccount.CursorMessage.CreatingAccoun": "Veuillez patienter.",
  "CreateAccount.CursorMessage.UserCreated":
    "C'est bon! Vous pouvez utilisez nos outils AI. Veuillez patienter.",
  "CreateAccount.CursorMessage.Error": "Ooooops! Regardez, il y a une erreur.",
  "CreateAccount.Question.0":
    "Quelques étapes pour finaliser...\n \nQuel est votre secteur d'activité ?",
  "CreateAccount.Question.1": "Dans quelle entreprise/marque travaillez-vous ?",
  "CreateAccount.Question.2": "Quel est votre profession ?",

  // CHANGE PASSWORD
  "ChangePassword.Question.0":
    "Vous pouvez désormais changer votre mot de passe.\n \nEntrez votre nouveau mot de passe",
  "ChangePassword.Question.1": "Veuillez confirmer votre mot de passe",

  // VALIDATORS
  "Validator.Message.Invalid": "Cet email semble invalide. Veuillez corriger.",
  "Validator.Message.NotSamePassword":
    "Les mots de passe ne sont pas les mêmes. Veuillez réessayer.",
  "Validator.Message.PhoneNumber":
    "Ce numéro de téléphone semble invalide. Veuillez corriger.",
  "Validator.Message.Password":
    "Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre minuscule, une lettre majuscule et un caractère spécial.",
  "Validator.Message.Min8Char": "Doit comporter au moins 8 caractères.",
  "Validator.Message.Max16Char": "Doit comporter au plus 16 caractères.",
  "Validator.Message.Max20Char": "Doit comporter au plus 20 caractères.",
  "Validator.Message.Required": "Ce champ est requis.",

  // ERRORS
  "Error.Message.Occured": "Une erreur est survenue.",
  "Error.Message.FailedToFetch": "Un problème réseau est survenue.",
  "Error.Message.EmailAlreadyExists": "Cet email existe déjà.",
  "Error.Message.InvalidCredentials":
    "Vos identifiants sont invalides. Veuillez réessayer.",
  "Error.Message.LinkExpired": "Le lien a expiré.",
  "Error.Message.ValidationUrlExpired":
    "Le lien a expiré. Je viens de vous en renvoyer un sur votre email.",
  "Error.Message.EmailNotVerified":
    "Votre email n'a pas été vérifié. Veuillez cliquer sur le lien transmis sur votre email.",
  "Error.Message.ManualProcessEngaged":
    "Votre demande est en cours de traitement. Dès que vos images seront prêtes, un bouton apparaîtra sur cet écran et une notification vous sera envoyé par e-mail.",
  "Error.Message.MiddleServiceError":
    "Un des services est actuellement indisponible. Veuillez réessayer plus tard.",
  "Error.Message.NoResultsFound": "Oops. Nous n'avons rien trouvé.",

  // CONVERSATION
  "ConversationForm.TextField.Placeholder": "Écrivez ici...",
  "ConversationForm.TextField.SeparatorTextAndOptions":
    "...ou Choisissez une option :",

  // OPTIONS
  "Option.Yes": "Oui",
  "Option.No": "Non",
  "Option.Default": "Choisissez une option...",
  "Option.LoadProduction": "Charger une production...",
  "Option.None.Feminin": "Aucune",
  "Option.None.Masculin": "Aucun",
  "Option.Freelance": "Indépendant(e)",
  "Option.CreateAccount": "Créer un compte",
  "Option.ForgotPassword": "Mot de passe oublié ?",
  "Option.Sector.HEALTHCARE": "Santé",
  "Option.Sector.MATERIALS": "Matières Premières",
  "Option.Sector.REAL_ESTATE": "Immobilier",
  "Option.Sector.CONSUMER_STAPLES": "Grande Consommation",
  "Option.Sector.LUXURY": "Luxe",
  "Option.Sector.UTILITIES": "Service Public",
  "Option.Sector.ENERGY": "Énergie",
  "Option.Sector.INDUSTRIALS": "Industrie",
  "Option.Sector.COMMUNICATION": "Communication",
  "Option.Sector.FINANCIAL": "Finance",
  "Option.Sector.TECHNOLOGY": "Technologie",

  // BOT

  // START
  "BotStart.Question.0": "Hello {{firstName}}\n \nVoici les nouveautés :",
  "BotStart.Question.0.Help":
    "Version Beta 0.5.1\nNOUVELLE IMAGE – La génération d'image et la livraison de vos images sont maintenant accélérées voire immédiates.\nEXTENSION D'IMAGE – Vous pouvez maintenant étendre le format votre image et l'IA créera la matière nécessaire.\nHISTORIQUE – Retrouvez toutes vos demandes et résultats de manière chronologique.\nCOLLECTIONS – Enregistrez vos images et organisez-les par projet.",
  "BotStart.Question.0.Option.0": "Un moodboard",
  "BotStart.Question.0.Option.1": "Une image",
  "BotStart.Question.0.Option.2": "Un email marketing",
  "BotStart.Question.0.Option.3": "Une marque",
  "BotStart.Question.0.Option.4": "Des post sociaux",

  "BotStart.Question.0.Option": "Go !",

  // MOODBOARD
  "BotMoodboard.Question.0":
    "Nous allons créer un moodboard ensemble à partir de 3 questions.\n \nÊtes-vous prêt ?",
  "BotMoodboard.Question.0.Option.0": "Go !",
  "BotMoodboard.Question.0.Option.1": "Non merci.",

  "BotMoodboard.Question.1":
    "Veuillez décrire le sujet de votre moodboard en quelques mots...",
  "BotMoodboard.Question.1.Help":
    'Ex.: "Une landing page pour un site de vente de café"',
  "BotMoodboard.Question.2":
    "Y-a-t-il des éléments que vous souhaiteriez voir apparaître impérativement ?",
  "BotMoodboard.Question.2.Help": 'Ex.: "Des grains de café"',
  "BotMoodboard.Question.3":
    "Quel type d'ambiance souhaitez-vous transmettre ?",
  "BotMoodboard.Question.3.Help": 'Ex.: "Haut de gamme"',
  "BotMoodboard.Question.4": "Je suis prêt à lancer.\nOk pour vous ?",
  "BotMoodboard.Question.4.Help":
    "Cet action consommera 1 crédit(s) / {{credit}} restant(s) et vous recevrez le résultat par e-mail {{timePromess}}.",
  "BotMoodboard.Question.4.Option.0": "Allez on y va !",
  "BotMoodboard.Question.4.Option.1": "Non merci.",

  // MOODBOARD ADJUST
  "BotMoodboardAdjust.Question.0":
    "Nous allons ajuster votre précédente demande.\nJe vais vous reposer 3 questions en vous rappelant vos précédentes réponses.\n \nOk pour vous ?",
  "BotMoodboardAdjust.Question.0.Option.0": "Go !",
  "BotMoodboardAdjust.Question.0.Option.1": "Non merci.",

  "BotMoodboardAdjust.Question.1": "Quel est le sujet de votre moodboard ?",
  "BotMoodboardAdjust.Question.1.Help":
    "Votre précédente réponse était : {{previousAnswer}}",
  "BotMoodboardAdjust.Question.2":
    "Quels sont les éléments à voir apparaître ?",
  "BotMoodboardAdjust.Question.2.Help":
    "Votre précédente réponse était : {{previousAnswer}}",
  "BotMoodboardAdjust.Question.3": "Quel type d'ambiance ?",
  "BotMoodboardAdjust.Question.3.Help":
    "Votre précédente réponse était : {{previousAnswer}}",
  "BotMoodboardAdjust.Question.4": "Je suis prêt à relancer.\nOk pour vous ?",
  "BotMoodboardAdjust.Question.4.Help":
    "Cet action consommera 1 crédit(s) / {{credit}} restant(s) et vous recevrez le résultat par e-mail {{timePromess}}.",
  "BotMoodboardAdjust.Question.4.Option.0": "Allez on y va !",
  "BotMoodboardAdjust.Question.4.Option.1": "Non merci.",

  // SUBSCRIPTION
  "Subscription.Question.0":
    "Vous n'avez plus de crédits et vous n'avez pas d'abonnement valide en cours.\n \nSouhaitez-vous contacter SoixanteSeize pour étendre votre accès ?",
  "Subscription.Question.0.Option.0": "Contacter SoixanteSeize",
  "Subscription.Question.0.Option.1": "Me Déconnecter",

  // "Subscription.Question.1":
  //   "Top ! Voici les modalités de souscription d'un abonnement.\nLe tarif est de 30.00 €TTC par mois.\nVous pourrez annuler votre abonnement à tout moment.\nPour chaque période souscrite, l'abonnement reste actif 1 mois après le dernier paiement.\n \nOk pour vous ?",
  // "Subscription.Question.1.Option.0": "Oui, je souscris",
  // "Subscription.Question.1.Option.1": "Non merci",

  // LEGALS
  "Legals.Mention.0": "©2023 SoixanteSeize. All rights reserved.",
  "Legals.Mention.1":
    "Pendant toute la durée 'BETA', les droits ne peuvent être automatiquement cédés et restent la propriété du Studio SoixanteSeize. Veuillez nous contacter pour toute demande de cession de droits de reproduction ou d'exploitation.",

  // IMAGERY
  "BotImagery.Question.0":
    "Nous allons créer une première image à partir de questions simples. Une fois réalisée, vous aurez la possibilité d'affiner les résultats en paramétrant plus finement les aspects techniques et artistiques de votre image.\n \nOn y va ?",
  "BotImagery.QuestionAdjust.0":
    "Nous allons modifier votre précédente demande.\nJe vais vous reposer des questions en vous rappelant vos précédentes réponses.\n \nOk pour vous ?",

  "BotImagery.Question.0.Option.0": "Go !",
  "BotImagery.Question.0.Option.1": "Non merci.",

  "BotImagery.Question.1": "Quelle type d'image souhaitez-vous créer ?",
  "BotImagery.Question.1.Help":
    "Votre précédente réponse était :\n'{{previousAnswer}}'",
  "BotImagery.Question.2": "Veuillez décrire votre image.",
  "BotImagery.Question.2.Help":
    "La précision de la description de la scène laissera à l'IA plus ou moins de liberté créative.",
  "BotImagery.Question.3": "De quoi votre image doit-elle s'inspirer ?",
  "BotImagery.Question.3.Help":
    "L'inspiration peut venir de n'importe quoi, un genre, une ambiance, un style, un artiste, une couleur, une image (url)...",
  "BotImagery.Question.LAUNCH": "Je suis prêt à lancer.\nOk pour vous ?",
  "BotImagery.Question.LAUNCH.Help":
    "Cet action consommera 1 crédit(s) sur {{credit}} restant(s).",
  "BotImagery.Question.LAUNCH.Option.0": "Allez c'est parti !",
  "BotImagery.Question.LAUNCH.Option.1": "Non merci.",
  "BotImagery.Question.RELAUNCH": "Je relance ?",
  "BotImagery.Question.RELAUNCH.Help":
    "Cet action consommera encore 1 crédit(s) sur vos {{credit}} restant(s).",
  "BotImagery.Question.RELAUNCH.Option.0": "Let's Go!",
  "BotImagery.Question.RELAUNCH.Option.1": "Non j'abandonne.",
  "BotImagery.Question.RESULTS":
    "Vos images sont générées. Laquelle souhaitez-vous utiliser ?",
  "BotImagery.Question.RESULTS.Help":
    "Un email vous a également été envoyé avec un lien vers ces résultats.",
  "BotImagery.Question.RESULTS.Option.0": "Aucune image ne me convient",
  "BotImagery.Question.RESULTS.Option.1": "L'image en haut à gauche",
  "BotImagery.Question.RESULTS.Option.2": "L'image en haut à droite",
  "BotImagery.Question.RESULTS.Option.3": "L'image en bas à gauche",
  "BotImagery.Question.RESULTS.Option.4": "L'image en bas à droite",

  "BotImagery.Question.PHOTOGRAPHY.0": "Quel type de photographie ?",
  "BotImagery.Question.PHOTOGRAPHY.0.Help":
    "Vous pouvez choisir dans la liste ou décrire le style souhaité.",
  "BotImagery.Question.PHOTOGRAPHY.1":
    "De quoi votre image doit-elle s'inspirer ?",
  "BotImagery.Question.PHOTOGRAPHY.1.Help":
    "L'inspiration peut venir de n'importe quoi, un genre, une ambiance, un style, un artiste, une couleur, une image (url)...",
  "BotImagery.Question.PHOTOGRAPHY.2": "Quel est la valeur de plan souhaitée ?",
  "BotImagery.Question.PHOTOGRAPHY.2.Help":
    "La valeur de plan est le point de vue de votre image, ce critère va définir la position de la caméra au moment de la prise de vue.",

  "BotImagery.Question.ILLUSTRATION.0": "Quel style d'illustration ?",
  "BotImagery.Question.ILLUSTRATION.0.Help":
    "Vous pouvez choisir dans la liste ou décrire le style souhaité.",
  "BotImagery.Question.ILLUSTRATION.1":
    "De quoi/qui votre image doit-elle s'inspirer ?",
  "BotImagery.Question.ILLUSTRATION.1.Help":
    "Vous pouvez choisir dans la liste ou décrire votre inspiration. Laissez vide si vous ne souhaitez pas d'inspiration particulière.",

  // IMAGERY ADJUST
  "BotImageryAdjust.Question.Parameters":
    "Nous allons ajuster en re-paramétrant certains aspects artistiques et techniques.",
  "BotImageryAdjust.Question.Parameters.Help":
    "Vos paramètres ont été enrichis en anglais par l'IA donc veuillez maintenant saisir en anglais pour éviter les erreurs d'interprétation.",
  "BotImageryAdjust.Question.Parameters.Option.0": "Go !",
  "BotImageryAdjust.Question.Parameters.Option.1": "Retour",

  "BotImageryAdjust.Question.Composition":
    "Tout d'abord, souhaitez-vous prendre cette image comme référence ?",
  "BotImageryAdjust.Question.Composition.Help":
    "Si oui, la composition et la construction de cette image sera la référence de votre prochaine génération.",
  "BotImageryAdjust.Question.Composition.Option.0": "Oui",
  "BotImageryAdjust.Question.Composition.Option.1": "Non",

  "BotImageryAdjust.Question.1": "Quel souhaitez-vous ajuster ?",
  "BotImageryAdjust.Question.1.Help":
    "'LA DEMANDE INITIALE' vous permet de modifier les réponses aux questions précédentes.\n'LES PARAMÈTRES' vous permet de modifier les paramètres techniques de l'image.",
  "BotImageryAdjust.Question.1.Option.0": "La demande initiale",
  "BotImageryAdjust.Question.1.Option.1": "Les paramètres",

  "BotImageryAdjust.Question.11": "Quel est le type d'image souhaité ?",
  "BotImageryAdjust.Question.11.Help":
    "Votre précédente réponse était :\n'{{previousAnswer}}'",
  "BotImageryAdjust.Question.11.Option.0": "Photo",
  "BotImageryAdjust.Question.11.Option.1": "Illustration",
  "BotImageryAdjust.Question.11.Option.2": "Sketch",
  "BotImageryAdjust.Question.11.Option.3": "Moodboard",
  "BotImageryAdjust.Question.12":
    "Que souhaitez-vous voir apparaître sur l'image ?",
  "BotImageryAdjust.Question.12.Help":
    "Votre précédente réponse était : {{previousAnswer}}",
  "BotImageryAdjust.Question.13": "De quoi l'image doit-elle s'inspirer ?",
  "BotImageryAdjust.Question.13.Help":
    "Votre précédente réponse était : {{previousAnswer}}",
  "BotImageryAdjust.Question.14": "Je suis prêt à lancer.\nOk pour vous ?",
  "BotImageryAdjust.Question.14.Help":
    "Cet action consommera 1 crédit(s) / {{credit}} restant(s).",
  "BotImageryAdjust.Question.14.Option.0": "Allez on y va !",
  "BotImageryAdjust.Question.14.Option.1": "Non merci.",

  "BotImageryAdjust.Question.Dynamic.Help":
    "L'interprétation de l'IA était : « {{value}} »",

  // IMAGERY ZOOMOUT
  "BotImageryZoomOut.Question.0": "Où voulez-vous que je crée de la matière ?",
  "BotImageryZoomOut.Question.0.Help":
    "Veuillez choisir de quel côté de l'image l'IA devra créer de la matière.",
  "BotImageryZoomOut.Question.0.Option.0": "Tout autour",
  "BotImageryZoomOut.Question.0.Option.1": "À droite",
  "BotImageryZoomOut.Question.0.Option.2": "En bas",
  "BotImageryZoomOut.Question.0.Option.3": "À gauche",
  "BotImageryZoomOut.Question.0.Option.4": "En haut",
  "BotImageryZoomOut.Question.1":
    "Que souhaitez-vous que j'ajoute autour de l'image ?",
  "BotImageryZoomOut.Question.1.Help":
    "Vous pouvez orienter la création de l'IA en décrivant ce qui doit apparaître autour de l'image.",

  "BotImageryZoomOut.Question.100": "Je suis prêt à lancer.\nOk pour vous ?",
  "BotImageryZoomOut.Question.100.Help":
    "Cet action consommera 1 crédit(s) / {{credit}} restant(s).",

  // MODALS
  // ADD ASSETS
  "SaveImage.Question.0":
    "Dans quelle collection voulez-vous enregistrer cette image ?",
  "SaveImage.Answer.0.New": "Nouvelle collection",
  "SaveImage.Question.1": "Quel est le nom de votre nouvelle collection ?",
  "SaveImage.Question.10": "Veuillez nommer votre image...",
  // REMOVE ASSETS
  "RemoveAsset.Question.0": "Êtes-vous sûr de vouloir supprimer cette image ?",
  "RemoveAsset.Answer.No": "Non",
  "RemoveAsset.Answer.Yes": "Oui",

  // SAVE PRODUCTION
  "SaveProduction.Question.0": "Veuiller donner un nom à votre projet.",
  "SaveProduction.Question.10": "Voulez-vous sauvegarder",
  "SaveProduction.Answer.CreateNew": "Créer un nouveau projet",
  "SaveProduction.Answer.Save": "Sauvegarder",
  "SaveProduction.Success": "Votre projet est sauvegardé.",

  // REMOVE PRODUCTION
  "RemoveProduction.Question.0":
    "Êtes-vous sûr de vouloir supprimer ce projet ?",
  "RemoveProduction.Answer.No": "Non",
  "RemoveProduction.Answer.Yes": "Oui",

  // EXPORT FORMAT
  "ExportFormat.Question.0": "Voulez-vous exporter ce format :",
  "ExportFormat.Question.0.Help":
    "Cet action consommera 1 crédit(s) / {{credit}} restant(s)",
  "ExportFormat.Answer.0.No": "Non",
  "ExportFormat.Answer.0.Yes": "Oui",
  "ExportFormat.Success":
    "Votre exportation est terminée. Retrouvez vos fichiers dans vos téléchargements.",
  // OPTIONS
  "Option.NONE": "Aucun",

  // IMAGE TYPE OPTIONS
  "Option.PHOTOGRAPHY": "Photographie",
  "Option.PHOTOGRAPHY.Description":
    "Image capturée à l'aide d'un appareil photo.",
  "Option.ILLUSTRATION": "Illustration",
  "Option.ILLUSTRATION.Description":
    "Image créée à des fins artistiques ou d'expression.",
  "Option.PAINTING": "Peinture",
  "Option.PAINTING.Description": "Image réalisée en utilisant des peintures.",
  "Option.DRAWING": "Dessin",
  "Option.DRAWING.Description": "Image créée à l'aide de techniques de dessin.",
  "Option.SKETCH": "Croquis",
  "Option.SKETCH.Description":
    "Esquisse ou représentation rapide d'une idée ou d'un concept.",

  // PHOTOGRAPHY TYPE OPTIONS
  "Option.PORTRAIT": "Portrait",
  "Option.PORTRAIT.Description": "Représentation artistique d'une personne.",
  "Option.PACKSHOT": "Produit",
  "Option.PACKSHOT.Description": "Un shooting produit.",
  "Option.STILL_LIFE": "Nature morte",
  "Option.STILL_LIFE.Description": "Représentation d'objets inanimés.",
  "Option.FASHION": "Mode",
  "Option.FASHION.Description": "Photographie liée à l'industrie de la mode.",
  "Option.STREET": "Street",
  "Option.STREET.Description":
    "Photographie de la vie urbaine et des scènes de rue.",
  "Option.ARCHITECTURE": "Architecture",
  "Option.ARCHITECTURE.Description":
    "Photographie d'édifices et de structures architecturales.",
  "Option.CITYSCAPE": "Paysage urbain",
  "Option.CITYSCAPE.Description":
    "Photographie panoramique d'une ville ou d'un paysage urbain.",
  "Option.SEASCAPE": "Paysage marin",
  "Option.SEASCAPE.Description":
    "Photographie de paysages marins, océans ou mers.",
  "Option.LANDSCAPE": "Paysage",
  "Option.LANDSCAPE.Description":
    "Photographie de vastes espaces naturels et de paysages.",
  "Option.NATURE": "Nature",
  "Option.NATURE.Description":
    "Photographie de la faune, de la flore et des phénomènes naturels.",
  "Option.WILDLIFE": "Faune sauvage",
  "Option.WILDLIFE.Description":
    "Photographie d'animaux dans leur habitat naturel.",
  "Option.DOCUMENTARY": "Documentaire",
  "Option.DOCUMENTARY.Description":
    "Photographie destinée à documenter des événements ou des réalités.",
  "Option.UNDERWATER": "Sous-marin",
  "Option.UNDERWATER.Description":
    "Photographie réalisée sous l'eau ou en milieu aquatique.",
  "Option.PANORAMIC": "Panoramique",
  "Option.PANORAMIC.Description":
    "Photographie panoramique étendue sur une grande largeur.",
  "Option.TIME_LAPSE": "Time-lapse",
  "Option.TIME_LAPSE.Description":
    "Technique photographique pour capturer des séquences accélérées.",
  "Option.ASTROPHOTOGRAPHY": "Astrophotographie",
  "Option.ASTROPHOTOGRAPHY.Description":
    "Photographie des objets célestes, du ciel et de l'univers.",
  "Option.MACRO": "Macro",
  "Option.MACRO.Description":
    "Photographie en gros plan de petits objets ou détails.",
  "Option.MICRO": "Micro",
  "Option.MICRO.Description":
    "Photographie à très haute résolution d'échantillons microscopiques.",
  "Option.FISH_EYE": "Œil de poisson",
  "Option.FISH_EYE.Description":
    "Photographie avec un objectif à très grand angle circulaire.",
  "Option.ACTION": "Action",
  "Option.ACTION.Description":
    "Photographie d'actions rapides et/ou de sports en mouvement.",
  "Option.AERIAL": "Aérien",
  "Option.AERIAL.Description": "Photographie prise depuis les airs, d'en haut.",
  "Option.PHOTOGRAMS": "Photogrammes",
  "Option.PHOTOGRAMS.Description":
    "Images obtenues sans appareil photo, par contact direct.",
  "Option.CYANOTYPE": "Cyanotype",
  "Option.CYANOTYPE.Description":
    "Procédé photographique monochrome négatif ancien, par le biais duquel on obtient un tirage photographique bleu de Prusse.",
  "Option.LOMOGRAPHY": "Lomographie",
  "Option.LOMOGRAPHY.Description":
    "Photographie expérimentale avec des appareils lomographiques.",
  "Option.POLAROID": "Polaroid",
  "Option.POLAROID.Description":
    "Photographie instantanée réalisée avec un appareil Polaroid.",
  /* "Option.HOLGA": "Holga",
  "Option.HOLGA.Description":
    "Photographie avec un appareil Holga, créant des effets vintages.",
  "Option.PINHOLE": "Sténopé",
  "Option.PINHOLE.Description":
    "Photographie réalisée avec une caméra obscura et un sténopé.",*/
  "Option.BLACK_AND_WHITE": "Noir et blanc",
  "Option.BLACK_AND_WHITE.Description":
    "Photographie sans couleur, en nuances de gris.",
  "Option.INFRARED": "Infrarouge",
  "Option.INFRARED.Description":
    "Photographie utilisant la lumière infrarouge invisible à l'œil humain.",
  "Option.DOUBLE_EXPOSURE": "Double exposition",
  "Option.DOUBLE_EXPOSURE.Description":
    "Technique superposant deux expositions sur une même photographie.",
  "Option.MIXED_MEDIA": "Média mixte",
  "Option.MIXED_MEDIA.Description":
    "Utilisation de différentes techniques artistiques combinées.",

  // CINEMA GENRE OPTIONS
  "Option.GENRE_ACTION": "Action",
  "Option.GENRE_ACTION.Description": "_",
  "Option.GENRE_ADVENTURE": "Aventure",
  "Option.GENRE_ADVENTURE.Description": "_",
  "Option.GENRE_ANIMATED": "Film Animé",
  "Option.GENRE_ANIMATED.Description": "_",
  "Option.GENRE_COMEDY": "Comédie",
  "Option.GENRE_COMEDY.Description": "_",
  "Option.GENRE_DRAMA": "Drame",
  "Option.GENRE_DRAMA.Description": "_",
  "Option.GENRE_FANTASY": "Fantasy",
  "Option.GENRE_FANTASY.Description": "_",
  "Option.GENRE_HISTORICAL": "Historique",
  "Option.GENRE_HISTORICAL.Description": "_",
  "Option.GENRE_HORROR": "Horreur",
  "Option.GENRE_HORROR.Description": "_",
  "Option.GENRE_MUSICAL": "Musical",
  "Option.GENRE_MUSICAL.Description": "_",
  "Option.GENRE_NOIR": "Film Noir",
  "Option.GENRE_NOIR.Description": "_",
  "Option.GENRE_ROMANCE": "Romance",
  "Option.GENRE_ROMANCE.Description": "_",
  "Option.GENRE_SCIFI": "Science Fiction",
  "Option.GENRE_SCIFI.Description": "_",
  "Option.GENRE_THRILLER": "Thriller",
  "Option.GENRE_THRILLER.Description": "_",
  "Option.GENRE_WESTERN": "Western",
  "Option.GENRE_WESTERN.Description": "_",

  // ART TYPE OPTIONS
  "Option.HYPER_REALISM": "Hyperréalisme",
  "Option.HYPER_REALISM.Description":
    "Style artistique qui vise à créer des œuvres extrêmement réalistes.",
  "Option.POST_MODERNISM": "Postmodernisme",
  "Option.POST_MODERNISM.Description":
    "Courant artistique caractérisé par l'expérimentation, la pluralité des styles et la remise en question des conventions.",
  "Option.POP_ART": "Pop art",
  "Option.POP_ART.Description":
    "Mouvement artistique qui utilise des éléments de la culture populaire dans ses créations.",
  "Option.CONSTRUCTIVISM": "Constructivisme",
  "Option.CONSTRUCTIVISM.Description":
    "Mouvement artistique qui met l'accent sur la construction et l'assemblage de formes géométriques.",
  "Option.CONCEPTUAL_ART": "Art conceptuel",
  "Option.CONCEPTUAL_ART.Description":
    "Forme d'art dans laquelle l'idée ou le concept est plus important que l'objet physique créé.",
  "Option.MINIMALISM": "Minimalisme",
  "Option.MINIMALISM.Description":
    "Style artistique caractérisé par la simplicité et la réduction des éléments visuels.",
  "Option.ABSTRACT": "Abstrait",
  "Option.ABSTRACT.Description":
    "Style artistique qui ne représente pas fidèlement la réalité, mais utilise des formes et des couleurs pour exprimer des idées ou des émotions.",
  "Option.REALISM": "Réalisme",
  "Option.REALISM.Description":
    "Représentation fidèle de la réalité dans l'art, avec un souci des détails et de la précision.",
  "Option.SURREALISM": "Surréalisme",
  "Option.SURREALISM.Description":
    "Mouvement artistique qui explore le monde des rêves, de l'inconscient et de l'imaginaire.",
  "Option.IMPRESSIONISM": "Impressionnisme",
  "Option.IMPRESSIONISM.Description":
    "Style artistique caractérisé par des coups de pinceau rapides et des jeux de lumière pour capturer les impressions fugaces de la réalité.",
  "Option.EXPRESSIONISM": "Expressionnisme",
  "Option.EXPRESSIONISM.Description":
    "Mouvement artistique qui cherche à exprimer les émotions et les expériences intérieures à travers des formes déformées et des couleurs vives.",
  "Option.ART_DECO": "Art déco",
  "Option.ART_DECO.Description":
    "Style artistique qui s'est développé dans les années 1920 et 1930, caractérisé par des formes géométriques, des motifs stylisés et des matériaux luxueux.",
  "Option.ART_NOUVEAU": "Art nouveau",
  "Option.ART_NOUVEAU.Description":
    "Mouvement artistique caractérisé par des lignes courbes, des motifs organiques et l'utilisation de matériaux modernes.",
  "Option.FAUVISM": "Fauvisme",
  "Option.FAUVISM.Description":
    "Mouvement artistique caractérisé par l'utilisation de couleurs vives et de formes simplifiées.",
  "Option.FUTURISM": "Futurisme",
  "Option.FUTURISM.Description":
    "Mouvement artistique qui célèbre la vitesse, la technologie et la modernité.",
  "Option.CUBISM": "Cubisme",
  "Option.CUBISM.Description":
    "Style artistique qui représente les objets en les décomposant en formes géométriques.",
  "Option.DADA": "Dada",
  "Option.DADA.Description":
    "Mouvement artistique qui rejette les conventions et explore le hasard, l'absurde et la provocation.",

  // ART MEDIUM OPTIONS
  "Option.DIGITAL_ART": "Art numérique",
  "Option.DIGITAL_ART.Description":
    "Créations artistiques réalisées à l'aide d'outils numériques.",
  "Option.DIGITAL_PAINTING": "Peinture numérique",
  "Option.DIGITAL_PAINTING.Description":
    "Technique de peinture utilisant des outils numériques pour créer des œuvres.",
  "Option.DIGITAL_DRAWING": "Dessin numérique",
  "Option.DIGITAL_DRAWING.Description":
    "Technique de dessin réalisée à l'aide d'outils numériques.",
  "Option.WATERCOLOR_ART": "Aquarelle",
  "Option.WATERCOLOR_ART.Description":
    "Technique de peinture à l'aide de pigments dilués dans de l'eau.",
  "Option.OIL_PAINTING": "Peinture à l'huile",
  "Option.OIL_PAINTING.Description":
    "Technique de peinture utilisant des pigments liés à l'huile.",
  "Option.ACRYLIC_PAINTING": "Peinture acrylique",
  "Option.ACRYLIC_PAINTING.Description":
    "Technique de peinture utilisant des pigments liés à une émulsion acrylique.",
  "Option.TEMPERA_PAINTING": "Peinture à la tempera",
  "Option.TEMPERA_PAINTING.Description":
    "Technique de peinture utilisant des pigments mélangés à un liant à base d'œuf.",
  "Option.ENCAUSTIC_ART": "Peinture à l'encaustique",
  "Option.ENCAUSTIC_ART.Description":
    "Technique de peinture à base de cire chauffée.",
  "Option.FRESCO_PAINTING": "Peinture à la fresque",
  "Option.FRESCO_PAINTING.Description":
    "Technique de peinture murale utilisant des pigments appliqués sur un enduit frais.",
  "Option.INK_DRAWING": "Dessin à l'encre",
  "Option.INK_DRAWING.Description": "Technique de dessin utilisant de l'encre.",
  "Option.PASTEL_ART": "Pastel",
  "Option.PASTEL_ART.Description":
    "Technique de dessin utilisant des bâtonnets de pastel.",
  "Option.CHARCOAL_DRAWING": "Dessin au fusain",
  "Option.CHARCOAL_DRAWING.Description":
    "Technique de dessin utilisant du fusain.",
  "Option.PENCIL_DRAWING": "Dessin au crayon",
  "Option.PENCIL_DRAWING.Description":
    "Technique de dessin utilisant un crayon.",
  "Option.POINTILLISM_PAINTING": "Peinture pointilliste",
  "Option.POINTILLISM_PAINTING.Description":
    "Technique de peinture utilisant de petites touches de couleur distinctes pour former l'image.",
  "Option.DIVISIONISM_PAINTING": "Peinture divisionniste",
  "Option.DIVISIONISM_PAINTING.Description":
    "Technique de peinture qui utilise de petites touches de couleur séparées pour créer des effets optiques.",

  // ILLUSTRATION GENRE OPTIONS
  "Option.FLAT_DESIGN": "Flat Design",
  "Option.FLAT_DESIGN.Description":
    "Style graphique minimaliste originellement utilisé dans la conception d’interfaces utilisateurs (UI).",
  "Option.ANIME": "Anime",
  "Option.ANIME.Description":
    "Illustrations de style japonais caractérisées par des personnages aux grands yeux et des motifs expressifs.",
  "Option.MANGA": "Manga",
  "Option.MANGA.Description":
    "Bandes dessinées japonaises ou illustrations dans le style manga.",
  "Option.CARTOON": "Dessin animé",
  "Option.CARTOON.Description":
    "Illustrations avec un style de dessin animé humoristique ou caricatural.",
  "Option.COMIC_BOOK": "Bande dessinée",
  "Option.COMIC_BOOK.Description":
    "Illustrations dans le style des bandes dessinées.",
  "Option.FANTASY": "Fantasy",
  "Option.FANTASY.Description":
    "Illustrations avec des éléments fantastiques, imaginaires ou magiques.",
  "Option.HORROR": "Horreur",
  "Option.HORROR.Description":
    "Illustrations avec des éléments effrayants, macabres ou horrifiques.",
  "Option.SCI_FI": "Science-fiction",
  "Option.SCI_FI.Description":
    "Illustrations avec des éléments de science-fiction, futuristes ou extraterrestres.",
  "Option.PIXEL_ART": "Pixel art",
  "Option.PIXEL_ART.Description":
    "Illustrations créées avec des pixels, rappelant les graphismes des jeux vidéo rétro.",
  "Option.LOW_POLY": "Low poly",
  "Option.LOW_POLY.Description":
    "Illustrations avec des modèles 3D simplifiés utilisant des polygones peu nombreux.",
  "Option.VAPORWAVE": "Vaporwave",
  "Option.VAPORWAVE.Description":
    "Illustrations avec un style artistique inspiré de la musique et de la culture vaporwave des années 1980.",
  "Option.RETRO_FUTURISM": "Rétro-futurisme",
  "Option.RETRO_FUTURISM.Description":
    "Illustrations représentant une vision du futur inspirée des perspectives du passé.",
  "Option.CYBERPUNK": "Cyberpunk",
  "Option.CYBERPUNK.Description":
    "Illustrations représentant un univers dystopique et technologique avec des éléments cyberpunk.",
  "Option.STEAMPUNK": "Steampunk",
  "Option.STEAMPUNK.Description":
    "Illustrations avec un style rétro-futuriste inspiré de l'esthétique du XIXe siècle.",
  "Option.URBAN": "Urban",
  "Option.URBAN.Description":
    "Illustrations avec des éléments urbains, des paysages urbains ou de la culture urbaine.",
  "Option.GRAFFITI": "Graffiti",
  "Option.GRAFFITI.Description":
    "Illustrations inspirées de l'art du graffiti urbain et de la culture hip-hop.",
  "Option.STREET_ART": "Art urbain",
  "Option.STREET_ART.Description":
    "Illustrations réalisées dans l'espace public, sur des murs ou des surfaces urbaines.",
  "Option.GLITCH_ART": "Art glitch",
  "Option.GLITCH_ART.Description":
    "Illustrations avec des artefacts numériques, des bugs ou des distorsions esthétiques intentionnelles.",
  "Option.UKIYOE": "Ukiyo-e",
  "Option.UKIYOE.Description":
    "Illustrations japonaises traditionnelles du genre Ukiyo-e, souvent des estampes sur bois.",

  // ARTIST AND STUDIOS OPTIONS
  "Option.STUDIO_GHIBLI": "Studio Ghibli",
  "Option.STUDIO_GHIBLI.Description": "Studio d'animation japonais renommé",
  "Option.PIXAR": "Pixar",
  "Option.PIXAR.Description":
    "Studio d'animation célèbre pour ses films en animation 3D",
  "Option.DISNEY_CLASSIC": "Disney Classique",
  "Option.DISNEY_CLASSIC.Description": "Les classiques de l'animation Disney",
  "Option.VINCENT_VAN_GOGH": "Vincent van Gogh",
  "Option.VINCENT_VAN_GOGH.Description":
    "Peintre post-impressionniste néerlandais",
  "Option.PABLO_PICASSO": "Pablo Picasso",
  "Option.PABLO_PICASSO.Description":
    "Peintre et sculpteur espagnol, l'un des pionniers du mouvement cubiste",
  "Option.LEONARDO_DA_VINCI": "Leonardo da Vinci",
  "Option.LEONARDO_DA_VINCI.Description":
    "Artiste et inventeur italien de la Renaissance",
  "Option.SALVADOR_DALI": "Salvador Dalí",
  "Option.SALVADOR_DALI.Description":
    "Peintre surréaliste espagnol connu pour ses œuvres étranges et oniriques",
  "Option.ANDY_WARHOL": "Andy Warhol",
  "Option.ANDY_WARHOL.Description":
    "Artiste et figure centrale du mouvement pop art",
  "Option.FRIDA_KAHLO": "Frida Kahlo",
  "Option.FRIDA_KAHLO.Description":
    "Artiste mexicaine connue pour ses autoportraits et son exploration de l'identité et de la douleur",
  "Option.BANKSY": "Banksy",
  "Option.BANKSY.Description":
    "Artiste urbain et mystérieux, connu pour ses œuvres politiques et provocantes",
  "Option.JEAN_MICHEL_BASQUIAT": "Jean-Michel Basquiat",
  "Option.JEAN_MICHEL_BASQUIAT.Description":
    "Artiste américain emblématique de la scène artistique underground des années 1980",
  "Option.WASSILY_KANDINSKY": "Wassily Kandinsky",
  "Option.WASSILY_KANDINSKY.Description":
    "Peintre et théoricien russe considéré comme un pionnier de l'art abstrait",
  "Option.MAX_ERNST": "Max Ernst",
  "Option.MAX_ERNST.Description":
    "Artiste allemand associé aux mouvements dadaïste et surréaliste",
  "Option.NORMAN_ROCKWELL": "Norman Rockwell",
  "Option.NORMAN_ROCKWELL.Description":
    "Illustrateur américain connu pour ses peintures de la vie quotidienne américaine",
  "Option.JACKSON_POLLOCK": "Jackson Pollock",
  "Option.JACKSON_POLLOCK.Description":
    "Peintre expressionniste abstrait américain, célèbre pour sa technique du dripping",
  "Option.CLAUDE_MONET": "Claude Monet",
  "Option.CLAUDE_MONET.Description":
    "Peintre impressionniste français, célèbre pour ses séries de tableaux des nymphéas et des meules",
  "Option.HENRI_MATISSE": "Henri Matisse",
  "Option.HENRI_MATISSE.Description":
    "Peintre, sculpteur et graveur français, considéré comme l'un des maîtres de l'art moderne",
  "Option.PAUL_KLEE": "Paul Klee",
  "Option.PAUL_KLEE.Description":
    "Artiste suisse associé au mouvement expressionniste abstrait",
  "Option.EDVARD_MUNCH": "Edvard Munch",
  "Option.EDVARD_MUNCH.Description":
    "Peintre norvégien, connu pour son œuvre emblématique « Le Cri »",
  "Option.EDGAR_DEGAS": "Edgar Degas",
  "Option.EDGAR_DEGAS.Description":
    "Peintre et sculpteur français, célèbre pour ses représentations de la danse et des ballets",
  "Option.JOAN_MIRO": "Joan Miró",
  "Option.JOAN_MIRO.Description":
    "Peintre, sculpteur et céramiste espagnol, associé au mouvement surréaliste",
  "Option.RENE_MAGRITTE": "René Magritte",
  "Option.RENE_MAGRITTE.Description":
    "Peintre surréaliste belge, connu pour ses images provocantes et mystérieuses",
  "Option.PAUL_GAUGUIN": "Paul Gauguin",
  "Option.PAUL_GAUGUIN.Description":
    "Peintre français associé au mouvement post-impressionniste et à l'art primitif",
  "Option.HENRI_ROUSSEAU": "Henri Rousseau",
  "Option.HENRI_ROUSSEAU.Description":
    "Peintre français autodidacte, connu pour ses paysages exotiques et sa naïveté artistique",
  "Option.GEORGIA_O_KEEFFE": "Georgia O'Keeffe",
  "Option.GEORGIA_O_KEEFFE.Description":
    "Artiste américaine connue pour ses peintures de fleurs et de paysages abstraits",
  "Option.EDWARD_HOPPER": "Edward Hopper",
  "Option.EDWARD_HOPPER.Description":
    "Peintre américain associé au réalisme et à la représentation de la solitude urbaine",
  "Option.MARK_ROTHKO": "Mark Rothko",
  "Option.MARK_ROTHKO.Description":
    "Peintre américain associé à l'expressionnisme abstrait et à la couleur de champ",
  "Option.FRANCIS_BACON": "Francis Bacon",
  "Option.FRANCIS_BACON.Description":
    "Peintre anglais connu pour ses portraits expressionnistes et ses représentations déformées du corps humain",

  // SHOT TYPE OPTIONS
  "Option.CLOSE_UP": "Gros plan",
  "Option.CLOSE_UP.Description":
    "Un plan rapproché qui met l'accent sur les détails du sujet.",
  "Option.MEDIUM_SHOT": "Plan moyen",
  "Option.MEDIUM_SHOT.Description":
    "Un plan qui cadre le sujet de la taille des épaules jusqu'à la taille.",
  "Option.LONG_SHOT": "Plan large",
  "Option.LONG_SHOT.Description":
    "Un plan qui montre le sujet dans son ensemble, mais à une certaine distance.",
  "Option.EXTREME_LONG_SHOT": "Plan très large",
  "Option.EXTREME_LONG_SHOT.Description":
    "Un plan très éloigné qui capture une petite partie du sujet.",
  "Option.EXTREME_CLOSE_UP": "Très gros plan rapproché",
  "Option.EXTREME_CLOSE_UP.Description":
    "Un plan très rapproché qui met l'accent sur une partie spécifique du sujet.",
  "Option.BIRD_S_EYE_VIEW": "Vue en plongée",
  "Option.BIRD_S_EYE_VIEW.Description":
    "Un plan pris d'en haut, comme si l'on regardait de haut en bas.",
  "Option.HIGH_ANGLE_SHOT": "Plongée",
  "Option.HIGH_ANGLE_SHOT.Description":
    "Un plan pris d'une position élevée, comme si l'on regardait de haut en bas.",
  "Option.LOW_ANGLE_SHOT": "Contre-plongée",
  "Option.LOW_ANGLE_SHOT.Description":
    "Un plan pris d'une position basse, comme si l'on regardait de bas en haut.",
  "Option.AERIAL_SHOT": "Plan aérien",
  "Option.AERIAL_SHOT.Description":
    "Un plan pris depuis les airs, généralement à l'aide d'un drone ou d'un hélicoptère.",
  "Option.DUTCH_ANGLE_SHOT": "Plan incliné",
  "Option.DUTCH_ANGLE_SHOT.Description":
    "Un plan pris avec une caméra inclinée, créant une sensation de tension ou de désorientation.",
  "Option.OVER_THE_SHOULDER_SHOT": "Plan en surplomb",
  "Option.OVER_THE_SHOULDER_SHOT.Description":
    "Un plan qui montre la perspective depuis l'épaule d'un personnage.",
  "Option.POINT_OF_VIEW_SHOT": "Plan en vue subjective",
  "Option.POINT_OF_VIEW_SHOT.Description":
    "Un plan qui montre la perspective d'un personnage, comme si l'on voyait à travers ses yeux.",
  "Option.WIDE_SHOT": "Plan large",
  "Option.WIDE_SHOT.Description":
    "Un plan qui montre une scène entière avec une perspective large.",
  "Option.FULL_BODY_SHOT": "Plan en pied",
  "Option.FULL_BODY_SHOT.Description":
    "Un plan qui cadre le sujet en entier, des pieds à la tête.",
  "Option.GROUP_SHOT": "Plan de groupe",
  "Option.GROUP_SHOT.Description": "Un plan qui montre un groupe de personnes.",
  "Option.PORTRAIT_SHOT": "Portrait",
  "Option.PORTRAIT_SHOT.Description":
    "Un plan qui met l'accent sur le visage et les expressions d'une personne.",
  "Option.PANORAMIC_SHOT": "Plan panoramique",
  "Option.PANORAMIC_SHOT.Description":
    "Un plan qui montre une vue panoramique étendue.",
  "Option.TILT_SHOT": "Plan vertical",
  "Option.TILT_SHOT.Description":
    "Un plan qui se déplace verticalement, généralement de haut en bas.",

  // LIGHTING OPTIONS
  "Option.NATURAL_LIGHTING": "Éclairage naturel",
  "Option.NATURAL_LIGHTING.Description":
    "Utilisation de la lumière naturelle, comme la lumière du soleil ou la lueur de la lune.",
  "Option.SUNLIGHT": "Lumière du soleil",
  "Option.SUNLIGHT.Description": "Lumière directe provenant du soleil.",
  "Option.MOONLIGHT": "Lumière de lune",
  "Option.MOONLIGHT.Description":
    "Lumière douce et diffuse provenant de la lune.",
  "Option.GOLDEN_HOUR": "Heure dorée",
  "Option.GOLDEN_HOUR.Description":
    "Lumière chaude et dorée lors du lever ou du coucher du soleil.",
  "Option.NORDIC_LIGHT": "Lumière nordique",
  "Option.NORDIC_LIGHT.Description":
    "Lumière douce et froide typique des régions nordiques.",
  "Option.MORNING_LIGHT": "Lumière du matin",
  "Option.MORNING_LIGHT.Description": "Lumière douce et fraîche du matin.",
  "Option.NEON_LIGHT": "Lumière néon",
  "Option.NEON_LIGHT.Description":
    "Lumière vive et colorée produite par des tubes néon.",
  "Option.VOLUMETRIC_LIGHTING": "Éclairage volumétrique",
  "Option.VOLUMETRIC_LIGHTING.Description":
    "Lumière qui crée des effets volumétriques en traversant un milieu particulier, comme la fumée ou la brume.",
  "Option.STUDIO_LIGHTING": "Éclairage de studio",
  "Option.STUDIO_LIGHTING.Description":
    "Éclairage contrôlé utilisé en studio pour créer des effets spécifiques.",
  "Option.HIGH_KEY_LIGHTING": "Éclairage à clé haute",
  "Option.HIGH_KEY_LIGHTING.Description":
    "Éclairage brillant et uniforme qui minimise les ombres.",
  "Option.LOW_KEY_LIGHTING": "Éclairage à clé basse",
  "Option.LOW_KEY_LIGHTING.Description":
    "Éclairage sombre avec de fortes ombres.",
  "Option.THREE_POINT_LIGHTING": "Éclairage à trois points",
  "Option.THREE_POINT_LIGHTING.Description":
    "Technique d'éclairage utilisant trois sources lumineuses principales : la lumière principale, la lumière de remplissage et la lumière d'arrière-plan.",
  "Option.REMBRANDT_LIGHTING": "Éclairage Rembrandt",
  "Option.REMBRANDT_LIGHTING.Description":
    "Éclairage dramatique avec une lumière principale venant d'un angle et créant un triangle de lumière sous un œil.",
  "Option.BUTTERFLY_LIGHTING": "Éclairage en papillon",
  "Option.BUTTERFLY_LIGHTING.Description":
    "Éclairage frontal avec une source lumineuse placée légèrement au-dessus et directement en face du sujet.",
  "Option.SPLIT_LIGHTING": "Éclairage partagé",
  "Option.SPLIT_LIGHTING.Description":
    "Éclairage divisé en deux parties, avec une moitié du visage/sujet éclairée et l'autre moitié dans l'ombre.",
  "Option.LOOP_LIGHTING": "Éclairage en boucle",
  "Option.LOOP_LIGHTING.Description":
    "Éclairage avec une boucle d'ombre sous l'œil du sujet.",
  "Option.SILHOUETTE_LIGHTING": "Éclairage en silhouette",
  "Option.SILHOUETTE_LIGHTING.Description":
    "Éclairage qui crée une silhouette sombre du sujet en le contre-jour.",
  "Option.RIM_LIGHTING": "Éclairage en contour",
  "Option.RIM_LIGHTING.Description":
    "Éclairage provenant de l'arrière du sujet, créant un contour lumineux autour de lui.",
  "Option.BACK_LIGHTING": "Éclairage arrière",
  "Option.BACK_LIGHTING.Description":
    "Éclairage venant de derrière le sujet, créant une séparation entre le sujet et l'arrière-plan.",
  "Option.SIDE_LIGHTING": "Éclairage latéral",
  "Option.SIDE_LIGHTING.Description":
    "Éclairage provenant d'un côté du sujet, créant des ombres prononcées sur le côté opposé.",
  "Option.HARD_LIGHTING": "Éclairage dur",
  "Option.HARD_LIGHTING.Description":
    "Éclairage direct et intense qui crée des ombres dures.",
  "Option.SOFT_LIGHTING": "Éclairage doux",
  "Option.SOFT_LIGHTING.Description":
    "Éclairage diffus et tamisé qui crée des ombres douces.",

  // FX OPTIONS
  "Option.MOTION_BLUR": "Flou de mouvement",
  "Option.MOTION_BLUR.Description":
    "Effet visuel de flou causé par le mouvement de la caméra ou du sujet.",
  "Option.ZOOM_BLUR": "Flou de zoom",
  "Option.ZOOM_BLUR.Description":
    "Effet visuel de flou causé par un mouvement de zoom pendant la prise de vue.",
  "Option.PANNING": "Suivi panoramique",
  "Option.PANNING.Description":
    "Technique de suivi d'un sujet en mouvement avec une caméra, créant un effet de flou directionnel.",
  "Option.TILT_SHIFT": "Effet de bascule/décentrement",
  "Option.TILT_SHIFT.Description":
    "Effet de mise au point sélective qui donne une apparence miniature ou maquette à la scène.",
  "Option.LONG_EXPOSURE": "Exposition longue",
  "Option.LONG_EXPOSURE.Description":
    "Technique d'exposition prolongée pour capturer le mouvement et créer un effet de traînée.",
  "Option.SHALLOW_DEPTH_OF_FIELD": "Profondeur de champ réduite",
  "Option.SHALLOW_DEPTH_OF_FIELD.Description":
    "Effet de profondeur de champ réduite pour mettre l'accent sur le sujet principal.",
  "Option.DEEP_DEPTH_OF_FIELD": "Profondeur de champ étendue",
  "Option.DEEP_DEPTH_OF_FIELD.Description":
    "Effet de profondeur de champ étendue pour une mise au point nette sur toute la scène.",
  "Option.BRIGHTNESS": "Luminosité",
  "Option.BRIGHTNESS.Description":
    "Ajustement de la luminosité globale de l'image.",
  "Option.CONTRAST": "Contraste",
  "Option.CONTRAST.Description":
    "Ajustement du contraste entre les tons clairs et sombres de l'image.",
  "Option.SATURATION": "Saturation",
  "Option.SATURATION.Description":
    "Ajustement de l'intensité des couleurs de l'image.",
};
