import { Fragment, FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";

export interface IPageHeaderProps {
  darkMode?: boolean;
  title: string;
  ontitle?: string | null;
  subtitle?: string | null;
  text?: string;
  topButton?: ReactNode;
  textButton?: ReactNode;
  buttons?: ReactNode[];
  id: string;
}

export const PageHeaderLayout: FunctionComponent<IPageHeaderProps> = ({
  darkMode,
  title,
  ontitle,
  subtitle,
  text,
  topButton,
  textButton,
  buttons = [],
  id,
}) => {
  //
  return (
    <PageHeaderContainer darkMode={darkMode}>
      <TopRow topButton={topButton ? true : false}>
        {topButton && topButton}
        <Id>{`${id}`}</Id>
      </TopRow>
      <Header>
        <TitleContainer>
          <div style={{ width: "100%" }}>
            {ontitle && <Ontitle>{ontitle}</Ontitle>}
            <TitleRow>
              <Title>{title}</Title>
              {buttons && buttons.length > 0 && (
                <ButtonRow>
                  {buttons.map((button, index) => (
                    <Fragment key={index}>{button}</Fragment>
                  ))}
                </ButtonRow>
              )}
            </TitleRow>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {(text || textButton) && (
              <TextContainer>
                {text && <Text>{text}</Text>}
                {textButton && textButton}
              </TextContainer>
            )}
          </div>
        </TitleContainer>
      </Header>
    </PageHeaderContainer>
  );
};

const TopRow = styled.div<{ topButton?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.topButton ? "space-between" : "flex-end"};
  width: 100%;
  max-width: 1080px;
  align-items: flex-start;
`;

const PageHeaderContainer = styled.div<{ darkMode?: boolean }>`
  width: 100%;
  max-width: 1080px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  align-items: flex-end;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 1080px;

  margin-top: 40px;
`;

const TitleRow = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
    gap: 20px;
  }
`;

const Title = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 72px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.fakeWhite};
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 48px;
  }
`;

const Ontitle = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 24px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: left;
`;

const Subtitle = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const Id = styled.div`
  font-size: 12px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.soixanteseize};
  text-align: right;
  margin-top: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: 100%;
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: left;
`;

const TextContainer = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
