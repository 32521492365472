import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

export const apiHost =
  process.env.REACT_APP_API_HOST ?? "http://localhost:8081"; //

/**********************************************************
 *** AUTH CONTEXT ***
 **********************************************************/

const middlewareUpdate = createUploadLink({ uri: apiHost + "/graphql" });
const authLink = setContext((_, { headers }) => {
  // Leer el storage almacenado
  const token = localStorage.getItem("token");
  // console.log(token);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Apollo-Require-Preflight": "true",
    },
  };
});

export const client = new ApolloClient({
  credentials: "same-origin",
  link: authLink.concat(middlewareUpdate),

  cache: new InMemoryCache(),
  connectToDevTools: true,
});
