import { motion } from "framer-motion";
import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export interface INavigationItemProps {
  index: number;
  color: string;
  children: any;
  destination?: string;
  category: string;
  withSuperMenu: boolean;
  openSuperMenu: boolean;
  setOpenSuperMenu: (index: number) => void;
}
const NavigationItem: FunctionComponent<INavigationItemProps> = ({
  index,
  color,
  children,
  destination,
  category,
  withSuperMenu,
  openSuperMenu,
  setOpenSuperMenu,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHover(true);
    if (withSuperMenu) setOpenSuperMenu(index);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    if (withSuperMenu) setOpenSuperMenu(-1);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <NavElement
        style={{
          color: isHover
            ? color
            : destination === location.pathname
            ? COLORS.fakeWhite
            : COLORS.transparentLight,
        }}
        onClick={() => {
          if (destination === location.pathname) window.location.reload();
          else if (destination?.includes("http")) window.open(destination);
          else if (destination) navigate(destination);
        }}
      >
        {children}
      </NavElement>
    </motion.div>
  );
};

export default NavigationItem;

const NavElement = styled.div`
  margin: 2px 20px 0px 20px;
`;
