import { gql } from "@apollo/client";
import { UserFragment } from "../fragments/user";

export const CREATE_USER = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      ...User
    }
  }
  ${UserFragment}
`;
