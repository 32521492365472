import { useEffect } from "react";
import styled from "styled-components";
import cursorDisplacementSprite from "./img/displace-circle.png";
import backgroundDisplacementSprite from "./img/map-9.jpg";
import Nature1 from "./img/try.jpg";
import "./rgbKineticSliderLib"; // Import the library code

interface Window {
  rgbKineticSlider: (options: any) => void;
}

export const KineticEffect = () => {
  // images setup
  const images = [Nature1, Nature1, Nature1];

  // content setup
  const texts = [
    ["Earth", "Surface gravity: 9.807 m/s²"],
    ["Mars", "Surface gravity: 3.711 m/s²"],
    ["Venus", "Surface gravity: 8.87 m/s²"],
  ];

  useEffect(() => {
    (window as unknown as Window).rgbKineticSlider({
      // images and content sources
      slideImages: images, // array of images >demo size : 1920 x 1080
      itemsTitles: texts, // array of titles / subtitles

      // displacement images sources
      backgroundDisplacementSprite, // slide displacement image
      cursorDisplacementSprite, // cursor displacement image

      // cursor displacement effect
      cursorImgEffect: true, // enable cursor effect
      cursorTextEffect: false, // enable cursor text effect
      cursorScaleIntensity: 0.65, // cursor effect intensity
      cursorMomentum: 0.14, // lower is slower

      // swipe
      swipe: true, // enable swipe
      swipeDistance: window.innerWidth * 0.4, // swipe distance - ex : 580
      swipeScaleIntensity: 2, // scale intensity during swipping

      // slide transition
      slideTransitionDuration: 1, // transition duration
      transitionScaleIntensity: 30, // scale intensity during transition
      transitionScaleAmplitude: 160, // scale amplitude during transition

      // regular navigation
      nav: true, // enable navigation
      navElement: ".main-nav", // set nav class

      // image rgb effect
      imagesRgbEffect: true, // enable img rgb effect
      imagesRgbIntensity: 0.9, // set img rgb intensity
      navImagesRgbIntensity: 80, // set img rgb intensity for regular nav

      // texts settings
      textsDisplay: false, // show title
      textsSubTitleDisplay: true, // show subtitles
      textsTiltEffect: true, // enable text tilt
      googleFonts: ["Playfair Display:700", "Roboto:400"], // select google font to use
      buttonMode: false, // enable button mode for title
      textsRgbEffect: true, // enable text rgb effect
      textsRgbIntensity: 0.03, // set text rgb intensity
      navTextsRgbIntensity: 15, // set text rgb intensity for regular nav

      textTitleColor: "white", // title color
      textTitleSize: 125, // title size
      mobileTextTitleSize: 125, // title size
      textTitleLetterspacing: 3, // title letterspacing

      textSubTitleColor: "white", // subtitle color ex : 0x000000
      textSubTitleSize: 21, // subtitle size
      mobileTextSubTitleSize: 21, // mobile subtitle size
      textSubTitleLetterspacing: 2, // subtitle letter spacing
      textSubTitleOffsetTop: 90, // subtitle offset top
      mobileTextSubTitleOffsetTop: 90, // mobile subtitle offset top
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KineticContainer id="rgbKineticSlider">
      {/* <nav>
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <BtnPrev href="#" className="main-nav prev" data-nav="previous">
          Prev <span></span>
        </BtnPrev>
        <BtnNext href="#" className="main-nav next" data-nav="next">
          Next <span></span>
        </BtnNext>
      </nav> */}
    </KineticContainer>
  );
};

const KineticContainer = styled.div`
  mix-blend-mode: screen;
  width: 100%;
  height: 100vh;
  canvas {
    position: absolute;
    z-index: 10000;
    display: block;
    top: 0 !important;
    left: 0 !important;
    transform: scale(1) !important;
  }
`;

/* const BtnPrev = styled.a`
  color: #fff;
  position: absolute;
  z-index: 10001;
  top: 50vh;
  background-color: black;
  left: 20px;
`;

const BtnNext = styled.a`
  color: #fff;
  position: absolute;
  z-index: 10001;
  top: 50vh;
  background-color: black;
  right: 20px;
`; */
