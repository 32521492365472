import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  faEye,
  faMagicWandSparkles,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { capitalize, find, forEach } from "lodash";
import moment from "moment";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Account, User } from "../../graphql/generated/graphql";
import { CREATE_IMAGERY } from "../../graphql/mutations/createImagery";
import { LAUNCH_IMAGERY } from "../../graphql/mutations/launchImagery";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import { getPromptObject } from "../../helpers/getPromptObject";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import {
  artistStudioOptions,
  cinemaGenreOptions,
  fxTypeOptions,
  illustrationArtistOptions,
  illustrationStyleOptions,
  lightingTypeOptions,
  photoTypeOptions,
  shotTypeOptions,
} from "../../libs/constants/options";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
export interface IBotProps {
  darkMode: boolean;
}

export const BotImageryAdjust: FunctionComponent<IBotProps> = ({
  darkMode,
}) => {
  //
  localStorage.removeItem("redirect");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const paramCodeValue = searchParams.get("code");
  const paramDeliveryValue = searchParams.get("delivery");

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);
  const [initialImagery, setInitialImagery] = useState<any | undefined>(
    undefined
  );
  const [compositionValidated, setCompositionValidated] =
    useState<boolean>(false);

  /**********************************************************
   *** NEW PARAMETERS ***
   **********************************************************/

  const [parametersStringArray, setParametersStringArray] = useState<string[]>(
    []
  );
  const updateParametersStringArray = useCallback(
    (newStr: string) =>
      setParametersStringArray([...parametersStringArray, newStr]),
    [parametersStringArray]
  );
  // console.log("parametersStringArray", parametersStringArray);

  /**********************************************************
   *** CONVERSATION STATE ***
   **********************************************************/

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** COMPONENTS ***
   **********************************************************/

  const ErrorButtons = [
    <Button
      key={"button0"}
      icon={faMagicWandSparkles}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => window.location.reload()}
      type="secondary"
    >
      {t("Button.Imagery.ReQuery")}
    </Button>,
    <Button
      key={"buttonA1"}
      icon={faTimes}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => navigate("/bot/start")}
      type="secondary"
    >
      {t("Button.BackBotStart")}
    </Button>,
  ];

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [user, setUser] = useState<User | undefined>(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      setAccount(account);
      setUser(account?.users[0]);
      if (
        account.credit <= 0 &&
        (!account.subscriptionExpiration ||
          (account.subscriptionExpiration &&
            moment().isAfter(moment(account.subscriptionExpiration))))
      )
        navigate("/subscription");
    },
  });

  /**********************************************************
   *** INITIAL IMAGERY ***
   **********************************************************/

  useQuery(IMAGERY, {
    variables: {
      imageryInput: { imageryId: paramCodeValue },
    },
    onCompleted: (data: any) => {
      setInitialImagery(data?.imagery);
    },
  });

  /**********************************************************
   *** IMAGERY ***
   **********************************************************/

  const [imageryId, setImageryId] = useState<string | undefined>(undefined);

  const [createImagery, { loading: createImageryLoading }] = useMutation(
    CREATE_IMAGERY,
    {
      onCompleted: (data) => {
        // console.log("CREATE_IMAGERY DATA:", data);
        setImageryId(data.createImagery.id);
        launchImagery({
          variables: { imageryInput: { imageryId: data.createImagery.id } },
        });
      },
      onError: (error: any) => {
        console.log("CREATE_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
        setButtons(ErrorButtons);
      },
    }
  );

  const [launchImagery, { loading: launchImageryLoading }] = useMutation(
    LAUNCH_IMAGERY,
    {
      onCompleted: (data) => {
        setSuccess(`${t("Success.Message.ImageryCreated")}`);
      },
      onError: (error: any) => {
        setSuccess(`${t("Error.Message.ManualProcessEngaged")}`);
        setButtons(ErrorButtons);
      },
    }
  );

  /**********************************************************
   *** DELIVERY ***
   **********************************************************/

  const [deliveryOk, setDeliveryOk] = useState<boolean>(false);

  const [getImagery, { data: imageryData }] = useLazyQuery(IMAGERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.imagery?.delivery?.length > 0) {
        setButtons([
          <Button
            icon={faEye}
            iconPosition="left"
            darkMode={darkMode}
            onClick={() =>
              navigate(`/imagery/delivery?code=${imageryData.imagery.id}`)
            }
            type="validation"
          >
            {t("Button.Imagery.SeeResults")}
          </Button>,
        ]);
        setDeliveryOk(true);
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageryId && !deliveryOk) {
        getImagery({ variables: { imageryInput: { imageryId } } });
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    createImageryLoading,
    deliveryOk,
    getImagery,
    imageryId,
    launchImageryLoading,
  ]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo((): IQuestion[] => {
    const image =
      initialImagery?.delivery[parseInt(paramDeliveryValue ?? "0")]?.asset;

    const parametersQuestions: IQuestion[] = [];

    const originalPrompt = getPromptObject(initialImagery?.prompt);
    console.log("originalPrompt", originalPrompt);

    const starterIndex = 21;

    const promptArray: { key: string; value: string }[] = [];
    forEach(originalPrompt, (value: string, key: string) => {
      if (
        !["type", "image", "camera_model", "camera_lense"].includes(
          key.toLowerCase()
        ) &&
        !key.toLowerCase().includes("https")
      )
        promptArray.push({ key, value });
    });
    // console.log("promptArray:", promptArray);

    // const techParamKeys = [
    //   /* "type", */
    //   /* "shot_type", */
    //   /* "camera_model",
    //   "camera_lense", */
    //   /* "lighting", */
    //   /* "special_effects", */
    // ];

    let promptArrayFiltered = promptArray;
    // if (compositionValidated) {
    //   promptArrayFiltered = promptArray.filter(
    //     (pa) => !techParamKeys.includes(pa.key.toLowerCase())
    //   );
    // }
    // console.log("promptArrayFiltered:", promptArrayFiltered);

    for (let index = 0; index < promptArrayFiltered.length; index++) {
      const p = promptArrayFiltered[index];

      const textAndListAnswerKeys = [
        { key: "type", options: photoTypeOptions(t) },
        { key: "genre", options: cinemaGenreOptions(t) },
        { key: "style", options: illustrationStyleOptions(t) },
        {
          key: "inspiration",
          options:
            initialImagery?.type.toLowerCase() === "photography"
              ? artistStudioOptions(t)
              : initialImagery?.type.toLowerCase() === "illustration"
              ? illustrationArtistOptions(t)
              : artistStudioOptions(t),
        },
        // { key: "emotion", options: photoTypeOptions(t) },
        { key: "lighting", options: lightingTypeOptions(t) },
        { key: "shot_type", options: shotTypeOptions(t) },
        /* { key: "camera_model", options: photoTypeOptions(t) },
        { key: "camera_lense", options: photoTypeOptions(t) }, */
        { key: "special_effects", options: fxTypeOptions(t) },
      ];

      const answerType = textAndListAnswerKeys
        .map((k) => k.key)
        .includes(p.key.toLowerCase())
        ? AnswerTypes.TEXT_AND_LIST
        : AnswerTypes.TEXT;

      const options =
        find(textAndListAnswerKeys, (k) => k.key === p.key.toLowerCase())
          ?.options ?? undefined;

      const q = {
        step: starterIndex + index,
        q: `${capitalize(p.key.replace(/_/g, " "))}:`,
        help: t("BotImageryAdjust.Question.Dynamic.Help", {
          value: p.value,
        }),
        answerType,
        options,
        initialValue: p.value,
        nextStep:
          index === promptArrayFiltered.length - 1
            ? 100
            : starterIndex + index + 1,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue =
            find(question.options, (o) => o.label === answer)?.value ?? answer;

          updateParametersStringArray(`${p.key}: ${answerValue}`);
          setStep(getNextStep(question, answer));
        },
      };
      parametersQuestions.push(q);
    }

    return [
      {
        step: 0,
        images: image ? [image] : undefined,
        q: t("BotImageryAdjust.Question.Parameters"),
        help: t("BotImageryAdjust.Question.Parameters.Help"),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImageryAdjust.Question.Parameters.Option.1"),
            label: t("BotImageryAdjust.Question.Parameters.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImageryAdjust.Question.Parameters.Option.0"),
            label: t("BotImageryAdjust.Question.Parameters.Option.0"),
          },
        ],
        nextStep: 1, //initialImagery?.type.toLowerCase() === "photography" ? 1 : 21,
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImageryAdjust.Question.Parameters.Option.0")) {
            updateParametersStringArray(`IMAGE: ${initialImagery?.type}`);
            setStep(getNextStep(question, answer));
          }
          if (
            answer === t("BotImageryAdjust.Question.Parameters.Option.1") &&
            initialImagery
          ) {
            navigate(`/imagery/delivery?code=${initialImagery.id}`);
          }
        },
      },
      {
        step: 1,
        q: t("BotImageryAdjust.Question.Composition"),
        help: t("BotImageryAdjust.Question.Composition.Help"),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImageryAdjust.Question.Composition.Option.1"),
            label: t("BotImageryAdjust.Question.Composition.Option.1"),
          },
          {
            value: t("BotImageryAdjust.Question.Composition.Option.0"),
            label: t("BotImageryAdjust.Question.Composition.Option.0"),
          },
        ],
        nextStep: 21,
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImageryAdjust.Question.Composition.Option.0")) {
            setCompositionValidated(true);
            setStep(getNextStep(question, answer));
          }
          if (answer === t("BotImageryAdjust.Question.Composition.Option.1")) {
            setCompositionValidated(false);
            setStep(getNextStep(question, answer));
          }
        },
      },

      ...parametersQuestions,

      {
        step: 100,
        q: t("BotImageryAdjust.Question.14"),
        help: t("BotImageryAdjust.Question.14.Help", {
          credit: account?.credit ?? 0,
          // timePromess: getTimePromess(120),
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImagery.Question.LAUNCH.Option.1"),
            label: t("BotImagery.Question.LAUNCH.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.LAUNCH.Option.0"),
            label: t("BotImagery.Question.LAUNCH.Option.0"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.LAUNCH.Option.0")) {
            const paramsToKeep = ["camera_model", "camera_lense"];
            if (compositionValidated) {
              forEach(originalPrompt, (value: string, key: string) => {
                if (
                  [/* ...techParamKeys, */ ...paramsToKeep].includes(
                    key.toLowerCase()
                  )
                )
                  parametersStringArray.push(`${key}: ${value}`);
              });
            } else {
              forEach(originalPrompt, (value: string, key: string) => {
                if ([...paramsToKeep].includes(key.toLowerCase()))
                  parametersStringArray.push(`${key}: ${value}`);
              });
            }
            // console.log("parametersStringArray:", parametersStringArray);
            const need = getPromptObject(parametersStringArray.join(" | "));

            // console.log("need:", need);
            // console.log("initialImagery:", initialImagery);

            createImagery({
              variables: {
                createImageryInput: {
                  type: initialImagery.type,
                  parametric: true,
                  need,
                  refUrl: compositionValidated ? image : undefined,
                  seeds: [
                    ...(initialImagery?.seeds ?? []).map((s: any) => ({
                      id: s.id,
                      asset: s.asset,
                      delivery: s.delivery,
                    })),
                    {
                      id: initialImagery?.id,
                      delivery: parseInt(paramDeliveryValue ?? "0"),
                      asset: image,
                    },
                  ],
                },
              },
            });
          }
          if (answer === t("BotImageryAdjust.Question.&4.Option.1")) {
            navigate("/bot/start");
          }
        },
      },
    ]
      .filter((q) => q.step >= (step ?? 0))
      .sort((a, b) => a.step - b.step);
  }, [
    account?.credit,
    compositionValidated,
    createImagery,
    initialImagery,
    navigate,
    paramDeliveryValue,
    parametersStringArray,
    step,
    t,
    updateParametersStringArray,
  ]);

  console.log("STEP", step);
  // console.log("ALL QUESTIONS", allQuestions);

  if (!user) return null;

  return (
    <Layout
      darkMode={darkMode}
      processing={createImageryLoading || launchImageryLoading}
      processingProgress={`${
        imageryData?.imagery?.progress?.percentage ?? "0"
      } %`}
    >
      {initialImagery && (
        <Conversation
          darkMode={darkMode}
          allQuestions={allQuestions}
          step={step ?? 0}
          setStep={setStep}
          error={error}
          success={success}
          resetErrors={() => setError(undefined)}
          processing={createImageryLoading || launchImageryLoading}
          buttons={buttons}
          resetButtons={() => setButtons([])}
        />
      )}
    </Layout>
  );
};
