import React, { FunctionComponent } from "react";
import Typist from "react-typist";
import styled from "styled-components";
import { COLORS } from "../styles/colors";

interface IFuncProps {
  children?: string;
  color?: React.CSSProperties["color"];
  backgroundColor?: React.CSSProperties["color"];
  darkMode?: boolean;
  style?: React.CSSProperties;
  onTypingDone?: () => void;
  onCharacterTyped?: () => void;
}

export const Typer: FunctionComponent<IFuncProps> = ({
  children,
  color,
  backgroundColor,
  darkMode,
  style,
  onTypingDone,
  onCharacterTyped,
}) => {
  return (
    <TyperContainer style={style}>
      <Typist
        cursor={{
          show: false,
          blink: true,
          element: "_",
          hideWhenDone: true,
        }}
        startDelay={0}
        avgTypingDelay={15}
        stdTypingDelay={1}
        onCharacterTyped={onCharacterTyped}
        onTypingDone={onTypingDone}
      >
        {children?.split("\n").map((child, i) => (
          <div key={i}>
            <Span
              color={color}
              darkMode={darkMode}
              backgroundColor={backgroundColor}
            >
              {child}
            </Span>
          </div>
        ))}
      </Typist>
    </TyperContainer>
  );
};

const TyperContainer = styled.div`
  color: ${COLORS.soixanteseize};
`;

const Span = styled.span<{
  color?: React.CSSProperties["color"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  darkMode?: boolean;
}>`
  --color: ${(props) => {
    return props.color
      ? props.color
      : props.darkMode
      ? COLORS.white
      : COLORS.black;
  }};
  --bg-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.darkMode
      ? COLORS.fakeBlack
      : COLORS.fakeWhite};

  color: var(--color);
  background-color: var(--bg-color);
  padding: 15px 0px 5px 0px;
`;
