import { faSquareMinus, faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
import { capitalize, map } from "lodash";
import { Fragment, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Settings } from "../../../graphql/generated/graphql";
import { DragIncrementer } from "../../../libs/components/fields/DragIncrementer";
import Radio from "../../../libs/components/radio/Radio";
import { COLORS } from "../../../libs/styles/colors";
import { IncrementSelector } from "../IncrementSelector";
import { animationSets } from "../constants";
import { MessageContainer } from "./MessageContainer";

export interface IParamAnimationProps {
  darkMode: boolean;
  displayAdSettings: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  setReRender: (value: SetStateAction<boolean>) => void;
  stepValidated: boolean;
}

export const ParamAnimation: FunctionComponent<IParamAnimationProps> = ({
  darkMode,
  displayAdSettings,
  setDisplayAdSettings,
  setReRender,
  stepValidated,
}) => {
  //
  const { t } = useTranslation();

  const animationParameters: any[] = map(
    displayAdSettings.parameters,
    (value, key) => ({
      name: key,
      animation: value.animation,
    })
  ).reverse();

  const setNestedValue = (obj: any, pathArray: string[], value: any) => {
    if (pathArray.length === 0) return;
    pathArray.reduce((acc, key, idx) => {
      if (idx === pathArray.length - 1) {
        acc[key] = value;
      } else if (!acc[key] || typeof acc[key] !== "object") {
        acc[key] = {};
      }
      return acc[key];
    }, obj);
  };

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={t("Factory.Message.Animation")}
        buttons={[]}
      />

      <ParamContainer>
        <Radio
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
          items={map(animationSets, (set, key) => {
            return {
              label: key,
              onLabel: "ANIMATION",
              onClick: () => {
                setDisplayAdSettings((prevstate: Settings) => {
                  prevstate.parameters.catchline.animation = set.catchline;
                  prevstate.parameters.cta.animation = set.cta;
                  prevstate.parameters.logo.animation = set.logo;
                  prevstate.parameters.image.animation = set.image;
                  return { ...prevstate };
                });
                setReRender(true);
              },
              active:
                JSON.stringify({
                  catchline: displayAdSettings.parameters.catchline.animation,
                  cta: displayAdSettings.parameters.cta.animation,
                  logo: displayAdSettings.parameters.logo.animation,
                  image: displayAdSettings.parameters.image.animation,
                }) === JSON.stringify(set),
            };
          })}
        />
        <Separator />
        {animationParameters && (
          <>
            {animationParameters.map((element, key) => {
              if (element.name === "destination") return null;
              return (
                <Fragment key={key}>
                  <Message>{capitalize(element.name)}</Message>
                  {map(element.animation, (level1, level1Key) => {
                    return (
                      <Param key={level1Key}>
                        {/* <TitleLabel>{level1Key}</TitleLabel> */}
                        <ParamLine>
                          {map(level1, (level2, level2Key) => {
                            if (typeof level2 !== "object") {
                              if (typeof level2 === "number")
                                return (
                                  <Param key={level2Key}>
                                    <TitleLabel>
                                      {capitalize(level2Key)}
                                    </TitleLabel>
                                    <ParamRow>
                                      <DragIncrementer
                                        value={level2}
                                        decimals={
                                          ["repeat", "x", "y", "z"].includes(
                                            level2Key
                                          )
                                            ? 0
                                            : 1
                                        }
                                        slow={
                                          ["repeat", "x", "y", "z"].includes(
                                            level2Key
                                          )
                                            ? 10
                                            : 40
                                        }
                                        setValue={(value: number) => {
                                          setDisplayAdSettings(
                                            (prevstate: Settings) => {
                                              setNestedValue(
                                                prevstate.parameters,
                                                [
                                                  element.name,
                                                  "animation",
                                                  level1Key,
                                                  level2Key,
                                                ],
                                                value
                                              );

                                              return { ...prevstate };
                                            }
                                          );
                                          setReRender(true);
                                        }}
                                      />
                                      <ConfigInput
                                        value={level2}
                                        onChange={(e) => {
                                          if (isNaN(Number(e.target.value)))
                                            return;
                                          setDisplayAdSettings(
                                            (prevstate: Settings) => {
                                              setNestedValue(
                                                prevstate.parameters,
                                                [
                                                  element.name,
                                                  "animation",
                                                  level1Key,
                                                  level2Key,
                                                ],
                                                Number(e.target.value)
                                              );

                                              return { ...prevstate };
                                            }
                                          );
                                          setReRender(true);
                                        }}
                                      />
                                      <IncrementSelector
                                        plusIcon={faSquarePlus}
                                        minusIcon={faSquareMinus}
                                        darkMode={darkMode}
                                        setPlus={() => {
                                          const increment = [
                                            "repeat",
                                            "x",
                                            "y",
                                            "z",
                                          ].includes(level2Key)
                                            ? 1
                                            : 0.1;
                                          setDisplayAdSettings(
                                            (prevstate: Settings) => {
                                              setNestedValue(
                                                prevstate.parameters,
                                                [
                                                  element.name,
                                                  "animation",
                                                  level1Key,
                                                  level2Key,
                                                ],
                                                parseFloat(
                                                  (level2 + increment).toFixed(
                                                    1
                                                  )
                                                )
                                              );

                                              return { ...prevstate };
                                            }
                                          );
                                          setReRender(true);
                                        }}
                                        setMinus={() => {
                                          const increment = [
                                            "repeat",
                                            "x",
                                            "y",
                                            "z",
                                          ].includes(level2Key)
                                            ? 1
                                            : 0.1;
                                          setDisplayAdSettings(
                                            (prevstate: Settings) => {
                                              setNestedValue(
                                                prevstate.parameters,
                                                [
                                                  element.name,
                                                  "animation",
                                                  level1Key,
                                                  level2Key,
                                                ],
                                                parseFloat(
                                                  (level2 - increment).toFixed(
                                                    1
                                                  )
                                                )
                                              );

                                              return { ...prevstate };
                                            }
                                          );
                                          setReRender(true);
                                        }}
                                      />
                                    </ParamRow>
                                  </Param>
                                );
                              else return null;
                            } else {
                              return (
                                <Param key={level2Key}>
                                  {/* <TitleLabel>{capitalize(level2Key)}</TitleLabel> */}
                                  <ParamLine>
                                    {map(level2, (level3, level3Key) => {
                                      if (typeof level3 === "number")
                                        return (
                                          <Param key={level3Key}>
                                            <TitleLabel>{`${capitalize(
                                              level2Key
                                            )} ${capitalize(
                                              level3Key
                                            )}`}</TitleLabel>
                                            <ParamRow>
                                              <DragIncrementer
                                                value={level3}
                                                decimals={
                                                  [
                                                    "repeat",
                                                    "x",
                                                    "y",
                                                    "z",
                                                  ].includes(level3Key)
                                                    ? 0
                                                    : 1
                                                }
                                                slow={
                                                  [
                                                    "repeat",
                                                    "x",
                                                    "y",
                                                    "z",
                                                  ].includes(level3Key)
                                                    ? 10
                                                    : 40
                                                }
                                                setValue={(value: number) => {
                                                  setDisplayAdSettings(
                                                    (prevstate: Settings) => {
                                                      setNestedValue(
                                                        prevstate.parameters,
                                                        [
                                                          element.name,
                                                          "animation",
                                                          level1Key,
                                                          level2Key,
                                                          level3Key,
                                                        ],
                                                        value
                                                      );
                                                      return { ...prevstate };
                                                    }
                                                  );
                                                  setReRender(true);
                                                }}
                                              />
                                              <ConfigInput
                                                value={level3}
                                                onChange={(e) => {
                                                  if (
                                                    isNaN(
                                                      Number(e.target.value)
                                                    )
                                                  )
                                                    return;
                                                  setDisplayAdSettings(
                                                    (prevstate: Settings) => {
                                                      setNestedValue(
                                                        prevstate.parameters,
                                                        [
                                                          element.name,
                                                          "animation",
                                                          level1Key,
                                                          level2Key,
                                                          level3Key,
                                                        ],
                                                        Number(e.target.value)
                                                      );

                                                      return { ...prevstate };
                                                    }
                                                  );
                                                  setReRender(true);
                                                }}
                                              />
                                              <IncrementSelector
                                                plusIcon={faSquarePlus}
                                                minusIcon={faSquareMinus}
                                                darkMode={darkMode}
                                                setPlus={() => {
                                                  const increment = [
                                                    "repeat",
                                                    "x",
                                                    "y",
                                                    "z",
                                                  ].includes(level3Key)
                                                    ? 1
                                                    : 0.1;
                                                  setDisplayAdSettings(
                                                    (prevstate: Settings) => {
                                                      setNestedValue(
                                                        prevstate.parameters,
                                                        [
                                                          element.name,
                                                          "animation",
                                                          level1Key,
                                                          level2Key,
                                                          level3Key,
                                                        ],
                                                        parseFloat(
                                                          (
                                                            level3 + increment
                                                          ).toFixed(1)
                                                        )
                                                      );

                                                      return { ...prevstate };
                                                    }
                                                  );
                                                  setReRender(true);
                                                }}
                                                setMinus={() => {
                                                  const increment = [
                                                    "repeat",
                                                    "x",
                                                    "y",
                                                    "z",
                                                  ].includes(level3Key)
                                                    ? 1
                                                    : 0.1;
                                                  setDisplayAdSettings(
                                                    (prevstate: Settings) => {
                                                      setNestedValue(
                                                        prevstate.parameters,
                                                        [
                                                          element.name,
                                                          "animation",
                                                          level1Key,
                                                          level2Key,
                                                          level3Key,
                                                        ],
                                                        parseFloat(
                                                          (
                                                            level3 - increment
                                                          ).toFixed(1)
                                                        )
                                                      );

                                                      return { ...prevstate };
                                                    }
                                                  );
                                                  setReRender(true);
                                                }}
                                              />
                                            </ParamRow>
                                          </Param>
                                        );
                                    })}
                                  </ParamLine>
                                </Param>
                              );
                            }
                          })}
                        </ParamLine>
                      </Param>
                    );
                  })}
                  <Separator />
                </Fragment>
              );
            })}
          </>
        )}
      </ParamContainer>
    </>
  );
};

const ParamContainer = styled.div`
  background-color: ${COLORS.fakeBlack};
  flex: 1;
  padding: 20px;
  border: 20px solid ${COLORS.darkgray};
  border-top: 0px solid ${COLORS.darkgray};
`;

const ParamRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border: 1px solid ${COLORS.darkgray};
  padding: 4px 6px 4px 17px;
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
`;

const Param = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 2px;
`;

const TitleLabel = styled.div`
  max-width: 1080px;
  font-size: 14px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ConfigInput = styled.input`
  max-width: 4ch;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${COLORS.fakeWhite};
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    border: none;
    outline: none;
  }
`;

const Message = styled.div`
  margin-top: -4px;
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.transparentGrey};
  margin-top: 20px;
  margin-bottom: 30px;
`;
