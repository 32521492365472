import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import {
  faChevronLeft,
  faUpDownLeftRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map, merge, uniq } from "lodash";
import { DateTime } from "luxon";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import WebFont from "webfontloader";
import { AppContext } from "../..";
import {
  Account,
  AccountsResponse,
  Imagery,
  Production,
  Safe,
  Settings,
} from "../../graphql/generated/graphql";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import { PRODUCTION } from "../../graphql/queries/production";
import { USER_SAFES } from "../../graphql/queries/safe";
import { deepCloneWithoutKey } from "../../helpers/removeTypeName";
import Button from "../../libs/components/button/Button";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { Modal } from "../../libs/components/modal/modal";
import { PageHeaderLayout } from "../../libs/components/pageHeaderLayout/PageHeaderLayout";
import Radio from "../../libs/components/radio/Radio";
import { AssetTypes } from "../../libs/constants/general";
import { COLORS } from "../../libs/styles/colors";
import { CreateNewImagery } from "../modals/createNewImagery";
import { ExportFormat } from "../modals/exportFormat";
import { GenerateCopyProposals } from "../modals/generateCopyProposals";
import { GenerateFontFamilies } from "../modals/generateFontFamilies";
import { ImageZoom } from "../modals/imageZoom";
import { SaveImage } from "../modals/saveImage";
import { SaveProduction } from "../modals/saveProduction";
import { DisplayAdResult } from "./DisplayAdResult";
import {
  DISPLAY_ADS_SETTINGS_LIST,
  ICatchlineSettings,
  ICtaSettings,
  IImageSettings,
  ILogoSettings,
} from "./constants";

export interface IImageryDeliveryProps {
  darkMode: boolean;
}

export const DisplayAdConfig: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("LOCATION", location);

  const context = useContext(AppContext);
  const { state, setState } = context;
  // console.log("STATE", state);

  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  // console.log("isGenerated", isGenerated);

  const [isContextApplied, setIsContextApplied] = useState<boolean>(false);

  /**********************************************************
   * STATE
   * *******************************************************/

  // Settings are applied by default
  const [displayAdSettings, setDisplayAdSettings] = useState<Settings>(
    DISPLAY_ADS_SETTINGS_LIST[0]
  );

  // If a safeId is set, the settings will be applied
  const [safeIdForImageSettings, setSafeIdForImageSettings] = useState<
    string | undefined
  >(undefined);

  const [safeIdForLogoSettings, setSafeIdForLogoSettings] = useState<
    string | undefined
  >(undefined);

  const [getSafes, { data: safesData }] = useLazyQuery(USER_SAFES, {
    variables: {
      userSafesInput: {
        skip: 0,
        limit: 100,
        order: ["createdAt:DESC"],
      },
    },
    onCompleted: (data: any) => {
      if (safeIdForImageSettings !== undefined) {
        const safe = data.userSafes?.results.find(
          (s: Safe) => s.id === safeIdForImageSettings
        );
        setDisplayAdSettings((prevstate: Settings) => {
          prevstate.parameters.image.safe = safe;
          prevstate.parameters.image.asset =
            safe.assets[safe.assets.length - 1];
          return { ...prevstate };
        });
        setSafeIdForImageSettings(undefined);
      }
      if (safeIdForLogoSettings !== undefined) {
        const safe = data.userSafes?.results.find(
          (s: Safe) => s.id === safeIdForLogoSettings
        );
        setDisplayAdSettings((prevstate: Settings) => {
          prevstate.parameters.logo.safe = safe;
          prevstate.parameters.logo.asset = safe.assets[safe.assets.length - 1];
          return { ...prevstate };
        });
        setSafeIdForLogoSettings(undefined);
      }
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    getSafes();
  }, [getSafes]);

  const safes = safesData?.userSafes?.results;

  useEffect(() => {
    if (
      safeIdForImageSettings !== undefined ||
      safeIdForLogoSettings !== undefined
    )
      getSafes();
  }, [getSafes, safeIdForImageSettings, safeIdForLogoSettings]);

  // If settings from the context are not applied, apply them
  useEffect(() => {
    if (
      !isContextApplied &&
      state.factory?.displayAd &&
      state.factory.displayAd[displayAdSettings?.formatName]
    ) {
      setDisplayAdSettings(
        state.factory.displayAd[displayAdSettings?.formatName]
      );
      setIsContextApplied(true);
      setIsGenerated(true);
    }
  }, [
    displayAdSettings?.formatName,
    isContextApplied,
    state,
    state.factory.displayAd,
  ]);

  // Set the persistent settings
  const [persistentParameters, setPersistentParameterss] = useState<
    | {
        parameters: {
          catchline: Partial<ICatchlineSettings>;
          cta: Partial<ICtaSettings>;
          logo: Partial<ILogoSettings>;
          image: Partial<IImageSettings>;
        };
      }
    | undefined
  >(undefined);

  // Each time the settings are updated, update the persistent settings
  useEffect(() => {
    if (displayAdSettings) {
      const {
        copyUpdated: clUpdated,
        color: clColor,
        copyProposals: clProposals,
        fontProposals: clFontProposals,
        copySelectionIndex: clSelectionIndex,
        font: clFont,
        animation: clAnimation,
      } = displayAdSettings.parameters.catchline;
      const {
        copyUpdated: ctUpdated,
        color: ctColor,
        copyProposals: ctProposals,
        fontProposals: ctFontProposals,
        copySelectionIndex: ctSelectionIndex,
        font: ctFont,
        animation: ctAnimation,
      } = displayAdSettings.parameters.cta;

      const {
        safe: lSafe,
        asset: lAsset,
        animation: lAnimation,
      } = displayAdSettings.parameters.logo;
      const {
        safe: iSafe,
        asset: iAsset,
        automaticAlignment,
        animation: iAnimation,
        gamma: iGamma,
      } = displayAdSettings.parameters.image;

      setPersistentParameterss({
        parameters: {
          catchline: {
            copyUpdated: clUpdated,
            color: clColor,
            copyProposals: clProposals,
            fontProposals: clFontProposals,
            copySelectionIndex: clSelectionIndex,
            font: clFont,
            animation: clAnimation,
          },
          cta: {
            copyUpdated: ctUpdated,
            font: ctFont,
            color: ctColor,
            copyProposals: ctProposals,
            fontProposals: ctFontProposals,
            copySelectionIndex: ctSelectionIndex,
            animation: ctAnimation,
          },
          logo: {
            safe: lSafe,
            asset: lAsset,
            animation: lAnimation,
          },
          image: {
            safe: iSafe,
            asset: iAsset,
            gamma: iGamma,
            automaticAlignment,
            animation: iAnimation,
          },
        },
      });
    }
  }, [displayAdSettings]);

  /**********************************************************
   * CONTEXT
   * *******************************************************/

  useEffect(() => {
    if (isGenerated && displayAdSettings)
      setState({
        factory: {
          displayAd: {
            ...state.factory.displayAd,
            [displayAdSettings.formatName]: displayAdSettings,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState, displayAdSettings]);

  /**********************************************************
   *** MODALS ***
   **********************************************************/

  const [generateCopyProposalsModal, setGenerateCopyProposalsModal] = useState<
    | {
        open: boolean;
      }
    | undefined
  >({ open: false });

  const [generateFontFamiliesModal, setGenerateFontFamiliesModal] = useState<
    | {
        open: boolean;
      }
    | undefined
  >({ open: false });

  const [exportFormatModal, setExportFormatModal] = useState<
    | {
        open: boolean;
        initialvalues: any;
      }
    | undefined
  >({ open: false, initialvalues: {} });

  const [createNewImageryModal, setCreateNewImageryModal] = useState<
    | {
        open: boolean;
      }
    | undefined
  >({ open: false });

  const [saveImageModal, setSaveImageModal] = useState<
    | {
        open: boolean;
        image: any;
        type: AssetTypes;
      }
    | undefined
  >(undefined);

  // LOAD FONTS
  useEffect(() => {
    if (
      displayAdSettings?.parameters.catchline.fontProposals &&
      displayAdSettings?.parameters.cta.fontProposals
    ) {
      const fontFamilyProposals = uniq([
        ...displayAdSettings?.parameters.catchline.fontProposals,
        ...displayAdSettings?.parameters.cta.fontProposals,
      ]).filter((fp) => fp !== "HelveticaNeueLTPro-Lt");
      if (fontFamilyProposals.length > 0)
        WebFont.load({
          google: {
            families: fontFamilyProposals,
          },
        });
    }
  }, [
    displayAdSettings?.parameters.catchline.fontProposals,
    displayAdSettings?.parameters.cta.fontProposals,
  ]);

  const [saveProductionModal, setSaveProductionModal] = useState<
    | {
        open: boolean;
        sets: Settings[];
        initialProduction?: Production;
      }
    | undefined
  >(undefined);

  const [imageModal, setImageModal] = useState<
    | {
        open: boolean;
        image: string;
        jobId: string;
        jobDeliveryIndex: number;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onCompleted: (data: { userAccounts: AccountsResponse }) => {
      if (data.userAccounts?.results && data.userAccounts?.results.length > 0) {
        setAccount(data.userAccounts?.results[0]);
      }
    },
    onError: (error: ApolloError) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
    },
  });

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [getProduction, { data: productionData }] = useLazyQuery(PRODUCTION, {
    onError: (error: ApolloError) => {
      console.log("GET PRODUCTION ERROR:", JSON.parse(JSON.stringify(error)));
    },
    onCompleted: (data: any) => {
      const cleanData = deepCloneWithoutKey(data, "__typename");
      const da: any = {};
      for (let item of cleanData.production.sets) {
        da[item.formatName] = item;
      }
      setState((prevstate: any) => {
        prevstate.factory.displayAd = da;
        return { ...prevstate };
      });
      setDisplayAdSettings(cleanData.production.sets[0]);
      setIsGenerated(true);
    },
  });
  const production = productionData?.production;

  useEffect(() => {
    if (
      !production &&
      location.search &&
      location.search.includes("production=")
    ) {
      getProduction({
        variables: {
          productionId: location.search.split("production=")[1],
        },
      });
    }
  }, [getProduction, location.search, production]);

  // If no production in url
  useEffect(() => {
    if (!location.search.includes("production=") && !state.factory.displayAd) {
      setGenerateCopyProposalsModal({ open: true });
    }
  }, [location.search, state.factory.displayAd]);

  /**********************************************************
   *** IS FUNCTIONALITY AVAILABLE ***
   **********************************************************/

  const [isFunctionalityAvailable, setIsFunctionalityAvailable] =
    useState<boolean>(false);

  const [getImagery] = useLazyQuery(IMAGERY, {
    onCompleted: (data: { imagery: Imagery }) => {
      const createdAt = DateTime.fromISO(data.imagery.createdAt); // Assumes data.imagery.createdAt is an ISO string
      const comparisonTime = DateTime.fromISO("2023-08-14T00:00:00.890Z");
      if (createdAt < comparisonTime) setIsFunctionalityAvailable(false);
      else setIsFunctionalityAvailable(true);
    },
  });

  useEffect(() => {
    if (displayAdSettings.parameters.image.asset?.jobId) {
      getImagery({
        variables: {
          imageryInput: {
            imageryId: displayAdSettings.parameters.image.asset?.jobId,
          },
        },
      });
    }
  }, [displayAdSettings.parameters.image.asset?.jobId, getImagery]);

  if (!account || !displayAdSettings) return null;

  return (
    <>
      <Layout darkMode={darkMode}>
        <Screen>
          <PageHeaderLayout
            title={
              production?.name ?? `Draft ${DateTime.local().toFormat("MMddHH")}`
            }
            ontitle={"DisplayAd"}
            subtitle={
              production?.name
                ? t("Page.DisplayAd.ProjectSubtitle", {
                    savedate: DateTime.fromISO(
                      production?.updatedAt
                    ).toLocaleString(DateTime.DATE_FULL),
                  })
                : t("Page.DisplayAd.ProjectSubtitleTemporary") ?? ""
            }
            buttons={[
              <Button
                onClick={() => {
                  setSaveProductionModal({
                    open: true,
                    sets: map(state.factory.displayAd, (set: any) => set),
                    initialProduction: production,
                  });
                }}
                type={"secondary"}
              >
                {t("Button.Save")}
              </Button>,
            ]}
            topButton={
              <Button
                onClick={() => {
                  navigate("/factory/display-ad");
                }}
                type={"secondary"}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            }
            id={production?.id ?? `${account.users[0].id}`}
          />
          <Content>
            {isGenerated && (
              <ConfigContainer>
                <ConfigLabel>{t("Factory.ConfigLabel.Formats")}</ConfigLabel>
                <ConfigRow>
                  <ProposalsContainer>
                    <Radio
                      direction="column"
                      items={DISPLAY_ADS_SETTINGS_LIST.filter(
                        (initialSettings) =>
                          initialSettings.support === "Desktop"
                      ).map((initialSettings) => ({
                        label: `${initialSettings.support} ${initialSettings.formatName} [${initialSettings.format}]`,
                        value: initialSettings.formatName,
                        onClick: () => {
                          const newSettings = merge(
                            {
                              ...initialSettings,
                              ...state.factory.displayAd[
                                initialSettings.formatName
                              ],
                            },
                            { ...persistentParameters }
                          );

                          setDisplayAdSettings(newSettings);
                        },
                        active:
                          displayAdSettings.formatName ===
                          initialSettings.formatName,
                      }))}
                    />
                  </ProposalsContainer>
                  <ProposalsContainer>
                    <Radio
                      direction="column"
                      items={DISPLAY_ADS_SETTINGS_LIST.filter(
                        (initialSettings) =>
                          initialSettings.support === "Mobile"
                      ).map((initialSettings) => ({
                        label: `${initialSettings.support} ${initialSettings.formatName} [${initialSettings.format}]`,
                        value: initialSettings.formatName,
                        onClick: () => {
                          const newSettings = merge(
                            {
                              ...initialSettings,
                              ...state.factory.displayAd[
                                initialSettings.formatName
                              ],
                            },
                            { ...persistentParameters }
                          );
                          console.log("NEW SETTINGS", newSettings);
                          setDisplayAdSettings(newSettings);
                        },
                        active:
                          displayAdSettings.formatName ===
                          initialSettings.formatName,
                      }))}
                    />
                  </ProposalsContainer>
                </ConfigRow>
              </ConfigContainer>
            )}

            {isGenerated && displayAdSettings && (
              <>
                <Separator />
                <DisplayAdResult
                  darkMode={darkMode}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  setGenerateCopyProposalsModal={setGenerateCopyProposalsModal}
                  setGenerateFontFamiliesModal={setGenerateFontFamiliesModal}
                  setIsGenerated={setIsGenerated}
                  setExportFormatModal={setExportFormatModal}
                  setSaveProductionModal={setSaveProductionModal}
                  setCreateNewImageryModal={setCreateNewImageryModal}
                  production={production}
                  setSaveImageModal={setSaveImageModal}
                  setImageModal={setImageModal}
                  safes={safes}
                />
              </>
            )}
          </Content>
          <Footer darkMode={darkMode} />
        </Screen>
      </Layout>

      <Modal
        open={generateCopyProposalsModal?.open ? true : false}
        title={`Generate Copy Proposals`}
        onClose={() => {
          navigate("/factory/display-ad");
        }}
      >
        <GenerateCopyProposals
          onClose={() => {
            setGenerateCopyProposalsModal(undefined);
          }}
          darkMode={darkMode}
          setCopyProposals={(proposals) => {
            let catchlineCopyProposals: string[] = [];
            let ctaCopyProposals: string[] = [];
            for (let i = 0; i < proposals.length; i++) {
              const proposal = proposals[i];
              catchlineCopyProposals.push(proposal.split("<br/>")[0]);
              ctaCopyProposals.push(proposal.split("<br/>")[1]);
            }
            setDisplayAdSettings((prevstate: Settings) => {
              prevstate.parameters.catchline.copyProposals =
                catchlineCopyProposals;
              prevstate.parameters.catchline.copyUpdated =
                catchlineCopyProposals[0];
              prevstate.parameters.catchline.copySelectionIndex = 0;
              prevstate.parameters.cta.copyProposals = ctaCopyProposals;
              prevstate.parameters.cta.copyUpdated = ctaCopyProposals[0];
              prevstate.parameters.cta.copySelectionIndex = 0;
              return { ...prevstate };
            });
            setTimeout(
              () => setGenerateFontFamiliesModal({ open: true }),
              3000
            );
          }}
        />
      </Modal>

      <Modal
        open={generateFontFamiliesModal?.open ? true : false}
        title={`Generate Catchlines`}
        onClose={() => {
          navigate("/factory/display-ad");
        }}
      >
        <GenerateFontFamilies
          onClose={() => {
            setGenerateFontFamiliesModal(undefined);
          }}
          darkMode={darkMode}
          setProposals={(proposals) => {
            setDisplayAdSettings((prevstate: Settings) => {
              prevstate.parameters.catchline.fontProposals = proposals;
              prevstate.parameters.catchline.font = proposals[0];
              prevstate.parameters.cta.fontProposals = proposals;
              prevstate.parameters.cta.font = proposals[0];
              prevstate.parameters.cta.copySelectionIndex = 0;
              return { ...prevstate };
            });
            setIsGenerated(true);
          }}
        />
      </Modal>

      <Modal
        open={saveProductionModal?.open ? true : false}
        title={`Save Production`}
        onClose={() => {
          setSaveProductionModal(undefined);
        }}
      >
        <SaveProduction
          onClose={() => {
            setSaveProductionModal(undefined);
          }}
          darkMode={darkMode}
          setsToSave={saveProductionModal?.sets}
          initialProduction={saveProductionModal?.initialProduction}
          type={"DISPLAY_AD"}
        />
      </Modal>

      <Modal
        open={exportFormatModal?.open ? true : false}
        title={`Save Production`}
        onClose={() => {
          setExportFormatModal(undefined);
        }}
      >
        <ExportFormat
          onClose={() => {
            setExportFormatModal(undefined);
          }}
          darkMode={darkMode}
          type={"DISPLAY_AD"}
          initialValues={exportFormatModal?.initialvalues}
          account={account}
          displayAdSettings={displayAdSettings}
          production={production}
        />
      </Modal>

      <Modal
        open={createNewImageryModal?.open ? true : false}
        title={`Create Imagery`}
        onClose={() => {
          setCreateNewImageryModal(undefined);
        }}
      >
        <CreateNewImagery
          onClose={() => setCreateNewImageryModal(undefined)}
          setSafeIdForSettings={setSafeIdForImageSettings}
        />
      </Modal>

      <Modal
        open={imageModal?.open ? true : false}
        title={`Add an asset`}
        onClose={() => {
          setImageModal(undefined);
        }}
        buttons={
          isFunctionalityAvailable
            ? [
                <Button
                  icon={faUpDownLeftRight}
                  iconPosition="left"
                  darkMode={darkMode}
                  onClick={() => {
                    navigate(
                      `/bot/imagery/zoom-out?code=${imageModal?.jobId}&delivery=${imageModal?.jobDeliveryIndex}`
                    );
                  }}
                  type="neutral"
                >
                  {t("Button.ZoomOut")}
                </Button>,
              ]
            : []
        }
      >
        <ImageZoom image={imageModal?.image} />
      </Modal>

      <Modal
        open={saveImageModal?.open ? true : false}
        title={`Add an image`}
        onClose={() => {
          setSaveImageModal(undefined);
        }}
      >
        <SaveImage
          onClose={() => {
            setSaveImageModal(undefined);
          }}
          imageFile={saveImageModal?.image}
          type={saveImageModal?.type}
          darkMode={darkMode}
          setSafeIdForSettings={
            saveImageModal?.type === AssetTypes.IMAGE
              ? setSafeIdForImageSettings
              : AssetTypes.LOGO
              ? setSafeIdForLogoSettings
              : undefined
          }
        />
      </Modal>

      {/* <Modal
        open={saveImageModal?.open ? true : false}
        title={`Add an asset`}
        onClose={() => {
          setSaveImageModal(undefined);
          setDisplayAdSettings((prevstate: Settings) => {
            prevstate.parameters.logo.asset = {};
            return { ...prevstate };
          });
          refetchSafeData();
        }}
      >
        <SaveImage
          onClose={() => {
            setSaveImageModal(undefined);
            setDisplayAdSettings((prevstate: Settings) => {
              prevstate.parameters.logo.asset = {};
              return { ...prevstate };
            });
            refetchSafeData();
          }}
          imageFile={saveImageModal?.image}
          type={AssetTypes.LOGO}
          darkMode={darkMode}
          setSafeIdForSettings={setSafeIdForLogoSettings}
        />
      </Modal> */}
    </>
  );
};

const Screen = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 100px 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const ConfigContainer = styled.div`
  max-width: 1004px;
  margin-top: 60px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${COLORS.fakeBlack};
  border: 20px solid ${COLORS.darkgray};
`;

const ConfigLabel = styled.div`
  max-width: 1080px;
  min-width: 125px;
  font-size: 20px;

  color: ${COLORS.transparentGrey};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ProposalsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  padding-top: 4px;
`;

const ConfigRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

const Separator = styled.div`
  width: 100vw;
  max-width: 1080px;
  height: 1px;
  background-color: ${COLORS.soixanteseize};
  margin: 60px 0 60px 0;
`;
