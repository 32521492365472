import { useMutation } from "@apollo/client";
import {
  faArrowRight,
  faPencil,
  faRefresh,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validate from "validate.js";
import { CHECK_EXISTING_USER_EMAIL } from "../../graphql/mutations/checkExistingUserEmail";
import { CREATE_USER } from "../../graphql/mutations/createUser";
import { Loader } from "../../libs/components/Loader";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
import {
  constraintConfirmPassword,
  constraintEMail,
  constraintFirstName,
  constraintLastName,
  constraintPassword,
  constraintPhoneNumber,
} from "./validations/validators";
export interface ISigninProps {
  darkMode?: boolean;
}

export const Register: FunctionComponent<ISigninProps> = ({ darkMode }) => {
  //
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string | null>(
    t("Register.CursorMessage.Welcome")
  );

  const [step, setStep] = useState<number | undefined>(0);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [questionToGetBack, setQuestionToGetBack] = useState<
    IQuestion | undefined
  >(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** CHECK EXISTING USER EMAIL ***
   **********************************************************/

  const [checkExistingUserEmail] = useMutation(CHECK_EXISTING_USER_EMAIL, {
    onCompleted: (data) => {
      console.log("CHECK_EXISTING_USER_EMAIL:", data);
      if (data.checkExistingUserEmail) {
        setEmail(data.checkExistingUserEmail);
        setStep(4);
      }
    },
    onError: (error: any) => {
      setError(errorMessage(error, t));
      setButtons([
        <Button
          icon={faPencil}
          iconPosition="left"
          key={0}
          darkMode={darkMode}
          onClick={() => {
            setQuestionToGetBack(allQuestions[3]);
          }}
          type="classic"
        >
          {t("Button.ChangeEmail")}
        </Button>,
        <Button
          icon={faArrowRight}
          iconPosition="left"
          key={0}
          darkMode={darkMode}
          onClick={() => navigate("/login")}
          type="classic"
        >
          {t("Button.ConnectWithEmail")}
        </Button>,
      ]);
    },
  });

  /**********************************************************
   *** CREATE USER ***
   **********************************************************/

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: () => {
      setSuccess(`${t("Success.Message.UserCreated")}`);
    },
    onError: (error: any) => {
      console.log("CREATE_USER ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
      setButtons([
        <Button
          icon={faRefresh}
          iconPosition="left"
          key={0}
          darkMode={darkMode}
          onClick={() => window.location.reload()}
          type="classic"
        >
          {t("Button.Restart")}
        </Button>,
      ]);
    },
  });

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    if (loading) setCursorMessage(t("Register.CursorMessage.CreatingUser"));
    if (success) setCursorMessage(t("Register.CursorMessage.UserCreated"));
    if (error) setCursorMessage(t("Register.CursorMessage.Error"));
  }, [error, loading, setCursorMessage, success, t]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("Register.Question.0"),
        answerType: AnswerTypes.TEXT,
        nextStep: 1,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate(
            { firstName: answer },
            constraintFirstName(t),
            {
              fullMessages: false,
            }
          );
          if (errors) setError(errors.firstName[0]);
          else {
            setFirstName(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 1,
        q: t("Register.Question.1"),
        answerType: AnswerTypes.TEXT,
        nextStep: 2,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ lastName: answer }, constraintLastName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.lastName[0]);
          else {
            setLastName(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 2,
        q: t("Register.Question.2"),
        answerType: AnswerTypes.TEXT,
        nextStep: 3,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate(
            { phoneNumber: answer },
            constraintPhoneNumber(t),
            {
              fullMessages: false,
            }
          );
          if (errors) setError(errors.phoneNumber[0]);
          else {
            setPhoneNumber(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 3,
        q: t("Register.Question.3"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ email: answer }, constraintEMail(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.email[0]);
          else {
            checkExistingUserEmail({
              variables: {
                checkExistingUserEmailInput: { email: answer },
              },
            });
          }
        },
      },
      {
        step: 4,
        q: t("Register.Question.4"),
        answerType: AnswerTypes.TEXT,
        secured: true,
        nextStep: 5,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ password: answer }, constraintPassword(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.password[0]);
          else {
            setPassword(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 5,
        q: t("Register.Question.5"),
        answerType: AnswerTypes.TEXT,
        secured: true,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate(
            { password, confirmPassword: answer },
            constraintConfirmPassword(t),
            {
              fullMessages: false,
            }
          );
          if (errors) setError(errors.confirmPassword[0]);
          else {
            createUser({
              variables: {
                createUserInput: {
                  firstName,
                  lastName,
                  email,
                  phoneNumber,
                  password: answer,
                  language: i18n.language,
                },
              },
            });
          }
        },
      },
    ];
    return q;
  }, [
    checkExistingUserEmail,
    createUser,
    email,
    firstName,
    i18n.language,
    lastName,
    password,
    phoneNumber,
    t,
  ]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        success={success}
        resetErrors={() => setError(undefined)}
        processing={loading}
        buttons={buttons}
        resetButtons={() => setButtons([])}
        questionToGetBack={questionToGetBack}
        resetQuestionToGetBack={() => setQuestionToGetBack(undefined)}
      />
      {loading && <Loader />}
    </Layout>
  );
};
