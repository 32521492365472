import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppIndex } from "./AppIndex";
import "./i18n/config";
import "./index.css";
import "./libs/styles/fonts/fonts.css";
import reportWebVitals from "./reportWebVitals";
interface AppContextState {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const AppContext = React.createContext<AppContextState>({
  state: undefined,
  setState: () => {},
});
const AppContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = React.useState<any>({ factory: {} });

  const value = {
    state,
    setState,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
root.render(
  <AppContextProvider>
    <AppIndex>
      <App />
    </AppIndex>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
