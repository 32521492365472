import { useEffect, useState } from "react";

export const useWindowInnerSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    ratio: 1,
  });

  useEffect(() => {
    let timeoutId = null as any;
    function handleResize() {
      timeoutId = setTimeout(() => {
        let vw = window.innerWidth * 0.01;
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--vw", `${vw}px`);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          ratio: window.innerWidth / window.innerHeight,
        });
      }, 150);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
