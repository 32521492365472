import {
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Safe, Settings } from "../../../graphql/generated/graphql";
import { DragIncrementer } from "../../../libs/components/fields/DragIncrementer";
import Radio from "../../../libs/components/radio/Radio";
import { AssetTypes } from "../../../libs/constants/general";
import { BREAKPOINTS } from "../../../libs/styles/breakpoints";
import { COLORS } from "../../../libs/styles/colors";
import { IncrementSelector } from "../IncrementSelector";
import { PositionSelector } from "../PositionSelector";
import { MessageContainer } from "./MessageContainer";

export interface IParamLogoProps {
  darkMode: boolean;
  displayAdSettings: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  stepValidated: boolean;
  setSaveImageModal: (value: {
    open: boolean;
    image: any;
    type: AssetTypes;
  }) => void;
  safes: Safe[];
}

export const ParamLogo: FunctionComponent<IParamLogoProps> = ({
  darkMode,
  displayAdSettings,
  setDisplayAdSettings,
  stepValidated,
  setSaveImageModal,
  safes,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** SAFES ***
   **********************************************************/

  /**********************************************************
   * ImageUpload
   * *******************************************************/

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (image)
      setSaveImageModal({
        open: true,
        image: image,
        type: AssetTypes.LOGO,
      });
  }, [image, setSaveImageModal]);

  const onImageChange = (imageList: any) => {
    const image = imageList[0];
    setImage(image);
  };

  const logoParameters = displayAdSettings.parameters.logo;

  return (
    <>
      <MessageContainer
        stepValidated={stepValidated}
        message={
          !logoParameters.safe?.id
            ? t("Factory.Message.CollectionLogos")
            : !logoParameters.asset?.id
            ? t("Factory.Message.Logo")
            : t("Factory.Message.UploadLogo")
        }
        buttons={[
          <ImageUploading onChange={onImageChange} maxNumber={1} multiple>
            {({ imageList, onImageUpload }: any) => (
              <div>
                {imageList.map((image: any, index: number) => (
                  <div key={index}>
                    <img src={image.dataURL} alt="" width="100" />
                  </div>
                ))}
                <ModalLink onClick={onImageUpload}>
                  {t("Button.UploadLogo").toUpperCase()}
                </ModalLink>
              </div>
            )}
          </ImageUploading>,
        ]}
      />

      <ParamContainer>
        <Param style={{ gap: 10 }}>
          <TitleLabel>{`Logo Collections`}</TitleLabel>

          <ParamRow style={{ gap: 50 }}>
            <Radio
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
              }}
              items={safes
                // .filter((s: any) => s.type === "IMAGE")
                .map((safe: any) => ({
                  label: safe.name,
                  onLabel:
                    safe.type === "IMAGE"
                      ? "IMAGE LIBRARY"
                      : safe.type === "LOGO"
                      ? "LOGO  LIBRARY"
                      : "AI GENERATIONS",
                  onClick: () => {
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.logo.safe = safe;
                      prevstate.parameters.logo.asset = {};
                      return { ...prevstate };
                    });
                  },
                  active: safe?.id === logoParameters?.safe?.id,
                }))}
            />
          </ParamRow>

          {logoParameters?.safe?.assets && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                gap: 20,
              }}
            >
              {logoParameters.safe?.assets.map((asset: any, i: number) => {
                return (
                  <div
                    key={i}
                    style={{
                      cursor: "pointer",
                      opacity:
                        logoParameters.asset?.cdnId === asset.cdnId ? 1 : 0.25,
                    }}
                    onClick={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.asset = asset;
                        return { ...prevstate };
                      })
                    }
                  >
                    <ImageCardContainer>
                      <ImageCard>
                        <Image src={asset.content} alt="Alt" />
                      </ImageCard>
                      <OptionRow>
                        <FontAwesomeIcon
                          icon={
                            logoParameters.asset?.cdnId === asset.cdnId
                              ? faSquareCheck
                              : faSquare
                          }
                          color={COLORS.fakeWhite}
                          style={{ paddingBottom: 5 }}
                        />
                        <Mentions>{asset.name}</Mentions>
                      </OptionRow>
                    </ImageCardContainer>
                  </div>
                );
              })}
            </div>
          )}
        </Param>

        {logoParameters.safe?.assets?.includes(logoParameters.asset) && (
          <>
            <Separator />

            <Param>
              <TitleLabel>{`Logo Position`}</TitleLabel>
              <PositionSelector
                position={logoParameters.position}
                darkMode={darkMode}
                setPosition={(position) =>
                  setDisplayAdSettings((prevstate: Settings) => {
                    prevstate.parameters.logo.position = position;
                    return { ...prevstate };
                  })
                }
              />
            </Param>
            <Param>
              <TitleLabel>{`Size`}</TitleLabel>
              <ParamRow>
                <DragIncrementer
                  value={logoParameters.size}
                  setValue={(value: number) =>
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.logo.size = value;
                      return { ...prevstate };
                    })
                  }
                />
                <ConfigInput
                  value={logoParameters.size}
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value))) return;
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.logo.size = Number(e.target.value);
                      return { ...prevstate };
                    });
                  }}
                />
                <IncrementSelector
                  plusIcon={faSquarePlus}
                  minusIcon={faSquareMinus}
                  darkMode={darkMode}
                  setPlus={() =>
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.logo.size =
                        prevstate.parameters.logo.size + 1;
                      return { ...prevstate };
                    })
                  }
                  setMinus={() =>
                    setDisplayAdSettings((prevstate: Settings) => {
                      prevstate.parameters.logo.size =
                        prevstate.parameters.logo.size - 1;
                      return { ...prevstate };
                    })
                  }
                />
              </ParamRow>
            </Param>
            <ParamLine>
              <Param>
                <TitleLabel>{`Top Margins`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={logoParameters.topPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.topPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={logoParameters.topPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.topPadding = Number(
                          e.target.value
                        );
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.topPadding =
                          logoParameters.topPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.topPadding =
                          logoParameters.topPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Right Margins`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={logoParameters.rightPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.rightPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={logoParameters.rightPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.rightPadding = Number(
                          e.target.value
                        );
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.rightPadding =
                          logoParameters.rightPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.rightPadding =
                          logoParameters.rightPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
              <Param>
                <TitleLabel>{`Bottom Margins`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={logoParameters.bottomPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.bottomPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={logoParameters.bottomPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.bottomPadding = Number(
                          e.target.value
                        );
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.bottomPadding =
                          logoParameters.bottomPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.bottomPadding =
                          logoParameters.bottomPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>

              <Param>
                <TitleLabel>{`Left Margins`}</TitleLabel>
                <ParamRow>
                  <DragIncrementer
                    value={logoParameters.leftPadding}
                    setValue={(value: number) =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.leftPadding = value;
                        return { ...prevstate };
                      })
                    }
                  />
                  <ConfigInput
                    value={logoParameters.leftPadding}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return;
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.leftPadding = Number(
                          e.target.value
                        );
                        return { ...prevstate };
                      });
                    }}
                  />
                  <IncrementSelector
                    plusIcon={faSquarePlus}
                    minusIcon={faSquareMinus}
                    darkMode={darkMode}
                    setPlus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.leftPadding =
                          logoParameters.leftPadding + 1;
                        return { ...prevstate };
                      })
                    }
                    setMinus={() =>
                      setDisplayAdSettings((prevstate: Settings) => {
                        prevstate.parameters.logo.leftPadding =
                          logoParameters.leftPadding - 1;
                        return { ...prevstate };
                      })
                    }
                  />
                </ParamRow>
              </Param>
            </ParamLine>
          </>
        )}
      </ParamContainer>
    </>
  );
};

const ParamContainer = styled.div`
  background-color: ${COLORS.fakeBlack};
  flex: 1;
  padding: 20px;
  border: 20px solid ${COLORS.darkgray};
  border-top: 0px solid ${COLORS.darkgray};
`;

const ParamRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border: 1px solid ${COLORS.darkgray};
  padding: 4px 6px 4px 17px;
`;

const ParamLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

const Param = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
  align-items: flex-start;
`;

const TitleLabel = styled.div`
  max-width: 1080px;
  font-size: 14px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const ConfigInput = styled.input`
  max-width: 4ch;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${COLORS.fakeWhite};
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    border: none;
    outline: none;
  }
`;

const Mentions = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ModalLink = styled.div`
  font-size: 14px;
  border-radius: 8px;
  padding: 10px 20px 7px 20px;
  color: ${COLORS.mediumgray};
  background-color: ${COLORS.transparent};
  border: 1px solid ${COLORS.mediumgray};
  cursor: pointer;
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: center;
  &:hover {
    color: ${COLORS.mediumgray};
    background-color: ${COLORS.fakeWhite};
    border: 1px solid ${COLORS.fakeWhite};
  }
`;

const ImageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;
const ImageCard = styled.div`
  height: 65px;
  background-color: ${COLORS.translucentDarkBG};
  border-radius: 10px;
  padding: 5px 5px 7px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 110px;
  max-height: 65px;
  object-fit: contain;
  border-radius: 10px;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.transparentGrey};
  margin-top: 20px;
  margin-bottom: 30px;
`;
