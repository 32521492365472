export enum AssetStatuses {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  DELETED = "DELETED",
  ARCHIVED = "ARCHIVED",
}

export enum AnswerTypes {
  TEXT = "TEXT",
  OPTIONS = "OPTIONS",
  LIST = "LIST",
  TEXT_AND_OPTIONS = "TEXT_AND_OPTIONS",
  TEXT_AND_LIST = "TEXT_AND_LIST",

  NONE = "NONE",
}

export enum SectorTypes {
  HEALTHCARE = "HEALTHCARE",
  MATERIALS = "MATERIALS",
  REAL_ESTATE = "REAL_ESTATE",
  CONSUMER_STAPLES = "CONSUMER_STAPLES",
  LUXURY = "LUXURY",
  UTILITIES = "UTILITIES",
  ENERGY = "ENERGY",
  INDUSTRIALS = "INDUSTRIALS",
  COMMUNICATION = "COMMUNICATION",
  FINANCIAL = "FINANCIAL",
  TECHNOLOGY = "TECHNOLOGY",
}

////// IMAGES

export enum ImageTypes {
  PHOTOGRAPHY = "PHOTOGRAPHY",
  ILLUSTRATION = "ILLUSTRATION",
  PAINTING = "PAINTING",
  DRAWING = "DRAWING",
  SKETCH = "SKETCH",
}

export enum PhotographyTypes {
  PORTRAIT = "Portrait",
  PACKSHOT = "Packshot",
  STILL_LIFE = "Still Life",
  FASHION = "Fashion",
  STREET = "Street",
  ARCHITECTURE = "Architecture",
  CITYSCAPE = "Cityscape",
  SEASCAPE = "Seascape",
  LANDSCAPE = "Landscape",
  NATURE = "Nature",
  WILDLIFE = "Wildlife",
  DOCUMENTARY = "Documentary",
  UNDERWATER = "Underwater",
  PANORAMIC = "Panoramic",
  TIME_LAPSE = "Time Lapse",
  ASTROPHOTOGRAPHY = "Astrophotography",
  MACRO = "Macro",
  MICRO = "Micro",
  ACTION = "Action",
  AERIAL = "Aerial",
}

export enum CinemaGenreTypes {
  ACTION = "Action",
  ADVENTURE = "Adventure",
  ANIMATED = "Animated",
  COMEDY = "Comedy",
  DRAMA = "Drama",
  FANTASY = "Fantasy",
  HISTORICAL = "Historical",
  HORROR = "Horror",
  MUSICAL = "Musical",
  NOIR = "Noir",
  ROMANCE = "Romance",
  SCIFI = "SciFi",
  THRILLER = "Thriller",
  WESTERN = "Western",
}

export enum ArtTypes {
  HYPER_REALISM = "Hyper Realism",
  POST_MODERNISM = "Post Modernism",
  POP_ART = "Pop Art",
  CONSTRUCTIVISM = "Constructivism",
  CONCEPTUAL_ART = "Conceptual Art",
  MINIMALISM = "Minimalism",
  ABSTRACT = "Abstract",
  REALISM = "Realism",
  SURREALISM = "Surrealism",
  IMPRESSIONISM = "Impressionism",
  EXPRESSIONISM = "Expressionism",
  ART_DECO = "Art Deco",
  ART_NOUVEAU = "Art Nouveau",
  FAUVISM = "Fauvism",
  FUTURISM = "Futurism",
  CUBISM = "Cubism",
  DADA = "Dada",
}

export enum ArtMediumTypes {
  DIGITAL_ART = "Digital Art",
  DIGITAL_PAINTING = "Digital Painting",
  DIGITAL_DRAWING = "Digital Drawing",
  WATERCOLOR_ART = "Watercolor Art",
  OIL_PAINTING = "Oil Painting",
  ACRYLIC_PAINTING = "Acrylic Painting",
  TEMPERA_PAINTING = "Tempera Painting",
  ENCAUSTIC_ART = "Encaustic Art",
  FRESCO_PAINTING = "Fresco Painting",
  INK_DRAWING = "Ink Drawing",
  PASTEL_ART = "Pastel Art",
  CHARCOAL_DRAWING = "Charcoal Drawing",
  PENCIL_DRAWING = "Pencil Drawing",
  POINTILLISM_PAINTING = "Pointillism Painting",
  DIVISIONISM_PAINTING = "Divisionism Painting",
}

export enum IllustrationGenreTypes {
  FLAT_DESIGN = "Flat Design",
  ANIME = "Anime",
  MANGA = "Manga",
  CARTOON = "Cartoon",
  COMIC_BOOK = "Comic Book",
  // FANTASY = "FANTASY",
  // HORROR = "HORROR",
  // SCI_FI = "SCI_FI",
  PIXEL_ART = "Pixel Art",
  LOW_POLY = "Low Poly",
  VAPORWAVE = "Vaporwave",
  RETRO_FUTURISM = "Retro Futurism",
  // CYBERPUNK = "CYBERPUNK",
  // STEAMPUNK = "STEAMPUNK",
  // URBAN = "URBAN",
  // SPRAYCAN = "Spraycan",
  // STREET_ART = "Street Art",
  GLITCH_ART = "Glitch Art",
  UKIYOE = "Ukiyoe",
}

export enum IllustrationArtistTypes {
  SHEPARD_FAIREY = "Shepard Fairey",
  MOEBIUS = "Moebius",
  HAYAO_MIYAZAKI = "Hayao Miyazaki",
  ENKI_BILAL = "Enki Bilal",
  KATSUHIRO_OTOMO = "Katsuhiro Otomo",
  ALFONS_MUCHA = "Alfons Mucha",
  QUENTIN_BLAKE = "Quentin Blake",
  JAMES_JEAN = "James Jean",
  J_C_LEYENDECKER = "J. C. Leyendecker",
  NORMAN_ROCKWELL = "Norman Rockwell",
  AUBREY_BEARDSLEY = "Aubrey Beardsley",
  STUDIO_GHIBLI = "Ghibli Studio",
  PIXAR = "Pixar Studio",
  DISNEY_CLASSIC = "Disney Classic",
}

export enum ArtistsStudiosTypes {
  VINCENT_VAN_GOGH = "Vincent Van Gogh",
  PABLO_PICASSO = "Pablo Picasso",
  LEONARDO_DA_VINCI = "Leonardo Da Vinci",
  SALVADOR_DALI = "Salvador Dali",
  ANDY_WARHOL = "Andy Warhol",
  FRIDA_KAHLO = "Frida Kahlo",
  BANKSY = "Banksy",
  JEAN_MICHEL_BASQUIAT = "Jean Michel Basquiat",
  WASSILY_KANDINSKY = "Wassily Kandinsky",
  MAX_ERNST = "Max Ernst",
  NORMAN_ROCKWELL = "Norman Rockwell",
  JACKSON_POLLOCK = "Jackson Pollock",
  CLAUDE_MONET = "Claude Monet",
  HENRI_MATISSE = "Henri Matisse",
  PAUL_KLEE = "Paul Klee",
  EDVARD_MUNCH = "EDVARD_MUNCH",
  EDGAR_DEGAS = "Edgar Degas",
  JOAN_MIRO = "Joan Miro",
  RENE_MAGRITTE = "Rene Magritte",
  PAUL_GAUGUIN = "Paul Gauguin",
  HENRI_ROUSSEAU = "Henri Rousseau",
  GEORGIA_O_KEEFFE = "Georgia O'Keeffe",
  EDWARD_HOPPER = "Edward Hopper",
  MARK_ROTHKO = "Mark Rothko",
  FRANCIS_BACON = "Francis Bacon",
}

export enum ShotTypes {
  CLOSE_UP = "Close Up",
  MEDIUM_SHOT = "Medium Shot",
  LONG_SHOT = "Long Shot",
  EXTREME_LONG_SHOT = "Extreme Long Shot",
  EXTREME_CLOSE_UP = "Extreme Close Up",
  BIRD_S_EYE_VIEW = "Bird's Eye View",
  HIGH_ANGLE_SHOT = "High Angle Shot",
  LOW_ANGLE_SHOT = "Low Angle Shot",
  AERIAL_SHOT = "Aerial Shot",
  DUTCH_ANGLE_SHOT = "Dutch Angle Shot",
  OVER_THE_SHOULDER_SHOT = "Over the Shoulder Shot",
  POINT_OF_VIEW_SHOT = "Point of View Shot",
  WIDE_SHOT = "Wide Shot",
  FULL_BODY_SHOT = "Full Shot",
  GROUP_SHOT = "Group Shot",
  PORTRAIT_SHOT = "Portrait Shot",
  PANORAMIC_SHOT = "Panoramic Shot",
  TILT_SHOT = "Tilt Shot",
}

export enum LightingTypes {
  NATURAL_LIGHTING = "Natural Lighting",
  SUNLIGHT = "Sunlight",
  MOONLIGHT = "Moonlight",
  GOLDEN_HOUR = "Golden Hour",
  NORDIC_LIGHT = "Nordic Light",
  MORNING_LIGHT = "Morning Light",
  NEON_LIGHT = "Neon Light",
  VOLUMETRIC_LIGHTING = "Volumetric Lighting",
  STUDIO_LIGHTING = "Studio Lighting",
  HIGH_KEY_LIGHTING = "High Key Lighting",
  LOW_KEY_LIGHTING = "Low Key Lighting",
  THREE_POINT_LIGHTING = "Three Point Lighting",
  REMBRANDT_LIGHTING = "Rembrandt Lighting",
  BUTTERFLY_LIGHTING = "Butterfly Lighting",
  SPLIT_LIGHTING = "Split Lighting",
  LOOP_LIGHTING = "Loop Lighting",
  SILHOUETTE_LIGHTING = "Silhouette Lighting",
  RIM_LIGHTING = "Rim Lighting",
  BACK_LIGHTING = "Back Lighting",
  SIDE_LIGHTING = "Side Lighting",
  HARD_LIGHTING = "Hard Lighting",
  SOFT_LIGHTING = "Soft Lighting",
}

export enum FxTypes {
  MOTION_BLUR = "Motion Blur",
  ZOOM_BLUR = "Zoom Blur",
  PANNING = "Panning",
  TILT_SHIFT = "Tilt Shift",
  LONG_EXPOSURE = "Long Exposure",
  SHALLOW_DEPTH_OF_FIELD = "Shallow Depth of Field",
  DEEP_DEPTH_OF_FIELD = "Deep Depth of Field",
  BRIGHTNESS = "Brightness",
  CONTRAST = "Contrast",
  SATURATION = "Saturation",
  /*   "3D" = "3D",
  PHOTOGRAMS = "PHOTOGRAMS", */
  CYANOTYPE = "Cyanotype",
  LOMOGRAPHY = "Lomography",
  POLAROID = "Polaroid",
  /*   HOLGA = "HOLGA", 
  PINHOLE = "PINHOLE",*/
  BLACK_AND_WHITE = "Black and White",
  FILM_NOIR = "Film Noir",
  INFRARED = "Infrared",
  DOUBLE_EXPOSURE = "Double Exposure",
  MIXED_MEDIA = "Mixed Media",
  FISH_EYE = "Fish Eye",
}

export enum AssetTypes {
  LOGO = "LOGO",
  IMAGE = "IMAGE",
  AI_IMAGERY = "AI_IMAGERY",
  TEXT = "TEXT",
}
export enum SafeTypes {
  AI = "AI",
  IMAGE = "IMAGE",
  LOGO = "LOGO",
}

export enum CreditTrackTypes {
  AI_IMAGERY_GENERATION = "AI_IMAGERY_GENERATION",
  FACTORY_DISPLAYAD_EXPORT = "FACTORY_DISPLAYAD_EXPORT",
  FACTORY_SOCIALPOST_EXPORT = "FACTORY_SOCIALPOST_EXPORT",
}
