import { TFunction } from "i18next";

export const constraintDemand = (t: TFunction) => ({
  demand: {
    length: {
      minimum: 5,
      tooShort: t("Validator.Message.Required"),
    },
  },
});

export const constraintInspiration = (t: TFunction) => ({
  inspiration: {
    length: {
      minimum: 5,
      tooShort: t("Validator.Message.Required"),
    },
  },
});
