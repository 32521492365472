import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { fr } from "./fr-FR";

export const availableLanguagesList = [
  {
    value: "fr-FR",
    label: "Français",
  },
  {
    value: "en",
    label: "English",
  },
];

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["navigator", "path"] },
    fallbackLng: ["en", "fr-FR"],
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      "fr-FR": {
        translation: fr,
      },
    },
  });
export default i18next;
