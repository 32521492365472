import { CSSProperties, FunctionComponent, ReactNode } from "react";
import styled, { css, keyframes } from "styled-components";
import { IAnimationSettings } from "../../../modules/factory/constants";

export interface IAnimatedDivProps {
  animationSettings: IAnimationSettings;
  children: ReactNode;
  style?: CSSProperties;
}

export const AnimatedDiv: FunctionComponent<IAnimatedDivProps> = ({
  animationSettings,
  children,
  style,
}) => {
  const initialScale = animationSettings.variants.initial.scale ?? 1;
  const animateScale = animationSettings.variants.animate.scale ?? 1;
  const initialX = animationSettings.variants.initial.x ?? 0;
  const animateX = animationSettings.variants.animate.x ?? 0;
  const initialY = animationSettings.variants.initial.y ?? 0;
  const animateY = animationSettings.variants.animate.y ?? 0;
  const initialOpacity = animationSettings.variants.initial.opacity ?? 1;
  const animateOpacity = animationSettings.variants.animate.opacity ?? 1;

  const imageAnimationKeyFrames =
    animationSettings.transition.repeatType === "reverse"
      ? keyframes`
    0%, 100% {
      transform: scale(${initialScale}) translateX(${initialX}px) translateY(${initialY}px);
      opacity: ${initialOpacity};
    }
    50% {
      transform: scale(${animateScale}) translateX(${animateX}px) translateY(${animateY}px);
      opacity: ${animateOpacity};
    }
  `
      : keyframes`
    from {
      transform: scale(${initialScale}) translateX(${initialX}px) translateY(${initialY}px);
      opacity: ${initialOpacity};
    }
    to{
      transform: scale(${animateScale}) translateX(${animateX}px) translateY(${animateY}px);
      opacity: ${animateOpacity};
    }
  `;

  const imageAnimationDuration =
    animationSettings.transition.repeatType === "reverse"
      ? animationSettings.transition.duration * 2
      : animationSettings.transition.duration ?? 0;

  const imageAnimationTimingFunction =
    animationSettings.transition.ease === "easeInOut"
      ? "ease-in-out"
      : animationSettings.transition.ease === "easeIn"
      ? "ease-in"
      : animationSettings.transition.ease === "easeOut"
      ? "ease-out"
      : "linear";

  // const imageAnimationDelay = animationSettings.transition.delay ?? 0;

  const animationIterationCount = animationSettings.transition.repeat ?? 1;

  const AnimatedDiv = styled.div`
    animation: ${css`
      ${imageAnimationKeyFrames} ${imageAnimationDuration}s ${imageAnimationTimingFunction}  infinite
    `};
    animation-iteration-count: ${animationIterationCount};
  `;

  return <AnimatedDiv style={style}>{children}</AnimatedDiv>;
};
