import { motion } from "framer-motion";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import NavigationItem from "./NavigationItem";
export interface INavigationProps {
  items: { label: string; destination?: string; withSuperMenu: boolean }[];
}

const Navigation: FunctionComponent<INavigationProps> = ({ items }) => {
  const [openSuperMenu, setOpenSuperMenu] = useState<number>(-1);

  ////////////////////////////////////////////////////////////////// RENDER

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
    >
      <Nav>
        <NavItemContainer>
          {items.map((item, i) => (
            <NavigationItem
              key={i}
              index={i}
              color={COLORS.soixanteseize}
              category={item.label}
              destination={item.destination}
              openSuperMenu={openSuperMenu === i}
              setOpenSuperMenu={setOpenSuperMenu}
              withSuperMenu={item.withSuperMenu}
            >
              {item.label}
            </NavigationItem>
          ))}
        </NavItemContainer>
      </Nav>
    </motion.div>
  );
};

export default Navigation;

const Nav = styled.div`
  position: fixed;
  height: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "HelveticaNeueLTPro-Bd";
  font-size: 10px;
  letter-spacing: 1px;
  top: 46px;
  right: 40px;
  background-color: ${COLORS.dark};
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    top: 30px;
    right: 30px;
  }
`;

const NavItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${COLORS.dark};
`;
