import { useQuery } from "@apollo/client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOODBOARD } from "../../graphql/queries/moodboard";
import Button from "../../libs/components/button";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";

export interface IMoodboardDeliveryProps {
  darkMode: boolean;
}

export const MoodboardDelivery: FunctionComponent<IMoodboardDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  /**********************************************************
   *** MOODBOARD ***
   **********************************************************/

  const { data } = useQuery(MOODBOARD, {
    variables: {
      moodboardInput: { moodboardId: location.search.substring(6) },
    },
  });
  // console.log(data);

  if (!data) return null;
  const images = data.moodboard.delivery
    .filter((d: any) => d.type === "IMAGE")
    .map((d: any) => d.asset);

  return (
    <Layout darkMode={darkMode}>
      <Screen>
        <Id>{`${data.moodboard.id}`}</Id>

        <Header>
          <div>
            <Title>Moodboards</Title>
            <Subtitle>{`Subject: ${data.moodboard.need.subject}`}</Subtitle>
            <Subtitle>{`Requirements: ${data.moodboard.need.requirements}`}</Subtitle>
            <Subtitle>{`Mood: ${data.moodboard.need.mood}`}</Subtitle>
          </div>
          <Button
            darkMode={darkMode}
            onClick={() => {
              localStorage.removeItem("redirect");
              navigate(`/bot/moodboard/adjust?code=${data.moodboard.id}`);
            }}
            type="classic"
          >
            {t("Button.AdjustQuery")}
          </Button>
        </Header>
        {/* <LogoContainer>
          <Logo src={Logo_SoixanteSeize} />
        </LogoContainer> */}
        <ImagesContainer>
          {images.reverse().map((image: any, key: number) => (
            <Image key={key} src={image} alt="Alt" />
          ))}
        </ImagesContainer>

        <Footer darkMode={darkMode} />
      </Screen>
    </Layout>
  );
};

const Screen = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 100px 20px;
`;

const Id = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 8px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.soixanteseize};
  text-align: right;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  margin-bottom: 20px;
  align-items: flex-end;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const Title = styled.div`
  margin-top: 30px;
  width: 100%;
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.fakeWhite};
  text-align: left;
`;

const Subtitle = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: left;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 1080px;
`;
