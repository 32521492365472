import { Transition, Variant } from "framer-motion";
import { COLORS } from "../../libs/styles/colors";

export interface IAnimationSettings {
  transition: Transition | any;
  variants: {
    initial: Variant | any;
    animate: Variant | any;
  };
}

export interface ICatchlineSettings {
  copyUpdated: string;
  color: string;
  fontProposals: string[];
  copyProposals: string[];
  copySelectionIndex: number;
  font: string;
  position: {
    horizontal: "flex-start" | "center" | "flex-end";
    vertical: "flex-start" | "center" | "flex-end";
    textAlign: "left" | "center" | "right";
  };
  fontSize: number;
  topPadding: number;
  rightPadding: number;
  bottomPadding: number;
  leftPadding: number;
  animation: IAnimationSettings;
}

export interface ILogoSettings {
  safe?: object;
  asset?: object;
  position: {
    horizontal: "flex-start" | "center" | "flex-end";
    vertical: "flex-start" | "center" | "flex-end";
    textAlign: "left" | "center" | "right";
  };
  topPadding: number;
  rightPadding: number;
  bottomPadding: number;
  leftPadding: number;
  size: number;
  animation: IAnimationSettings;
}

export interface ICtaSettings {
  copyUpdated: string;
  font: string;
  color: string;
  copyProposals: string[];
  fontProposals: string[];
  copySelectionIndex: number;
  fontSize: number;
  topPadding: number;
  rightPadding: number;
  bottomPadding: number;
  leftPadding: number;
  animation: IAnimationSettings;
}

export interface IImageSettings {
  safe?: object;
  asset?: object;
  gamma: number;
  automaticAlignment: boolean;
  imageVerticalAlignment: number;
  imageHorizontalAlignment: number;
  backgroundColor: string;
  animation: IAnimationSettings;
}

export interface IDestinationSettings {
  url: string;
}

export interface Settings {
  support: string;
  formatName: string;
  format: string;
  parameters: {
    catchline: ICatchlineSettings;
    cta: ICtaSettings;
    logo: ILogoSettings;
    image: IImageSettings;
    destination: IDestinationSettings;
  };
}

export const animationSets = {
  CLASSIC: {
    catchline: {
      transition: {
        type: "tween",
        duration: 2,
        ease: "easeInOut",
        repeat: 1,
        repeatType: "loop",
      },
      variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      },
    },
    cta: {
      transition: {
        type: "tween",
        duration: 2,
        ease: "easeInOut",
        repeat: 5,
        repeatType: "reverse",
      },
      variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      },
    },
    logo: {
      transition: {
        type: "tween",
        duration: 2,
        ease: "easeInOut",
        repeat: 1,
        repeatType: "loop",
      },
      variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      },
    },
    image: {
      transition: {
        type: "tween",
        duration: 2,
        ease: "easeInOut",
        repeat: 5,
        repeatType: "reverse",
      },
      variants: {
        initial: { scale: 1 },
        animate: { scale: 1.1 },
      },
    },
  },
  TEST: {
    catchline: {
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut",
        repeat: 1,
      },
      variants: {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0 },
      },
    },
    cta: {
      transition: {
        type: "tween",
        duration: 0.6,
        ease: "easeInOut",
        repeat: 1,
      },
      variants: {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0 },
      },
    },
    logo: {
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut",
        repeat: 1,
      },
      variants: {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
      },
    },
    image: {
      transition: {
        type: "tween",
        duration: 1,
        ease: "easeInOut",
        repeat: 1,
      },
      variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      },
    },
  },
  NO_ANIM: {
    catchline: {
      transition: {
        type: "tween",
        duration: 0,
        ease: "easeInOut",
        repeat: 0,
      },
      variants: {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
      },
    },
    cta: {
      transition: {
        type: "tween",
        duration: 0,
        ease: "easeInOut",
        repeat: 0,
      },
      variants: {
        initial: { opacity: 1, x: 0 },
        animate: { opacity: 1, x: 0 },
      },
    },
    logo: {
      transition: {
        type: "tween",
        duration: 0,
        ease: "easeInOut",
        repeat: 0,
      },
      variants: {
        initial: { opacity: 1, x: 0 },
        animate: { opacity: 1, x: 0 },
      },
    },
    image: {
      transition: {
        type: "tween",
        duration: 1,
        ease: "easeInOut",
        repeat: 0,
      },
      variants: {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
      },
    },
  },
};

// INITIAL SETTINGS

const initialCatchlineSettings: ICatchlineSettings = {
  copyUpdated: "",
  color: COLORS.fakeWhite,
  copyProposals: [],
  fontProposals: ["HelveticaNeueLTPro-Lt"],
  copySelectionIndex: 0,
  font: "HelveticaNeueLTPro-Lt",
  position: {
    horizontal: "flex-start",
    vertical: "center",
    textAlign: "left",
  },
  fontSize: 24,
  topPadding: 0,
  rightPadding: 0,
  bottomPadding: 0,
  leftPadding: 20,
  animation: { ...animationSets["CLASSIC"].catchline },
};

const initialCtaSettings: ICtaSettings = {
  copyUpdated: "",
  font: "",
  color: COLORS.fakeWhite,
  copyProposals: [],
  fontProposals: ["HelveticaNeueLTPro-Lt"],
  copySelectionIndex: 0,
  fontSize: 16,
  topPadding: 5,
  rightPadding: 0,
  bottomPadding: 0,
  leftPadding: 20,
  animation: { ...animationSets["CLASSIC"].cta },
};

const initialLogoSettings: ILogoSettings = {
  safe: undefined,
  asset: undefined,
  position: {
    horizontal: "flex-end",
    vertical: "center",
    textAlign: "left",
  },
  topPadding: 20,
  rightPadding: 20,
  bottomPadding: 20,
  leftPadding: 20,
  size: 150,
  animation: { ...animationSets["CLASSIC"].logo },
};

const initialImageSettings: IImageSettings = {
  safe: undefined,
  asset: undefined,
  gamma: 100,
  automaticAlignment: true,
  imageHorizontalAlignment: 50,
  imageVerticalAlignment: 50,
  backgroundColor: COLORS.fakeBlack,
  animation: { ...animationSets["CLASSIC"].image },
};

const initialDestinationSettings: IDestinationSettings = {
  url: "https://www.soixanteseize.com",
};

// COMPLETE SETTINGS LIST

export const DISPLAY_ADS_SETTINGS_LIST: Settings[] = [
  {
    support: "Desktop",
    formatName: "Leaderboard",
    format: "720x90",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        },
        fontSize: 24,
        topPadding: 0,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 20,
      },
      cta: {
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 5,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 20,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "center",
          textAlign: "left",
        },
        topPadding: 20,
        rightPadding: 20,
        bottomPadding: 20,
        leftPadding: 20,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Desktop",
    formatName: "Skyscraper Medium",
    format: "120x600",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "flex-start",
          textAlign: "left",
        },
        fontSize: 24,
        topPadding: 50,
        rightPadding: 10,
        bottomPadding: 0,
        leftPadding: 10,
      },
      cta: {
        ...initialCtaSettings,
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 10,
        leftPadding: 10,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "center",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 20,
        leftPadding: 5,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Desktop",
    formatName: "Skyscraper Wide",
    format: "160x600",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "flex-start",
          textAlign: "left",
        },
        fontSize: 24,
        topPadding: 50,
        rightPadding: 10,
        bottomPadding: 0,
        leftPadding: 10,
      },
      cta: {
        ...initialCtaSettings,
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 10,
        leftPadding: 10,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "center",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 20,
        leftPadding: 5,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Desktop",
    formatName: "Rectangle/MPU Medium",
    format: "300x250",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        },
        fontSize: 24,
        topPadding: 0,
        rightPadding: 20,
        bottomPadding: 0,
        leftPadding: 20,
      },
      cta: {
        ...initialCtaSettings,
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 5,
        rightPadding: 20,
        bottomPadding: 50,
        leftPadding: 20,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 0,
        rightPadding: 15,
        bottomPadding: 15,
        leftPadding: 0,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Desktop",
    formatName: "Rectangle/MPU Large",
    format: "336x280",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        },
        fontSize: 26,
        topPadding: 0,
        rightPadding: 20,
        bottomPadding: 0,
        leftPadding: 20,
      },
      cta: {
        ...initialCtaSettings,
        ...initialCtaSettings,
        fontSize: 18,
        topPadding: 5,
        rightPadding: 20,
        bottomPadding: 50,
        leftPadding: 20,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 0,
        rightPadding: 15,
        bottomPadding: 15,
        leftPadding: 0,
        size: 150,
      },
      image: initialImageSettings,
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Desktop",
    formatName: "Halfpage",
    format: "300x600",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "center",
          vertical: "center",
          textAlign: "center",
        },
        fontSize: 24,
        topPadding: 0,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 0,
      },
      cta: {
        ...initialCtaSettings,
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 10,
        rightPadding: 0,
        bottomPadding: 100,
        leftPadding: 0,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "center",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 35,
        leftPadding: 10,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Mobile",
    formatName: "Banner",
    format: "320x50",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        },
        fontSize: 14,
        topPadding: 0,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 10,
      },
      cta: {
        ...initialCtaSettings,
        fontSize: 10,
        topPadding: 0,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 10,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "center",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 10,
        leftPadding: 10,
        size: 110,
      },
      image: initialImageSettings,
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Mobile",
    formatName: "Double Banner",
    format: "320x100",

    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "flex-start",
          textAlign: "left",
        },
        fontSize: 20,
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 0,
        leftPadding: 10,
      },
      cta: {
        ...initialCtaSettings,
        fontSize: 14,
        topPadding: 5,
        rightPadding: 10,
        bottomPadding: 0,
        leftPadding: 10,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 10,
        leftPadding: 10,
        size: 130,
      },
      image: initialImageSettings,
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Mobile",
    formatName: "Rectangle",
    format: "320x250",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "flex-start",
          vertical: "center",
          textAlign: "left",
        },
        fontSize: 24,
        topPadding: 0,
        rightPadding: 10,
        bottomPadding: 0,
        leftPadding: 10,
      },
      cta: {
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 5,
        rightPadding: 10,
        bottomPadding: 20,
        leftPadding: 10,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "flex-end",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 0,
        rightPadding: 15,
        bottomPadding: 15,
        leftPadding: 0,
        size: 150,
      },
      image: initialImageSettings,
      destination: { ...initialDestinationSettings },
    },
  },
  {
    support: "Mobile",
    formatName: "Interscroller",
    format: "320x640",
    parameters: {
      catchline: {
        ...initialCatchlineSettings,
        position: {
          horizontal: "center",
          vertical: "center",
          textAlign: "center",
        },
        fontSize: 24,
        topPadding: 0,
        rightPadding: 20,
        bottomPadding: 0,
        leftPadding: 20,
      },
      cta: {
        ...initialCtaSettings,
        fontSize: 16,
        topPadding: 10,
        rightPadding: 20,
        bottomPadding: 100,
        leftPadding: 20,
      },
      logo: {
        ...initialLogoSettings,
        position: {
          horizontal: "center",
          vertical: "flex-end",
          textAlign: "left",
        },
        topPadding: 10,
        rightPadding: 10,
        bottomPadding: 35,
        leftPadding: 10,
        size: 150,
      },
      image: { ...initialImageSettings },
      destination: { ...initialDestinationSettings },
    },
  },
];
