import { gql } from "@apollo/client";
import { ImageryFragment } from "../fragments/imagery";

export const LAUNCH_IMAGERY = gql`
  mutation launchImagery($imageryInput: ImageryInput!) {
    launchImagery(imageryInput: $imageryInput) {
      ...Imagery
    }
  }
  ${ImageryFragment}
`;
