import { faSquare, faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS } from "../../styles/colors";
export interface IRadioProps {
  items: {
    label: string;
    onLabel?: string;
    onClick?: () => void;
    active: boolean;
  }[];
  direction?: "row" | "column";
  style?: CSSProperties;
}

const Radio: FunctionComponent<IRadioProps> = ({
  items,
  direction = "row",
  style,
}) => {
  ////////////////////////////////////////////////////////////////// RENDER

  return (
    <RadioItemContainer direction={direction} style={style}>
      {items.map((item, i) => (
        <OptionRow key={i} onClick={item.onClick}>
          <FontAwesomeIcon
            icon={item.active ? faSquareCheck : faSquare}
            color={item.active ? COLORS.fakeWhite : COLORS.transparentGrey}
            style={{ paddingBottom: 5 }}
          />
          <div>
            <OptionOnLabel selected={item.active}>{item.onLabel}</OptionOnLabel>
            <OptionLabel selected={item.active}>{item.label}</OptionLabel>
          </div>
        </OptionRow>
      ))}
    </RadioItemContainer>
  );
};

export default Radio;

const RadioItemContainer = styled.div<{ direction: "row" | "column" }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${(props) => (props.direction === "row" ? "20px" : "8px")};
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  margin-right: 20px;
`;

const OptionLabel = styled.div<{ selected: boolean }>`
  max-width: 1080px;
  font-size: 18px;
  color: ${(props) =>
    props.selected ? COLORS.fakeWhite : COLORS.transparentGrey};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const OptionOnLabel = styled.div<{ selected: boolean }>`
  max-width: 1080px;
  font-size: 9px;
  color: ${(props) =>
    props.selected ? COLORS.fakeWhite : COLORS.transparentGrey};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;
