import { gql } from "@apollo/client";
import { ProductionFragment } from "../fragments/production";

export const PRODUCTION = gql`
  query production($productionId: String!) {
    production(productionId: $productionId) {
      ...Production
    }
  }
  ${ProductionFragment}
`;
