import { AnswerTypes } from "../../libs/constants/general";
import { IQuestion } from "./Conversation";

export const getNextStep = (question?: IQuestion, answer?: string) => {
  let nextStep: number | undefined = undefined;
  if (question?.answerType === AnswerTypes.TEXT) {
    nextStep = question.nextStep;
  }
  if (
    question?.answerType === AnswerTypes.TEXT_AND_LIST ||
    question?.answerType === AnswerTypes.TEXT_AND_OPTIONS ||
    question?.answerType === AnswerTypes.OPTIONS ||
    question?.answerType === AnswerTypes.LIST
  ) {
    const optionNextStep = question.options?.find((o) => {
      return o.label === answer;
    })?.nextStep;

    if (optionNextStep) {
      nextStep = optionNextStep;
    } else {
      nextStep = question.nextStep;
    }
  }

  return nextStep;
};
