import { TFunction } from "i18next";

export const constraintEMail = (t: TFunction) => ({
  email: {
    email: {
      message: t("Validator.Message.Invalid"),
    },
  },
});

export const constraintPassword = (t: TFunction) => ({
  password: {
    presence: { message: t("validator.message.required") },
    length: {
      minimum: 1,
      tooShort: t("validator.message.required"),
      maximum: 20,
      tooLong: t("validator.message.max20char"),
    },
  },
});
