import { ApolloProvider } from "@apollo/client";
// import "bootstrap";
import { FunctionComponent, ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { client } from "./graphql/client";

export interface IAppIndexProps {
  children: ReactNode;
}

export const AppIndex: FunctionComponent<IAppIndexProps> = ({ children }) => {
  return (
    <div id="application">
      <BrowserRouter>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </BrowserRouter>
    </div>
  );
};
