import { useMutation } from "@apollo/client";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import validate from "validate.js";
import { UPDATE_USER } from "../../graphql/mutations/updateUser";
import { Loader } from "../../libs/components/Loader";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
import {
  constraintConfirmPassword,
  constraintPassword,
} from "./validations/validators";
export interface ISigninProps {
  darkMode?: boolean;
}

export const ChangePassword: FunctionComponent<ISigninProps> = ({
  darkMode,
}) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string | null>(
    t("ChangePassword.CursorMessage.Welcome")
  );

  const [step, setStep] = useState<number | undefined>(0);

  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** UPDATE USER ***
   **********************************************************/

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setSuccess(`${t("Success.Message.PasswordChanged")}`);
      setTimeout(() => navigate("/login"), 5000);
    },
    onError: (error: any) => {
      console.log("UPDATE_USER ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
      setButtons([
        <Button
          icon={faRefresh}
          iconPosition="left"
          key={0}
          darkMode={darkMode}
          onClick={() => navigate("/login")}
          type="classic"
        >
          {t("Button.Restart")}
        </Button>,
      ]);
    },
  });

  useEffect(() => {
    if (
      location.search &&
      confirmedPassword &&
      !loading &&
      !error &&
      !success
    ) {
      updateUser({
        variables: {
          updateUserInput: {
            hash: location.search.substring(6),
            password: confirmedPassword,
          },
        },
      });
    }
  }, [confirmedPassword, error, loading, location.search, success, updateUser]);

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    if (loading)
      setCursorMessage(t("ChangePassword.CursorMessage.CreatingUser"));
    if (success)
      setCursorMessage(t("ChangePassword.CursorMessage.UserCreated"));
    if (error) setCursorMessage(t("ChangePassword.CursorMessage.Error"));
  }, [error, loading, setCursorMessage, success, t]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("ChangePassword.Question.0"),
        answerType: AnswerTypes.TEXT,
        secured: true,
        nextStep: 1,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ password: answer }, constraintPassword(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.password[0]);
          else {
            setPassword(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 1,
        q: t("ChangePassword.Question.1"),
        answerType: AnswerTypes.TEXT,
        secured: true,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate(
            { password, confirmPassword: answer },
            constraintConfirmPassword(t),
            {
              fullMessages: false,
            }
          );
          if (errors) setError(errors.confirmPassword[0]);
          else {
            setConfirmedPassword(answer);
          }
        },
      },
    ];
    return q;
  }, [password, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        success={success}
        resetErrors={() => setError(undefined)}
        processing={loading}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {loading && <Loader />}
    </Layout>
  );
};
