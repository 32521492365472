import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  faEye,
  faMagicWandSparkles,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Account, User } from "../../graphql/generated/graphql";
import { CREATE_IMAGERY } from "../../graphql/mutations/createImagery";
import { LAUNCH_IMAGERY } from "../../graphql/mutations/launchImagery";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
export interface IBotProps {
  darkMode: boolean;
}

export const BotImageryZoomOut: FunctionComponent<IBotProps> = ({
  darkMode,
}) => {
  //
  localStorage.removeItem("redirect");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const paramCodeValue = searchParams.get("code");
  const paramDeliveryValue = searchParams.get("delivery");

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);
  const [initialImagery, setInitialImagery] = useState<any | undefined>(
    undefined
  );

  /**********************************************************
   *** NEW PARAMETERS ***
   **********************************************************/

  const [direction, setDirection] = useState<
    "around" | "left" | "right" | "up" | "down" | undefined
  >(undefined);
  const [prompt, setPrompt] = useState<string | undefined>(undefined);

  /**********************************************************
   *** CONVERSATION STATE ***
   **********************************************************/

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** COMPONENTS ***
   **********************************************************/

  const ErrorButtons = [
    <Button
      key={"button0"}
      icon={faMagicWandSparkles}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => window.location.reload()}
      type="secondary"
    >
      {t("Button.Imagery.ReQuery")}
    </Button>,
    <Button
      key={"buttonA1"}
      icon={faTimes}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => navigate("/bot/start")}
      type="secondary"
    >
      {t("Button.BackBotStart")}
    </Button>,
  ];

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [user, setUser] = useState<User | undefined>(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      setAccount(account);
      setUser(account?.users[0]);
      if (
        account.credit <= 0 &&
        (!account.subscriptionExpiration ||
          (account.subscriptionExpiration &&
            moment().isAfter(moment(account.subscriptionExpiration))))
      )
        navigate("/subscription");
    },
  });

  /**********************************************************
   *** INITIAL IMAGERY ***
   **********************************************************/

  useQuery(IMAGERY, {
    variables: {
      imageryInput: { imageryId: paramCodeValue },
    },
    onCompleted: (data: any) => {
      setInitialImagery(data?.imagery);
    },
  });

  /**********************************************************
   *** IMAGERY ***
   **********************************************************/

  const [imageryId, setImageryId] = useState<string | undefined>(undefined);

  const [createImagery, { loading: createImageryLoading }] = useMutation(
    CREATE_IMAGERY,
    {
      onCompleted: (data) => {
        // console.log("CREATE_IMAGERY DATA:", data);
        setImageryId(data.createImagery.id);
        launchImagery({
          variables: { imageryInput: { imageryId: data.createImagery.id } },
        });
      },
      onError: (error: any) => {
        console.log("CREATE_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
        setButtons(ErrorButtons);
      },
    }
  );

  const [launchImagery, { loading: launchImageryLoading }] = useMutation(
    LAUNCH_IMAGERY,
    {
      onCompleted: (data) => {
        setSuccess(`${t("Success.Message.ImageryCreated")}`);
      },
      onError: (error: any) => {
        setSuccess(`${t("Error.Message.ManualProcessEngaged")}`);
        setButtons(ErrorButtons);
      },
    }
  );

  /**********************************************************
   *** DELIVERY ***
   **********************************************************/

  const [deliveryOk, setDeliveryOk] = useState<boolean>(false);

  const [getImagery, { data: imageryData }] = useLazyQuery(IMAGERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.imagery?.delivery?.length > 0) {
        setButtons([
          <Button
            icon={faEye}
            iconPosition="left"
            darkMode={darkMode}
            onClick={() =>
              navigate(`/imagery/delivery?code=${imageryData.imagery.id}`)
            }
            type="validation"
          >
            {t("Button.Imagery.SeeResults")}
          </Button>,
        ]);
        setDeliveryOk(true);
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageryId && !deliveryOk) {
        getImagery({ variables: { imageryInput: { imageryId } } });
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    createImageryLoading,
    deliveryOk,
    getImagery,
    imageryId,
    launchImageryLoading,
  ]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo((): IQuestion[] => {
    const image =
      initialImagery?.delivery[parseInt(paramDeliveryValue ?? "0")]?.asset;

    return [
      {
        step: 0,
        images: image ? [image] : undefined,
        q: t("BotImageryZoomOut.Question.0"),
        help: t("BotImageryZoomOut.Question.0.Help"),
        answerType: AnswerTypes.LIST,
        options: [
          {
            value: "around",
            label: t("BotImageryZoomOut.Question.0.Option.0"),
            nextStep: 1,
          },
          {
            value: "right",
            label: t("BotImageryZoomOut.Question.0.Option.1"),
            type: "secondary",
            nextStep: 100,
          },
          {
            value: "down",
            label: t("BotImageryZoomOut.Question.0.Option.2"),
            type: "secondary",
            nextStep: 100,
          },
          {
            value: "left",
            label: t("BotImageryZoomOut.Question.0.Option.3"),
            type: "secondary",
            nextStep: 100,
          },
          {
            value: "up",
            label: t("BotImageryZoomOut.Question.0.Option.4"),
            type: "secondary",
            nextStep: 100,
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          const a = question.options?.find((o) => o.label === answer)?.value;
          setDirection(a);

          setStep(getNextStep(question, answer));
        },
      },
      {
        step: 1,
        q: t("BotImageryZoomOut.Question.1"),
        help: t("BotImageryZoomOut.Question.1.Help"),
        answerType: AnswerTypes.TEXT,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          setPrompt(answer);
          setStep(getNextStep(question, answer));
        },
      },
      {
        step: 100,
        q: t("BotImageryZoomOut.Question.100"),
        help: t("BotImageryZoomOut.Question.100.Help", {
          credit: account?.credit ?? 0,
          // timePromess: getTimePromess(120),
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImagery.Question.LAUNCH.Option.1"),
            label: t("BotImagery.Question.LAUNCH.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.LAUNCH.Option.0"),
            label: t("BotImagery.Question.LAUNCH.Option.0"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.LAUNCH.Option.0")) {
            createImagery({
              variables: {
                createImageryInput: {
                  type: initialImagery.type,
                  parametric: false,
                  need: {
                    ...initialImagery.need,
                    ZOOMOUT: { direction, prompt },
                  },
                  refUrl: image,
                  seeds: [
                    ...(initialImagery?.seeds ?? []).map((s: any) => ({
                      id: s.id,
                      asset: s.asset,
                      delivery: s.delivery,
                    })),
                    {
                      id: initialImagery?.id,
                      delivery: parseInt(paramDeliveryValue ?? "0"),
                      asset: image,
                    },
                  ],
                },
              },
            });
          }
          if (answer === t("BotImagery.Question.LAUNCH.Option.1")) {
            navigate(-1);
          }
        },
      },
    ]
      .filter((q) => q.step >= (step ?? 0))
      .sort((a, b) => a.step - b.step);
  }, [
    account?.credit,
    createImagery,
    direction,
    initialImagery,
    navigate,
    paramDeliveryValue,
    prompt,
    step,
    t,
  ]);

  // console.log("STEP", step);
  // console.log("ALL QUESTIONS", allQuestions);

  if (!user) return null;

  return (
    <Layout
      darkMode={darkMode}
      processing={createImageryLoading || launchImageryLoading}
      processingProgress={`${
        imageryData?.imagery?.progress?.percentage ?? "0"
      } %`}
    >
      {initialImagery && (
        <Conversation
          darkMode={darkMode}
          allQuestions={allQuestions}
          step={step ?? 0}
          setStep={setStep}
          error={error}
          success={success}
          resetErrors={() => setError(undefined)}
          processing={createImageryLoading || launchImageryLoading}
          buttons={buttons}
          resetButtons={() => setButtons([])}
        />
      )}
    </Layout>
  );
};
