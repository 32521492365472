import gql from "graphql-tag";
import { AccountFragment } from "./account";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const CreditTrackFragment = gql`
  fragment CreditTrack on CreditTrack {
    id
    accountId
    account {
      ...Account
    }
    type
    deliveredAssetId
    amount
    comment
    createdAt
    createdBy
  }
  ${AccountFragment}
`;
