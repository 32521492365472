import gql from "graphql-tag";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const UserFragment = gql`
  fragment User on User {
    id
    role
    # activated
    email
    firstName
    lastName
    language
    phoneNumber
    # optinSystem
    # optinMarket
    createdAt
    updatedAt
  }
`;
