import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { find } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import validate from "validate.js";
import { Account, Imagery } from "../../graphql/generated/graphql";
import { CREATE_IMAGERY } from "../../graphql/mutations/createImagery";
import { CREATE_SAFE } from "../../graphql/mutations/createSafe";
import { LAUNCH_IMAGERY } from "../../graphql/mutations/launchImagery";
import { SAVE_IMAGE } from "../../graphql/mutations/saveImage";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import { USER_SAFES } from "../../graphql/queries/safe";
import Button from "../../libs/components/button";
import { LoadingScreen } from "../../libs/components/layout/LoadingScreen";
import {
  AnswerTypes,
  AssetTypes,
  SafeTypes,
} from "../../libs/constants/general";
import {
  illustrationStyleOptions,
  imageTypeOptions,
  photoTypeOptions,
} from "../../libs/constants/options";
import { errorMessage } from "../../libs/errors/errorMessage";
import { COLORS } from "../../libs/styles/colors";
import { constraintName } from "../account/validations/validators";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";

export interface ICreateNewImageryProps {
  darkMode?: boolean;
  onClose: () => void;
  initialImageryId?: string;
  setSafeIdForSettings: (safeId: string) => void;
}

export const CreateNewImagery: FunctionComponent<ICreateNewImageryProps> = ({
  darkMode,
  onClose,
  initialImageryId,
  setSafeIdForSettings,
}) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);
  const [image, setImage] = useState<string>("");
  const [type, setType] = useState<string | undefined>(undefined);
  // const [inspiration, setInspiration] = useState<string | undefined>(undefined);
  const [style, setStyle] = useState<string | undefined>(undefined);
  const [scene, setScene] = useState<string>("");
  // const [shotType, setShotType] = useState<string>("");

  /**********************************************************
   *** INITIAL IMAGERY ***
   **********************************************************/

  const [initialImagery, setInitialImagery] = useState<any>(undefined);

  const [getInitialImagery] = useLazyQuery(IMAGERY, {
    variables: {
      imageryInput: { imageryId: initialImageryId },
    },
    onCompleted: (data: any) => {
      setImage(data?.imagery?.type);
      setInitialImagery(data?.imagery);
    },
  });

  useEffect(() => {
    if (initialImageryId) {
      getInitialImagery();
    }
  }, [getInitialImagery, initialImageryId]);

  /**********************************************************
   *** CONVERSATION STATE ***
   **********************************************************/

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** COMPONENTS ***
   **********************************************************/

  const ErrorButtons = [
    <Button
      key={"buttonA0"}
      icon={faTimes}
      iconPosition="left"
      darkMode={darkMode}
      onClick={() => onClose()}
      type="classic"
      style={{
        backgroundColor: COLORS.transparent,
        borderColor: COLORS.fakeWhite,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {t("Button.Imagery.ReQuery")}
    </Button>,
  ];

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  // const [user, setUser] = useState<User | undefined>(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      setAccount(account);
      // setUser(account?.users[0]);
      if (
        account.credit <= 0 &&
        (!account.subscriptionExpiration ||
          (account.subscriptionExpiration &&
            moment().isAfter(moment(account.subscriptionExpiration))))
      )
        navigate("/subscription");
    },
    fetchPolicy: "network-only",
  });

  /**********************************************************
   *** IMAGERY ***
   **********************************************************/

  const [imageryId, setImageryId] = useState<string | undefined>(undefined);

  const [createImagery, { loading: createImageryLoading }] = useMutation(
    CREATE_IMAGERY,
    {
      onCompleted: (data) => {
        setImageryId(data.createImagery.id);
        launchImagery({
          variables: { imageryInput: { imageryId: data.createImagery.id } },
        });
      },
      onError: (error: any) => {
        console.log("CREATE_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
        setButtons(ErrorButtons);
      },
    }
  );

  const [launchImagery, { loading: launchImageryLoading }] = useMutation(
    LAUNCH_IMAGERY,
    {
      onError: (error: any) => {
        console.log("LAUNCH_IMAGERY ERROR:", JSON.parse(JSON.stringify(error)));
        setSuccess(`${t("Error.Message.ManualProcessEngaged")}`);
        setButtons(ErrorButtons);
      },
    }
  );

  /**********************************************************
   *** DELIVERY ***
   **********************************************************/

  const [deliveryOk, setDeliveryOk] = useState<boolean>(false);

  const [imagery, setImagery] = useState<Imagery | undefined>(undefined);
  const [chosenDeliveryIndex, setChosenDeliveryIndex] = useState<
    number | undefined
  >(undefined);

  const [getImagery, { data: imageryData }] = useLazyQuery(IMAGERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      // console.log("IMAGERY DATA:", data);
      if (data?.imagery?.delivery?.length > 0) {
        setImagery(data.imagery);
        setDeliveryOk(true);
        setStep(200);
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageryId && !deliveryOk) {
        getImagery({ variables: { imageryInput: { imageryId } } });
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    createImageryLoading,
    deliveryOk,
    getImagery,
    imageryId,
    launchImageryLoading,
  ]);

  /**********************************************************
   *** SAFE PROCESS ***
   **********************************************************/

  const [safeName, setSafeName] = useState<string>("");
  const [safeId, setSafeId] = useState<string>("");
  const [asset, setAsset] = useState<
    | {
        imageName: string;
        imageryId?: string;
        deliveryIndex?: number;
        imageFile?: any;
      }
    | undefined
  >(undefined);
  const [safesOptions, setSafesOptions] = useState<
    { value: string; label: string; nextStep: number }[]
  >([]);

  useQuery(USER_SAFES, {
    variables: {
      userSafesInput: {
        type: SafeTypes.AI,
        skip: 0,
        limit: 100,
        order: ["createdAt:DESC"],
      },
    },
    onError: (error: any) => {
      console.log("ERROR", error);
      // setError(errorMessage(error, t));
    },
    onCompleted: (data: any) => {
      const safes = data.userSafes.results.map((s: any) => ({
        value: s.id,
        label: s.name,
        nextStep: 310,
      }));

      setSafesOptions([
        {
          value: "new",
          label: t("SaveImage.Answer.0.New"),
          nextStep: 301,
        },
        ...safes,
      ]);
    },
    fetchPolicy: "network-only",
  });

  /**********************************************************
   *** CREATE SAFE ***
   **********************************************************/

  const [createSafe, { loading: createSafeLoading }] = useMutation(
    CREATE_SAFE,
    {
      onCompleted: (data: any) => {
        setSafeId(data.createSafe.id);
        setSuccess(`${t("Success.Message.SafeCreated")}`);
        setTimeout(() => {
          setSuccess(``);
          setStep(310);
        }, 2000);
      },
      onError: (error: any) => {
        console.log("SAFE CREATION ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
      },
    }
  );

  useEffect(() => {
    if (safeName) {
      createSafe({
        variables: {
          createSafeInput: {
            name: safeName,
            type: SafeTypes.AI,
          },
        },
      });
    }
  }, [createSafe, safeName, type]);

  /**********************************************************
   *** ADD ASSET ***
   **********************************************************/

  const [saveImage, { loading: saveImageLoading }] = useMutation(SAVE_IMAGE, {
    onCompleted: (data: any) => {
      setSuccess(`${t("Success.Message.ImageSaved")} `);
      setTimeout(() => {
        setSafeIdForSettings(safeId);
        onClose();
      }, 2000);
    },
    onError: (error: any) => {
      console.log("SAVE IMAGE ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (safeId && asset && !asset.imageFile) {
      saveImage({
        variables: {
          saveImageFromUrlInput: {
            type: AssetTypes.AI_IMAGERY,
            filename: asset.imageName,
            safeId,
            jobId: imagery?.id,
            jobDeliveryIndex: chosenDeliveryIndex,
            url: imagery?.delivery
              ? imagery?.delivery[chosenDeliveryIndex ?? 0]?.asset
              : "",
          },
        },
      });
    }
  }, [
    asset,
    chosenDeliveryIndex,
    imagery?.delivery,
    imagery?.id,
    safeId,
    saveImage,
  ]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(
    (): IQuestion[] => [
      {
        step: 0,
        q: t("BotImagery.Question.1"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  imageTypeOptions(t),
                  (o: any) => o.value === initialImagery.type
                )?.label ?? initialImagery.type,
            })
          : "",
        answerType: AnswerTypes.LIST,
        options: imageTypeOptions(t).map((option, i) => ({
          ...option,
          nextStep: (i + 1) * 10,
        })),
        initialValue: initialImagery?.type,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setImage(answerValue);
          setStep(getNextStep(question, answer));
        },
      },

      /* Photographie */
      {
        step: 10,
        q: t("BotImagery.Question.PHOTOGRAPHY.0"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  photoTypeOptions(t),
                  (o: any) => o.value === initialImagery.need.TYPE
                )?.label ?? initialImagery.need.TYPE,
            })
          : t("BotImagery.Question.PHOTOGRAPHY.0.Help"),
        answerType: AnswerTypes.TEXT_AND_LIST,
        options: photoTypeOptions(t),
        initialValue: initialImagery?.need.TYPE,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setType(answerValue ?? answer);
          setStep(getNextStep(question, answer));
        },
      },

      /* Illustration */
      {
        step: 20,
        q: t("BotImagery.Question.ILLUSTRATION.0"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer:
                find(
                  illustrationStyleOptions(t),
                  (o: any) => o.value === initialImagery.need.STYLE
                )?.label ?? initialImagery.need.STYLE,
            })
          : t("BotImagery.Question.ILLUSTRATION.0.Help"),
        answerType: AnswerTypes.TEXT_AND_LIST,
        options: illustrationStyleOptions(t),
        initialValue: initialImagery?.need.STYLE,
        nextStep: 100,
        onAnswer: (question: IQuestion, answer: string) => {
          const answerValue = question.options?.find(
            (o) => o.label === answer
          )?.value;
          setStyle(answerValue ?? answer);
          setStep(getNextStep(question, answer));
        },
      },
      // {
      //   step: 21,
      //   q: t("BotImagery.Question.ILLUSTRATION.1"),
      //   help: initialImagery
      //     ? t("BotImagery.Question.1.Help", {
      //         previousAnswer:
      //           find(
      //             illustrationArtistOptions(t),
      //             (o: any) => o.value === initialImagery.need.INSPIRATION
      //           )?.label ?? initialImagery.need.INSPIRATION,
      //       })
      //     : t("BotImagery.Question.ILLUSTRATION.1.Help"),
      //   answerType: AnswerTypes.TEXT_AND_LIST,
      //   options: illustrationArtistOptions(t),
      //   initialValue: initialImagery?.need.INSPIRATION,
      //   nextStep: 100,
      //   onAnswer: (question: IQuestion, answer: string) => {
      //     const answerValue = question.options?.find(
      //       (o) => o.label === answer
      //     )?.value;
      //     setInspiration(answerValue ?? answer);
      //     setStep(getNextStep(question, answer));
      //   },
      // },

      /* FINAL */
      {
        step: 100,
        q: t("BotImagery.Question.2"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer: initialImagery.need.SCENE,
            })
          : t("BotImagery.Question.2.Help"),
        answerType: AnswerTypes.TEXT,
        initialValue: initialImagery?.need.SCENE,
        nextStep: 102,
        onAnswer: (question: IQuestion, answer: string) => {
          setScene(answer);
          setStep(getNextStep(question, answer));
        },
      },
      /*       {
        step: 101,
        q: t("BotImagery.Question.PHOTOGRAPHY.1"),
        help: initialImagery
          ? t("BotImagery.Question.1.Help", {
              previousAnswer: initialImagery.need.INSPIRATION,
            })
          : t("BotImagery.Question.PHOTOGRAPHY.1.Help"),
        answerType: AnswerTypes.TEXT,
        initialValue: initialImagery?.need.INSPIRATION,
        nextStep: 102,
        onAnswer: (question: IQuestion, answer: string) => {
          setInspiration(answer);
          setStep(getNextStep(question, answer));
        },
      }, */
      {
        step: 102,
        q: t("BotImagery.Question.LAUNCH"),
        help: t("BotImagery.Question.LAUNCH.Help", {
          credit: account?.credit ?? 0,
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImagery.Question.LAUNCH.Option.1"),
            label: t("BotImagery.Question.LAUNCH.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.LAUNCH.Option.0"),
            label: t("BotImagery.Question.LAUNCH.Option.0"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.LAUNCH.Option.0")) {
            createImagery({
              variables: {
                createImageryInput: {
                  type: image,
                  need: {
                    TYPE: type,
                    SCENE: scene,
                    // INSPIRATION: inspiration,
                    STYLE: style,
                  },
                  parametric: false,
                },
              },
            });
          }
          if (answer === t("BotImagery.Question.4.Option.1")) {
            onClose();
          }
        },
      },
      {
        step: 200,
        q: t("BotImagery.Question.RESULTS"),
        help: t("BotImagery.Question.RESULTS.Help", {
          credit: account?.credit ?? 0,
        }),
        images: imagery?.delivery?.map((d) => d.asset ?? ""),
        answerType: AnswerTypes.LIST,
        options: [
          {
            value: t("BotImagery.Question.RESULTS.Option.1"),
            label: t("BotImagery.Question.RESULTS.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.RESULTS.Option.2"),
            label: t("BotImagery.Question.RESULTS.Option.2"),
          },
          {
            value: t("BotImagery.Question.RESULTS.Option.3"),
            label: t("BotImagery.Question.RESULTS.Option.3"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.RESULTS.Option.4"),
            label: t("BotImagery.Question.RESULTS.Option.4"),
          },
          {
            value: t("BotImagery.Question.RESULTS.Option.0"),
            label: t("BotImagery.Question.RESULTS.Option.0"),
            type: "secondary",
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.RESULTS.Option.0")) onClose();
          if (answer === t("BotImagery.Question.RESULTS.Option.1")) {
            setChosenDeliveryIndex(0);
            setStep(300);
          }
          if (answer === t("BotImagery.Question.RESULTS.Option.2")) {
            setChosenDeliveryIndex(1);
            setStep(300);
          }
          if (answer === t("BotImagery.Question.RESULTS.Option.3")) {
            setChosenDeliveryIndex(2);
            setStep(300);
          }
          if (answer === t("BotImagery.Question.RESULTS.Option.4")) {
            setChosenDeliveryIndex(3);
            setStep(300);
          }
        },
      },
      /* {
        step: 201,
        q: t("BotImagery.Question.RELAUNCH"),
        help: t("BotImagery.Question.RELAUNCH.Help", {
          credit: account?.credit ?? 0,
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("BotImagery.Question.RELAUNCH.Option.1"),
            label: t("BotImagery.Question.RELAUNCH.Option.1"),
            type: "secondary",
          },
          {
            value: t("BotImagery.Question.RELAUNCH.Option.0"),
            label: t("BotImagery.Question.RELAUNCH.Option.0"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("BotImagery.Question.RELAUNCH.Option.0")) {
            setImagery(undefined);
            setDeliveryOk(false);
            createImagery({
              variables: {
                createImageryInput: {
                  type: image,
                  need: {
                    TYPE: type,
                    SCENE: scene,
                    // INSPIRATION: inspiration,
                    STYLE: style,
                  },
                  parametric: false,
                },
              },
            });
          }
          if (answer === t("BotImagery.Question.RELAUNCH.Option.1")) {
            onClose();
          }
        },
      }, */
      {
        step: 300,
        images: imagery?.delivery
          ? [imagery?.delivery[chosenDeliveryIndex ?? 0]?.asset ?? ""]
          : [],
        q: t("SaveImage.Question.0"),
        answerType: AnswerTypes.LIST,
        options: safesOptions,
        nextStep: 1,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            const safeId = find(question.options, { label: answer })?.value;
            setSafeId(safeId);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 301,
        q: t("SaveImage.Question.1"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setSafeName(answer);
          }
        },
      },
      {
        step: 310,
        q: t("SaveImage.Question.10"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setAsset({
              imageName: answer,
              deliveryIndex: chosenDeliveryIndex,
              imageryId: imagery?.id,
            });
          }
        },
      },
    ],
    [
      t,
      initialImagery,
      account?.credit,
      imagery?.delivery,
      imagery?.id,
      chosenDeliveryIndex,
      safesOptions,
      createImagery,
      image,
      type,
      scene,
      style,
      onClose,
    ]
  );
  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const processing = createImageryLoading || launchImageryLoading;

  return (
    <>
      <Conversation
        darkMode={true}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        info={
          createImageryLoading ||
          launchImageryLoading ||
          createSafeLoading ||
          saveImageLoading
            ? t("Loading.Message.Waiting")
            : undefined
        }
        resetErrors={() => setError(undefined)}
        success={success}
        resetSuccess={() => setSuccess(undefined)}
        processing={
          createImageryLoading ||
          launchImageryLoading ||
          createSafeLoading ||
          saveImageLoading
        }
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {processing && (
        <LoaderContainer darkMode={darkMode}>
          <LoadingScreen
            waitingMessage={t("Loading.Message.Modal")}
            processingProgress={`${
              imageryData?.imagery?.progress?.percentage ?? "0"
            } %`}
            onClose={() => onClose()}
          />
        </LoaderContainer>
      )}
    </>
  );
};

const LoaderContainer = styled.div<{
  darkMode?: boolean;
}>`
  --bg-color: ${(props) =>
    props.darkMode ? COLORS.fakeBlack : COLORS.fakeWhite};

  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--bg-color);
`;
