import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import {
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import styled, { ServerStyleSheet } from "styled-components";
import { AppContext } from "../..";
import { Production, Safe } from "../../graphql/generated/graphql";
import Button from "../../libs/components/button";
import { StepSelector } from "../../libs/components/selector/StepSelector";
import { AssetTypes } from "../../libs/constants/general";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { DisplayAdRender } from "./DisplayAdRender";
import { Settings } from "./constants";
import { ParamAnimation } from "./paramComponents/ParamAnimation";
import { ParamCatchlineCta } from "./paramComponents/ParamCatchlineCta";
import { ParamDestination } from "./paramComponents/ParamDestination";
import { ParamExportation } from "./paramComponents/ParamExportation";
import { ParamImage } from "./paramComponents/ParamImage";
import { ParamLogo } from "./paramComponents/ParamLogo";

export interface IDisplayAdResultProps {
  darkMode: boolean;
  displayAdSettings?: Settings;
  setDisplayAdSettings: (value: SetStateAction<Settings>) => void;
  setIsGenerated: (arg: boolean) => void;
  setGenerateCopyProposalsModal: (modal: { open: boolean }) => void;
  setGenerateFontFamiliesModal: (modal: { open: boolean }) => void;
  setExportFormatModal: (modal: { open: boolean; initialvalues: any }) => void;
  setSaveProductionModal: (modal: {
    open: boolean;
    sets: any;
    initialProduction: any;
  }) => void;
  production: Production;
  setCreateNewImageryModal: (modal: { open: boolean }) => void;
  setSaveImageModal: (value: {
    open: boolean;
    image: any;
    type: AssetTypes;
  }) => void;
  setImageModal: (value: {
    open: boolean;
    image: any;
    jobId: string;
    jobDeliveryIndex: number;
  }) => void;
  safes: Safe[];
}

export const DisplayAdResult: FunctionComponent<IDisplayAdResultProps> = ({
  darkMode,
  displayAdSettings,
  setDisplayAdSettings,
  setIsGenerated,
  setGenerateCopyProposalsModal,
  setGenerateFontFamiliesModal,
  setExportFormatModal,
  setSaveProductionModal,
  production,
  setCreateNewImageryModal,
  setSaveImageModal,
  setImageModal,
  safes,
}) => {
  //
  const { t } = useTranslation();

  const context = useContext(AppContext);
  const { state /* , setState */ } = context;
  // console.log("STATE", state);

  /**********************************************************
   *** STATE ***
   **********************************************************/
  const [reRender, setReRender] = useState<boolean>(false);

  useEffect(() => {
    if (reRender) setTimeout(() => setReRender(false), 1000);
  }, [displayAdSettings, reRender]);

  const [selectorPosition, setSelectorPosition] = useState<string>("copy");

  /**********************************************************
   *** VALIDATORS ***
   **********************************************************/

  const isCopyValidated =
    displayAdSettings?.parameters?.catchline?.copyUpdated !== undefined &&
    displayAdSettings?.parameters?.cta?.copyUpdated !== undefined;
  const isImageValidated =
    displayAdSettings?.parameters?.image?.safe !== undefined &&
    displayAdSettings?.parameters?.image?.asset !== undefined;
  const isLogoValidated =
    displayAdSettings?.parameters?.logo?.safe !== undefined &&
    displayAdSettings?.parameters?.logo?.asset !== undefined;
  const isAnimationValidated = true;
  const isDestinationValidated =
    displayAdSettings?.parameters?.destination?.url !== undefined &&
    displayAdSettings?.parameters?.destination?.url !== "" &&
    displayAdSettings?.parameters?.destination?.url !==
      "https://www.soixanteseize.com";

  const selectors = useMemo(() => {
    return [
      {
        label: "Accroche & CTA",
        onClick: () => setSelectorPosition("copy"),
        active: selectorPosition === "copy",
        stepValidated: isCopyValidated,
      },
      {
        label: "Image de fond",
        onClick: () => setSelectorPosition("image"),
        active: selectorPosition === "image",
        stepValidated: isImageValidated,
      },
      {
        label: "Logo",
        onClick: () => setSelectorPosition("logo"),
        active: selectorPosition === "logo",
        stepValidated: isLogoValidated,
      },
      {
        label: "Animations",
        onClick: () => setSelectorPosition("animation"),
        active: selectorPosition === "animation",
        stepValidated: isAnimationValidated,
      },
      {
        label: "Destination",
        onClick: () => setSelectorPosition("destination"),
        active: selectorPosition === "destination",
        stepValidated: isDestinationValidated,
      },
      {
        label: "Exportation",
        onClick: () => setSelectorPosition("exportation"),
        active: selectorPosition === "exportation",
        stepValidated:
          isCopyValidated &&
          isImageValidated &&
          isLogoValidated &&
          isAnimationValidated &&
          isDestinationValidated,
      },
    ];
  }, [
    isAnimationValidated,
    isCopyValidated,
    isDestinationValidated,
    isImageValidated,
    isLogoValidated,
    selectorPosition,
  ]);

  if (!displayAdSettings) return null;

  const width = parseInt(displayAdSettings.format.split("x")[0]);
  const height = parseInt(displayAdSettings.format.split("x")[1]);

  // console.log("DISPLAY AD", displayAdSettings);

  return (
    <Element>
      <TitleRow>
        <div>
          <Subtitle>{`${displayAdSettings.support}  |  ${displayAdSettings.format}`}</Subtitle>
          <Title darkMode={darkMode}>{displayAdSettings.formatName}</Title>
        </div>
        <Button
          onClick={() => {
            setSaveProductionModal({
              open: true,
              sets: map(state.factory.displayAd, (set: any) => set),
              initialProduction: production,
            });
          }}
          type={"validation"}
        >
          {t("Button.Save")}
        </Button>
      </TitleRow>

      <ResultContainer width={width} /* id="animation" */>
        <RenderContainer>
          {!reRender ? (
            <FormatRow>
              <DisplayAdRender displayAdSettings={displayAdSettings} />
            </FormatRow>
          ) : (
            <FormatContainer
              style={{ width, height, backgroundColor: COLORS.fakeBlack }}
            ></FormatContainer>
          )}
          <RefreshLink
            onClick={() => {
              setReRender(true);
            }}
          >
            <FontAwesomeIcon icon={faRefresh} style={{ marginRight: 10 }} />
            {t("Button.RefreshResult").toUpperCase()}
          </RefreshLink>
        </RenderContainer>

        <ParamsContainer>
          <StepSelector items={selectors} />
          <Parameters>
            <>
              {selectorPosition === "image" && (
                <ParamImage
                  darkMode={darkMode}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  stepValidated={isImageValidated}
                  setCreateNewImageryModal={setCreateNewImageryModal}
                  setSaveImageModal={setSaveImageModal}
                  setImageModal={setImageModal}
                  safes={safes}
                />
              )}
              {selectorPosition === "logo" && (
                <ParamLogo
                  darkMode={darkMode}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  stepValidated={isLogoValidated}
                  setSaveImageModal={setSaveImageModal}
                  safes={safes}
                />
              )}
              {selectorPosition === "copy" && (
                <ParamCatchlineCta
                  darkMode={darkMode}
                  setIsGenerated={setIsGenerated}
                  setGenerateCopyProposalsModal={setGenerateCopyProposalsModal}
                  setGenerateFontFamiliesModal={setGenerateFontFamiliesModal}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  stepValidated={isCopyValidated}
                />
              )}
              {selectorPosition === "animation" && (
                <ParamAnimation
                  darkMode={darkMode}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  setReRender={setReRender}
                  stepValidated={isAnimationValidated}
                />
              )}
              {selectorPosition === "destination" && (
                <ParamDestination
                  darkMode={darkMode}
                  displayAdSettings={displayAdSettings}
                  setDisplayAdSettings={setDisplayAdSettings}
                  stepValidated={isDestinationValidated}
                />
              )}
              {selectorPosition === "exportation" && (
                <ParamExportation
                  darkMode={darkMode}
                  stepValidated={isDestinationValidated}
                  button={
                    <Button
                      onClick={() => {
                        if (!isDestinationValidated) return;
                        const sheet = new ServerStyleSheet();
                        const html = ReactDOMServer.renderToString(
                          sheet.collectStyles(
                            <DisplayAdRender
                              displayAdSettings={displayAdSettings}
                            />
                          )
                        );
                        const css = sheet.getStyleTags(); // or sheet.getStyleElement();

                        setExportFormatModal({
                          open: true,
                          initialvalues: {
                            html,
                            css,
                          },
                        });
                      }}
                      style={{ opacity: isDestinationValidated ? 1 : 0.5 }}
                      type={"validation"}
                    >
                      {t("Button.ExportFormat")}
                    </Button>
                  }
                />
              )}
            </>
          </Parameters>
        </ParamsContainer>
      </ResultContainer>
    </Element>
  );
};

const Element = styled.div`
  width: 100%;
  /* background-color: aliceblue; */
`;

const ResultContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.width < 300 ? "row" : "column")};
  justify-content: ${(props) => (props.width < 300 ? "center" : "flex-start")};
  align-items: ${(props) => (props.width < 300 ? "flex-start" : "center")};
  gap: 20px;
  margin-top: 20px;
  padding-top: 20px;
  /* background-color: ${COLORS.transparentDark}; */
`;

const RenderContainer = styled.div`
  /* background-color: ${COLORS.fakeBlack};
  flex: 1;
  padding: 20px 20px 0px 20px; */
`;

const FormatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const Parameters = styled.div`
  width: 100%;
`;

const ParamsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FormatContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div<{ darkMode: boolean }>`
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeBlack)};
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const RefreshLink = styled.div`
  font-size: 10px;
  color: ${COLORS.soixanteseize};
  background-color: ${COLORS.fakeBlack};
  padding: 10px 20px 9px 20px;
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${COLORS.mediumgray};
    background-color: ${COLORS.fakeWhite};
  }
`;
