import { gql } from "@apollo/client";
import { AssetFragment } from "../fragments/asset";

export const SAVE_IMAGE = gql`
  mutation saveImage($saveImageFromUrlInput: SaveImageFromUrlInput!) {
    saveImage(saveImageFromUrlInput: $saveImageFromUrlInput) {
      ...Asset
    }
  }
  ${AssetFragment}
`;
