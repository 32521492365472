import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from "final-form-arrays";
import createFocusOnFirstFieldDecorator from "final-form-focus-on-first-field";
import { motion } from "framer-motion";
import { find } from "lodash";
import { FunctionComponent, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../libs/components/button";
import SelectField from "../../libs/components/formFields/select/SelectField";
import { BotTextField } from "../../libs/components/formFields/text/BotTextField";
import { AnswerTypes } from "../../libs/constants/general";
import { FormHtml } from "../../libs/styledComponents/form";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { textFieldRequired } from "../../libs/validations/textFieldValidators";
import { IQuestion } from "./Conversation";

interface IFuncCompProps {
  initialValues?: any;
  onSubmit: (values: any) => void;
  onClose?: () => void;
  loading?: boolean;
  error?: unknown;
  darkMode?: boolean;
  question?: IQuestion;
  multiline?: number;
}

export const ConversationForm: FunctionComponent<IFuncCompProps> = ({
  initialValues,
  onSubmit,
  darkMode,
  question,
  multiline,
}) => {
  //
  const { t } = useTranslation();

  const focusOnFirstFieldDecorator = useMemo(
    () => createFocusOnFirstFieldDecorator(),
    []
  );

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  const variants = {
    exit: { opacity: 0, delay: 0, duration: 0.5 },
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      decorators={[focusOnFirstFieldDecorator]}
      mutators={{
        ...arrayMutators,
        setFilesInField: (args, state, utils) => {
          utils.changeValue(state, args[0].path, () => {
            return [...args[0].fileDocuments];
          });
        },
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        // console.log("VALUES", values);
        return (
          <FormHtml
            style={{
              backgroundColor: darkMode ? COLORS.fakeBlack : COLORS.fakeWhite, //COLORS.soixanteseize, //
              gap: 0,
            }}
          >
            {question?.answerType === AnswerTypes.TEXT && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 1 }}
              >
                <TextRow>
                  <motion.div
                    style={{ flex: 1 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: 1 }}
                  >
                    <Field
                      secured={question.secured}
                      darkMode={darkMode}
                      editable={true}
                      name={`answer`}
                      component={BotTextField}
                      multiline={multiline}
                      placeholder={t("ConversationForm.TextField.Placeholder")}
                      validate={textFieldRequired}
                    />
                  </motion.div>
                  <SendAnswer
                    type="submit"
                    darkMode={darkMode}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      form.reset();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ fontSize: 20 }}
                    />
                  </SendAnswer>
                </TextRow>
              </motion.div>
            )}
            {question?.answerType === AnswerTypes.OPTIONS && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 1 }}
              >
                <OptionsRow>
                  {question.options?.map((option, index) => (
                    <Button
                      key={index}
                      darkMode={darkMode}
                      onClick={() => onSubmit({ answer: option.label })}
                      type={option.type ?? "classic"}
                    >
                      {option.label}
                    </Button>
                  ))}
                </OptionsRow>
              </motion.div>
            )}

            {question?.answerType === AnswerTypes.LIST && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 1 }}
              >
                <OptionsRow>
                  <SelectField
                    darkMode={darkMode}
                    options={question.options}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      onSubmit({
                        answer:
                          find(
                            question.options,
                            (o) => o.value === e.target.value
                          )?.label ?? "",
                      })
                    }
                  />
                </OptionsRow>
              </motion.div>
            )}

            {question?.answerType === AnswerTypes.TEXT_AND_OPTIONS && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 1 }}
              >
                <AnswerRow>
                  <TextRow>
                    <Field
                      secured={question.secured}
                      darkMode={darkMode}
                      editable={true}
                      name={`answer`}
                      component={BotTextField}
                      multiline={multiline}
                      placeholder={t("ConversationForm.TextField.Placeholder")}
                      validate={textFieldRequired}
                    />

                    <SendAnswer
                      type="submit"
                      darkMode={darkMode}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        form.reset();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        style={{ fontSize: 20 }}
                      />
                    </SendAnswer>
                  </TextRow>

                  <Separator darkMode={darkMode} />

                  <OptionsRow style={{ paddingTop: 0 }}>
                    {question.options?.map((option, index) => (
                      <Button
                        key={index}
                        darkMode={darkMode}
                        onClick={() => onSubmit({ answer: option.label })}
                        type={option.type ?? "classic"}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </OptionsRow>
                </AnswerRow>
              </motion.div>
            )}

            {question?.answerType === AnswerTypes.TEXT_AND_LIST && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={"exit"}
                variants={variants}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 1 }}
              >
                <AnswerRow>
                  <TextRow>
                    <Field
                      secured={question.secured}
                      darkMode={darkMode}
                      editable={true}
                      name={`answer`}
                      component={BotTextField}
                      multiline={multiline}
                      placeholder={t("ConversationForm.TextField.Placeholder")}
                      validate={textFieldRequired}
                    />

                    <SendAnswer
                      type="submit"
                      darkMode={darkMode}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        form.reset();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        style={{ fontSize: 20 }}
                      />
                    </SendAnswer>
                  </TextRow>

                  <Separator darkMode={darkMode} />

                  <OptionsRow style={{ paddingTop: 0 }}>
                    <SelectField
                      darkMode={darkMode}
                      options={question.options}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        onSubmit({
                          answer:
                            find(
                              question.options,
                              (o) => o.value === e.target.value
                            )?.label ?? "",
                        })
                      }
                    />
                  </OptionsRow>
                </AnswerRow>
              </motion.div>
            )}
          </FormHtml>
        );
      }}
    />
  );
};

const AnswerRow = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
  }
`;
const TextRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  padding: 0 20px 0 20px;
  margin: 20px 0;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    padding: 0px;
    /* width: 100%; */
    gap: 10px;
    margin: 0px 20px 20px 20px;
    padding: 20px 0 0 0;
  }
`;

const SendAnswer = styled.button<{ darkMode?: boolean }>`
  cursor: pointer;
  color: ${COLORS.fakeWhite};
  background-color: ${COLORS.soixanteseize};
  border: none;
  margin: 0 20px;
  padding: 0 5px 0 0;
  height: 50px;
  width: 54px;
  border-radius: 50%;
  &:hover,
  &:active {
    color: ${COLORS.soixanteseize};
    background-color: ${COLORS.fakeWhite};
  }

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    height: 50px;
    min-width: 52px;
  }
`;

const Separator = styled.div<{
  darkMode?: boolean;
}>`
  --color: ${(props) => (props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite)};
  opacity: 0.5;
  border-color: var(--color);
  border-right-width: 0.1px;
  border-style: solid;
  margin: 20px 0;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    display: none;
  }
`;
