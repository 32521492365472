import { useQuery } from "@apollo/client";
import moment from "moment";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";

export interface ISubscriptionProps {
  darkMode?: boolean;
}

export const Subscription: FunctionComponent<ISubscriptionProps> = ({
  darkMode,
}) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const location = useLocation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string>(
    "Il semble que vous n'ayez plus de crédits"
  );

  const [step, setStep] = useState<number | undefined>(0);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success /* setSuccess */] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  // const [account, setAccount] = useState<Account | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    fetchPolicy: "network-only",
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") logout();
    },
    onCompleted: (data: any) => {
      const account = data?.userAccounts?.results[0];
      // setAccount(account);
      if (
        account.credit > 0 ||
        (account.subscriptionExpiration &&
          moment().isBefore(moment(account.subscriptionExpiration)))
      )
        navigate("/bot/start");
    },
  });

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  // const [Subscription, { loading }] = useMutation(Subscription_USER_EMAIL, {
  //   onCompleted: () => {
  //     setSuccess(`${t("Success.Message.EmailSubscriptioned")}`);
  //     setTimeout(() => navigate("/login"), 5000);
  //   },
  //   onError: (error: any) => {
  //     console.log("Subscription_USER_EMAIL ERROR:", JSON.parse(JSON.stringify(error)));
  //     setError(errorMessage(error, t));
  //   },
  // });

  // useEffect(() => {
  //   if (location.search && !loading && !error && !success) {
  //     Subscription({
  //       variables: {
  //         SubscriptionUserEmailInput: { hash: location.search.substring(6) },
  //       },
  //     });
  //   }
  // }, [Subscription, error, loading, location.search, success]);

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    // if (loading) setCursorMessage("Je charge.");
    if (error) setCursorMessage("Oooops. An error occured");
  }, [error, /* loading, */ setCursorMessage]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("Subscription.Question.0"),
        nextStep: 1,
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("Subscription.Question.0.Option.0"),
            label: t("Subscription.Question.0.Option.0"),
          },
          {
            value: t("Subscription.Question.0.Option.1"),
            label: t("Subscription.Question.0.Option.1"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("Subscription.Question.0.Option.0")) {
            window.open("https://soixanteseize.com/contacts", "_blank");
            navigate("/");
            // setStep(getNextStep(question, answer));
          }
          if (answer === t("Subscription.Question.0.Option.1")) {
            navigate("/");
          }
        },
      },
      {
        step: 1,
        q: t("Subscription.Question.1"),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("Subscription.Question.1.Option.0"),
            label: t("Subscription.Question.1.Option.0"),
          },
          {
            value: t("Subscription.Question.1.Option.1"),
            label: t("Subscription.Question.1.Option.1"),
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("Subscription.Question.1.Option.0")) {
            setStep(getNextStep(question, answer));
          }
          if (answer === t("Subscription.Question.1.Option.1")) {
            window.open("https://soixanteseize.com/contacts", "_blank");
          }
        },
      },
    ];
    return q;
  }, [navigate, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        success={success}
        resetErrors={() => setError(undefined)}
        processing={false}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {/* loading && <Loader /> */}
    </Layout>
  );
};
