import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import Button from "../button";

export interface IFooterProps {
  darkMode: boolean;
}

export const Footer: FunctionComponent<IFooterProps> = ({ darkMode }) => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Separator />
      <FooterLine>
        <MentionsContainer>
          <Mentions>{t("Legals.Mention.0")}</Mentions>
          <Mentions>{t("Legals.Mention.1")}</Mentions>
        </MentionsContainer>

        <ButtonContainer>
          <Button
            darkMode={darkMode}
            onClick={() => {
              localStorage.removeItem("redirect");
              window.open("https://soixanteseize.com/contacts");
            }}
            type="secondary"
          >
            {t("Button.Contacts")}
          </Button>
        </ButtonContainer>
      </FooterLine>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const FooterLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 5px;
  }
`;
const MentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column-reverse;
  }
`;
const Mentions = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  max-width: 1080px;
`;
const Separator = styled.div`
  width: 100%;
  max-width: 1080px;
  height: 1px;
  background-color: ${COLORS.mediumgray};
  margin: 60px 0 60px 0;
`;
