import { useMutation } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import validate from "validate.js";
import { GENERATE_FONTFAMILIES } from "../../graphql/mutations/generateFontFamilies";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { constraintInspiration } from "./validations/validators";

export interface IGenerateFontFamiliesProps {
  darkMode?: boolean;
  onClose: () => void;
  setProposals: (copyProposals: string[]) => void;
}

export const GenerateFontFamilies: FunctionComponent<
  IGenerateFontFamiliesProps
> = ({ darkMode, onClose, setProposals }) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);

  const [inspiration, setInspiration] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  /**********************************************************
   *** GENERATE CATCHLINES ***
   **********************************************************/

  const [generateFontFamilies, { loading }] = useMutation(
    GENERATE_FONTFAMILIES,
    {
      onCompleted: (data: any) => {
        setSuccess(`${t("Success.Message.FontFamiliesGenerated")} `);
        setProposals(data.generateFontFamilies.proposals);
        setTimeout(() => {
          onClose();
        }, 2000);
      },
      onError: (error: any) => {
        console.log(
          "GENERATE CATCHLINES ERROR:",
          JSON.parse(JSON.stringify(error))
        );
        setError(errorMessage(error, t));
      },
    }
  );

  useEffect(() => {
    if (inspiration) {
      generateFontFamilies({
        variables: {
          generateFontFamiliesInput: { inspiration },
        },
      });
    }
  }, [generateFontFamilies, inspiration]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("GenerateFontFamilies.Question.0"),
        help: t("GenerateFontFamilies.Help.0"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate(
            { demand: answer },
            constraintInspiration(t),
            {
              fullMessages: false,
            }
          );
          if (errors) setError(errors.name[0]);
          else {
            setInspiration(answer);
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [resQuestion, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={loading ? t("Loading.Message.Waiting") : undefined}
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
