import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";
import backgroundWht from "../../../img/bg-pattern.png";
import { useWindowInnerSize } from "../../hooks/useWindowSize";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";
import Button from "../button";
// import background from "../../../img/bg-pattern.png";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IModal {
  open: boolean;
  title?: string;
  content?: string;
  width?: string | number;
  scroll?: "body" | "paper";
  onClose?: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  buttons?: React.ReactNode[];
}

export const Modal: React.FC<IModal> = ({
  open,
  onClose,
  children,
  title,
  content,
  style,
  buttons,
}) => {
  const windowDimensions = useWindowInnerSize();

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
        >
          <Container
            style={{
              backgroundImage: `url(${backgroundWht})`,
              backgroundRepeat: "repeat",
              ...style,
            }}
          >
            {children}
            {onClose && (
              <ButtonContainer>
                <Button darkMode onClick={onClose} type={"secondary"}>
                  <FontAwesomeIcon icon={faTimes} size={"sm"} />
                </Button>
              </ButtonContainer>
            )}
            {buttons && (
              <Fonctionnalities windowDimensions={windowDimensions}>
                {buttons.map((button, index) => button)}
              </Fonctionnalities>
            )}
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.9);
  /* box-shadow: inset 0 0 0 20px ${COLORS.fakeBlack}; */
`;

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 10001;
  top: 40px;
  right: 40px;
`;

const Fonctionnalities = styled.div<{ windowDimensions: { width: number } }>`
  --width: ${(props) => `${props.windowDimensions.width - 80}px`};
  position: absolute;
  width: var(--width);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  z-index: 10001;
  bottom: 40px;
  margin: 0 40px;

  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;
