import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import styled from "styled-components/macro";
import { BREAKPOINTS } from "../../styles/breakpoints";
import { COLORS } from "../../styles/colors";

export type ButtonType =
  | "alert"
  | "classic"
  | "secondary"
  | "disabled"
  | "validation"
  | "neutral"
  | "outlined"
  | "transparent"
  | string;

interface ButtonProps {
  children?: ReactNode;
  onClick: (arg: any) => void;
  type: ButtonType;
  darkMode?: boolean;
  icon?: IconProp;
  iconPosition?: "left" | "right";
  style?: React.CSSProperties;
  onlyIcon?: boolean;
  loading?: boolean;
  submit?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  type,
  darkMode = false,
  icon,
  iconPosition = "right",
  style,
  onlyIcon = false,
  loading,
  submit = false,
}) => {
  return (
    <Container>
      <Btn
        style={style}
        onClick={onClick}
        data-type={type}
        darkMode={darkMode}
        disabled={type === "disabled" || loading}
        type={submit ? "submit" : "button"}
      >
        {icon && iconPosition === "left" && !loading && (
          <FontAwesomeIcon icon={icon} />
        )}
        <span style={{ paddingTop: 4 }}>
          {!onlyIcon && loading ? "Loading..." : children}
        </span>
        {icon && iconPosition === "right" && !loading && (
          <FontAwesomeIcon icon={icon} />
        )}
      </Btn>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: 100%;
  }
`;

const Btn = styled.button<{
  darkMode?: boolean;
}>`
  display: flex;
  background-color: var(--bg-color, ${COLORS.transparent});
  border: 1px solid var(--border-color, ${COLORS.transparent});
  border-radius: 10px;
  color: var(--color, ${COLORS.fakeWhite});
  align-items: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  font-family: "HelveticaNeueLTPro-Bd";
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  justify-content: center;

  &:hover,
  &:active {
    background-color: var(--hover-bg-color, ${COLORS.transparent});
    color: var(--hover-color, ${COLORS.fakeWhite});
    --hover-bg-color: ${(props) =>
      props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite};
    --hover-color: ${(props) =>
      props.darkMode ? COLORS.mediumgray : COLORS.mediumgray};
  }

  &[data-type="validation"] {
    cursor: pointer;
    --bg-color: ${COLORS.green};
  }

  &[data-type="outlined"] {
    cursor: pointer;
    --border-color: ${(props) =>
      props.darkMode ? COLORS.fakeWhite : COLORS.fakeBlack};
    --color: ${(props) =>
      props.darkMode ? COLORS.fakeWhite : COLORS.fakeBlack};
  }

  &[data-type="alert"] {
    cursor: pointer;
    --bg-color: ${COLORS.red};
  }

  &[data-type="classic"] {
    cursor: pointer;
    --bg-color: ${(props) =>
      props.darkMode ? COLORS.soixanteseize : COLORS.soixanteseize};
    --color: ${(props) =>
      props.darkMode ? COLORS.fakeWhite : COLORS.fakeWhite};
  }

  &[data-type="secondary"] {
    cursor: pointer;
    --bg-color: ${(props) =>
      props.darkMode ? COLORS.transparent : COLORS.transparent};
    --border-color: ${(props) =>
      props.darkMode ? COLORS.mediumgray : COLORS.mediumgray};
    --color: ${(props) =>
      props.darkMode ? COLORS.mediumgray : COLORS.mediumgray};
  }

  &[data-type="neutral"] {
    cursor: pointer;
    --color: ${(props) =>
      props.darkMode ? COLORS.transparentGrey : COLORS.transparentGrey};
  }

  &[data-type="disabled"] {
    cursor: default;
    --color: ${(props) =>
      props.darkMode ? COLORS.mediumgray : COLORS.lightgray};
  }

  &[data-type="transparent"] {
    cursor: pointer;
    --color: ${() => COLORS.transparent};
  }
`;

export default Button;
