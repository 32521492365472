import React, { ReactNode } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../styles/colors";

type TextAlign = "left" | "center" | "right";

type TextType = "alert" | "classic" | "disabled" | "validation" | "obsolete";

interface WxTextProps {
  title?: string;
  text?: ReactNode;
  small?: boolean;
  darkMode?: boolean;
  align?: TextAlign;
  type?: TextType;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const Text: React.FC<WxTextProps> = ({
  title = "",
  text = "",
  small = false,
  darkMode,
  align = "left",
  type = "classic",
  style,
  onClick,
}) => {
  return (
    <Container
      darkMode={darkMode}
      align={align}
      type={type}
      small={small}
      style={style}
      onClick={onClick}
    >
      {title && <h3>{title}</h3>}
      {text && <h4>{text}</h4>}
    </Container>
  );
};

const Container = styled.div<{
  darkMode?: boolean;
  align: string;
  type: TextType;
  small: boolean;
  onClick?: () => void;
}>`
  --color: ${(props) => {
    if (props.type === "classic")
      return props.darkMode ? COLORS.white : COLORS.black;
    if (props.type === "alert") return COLORS.red;
    if (props.type === "validation") return COLORS.green;
    if (props.type === "disabled") return COLORS.mediumgray;
    if (props.type === "obsolete") return COLORS.mediumgray;
  }};
  --align: ${(props) => props.align};
  --linethrough: ${(props) =>
    props.type === "obsolete" ? "line-through" : ""};
  --fontsize: ${(props) => (props.small ? "9px" : "12px")};
  --cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  cursor: var(--cursor);
  font-size: var(--fontsize);
  text-decoration: var(--linethrough);
  width: fit-content;

  h3,
  h4,
  h3 {
    color: var(--color);
    font-weight: 900;
    text-align: var(--align);
    margin: 0;
    line-height: 1.4rem;
  }
  h4 {
    color: var(--color);
    font-weight: 400;
    text-align: var(--align);
    margin: 0;
    line-height: 1.4rem;
  }
  p {
    font-size: 1.2rem;
    text-align: var(--align);
    color: var(--color);
    max-width: 110ch;
    font-weight: 300;
    margin-bottom: 1.2rem;
  }
`;
