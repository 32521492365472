import { gql } from "@apollo/client";

export const CHECK_EXISTING_USER_EMAIL = gql`
  mutation checkExistingUserEmail(
    $checkExistingUserEmailInput: CheckExistingUserEmailInput!
  ) {
    checkExistingUserEmail(
      checkExistingUserEmailInput: $checkExistingUserEmailInput
    )
  }
`;
