import { useMutation } from "@apollo/client";
import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Account, Production, Settings } from "../../graphql/generated/graphql";
import { DEBIT_CREDITS } from "../../graphql/mutations/debitCredits";
import { AnswerTypes, CreditTrackTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";

export interface IExportFormatProps {
  darkMode: boolean;
  onClose: () => void;
  type: string;
  account: Account;
  displayAdSettings: Settings;
  production: Production;
  initialValues: any;
}

export const ExportFormat: FunctionComponent<IExportFormatProps> = ({
  darkMode,
  onClose,
  type,
  account,
  displayAdSettings,
  production,
  initialValues,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  const exportHtml = () => {
    console.log("exportHtml", type);
    if (type === "DISPLAY_AD") {
      const name = `${displayAdSettings.formatName.replaceAll(" ", "-")}_${
        displayAdSettings.format
      }`;

      let fontFamilyLink = "";
      if (
        displayAdSettings.parameters.catchline.font ===
        displayAdSettings.parameters.cta.font
      )
        fontFamilyLink = `<link href="https://fonts.googleapis.com/css2?family=${displayAdSettings.parameters.catchline.font}&display=swap" rel="stylesheet">`;
      else
        fontFamilyLink = `<link href="https://fonts.googleapis.com/css2?family=${displayAdSettings.parameters.catchline.font}&family=${displayAdSettings.parameters.cta.font}&display=swap" rel="stylesheet">`;

      const staticHtml = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${name}</title>
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          ${fontFamilyLink}
          ${initialValues.css}
      </head>
      <body>
      <a href="${displayAdSettings.parameters.destination.url}" target="_blank">
      ${initialValues.html}
      </body>
      </a>
      </html>`;

      const blob1 = new Blob([staticHtml], { type: "text/html" });
      const url1 = URL.createObjectURL(blob1);
      const a1 = document.createElement("a");
      a1.href = url1;
      a1.download = `${name}`;
      a1.click();
      URL.revokeObjectURL(url1); // Clean up memory
    }
  };

  /**********************************************************
   *** CREATE PRODUCTION ***
   **********************************************************/

  const [debitCredits, { loading }] = useMutation(DEBIT_CREDITS, {
    onCompleted: (data: any) => {
      exportHtml();
      setSuccess(`${t("ExportFormat.Success")}`);
      setTimeout(() => {
        onClose();
      }, 5000);
    },
    onError: (error: any) => {
      console.log("DEBIT CREDITS ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: `${t("ExportFormat.Question.0")} ${displayAdSettings.formatName} (${
          displayAdSettings.format
        }) ?`,
        help: t("ExportFormat.Question.0.Help", {
          credit: account?.credit ?? 0,
        }),
        answerType: AnswerTypes.OPTIONS,
        options: [
          {
            value: t("ExportFormat.Answer.0.No"),
            label: t("ExportFormat.Answer.0.No"),
            type: "secondary",
          },
          {
            value: t("ExportFormat.Answer.0.Yes"),
            label: t("ExportFormat.Answer.0.Yes"),
            type: "validation",
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("ExportFormat.Answer.0.Yes")) {
            debitCredits({
              variables: {
                createCreditTrackInput: {
                  accountId: account.id,
                  type: CreditTrackTypes.FACTORY_DISPLAYAD_EXPORT,
                  deliveredAssetId: production.id,
                  comment: `Export format: ${displayAdSettings.formatName} ${displayAdSettings.format}`,
                },
              },
            });
          } else {
            onClose();
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [
    account?.credit,
    account.id,
    debitCredits,
    displayAdSettings.format,
    displayAdSettings.formatName,
    onClose,
    production.id,
    resQuestion,
    t,
  ]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={loading ? t("Loading.Message.Waiting") : undefined}
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
