import { gql } from "@apollo/client";

export const GENERATE_FONTFAMILIES = gql`
  mutation generateFontFamilies(
    $generateFontFamiliesInput: GenerateFontFamiliesInput!
  ) {
    generateFontFamilies(
      generateFontFamiliesInput: $generateFontFamiliesInput
    ) {
      proposals
    }
  }
`;
