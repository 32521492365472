import { useLazyQuery, useMutation } from "@apollo/client";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validate from "validate.js";
import { CHANGE_PASSWORD_REQUEST } from "../../graphql/mutations/changePasswordRequest";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { AUTH } from "../../graphql/queries/user";
import { Loader } from "../../libs/components/Loader";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
import { constraintEMail, constraintPassword } from "./validations/validators";
export interface ISigninProps {
  darkMode?: boolean;
}

export interface IAuthRequest {
  email: string;
  password: string;
}

export const Login: FunctionComponent<ISigninProps> = ({ darkMode }) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string>(
    "This is the login interface"
  );

  const [step, setStep] = useState<number | undefined>(0);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  /**********************************************************
   *** AUTH ***
   **********************************************************/

  const [isAuth, setIsAuth] = useState<boolean>(false);

  const [authenticate, { loading }] = useLazyQuery(AUTH, {
    onCompleted: (data: any) => {
      localStorage.setItem("token", data.auth.accessToken);
      setSuccess(`${t("Login.Question.2")} `);
      setIsAuth(true);
    },
    onError: (error: any) => {
      console.log("AUTH ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
      setButtons([
        <Button
          icon={faRefresh}
          iconPosition="left"
          key={0}
          darkMode={darkMode}
          onClick={() => window.location.reload()}
          type="classic"
        >
          {t("Button.Retry")}
        </Button>,
      ]);
    },
  });

  useEffect(() => {
    if (email && password) {
      authenticate({
        variables: {
          email: email.toLowerCase(),
          password: password,
        },
      });
    }
  }, [authenticate, email, password]);

  /**********************************************************
   *** ACCOUNT ***
   **********************************************************/

  const redirect = localStorage.getItem("redirect");

  const [getUserAccount] = useLazyQuery(USER_ACCOUNTS, {
    onCompleted: (data: any) => {
      console.log("USER ACCOUNTS:", data);
      setTimeout(() => {
        if (data.userAccounts.total === 0) navigate("/create-account");
        else if (redirect) {
          navigate(redirect);
          localStorage.removeItem("redirect");
        } else navigate("/bot/start");
      }, 3000);
    },
    onError: (error: any) => {
      console.log("USER ACCOUNTS:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (isAuth) {
      getUserAccount({
        variables: {
          filter: {
            skip: 0,
            limit: 100,
            order: ["createdAt:DESC"],
          },
        },
      });
    }
  }, [getUserAccount, isAuth]);

  /**********************************************************
   *** ACCOUNT ***
   **********************************************************/

  const [changePasswordRequest] = useMutation(CHANGE_PASSWORD_REQUEST, {
    onCompleted: (data: any) => {
      setSuccess(`${t("Success.Message.ChangePasswordRequest")}`);
    },
    onError: (error: any) => {
      console.log(
        "CHANGE_PASSWORD_REQUEST:",
        JSON.parse(JSON.stringify(error))
      );
      setError(errorMessage(error, t));
    },
  });

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    if (loading) setCursorMessage("Je check...");
    if (error) setCursorMessage("Oooops. Une erreur est survenue.");
  }, [error, loading, setCursorMessage]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("Login.Question.0"),
        answerType: AnswerTypes.TEXT_AND_OPTIONS,
        answerTruncateLength: window.innerWidth < 1024 ? 13 : undefined,
        nextStep: 1,
        options: [
          {
            value: "Create Account",
            label: t("Option.CreateAccount"),
            type: "secondary",
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("Option.CreateAccount")) {
            navigate("/register");
          } else {
            const errors = validate({ email: answer }, constraintEMail(t), {
              fullMessages: false,
            });
            if (errors) setError(errors.email[0]);
            else {
              setEmail(answer);
              setStep(getNextStep(question, answer));
            }
          }
        },
      },
      {
        step: 1,
        q: t("Login.Question.1"),
        answerType: AnswerTypes.TEXT_AND_OPTIONS,
        secured: true,
        options: [
          {
            value: "Forgot Password",
            label: t("Option.ForgotPassword"),
            type: "secondary",
          },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          if (answer === t("Option.ForgotPassword")) {
            console.log("FORGOT PASSWORD", email);
            changePasswordRequest({
              variables: {
                changePasswordRequestInput: { email },
              },
            });
          } else {
            const errors = validate(
              { password: answer },
              constraintPassword(t),
              {
                fullMessages: false,
              }
            );
            if (errors) setError(errors.password[0]);
            else {
              setPassword(answer);
            }
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [changePasswordRequest, email, navigate, resQuestion, t]);

  // const goToNextStep = (question?: IQuestion, answer?: string) => {
  //   if (question?.answerType === AnswerTypes.TEXT) {
  //     const nextStep = question.nextSteps[0];
  //     setStep(nextStep);
  //   }
  //   if (question?.answerType === AnswerTypes.OPTIONS) {
  //     const nextStepIndex =
  //       question.options?.findIndex((o) => o.value === answer) ?? 0;
  //     const nextStep = question.nextSteps[nextStepIndex];
  //     setStep(nextStep);
  //   }
  //   if (question?.answerType === AnswerTypes.TEXT_AND_OPTIONS) {
  //     const nextStep = question.nextSteps[0];
  //     setStep(nextStep);
  //   }
  // };

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        success={success}
        info={loading ? t("Loading.Message.Waiting") : undefined}
        resetErrors={() => setError(undefined)}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {loading && <Loader />}
    </Layout>
  );
};
