import gql from "graphql-tag";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const AssetFragment = gql`
  fragment Asset on Asset {
    id
    type
    name
    jobId
    jobDeliveryIndex
    content
    cdnId
    additionalDetails
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
