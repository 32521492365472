import { useLazyQuery } from "@apollo/client";
import { FunctionComponent, Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { USER_ACCOUNTS } from "./graphql/queries/account";
import { CreateAccount } from "./modules/account/CreateAccount";
import { BotStart } from "./modules/bot/Bot";
import { BotImagery } from "./modules/bot/Imagery";
import { BotImageryAdjust } from "./modules/bot/ImageryAdjust";
import { BotImageryZoomOut } from "./modules/bot/ImageryZoomOut";
import { Check } from "./modules/check/Check";
import { Collections } from "./modules/collections/Collections";
import { ImageryDelivery } from "./modules/delivery/ImageryDelivery";
import { MoodboardDelivery } from "./modules/delivery/MoodboardDelivery";
import { DisplayAd } from "./modules/factory/DisplayAd";
import { DisplayAdConfig } from "./modules/factory/DisplayAdConfig";
import { Factory } from "./modules/factory/Factory";
import { LandingPage } from "./modules/factory/LandingPage";
import { History } from "./modules/history/History";
import { Login } from "./modules/login/Login";
import { ChangePassword } from "./modules/password/ChangePassword";
import { Register } from "./modules/register/Register";
import { Subscription } from "./modules/subscription/Subscription";
import { Welcome } from "./modules/welcome/Welcome";

const TRACKING_ID = "G-NZC42B8C65";
ReactGA.initialize(TRACKING_ID);

export interface IAppProps {}

const App: FunctionComponent<IAppProps> = () => {
  //
  const navigate = useNavigate();
  const [darkMode /* , setDarkMode */] = useState(true);
  const location = useLocation();

  // console.log("LOCATION", location);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  /**********************************************************
   *** AUTH CHECK ***
   **********************************************************/

  const storedToken = localStorage.getItem("token");

  const [getAccounts] = useLazyQuery(USER_ACCOUNTS, {
    fetchPolicy: "network-only",
    variables: { filter: { skip: 0, limit: 100 } },
    onCompleted: (data: any) => {
      // console.log("USER_ACCOUNTS", data);
      if (data.userAccounts.total === 0) navigate("/create-account");
    },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
      if (error.message === "Unauthorized") {
        logout();
        // navigate("/");
      }
    },
  });

  useEffect(() => {
    if (!storedToken) {
      if (
        location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/user/check" &&
        location.pathname !== "/user/change-password"
      )
        navigate("/");
      if (location.pathname === "/imagery/delivery") {
        localStorage.setItem(
          "redirect",
          `${location.pathname}${location.search}`
        );
        navigate("/login");
      }
    }
    if (storedToken) getAccounts();
  }, [getAccounts, location.pathname, location.search, navigate, storedToken]);

  return (
    <Suspense fallback={<div>{"Version 1.0.0 (1)"}</div>}>
      <Routes>
        <Route path="/" element={<Welcome darkMode={darkMode} />} />
        <Route path="/login" element={<Login darkMode={darkMode} />} />
        <Route path="/register" element={<Register darkMode={darkMode} />} />
        <Route
          path="/collections"
          element={<Collections darkMode={darkMode} />}
        />
        <Route path="/history" element={<History darkMode={darkMode} />} />
        <Route path="/user/check" element={<Check darkMode={darkMode} />} />
        <Route
          path="/user/change-password"
          element={<ChangePassword darkMode={darkMode} />}
        />
        <Route
          path="/create-account"
          element={<CreateAccount darkMode={darkMode} />}
        />
        <Route
          path="/subscription"
          element={<Subscription darkMode={darkMode} />}
        />
        <Route path="/welcome" element={<Welcome darkMode={darkMode} />} />
        <Route path="/bot/start" element={<BotStart darkMode={darkMode} />} />
        <Route
          path="/moodboard/delivery"
          element={<MoodboardDelivery darkMode={darkMode} />}
        />
        <Route
          path="/bot/imagery"
          element={<BotImagery darkMode={darkMode} />}
        />
        <Route
          path="/bot/imagery/adjust"
          element={<BotImageryAdjust darkMode={darkMode} />}
        />
        <Route
          path="/bot/imagery/zoom-out"
          element={<BotImageryZoomOut darkMode={darkMode} />}
        />
        <Route
          path="/imagery/delivery"
          element={<ImageryDelivery darkMode={darkMode} />}
        />

        <Route path="/factory" element={<Factory darkMode={darkMode} />} />
        <Route
          path="/factory/display-ad"
          element={<DisplayAd darkMode={darkMode} />}
        />
        <Route
          path="/factory/display-ad/config"
          element={<DisplayAdConfig darkMode={darkMode} />}
        />

        <Route path="/factory/landing-page" element={<LandingPage />} />
      </Routes>
    </Suspense>
  );
};

export default App;
