import { gql } from "@apollo/client";
import { ProductionFragment } from "../fragments/production";

export const PRODUCTIONS = gql`
  query productions($productionsFilterInput: ProductionsFilterInput!) {
    productions(productionsFilterInput: $productionsFilterInput) {
      total
      results {
        ...Production
      }
    }
  }
  ${ProductionFragment}
`;
