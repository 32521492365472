import styled from "styled-components/macro";
import { COLORS } from "../styles/colors";

export const FormHtml = styled.form`
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 20px;
`;
export const FormHorizontalScroll = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 0px 0; */
  overflow-y: scroll;
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

export const FormBigColumn = styled.div<{ editable?: boolean }>`
  --backgroundColor: ${(props) => {
    if (props.editable) return `${COLORS.darkgray}`;
    return `${COLORS.lightgray}`;
  }};

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: ${COLORS.lightgray};
  min-width: 500px;
  background-color: var(--backgroundColor);
  padding: 20px;
`;

export const FormColumn = styled.div<{ editable?: boolean }>`
  --backgroundColor: ${(props) => {
    if (props.editable) return `${COLORS.transparent}`;
    return `${COLORS.lightgray}`;
  }};

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--backgroundColor);
  padding: 20px 0;
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
export const FieldArrayRow = styled.div`
  display: flex;
  justify-content: "flex-start";
  align-items: "flex-end";
  width: 100%;
  gap: 0px;
`;

export const FormTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 20px 0;
`;

export const FormButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const FormButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
export const FieldArrayMinusButton = styled.div`
  display: flex;
  height: 46px;
  margin-top: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.black};
`;
