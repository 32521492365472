import { gql } from "@apollo/client";
import { CreditTrackFragment } from "../fragments/creditTrack";

export const DEBIT_CREDITS = gql`
  mutation debitCredits($createCreditTrackInput: CreateCreditTrackInput!) {
    debitCredits(createCreditTrackInput: $createCreditTrackInput) {
      ...CreditTrack
    }
  }
  ${CreditTrackFragment}
`;
