import { useMutation } from "@apollo/client";
import i18n from "i18next";
import { find } from "lodash";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import validate from "validate.js";
import { GENERATE_CATCHLINES } from "../../graphql/mutations/generateCatchlines";
import { AnswerTypes } from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { constraintDemand } from "./validations/validators";

export interface IGenerateCopyProposalsProps {
  darkMode?: boolean;
  onClose: () => void;
  setCopyProposals: (proposals: string[]) => void;
}

export const GenerateCopyProposals: FunctionComponent<
  IGenerateCopyProposalsProps
> = ({ darkMode, onClose, setCopyProposals }) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);

  const [language, setLanguage] = useState<string>("");
  const [demand, setDemand] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  /**********************************************************
   *** GENERATE CATCHLINES ***
   **********************************************************/

  const [generateCatchlines, { loading }] = useMutation(GENERATE_CATCHLINES, {
    onCompleted: (data: any) => {
      setSuccess(`${t("Success.Message.CatchlinesGenerated")} `);
      setCopyProposals(data.generateCatchlines.proposals);
      setTimeout(() => {
        onClose();
      }, 2000);
    },
    onError: (error: any) => {
      console.log(
        "GENERATE CATCHLINES ERROR:",
        JSON.parse(JSON.stringify(error))
      );
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (demand && language) {
      generateCatchlines({
        variables: {
          generateCatchlinesInput: { demand, language },
        },
      });
    }
  }, [demand, generateCatchlines, language]);

  /**********************************************************
   *** LANGUAGES ***
   **********************************************************/

  // Get the list of available languages from i18next
  const availableLanguages = i18n.languages
    .map((languageCode) => ({
      label: i18n.t(`Languages.${languageCode}`),
      value: languageCode,
    }))
    .filter((language) => language.value !== "fr")
    .reverse();

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("GenerateCopyProposals.Question.0"),
        help: t("GenerateCopyProposals.Question.0"),
        answerType: AnswerTypes.OPTIONS,
        options: availableLanguages,
        onAnswer: (question: IQuestion, answer: string) => {
          const a = find(availableLanguages, { label: answer })?.value;
          setLanguage(a ?? "fr-FR");
          setStep(1);
        },
      },
      {
        step: 1,
        q: t("GenerateCopyProposals.Question.1"),
        help: t("GenerateCopyProposals.Help.1"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ demand: answer }, constraintDemand(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setDemand(answer);
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [availableLanguages, resQuestion, t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={loading ? t("Loading.Message.Waiting") : undefined}
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
