import { useLazyQuery, useQuery } from "@apollo/client";
import { uniq } from "lodash";
import moment, { Moment } from "moment";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { USER_IMAGERIES } from "../../graphql/queries/userImageries";
import { Calendar } from "../../libs/components/calendar/Calendar";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { PageHeaderLayout } from "../../libs/components/pageHeaderLayout/PageHeaderLayout";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { HistoryImage } from "./HistoryImage";

export interface IImageryDeliveryProps {
  darkMode: boolean;
}

export const History: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const location = useLocation();
  // console.log(location.search.substring(6));

  /**********************************************************
   *** QUERY ***
   **********************************************************/

  const [account, setAccount] = useState<any>(undefined);
  const [date, setDate] = useState<string | undefined>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onCompleted: (data: any) => {
      if (data.userAccounts?.results && data.userAccounts?.results.length > 0) {
        setAccount(data.userAccounts?.results[0]);
      }
    },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
    },
  });

  /**********************************************************
   *** GET THE RIGHT DATES ***
   **********************************************************/

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const [getAllImageries, { data: monthImageryData }] = useLazyQuery(
    USER_IMAGERIES,
    {
      onError: (error: any) => {
        console.log("ERROR", error);
      },
      onCompleted: (data: any) => {
        const dates: string[] = uniq(
          data.userImageries?.results.map((imagery: any) =>
            moment(imagery.createdAt).format("M-D")
          )
        );
        setSelectedDates(dates);
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (account)
      getAllImageries({
        variables: {
          filter: {
            dateFrom: moment(date)
              .subtract(1, "year")
              .startOf("month")
              .toISOString(),
            dateTo: moment(date).add(1, "year").endOf("month").toISOString(),
            skip: 0,
            limit: 1000,
            order: ["createdAt:DESC"],
          },
        },
      });
  }, [account, date, getAllImageries]);

  // Set the date to the last imagery date
  useEffect(() => {
    if (!date) {
      if (location.search) {
        const date = moment(location.search.substring(6)).toISOString();
        setDate(date);
      } else if (monthImageryData) {
        const date = moment(
          monthImageryData.userImageries?.results[0].createdAt
        ).toISOString();
        setDate(date);
      }
    } else {
      window.history.replaceState(
        {},
        "",
        "/history?date=" + moment(date).format("YYYY-MM-DD")
      );
    }
  }, [date, location.search, monthImageryData]);

  /**********************************************************
   *** GET DATE IMAGERY ***
   **********************************************************/

  const [getImageries, { data: imageriesData, loading: imageriesLoading }] =
    useLazyQuery(USER_IMAGERIES, {
      onError: (error: any) => {
        console.log("ERROR", error);
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (account && date)
      getImageries({
        variables: {
          filter: {
            dateFrom: date,
            dateTo: date,
            skip: 0,
            limit: 50,
            order: ["createdAt:DESC"],
          },
        },
      });
  }, [account, date, getImageries]);

  const imageries = imageriesData?.userImageries?.results;

  if (!account) return <div>loading</div>;
  return (
    <Layout darkMode={darkMode}>
      <Screen>
        <PageHeaderLayout
          title={t("Page.History.Title")}
          id={`${account.users[0].id}`}
        />

        <CalendarContainer>
          <Calendar
            darkMode={darkMode}
            selectedDates={selectedDates}
            date={date}
            changeDate={(moment: Moment) => setDate(moment.toISOString())}
            notInTheFuture
          />
        </CalendarContainer>

        <ImagesContainer>
          {imageriesLoading || !imageries ? (
            <Subtitle>{t("Loading.Message.Waiting")}</Subtitle>
          ) : imageries.length > 0 ? (
            <>
              <Subtitle>{t("Page.History.Subtitle")}</Subtitle>
              <>
                {imageries.map((imagery: any, i: number) => (
                  <Fragment key={i}>
                    <Separator />
                    <HistoryImage darkMode={darkMode} imagery={imagery} />
                  </Fragment>
                ))}
              </>
            </>
          ) : (
            <Subtitle>{t("Page.History.NoHistory")}</Subtitle>
          )}
        </ImagesContainer>

        <Footer darkMode={darkMode} />
      </Screen>
    </Layout>
  );
};

const Screen = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 100px 20px;
`;

const CalendarContainer = styled.div`
  margin-top: -10px;
  width: 100%;
  max-width: 1080px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  align-items: center;
  margin-bottom: 0px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.soixanteseize};
  margin: 40px 0 30px 0;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin: 20px 0 20px 0;
  }
`;

const Subtitle = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;
