import { gql } from "@apollo/client";
import { ImageryFragment } from "../fragments/imagery";

export const IMAGERY = gql`
  query imagery($imageryInput: ImageryInput!) {
    imagery(imageryInput: $imageryInput) {
      ...Imagery
    }
  }
  ${ImageryFragment}
`;
