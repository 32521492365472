import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faUpDownLeftRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DELETE_SAFE } from "../../graphql/mutations/deleteSafe";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { IMAGERY } from "../../graphql/queries/imagery";
import { USER_SAFES } from "../../graphql/queries/safe";
import Button from "../../libs/components/button";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { Modal } from "../../libs/components/modal/modal";
import { PageHeaderLayout } from "../../libs/components/pageHeaderLayout/PageHeaderLayout";
import Selector from "../../libs/components/selector/Selector";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { ImageZoom } from "../modals/imageZoom";
import { RemoveAsset } from "../modals/removeAsset";

export interface IImageryDeliveryProps {
  darkMode: boolean;
}

export const Collections: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  // const [error, setError] = useState<string | undefined>(undefined);

  /**********************************************************
   *** MODALS ***
   **********************************************************/

  const [imageModal, setImageModal] = useState<
    | {
        open: boolean;
        image: string;
        jobId: string;
        jobDeliveryIndex: number;
      }
    | undefined
  >(undefined);

  const [removeModal, setRemoveModal] = useState<
    | {
        open: boolean;
        safeId: string;
        assetId: string;
        image: string;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** ACCOUNT ***
   **********************************************************/

  const [account, setAccount] = useState<any>(undefined);

  useQuery(USER_ACCOUNTS, {
    variables: { filter: { skip: 0, limit: 100 } },
    onCompleted: (data: any) => {
      if (data.userAccounts?.results && data.userAccounts?.results.length > 0) {
        setAccount(data.userAccounts?.results[0]);
      }
    },
    onError: (error: any) => {
      console.log("USER_ACCOUNTS ERROR:", JSON.parse(JSON.stringify(error)));
    },
  });

  /**********************************************************
   *** SAFES ***
   **********************************************************/

  const [selectorPosition, setSelectorPosition] = useState<string>("AI");
  const selectors = [
    {
      label: "AI Generations",
      value: "AI",
      onClick: () => setSelectorPosition("AI"),
      active: selectorPosition === "AI",
    },
    /* {
      label: "Images",
      value: "IMAGE",
      onClick: () => setSelectorPosition("IMAGE"),
      active: selectorPosition === "IMAGE",
    },
    {
      label: "Logos",
      value: "LOGO",
      onClick: () => setSelectorPosition("LOGO"),
      active: selectorPosition === "LOGO",
    }, */
  ];

  const [getSafes, { data: safesData }] = useLazyQuery(USER_SAFES, {
    onError: (error: any) => {
      console.log("ERROR", error);
      // setError(errorMessage(error, t));
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (account)
      getSafes({
        variables: {
          userSafesInput: {
            type: selectorPosition,
            skip: 0,
            limit: 100,
            order: ["createdAt:DESC"],
          },
        },
      });
  }, [account, getSafes, selectorPosition]);

  /**********************************************************
   *** IS FUNCTIONALITY AVAILABLE ***
   **********************************************************/

  const [isFunctionalityAvailable, setIsFunctionalityAvailable] =
    useState<boolean>(true);

  const [getImagery] = useLazyQuery(IMAGERY, {
    onCompleted: (data: any) => {
      if (
        moment(data.imagery.createdAt).isBefore(
          moment("2023-08-14T00:00:00.890Z")
        )
      )
        setIsFunctionalityAvailable(false);
      else setIsFunctionalityAvailable(true);
    },
  });

  useEffect(() => {
    if (imageModal?.jobId) {
      getImagery({
        variables: { imageryInput: { imageryId: imageModal?.jobId } },
      });
    }
  }, [getImagery, imageModal]);

  /**********************************************************
   *** MUTATIONS ***
   **********************************************************/

  const [deleteSafe] = useMutation(DELETE_SAFE, {
    onError: (error: any) => {
      console.log("ERROR", error);
      // setError(errorMessage(error, t));
    },
    onCompleted: (data: any) => {
      getSafes({
        variables: {
          filter: { skip: 0, limit: 100, order: ["createdAt:DESC"] },
        },
      });
    },
  });

  const safes = safesData?.userSafes?.results;

  if (!account) return <div>loading</div>;
  return (
    <>
      <Layout darkMode={darkMode}>
        <Screen>
          <PageHeaderLayout
            title={t("Page.Collections.Title")}
            subtitle={
              safes && safes.length > 0
                ? t("Page.Collections.Subtitle")
                : t("Page.Collections.Message.NoCollection")
            }
            text={""}
            id={`${account.users[0].id}`}
          />

          <TitleContainer>
            <SelectorContainer>
              <Selector items={selectors} />
            </SelectorContainer>
            <CollectionsContainer>
              {safes && safes.length > 0 && (
                <>
                  {safes.map((safe: any, i: number) => (
                    <Fragment key={i}>
                      {i === 0 && (
                        <Subtitle style={{ color: COLORS.mediumgray }}>
                          {selectorPosition === "AI"
                            ? t("Page.Collections.Message.AI")
                            : selectorPosition === "AI"
                            ? t("Page.Collections.Message.IMAGE")
                            : t("Page.Collections.Message.LOGO")}
                        </Subtitle>
                      )}
                      {i > 0 && <Separator />}
                      <SafeTitleContainer>
                        <Title>{safe.name}</Title>
                        {safe.assets.length === 0 && (
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTrash}
                            color={COLORS.fakeWhite}
                            size={"1x"}
                            onClick={() =>
                              deleteSafe({ variables: { safeId: safe.id } })
                            }
                          />
                        )}
                      </SafeTitleContainer>
                      <ImageCardsContainer>
                        {(!safe.assets || safe.assets.length === 0) && (
                          <Mentions
                            style={{ marginBottom: 20, textAlign: "left" }}
                          >
                            {t("Page.Collections.Message.NoAssets")}
                          </Mentions>
                        )}
                        <ImageCardsGrid>
                          {safe.assets &&
                            safe.assets.length > 0 &&
                            safe.assets.map((asset: any, i: number) => (
                              <ImageCardContainer key={i} onClick={() => {}}>
                                <ImageCard
                                  src={asset.content}
                                  alt="Alt"
                                  onClick={() =>
                                    setImageModal({
                                      open: true,
                                      image: asset.content,
                                      jobId: asset.jobId,
                                      jobDeliveryIndex: asset.jobDeliveryIndex,
                                    })
                                  }
                                />
                                <ImageDetailsRow>
                                  <Mentions>{asset.name}</Mentions>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    color={COLORS.fakeWhite}
                                    size={"sm"}
                                    onClick={() =>
                                      setRemoveModal({
                                        open: true,
                                        safeId: safe.id,
                                        assetId: asset.id,
                                        image: asset.content,
                                      })
                                    }
                                  />
                                </ImageDetailsRow>
                              </ImageCardContainer>
                            ))}
                        </ImageCardsGrid>
                      </ImageCardsContainer>
                    </Fragment>
                  ))}
                </>
              )}
            </CollectionsContainer>
          </TitleContainer>

          <Footer darkMode={darkMode} />
        </Screen>
      </Layout>

      <Modal
        open={removeModal?.open ? true : false}
        title={`Remove asset`}
        onClose={() => {
          setRemoveModal(undefined);
        }}
      >
        <RemoveAsset
          image={removeModal?.image}
          onClose={() => {
            setRemoveModal(undefined);
            window.location.reload();
            // getSafes({ variables: { filter: { skip: 0, limit: 100 } } });
          }}
          darkMode={darkMode}
          safeId={removeModal?.safeId ?? ""}
          assetId={removeModal?.assetId ?? ""}
        />
      </Modal>

      <Modal
        open={imageModal?.open ? true : false}
        title={`Image`}
        onClose={() => {
          setImageModal(undefined);
        }}
        buttons={
          isFunctionalityAvailable
            ? [
                <Button
                  icon={faUpDownLeftRight}
                  iconPosition="left"
                  darkMode={darkMode}
                  onClick={() => {
                    navigate(
                      `/bot/imagery/zoom-out?code=${imageModal?.jobId}&delivery=${imageModal?.jobDeliveryIndex}`
                    );
                  }}
                  type="neutral"
                >
                  {t("Button.ZoomOut")}
                </Button>,
              ]
            : []
        }
      >
        <ImageZoom image={imageModal?.image} />
      </Modal>
    </>
  );
};

const Screen = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 100px 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const SafeTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
`;

const Title = styled.div`
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.fakeWhite};
  text-align: left;
  padding-top: 4px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    font-size: 24px;
  }
`;

const ImageDetailsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Mentions = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
  }
`;

const ImageCardsContainer = styled.div``;

const ImageCardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* Three columns with the second column being twice the width of the others */
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

const ImageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  background-color: ${COLORS.black};
`;
const ImageCard = styled.img`
  width: 100%;
  max-width: 200px;
  height: 100px;
  border-radius: 20px;
  object-fit: cover;
  background-color: black;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 100%;
    border-radius: 10px;
    height: unset;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.soixanteseize};
  margin: 60px 0 60px 0;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin: 10px 0 30px 0;
  }
`;
const SelectorContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: flex-start;
`;

const CollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  background-color: ${COLORS.fakeBlack};
  width: 100%;
  max-width: 1040px;
  padding: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    width: unset;
  }
`;
const Subtitle = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  margin-bottom: 40px;
`;
