import { useMutation, useQuery } from "@apollo/client";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { find } from "lodash";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validate from "validate.js";
import { CREATE_ACCOUNT } from "../../graphql/mutations/createAccount";
import { USER_ACCOUNTS } from "../../graphql/queries/account";
import { Loader } from "../../libs/components/Loader";
import Button from "../../libs/components/button";
import { Layout } from "../../libs/components/layout/Layout";
import { AnswerTypes } from "../../libs/constants/general";
import { sectorOptions } from "../../libs/constants/options";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";
import {
  constraintCompany,
  constraintPosition,
  constraintSector,
} from "./validations/validators";
export interface ISigninProps {
  darkMode?: boolean;
}

export interface IAuthRequest {
  email: string;
  password: string;
}

export const CreateAccount: FunctionComponent<ISigninProps> = ({
  darkMode,
}) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string | null>(
    t("CreateAccount.CursorMessage.Welcome")
  );

  const [step, setStep] = useState<number | undefined>(0);

  const [sector, setSector] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [position, setPosition] = useState<string>("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** ACCOUNT ***
   **********************************************************/

  useQuery(USER_ACCOUNTS, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        skip: 0,
        limit: 100,
        order: ["createdAt:DESC"],
      },
    },
    onCompleted: (data: any) => {
      if (data.userAccounts.total > 0) navigate("/bot/start");
    },
    onError: (error: any) => {
      setError(errorMessage(error, t));
    },
  });

  /**********************************************************
   *** CREATE USER ***
   **********************************************************/

  const [goCreateAccount, setGoCreateAccount] = useState<boolean>(false);

  const [createAccount, { loading, error: createAccountError }] = useMutation(
    CREATE_ACCOUNT,
    {
      onCompleted: () => {
        setSuccess(`${t("Success.Message.AccountCreated")}`);
        setGoCreateAccount(false);
        setTimeout(() => navigate("/bot/start"), 5000);
      },
      onError: (error: any) => {
        console.log("CREATE_ACCOUNT ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
        setGoCreateAccount(false);
        setButtons([
          <Button
            icon={faRefresh}
            iconPosition="left"
            key={0}
            darkMode={darkMode}
            onClick={() => window.location.reload()}
            type="classic"
          >
            {t("Button.Restart")}
          </Button>,
        ]);
      },
    }
  );

  useEffect(() => {
    if (!loading && !createAccountError && goCreateAccount)
      if (sector && position) {
        createAccount({
          variables: {
            createAccountInput: {
              sector,
              company,
              position,
            },
          },
        });
      }
  }, [
    company,
    createAccount,
    createAccountError,
    goCreateAccount,
    loading,
    position,
    sector,
  ]);

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    if (loading)
      setCursorMessage(t("CreateAccount.CursorMessage.CreatingAccount"));
    if (success)
      setCursorMessage(t("CreateAccount.CursorMessage.AccountCreated"));
    if (error) setCursorMessage(t("CreateAccount.CursorMessage.Error"));
  }, [error, loading, setCursorMessage, success, t]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("CreateAccount.Question.0"),
        answerType: AnswerTypes.LIST,
        options: sectorOptions(t).map((so) => ({
          label: so.label,
          value: so.label,
          nextStep: 1,
        })),
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ sector: answer }, constraintSector(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.sector[0]);
          else {
            setSector(
              find(sectorOptions(t), (o) => o.label === answer)?.value ?? ""
            );
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 1,
        q: t("CreateAccount.Question.1"),
        answerType: AnswerTypes.TEXT_AND_OPTIONS,
        nextStep: 2,
        options: [
          { value: "Freelance", label: t("Option.Freelance"), nextStep: 2 },
          { value: "None", label: t("Option.None.Feminin"), nextStep: 2 },
        ],
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ company: answer }, constraintCompany(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.company[0]);
          else {
            setCompany(answer);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 2,
        q: t("CreateAccount.Question.2"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ position: answer }, constraintPosition(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.position[0]);
          else {
            setPosition(answer);
            setGoCreateAccount(true);
          }
        },
      },
    ];
    return q;
  }, [t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step ?? 0}
        setStep={setStep}
        error={error}
        success={success}
        resetErrors={() => setError(undefined)}
        processing={loading}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {loading && <Loader />}
    </Layout>
  );
};
