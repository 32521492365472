import { useMutation } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CHECK_USER_EMAIL } from "../../graphql/mutations/checkUserEmail";
import { Loader } from "../../libs/components/Loader";
import { Layout } from "../../libs/components/layout/Layout";
import { errorMessage } from "../../libs/errors/errorMessage";
import { Conversation, IQuestion } from "../conversation/Conversation";

export interface ICheckProps {
  darkMode?: boolean;
}

export const Check: FunctionComponent<ICheckProps> = ({ darkMode }) => {
  //
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [cursorMessage, setCursorMessage] = useState<string>(
    "This is the Check interface"
  );

  const [step, setStep] = useState<number>(0);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  /**********************************************************
   *** MUTATION ***
   **********************************************************/

  const [check, { loading }] = useMutation(CHECK_USER_EMAIL, {
    onCompleted: () => {
      setSuccess(`${t("Success.Message.EmailChecked")}`);
      setTimeout(() => navigate("/login"), 5000);
    },
    onError: (error: any) => {
      console.log("CHECK_USER_EMAIL ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (location.search && !loading && !error && !success) {
      check({
        variables: {
          checkUserEmailInput: { hash: location.search.substring(6) },
        },
      });
    }
  }, [check, error, loading, location.search, success]);

  /**********************************************************
   *** CURSOR ***
   **********************************************************/

  useEffect(() => {
    if (loading) setCursorMessage("I am validating your credentials...");
    if (error) setCursorMessage("Oooops. An error occured");
  }, [error, loading, setCursorMessage]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        q: t("Check.Message.Waiting"),
      },
    ];
    return q;
  }, [t]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Layout darkMode={darkMode} cursorMessage={cursorMessage}>
      <Conversation
        darkMode={darkMode}
        allQuestions={allQuestions}
        step={step}
        setStep={setStep}
        error={error}
        success={success}
        resetErrors={() => setError(undefined)}
        processing={loading}
        buttons={buttons}
        resetButtons={() => setButtons([])}
      />
      {loading && <Loader />}
    </Layout>
  );
};
