import { useQuery } from "@apollo/client";
import {
  faHistory,
  faPlus,
  faTimes,
  faUpDownLeftRight,
  faWandMagicSparkles,
} from "@fortawesome/pro-solid-svg-icons";
import { forEach } from "lodash";
import { DateTime } from "luxon";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IMAGERY } from "../../graphql/queries/imagery";
import Button from "../../libs/components/button";
import { Footer } from "../../libs/components/footer/Footer";
import { Layout } from "../../libs/components/layout/Layout";
import { Modal } from "../../libs/components/modal/modal";
import { PageHeaderLayout } from "../../libs/components/pageHeaderLayout/PageHeaderLayout";
import { useWindowInnerSize } from "../../libs/hooks/useWindowSize";
import { BREAKPOINTS } from "../../libs/styles/breakpoints";
import { COLORS } from "../../libs/styles/colors";
import { SaveImage } from "../modals/saveImage";

export interface IImageryDeliveryProps {
  darkMode: boolean;
}

export const ImageryDelivery: FunctionComponent<IImageryDeliveryProps> = ({
  darkMode,
}) => {
  //

  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowDimensions = useWindowInnerSize();

  const [error, setError] = useState<boolean>(false);
  const [imageIndexHover, setImageIndexHover] = useState<number | undefined>(
    undefined
  );

  /**********************************************************
   *** MODALS ***
   **********************************************************/

  const [saveImageModal, setSaveImageModal] = useState<
    | {
        open: boolean;
        imageryId: string;
        deliveryIndex: number;
      }
    | undefined
  >(undefined);

  /**********************************************************
   *** PROTECTION ***
   **********************************************************/

  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e: any) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  /**********************************************************
   *** IMAGERY ***
   **********************************************************/

  const searchParams = new URLSearchParams(window.location.search);
  const paramCodeValue = searchParams.get("code");
  const paramDeliveryIndexValue = searchParams.get("delivery");

  const { data } = useQuery(IMAGERY, {
    variables: {
      imageryInput: { imageryId: paramCodeValue },
    },
    fetchPolicy: "network-only",
    onError: (error: any) => setError(true),
  });

  if (error)
    return (
      <Layout darkMode={darkMode}>
        <Screen>
          <Header>
            <TitleContainer>
              <TitleRow>
                <Title>{t("Error.Message.NoResultsFound")}</Title>
              </TitleRow>
            </TitleContainer>
            <ButtonContainer>
              <Button
                darkMode={darkMode}
                onClick={() => {
                  localStorage.removeItem("redirect");
                  window.location.reload();
                }}
                type="neutral"
              >
                {t("Button.Reload")}
              </Button>
              <Button
                icon={faTimes}
                iconPosition="left"
                darkMode={darkMode}
                onClick={() => {
                  localStorage.removeItem("redirect");
                  navigate("/bot/start");
                }}
                type="secondary"
              >
                {t("Button.Close")}
              </Button>
            </ButtonContainer>
          </Header>
        </Screen>
      </Layout>
    );

  const imagery = data?.imagery;

  const isZoomedOut = data?.imagery?.need?.ZOOMOUT ? true : false;

  if (imagery?.delivery) {
    const images = imagery.delivery
      .filter((d: any) => d.type === "IMAGE")
      .map((d: any, i: number) => ({ url: d.asset, deliveryIndex: i }));

    const details: any[] = [];
    forEach(JSON.parse(JSON.stringify(imagery.need)), (value, key) => {
      details.push({ key, value });
    });

    return (
      <>
        <Layout darkMode={darkMode}>
          <Screen>
            <PageHeaderLayout
              title={DateTime.fromISO(imagery.updatedAt).toLocaleString(
                DateTime.DATETIME_MED
              )}
              subtitle={
                isZoomedOut
                  ? t("Page.Delivery.Subtitle.2")
                  : t("Page.Delivery.Subtitle.1")
              }
              buttons={[
                <Button
                  icon={faHistory}
                  iconPosition="left"
                  darkMode={darkMode}
                  onClick={() => {
                    navigate(
                      `/history?date=${DateTime.fromISO(
                        imagery.updatedAt
                      ).toFormat("yyyy-MM-dd")}`
                    );
                  }}
                  type="neutral"
                >
                  {t("Button.GoToHistory")}
                </Button>,
              ]}
              text={isZoomedOut ? undefined : imagery.prompt.split("|")[0]}
              textButton={
                isZoomedOut ? undefined : (
                  <Button
                    icon={faWandMagicSparkles}
                    iconPosition="left"
                    darkMode={darkMode}
                    onClick={() => {
                      navigate(`/bot/imagery?code=${imagery.id}`);
                    }}
                    type="secondary"
                  >
                    {t("Button.ReQuery")}
                  </Button>
                )
              }
              id={`${imagery.id}`}
            />

            <TitleContainer>
              <Separator />
            </TitleContainer>

            {imagery.seeds && imagery.seeds?.length > 0 && (
              <TitleContainer style={{ marginBottom: 60 }}>
                <PreviousImagesContainer>
                  <Subtitle>{t("Page.Delivery.PreviousResults")}</Subtitle>
                  <Mentions style={{ marginBottom: 20, textAlign: "left" }}>
                    {t("Page.Delivery.PreviousResultsDetails")}
                  </Mentions>
                  <PreviousImagesRow>
                    {imagery.seeds.map((seed: any, i: number) => (
                      <PreviousImageContainer
                        key={i}
                        onClick={() =>
                          navigate(
                            `/imagery/delivery?code=${seed.id}&delivery=${seed.delivery}`
                          )
                        }
                      >
                        <PreviousImage src={seed.asset} alt="Alt" />
                      </PreviousImageContainer>
                    ))}
                  </PreviousImagesRow>
                  <Separator style={{ marginBottom: 0 }} />
                </PreviousImagesContainer>
              </TitleContainer>
            )}

            <ImagesContainer>
              {images /* .reverse() */
                .map((image: any, key: number) => {
                  return (
                    <Fragment key={key}>
                      <div
                        style={{
                          position: "relative",
                        }}
                        onMouseOver={() => setImageIndexHover(key)}
                        onMouseOut={() => setImageIndexHover(undefined)}
                      >
                        <Image src={image.url} alt="Alt" />

                        <ImageButtonContainer
                          isHover={imageIndexHover === key}
                          windowDimensions={windowDimensions}
                        >
                          {!isZoomedOut && (
                            <Button
                              icon={faWandMagicSparkles}
                              iconPosition="left"
                              darkMode={darkMode}
                              onClick={() => {
                                localStorage.removeItem("redirect");
                                navigate(
                                  `/bot/imagery/adjust?code=${imagery.id}&delivery=${image.deliveryIndex}`
                                );
                              }}
                              type="neutral"
                            >
                              {t("Button.AdjustQuery")}
                            </Button>
                          )}
                          <Button
                            icon={faUpDownLeftRight}
                            iconPosition="left"
                            darkMode={darkMode}
                            onClick={() => {
                              navigate(
                                `/bot/imagery/zoom-out?code=${imagery.id}&delivery=${image.deliveryIndex}`
                              );
                            }}
                            type="neutral"
                          >
                            {t("Button.ZoomOut")}
                          </Button>
                        </ImageButtonContainer>

                        <ImageButtonContainer
                          isHover={imageIndexHover === key}
                          windowDimensions={windowDimensions}
                          style={{
                            top: "unset",
                            bottom: 25,
                            paddingTop: windowDimensions.width < 1280 ? 10 : 0,
                          }}
                        >
                          <Text
                            style={{
                              width: 30,
                              paddingTop: 8,
                              display:
                                windowDimensions.width < 1280
                                  ? "none"
                                  : "unset",
                            }}
                          >{`${key + 1} / ${images.length}`}</Text>
                          <Button
                            icon={faPlus}
                            iconPosition="left"
                            darkMode={darkMode}
                            onClick={() => {
                              setSaveImageModal({
                                open: true,
                                imageryId: imagery.id,
                                deliveryIndex: key,
                              });
                            }}
                            type="classic"
                          >
                            {t("Button.SaveImage")}
                          </Button>
                        </ImageButtonContainer>
                      </div>
                      <Separator />
                    </Fragment>
                  );
                })
                .filter((image: any, key: number) => {
                  if (paramDeliveryIndexValue)
                    return key === Number(paramDeliveryIndexValue);
                  else return true;
                })}
            </ImagesContainer>

            <Footer darkMode={darkMode} />
          </Screen>
        </Layout>

        <Modal
          open={saveImageModal?.open ? true : false}
          title={`Add an asset`}
          onClose={() => {
            setSaveImageModal(undefined);
          }}
        >
          <SaveImage
            onClose={() => {
              setSaveImageModal(undefined);
            }}
            imageUrl={images[saveImageModal?.deliveryIndex ?? 0].url}
            darkMode={darkMode}
            imageryId={saveImageModal?.imageryId ?? ""}
            deliveryIndex={saveImageModal?.deliveryIndex ?? 0}
            setSafeIdForSettings={() => {}}
          />
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const Screen = styled.div`
  width: var(--width);
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px 100px 0px;
  margin: 0 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  margin: 20px 0;
  align-items: flex-end;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const TitleRow = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 48px;
  font-family: "HelveticaNeueLTPro-Bd";
  color: ${COLORS.fakeWhite};
  text-align: left;
`;

const Subtitle = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 20px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
`;

const Mentions = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.fakeWhite};
  font-family: "HelveticaNeueLTPro-Lt";
  text-align: left;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    text-align: center;
  }
`;

const ImageButtonContainer = styled.div<{
  isHover: boolean;
  windowDimensions: any;
}>`
  --display: ${(props) =>
    props.windowDimensions.width < 1280
      ? "flex"
      : props.isHover
      ? "flex"
      : "none"};
  --position: ${(props) =>
    props.windowDimensions.width < 1280 ? "static" : "absolute"};
  position: var(--position);
  top: 20px;
  right: 20px;
  display: var(--display);
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    flex-direction: column;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  align-items: center;
`;

const PreviousImagesContainer = styled.div``;

const PreviousImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    gap: 5%;
  }
`;

const PreviousImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;
const PreviousImage = styled.img`
  width: 100%;
  max-width: 200px;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 1080px;
  border-radius: 20px;
`;

const Text = styled.div`
  width: 100%;
  max-width: 1080px;
  font-size: 12px;
  color: ${COLORS.mediumgray};
  font-family: "HelveticaNeueLTPro-Bd";
  text-align: left;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.soixanteseize};
  margin: 60px 0 60px 0;
  @media (max-width: ${BREAKPOINTS["breakpoint-tablet"]}) {
    margin: 40px 0 30px 0;
  }
`;
