import { useMutation, useQuery } from "@apollo/client";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import validate from "validate.js";

import { find } from "lodash";
import { CREATE_SAFE } from "../../graphql/mutations/createSafe";
import { SAVE_IMAGE } from "../../graphql/mutations/saveImage";
import { UPLOAD_IMAGE } from "../../graphql/mutations/uploadImage";
import { USER_SAFES } from "../../graphql/queries/safe";
import {
  AnswerTypes,
  AssetTypes,
  SafeTypes,
} from "../../libs/constants/general";
import { errorMessage } from "../../libs/errors/errorMessage";
import { constraintName } from "../account/validations/validators";
import { Conversation, IQuestion } from "../conversation/Conversation";
import { getNextStep } from "../conversation/getNextStep";

export interface ISaveImageProps {
  darkMode?: boolean;
  imageUrl?: string;
  imageFile?: any;
  imageryId?: string;
  deliveryIndex?: number;
  type?: AssetTypes;
  onClose: () => void;
  setSafeIdForSettings?: (safeId: string) => void;
}

export const SaveImage: FunctionComponent<ISaveImageProps> = ({
  darkMode,
  onClose,
  imageryId,
  deliveryIndex,
  imageUrl,
  imageFile,
  type,
  setSafeIdForSettings,
}) => {
  //
  const { t } = useTranslation();

  /**********************************************************
   *** STATE ***
   **********************************************************/

  const [step, setStep] = useState<number | undefined>(0);

  const [safeName, setSafeName] = useState<string>("");
  const [safeId, setSafeId] = useState<string>("");
  const [asset, setAsset] = useState<
    | {
        imageName: string;
        imageryId?: string;
        deliveryIndex?: number;
        imageFile?: any;
      }
    | undefined
  >(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [buttons, setButtons] = useState<ReactNode[]>([]);

  const [resQuestion] = useState<IQuestion | undefined>(undefined);

  const [safesOptions, setSafesOptions] = useState<
    { value: string; label: string; nextStep: number }[]
  >([]);

  useQuery(USER_SAFES, {
    variables: {
      userSafesInput: {
        type:
          imageFile && type === AssetTypes.IMAGE
            ? SafeTypes.IMAGE
            : imageFile && type === AssetTypes.LOGO
            ? SafeTypes.LOGO
            : SafeTypes.AI,
        skip: 0,
        limit: 100,
        order: ["createdAt:DESC"],
      },
    },
    onError: (error: any) => {
      console.log("ERROR", error);
      // setError(errorMessage(error, t));
    },
    onCompleted: (data: any) => {
      const safes = data.userSafes.results.map((s: any) => ({
        value: s.id,
        label: s.name,
        nextStep: 10,
      }));

      setSafesOptions([
        {
          value: "new",
          label: t("SaveImage.Answer.0.New"),
          nextStep: 1,
        },
        ...safes,
      ]);
    },
    fetchPolicy: "network-only",
  });

  /**********************************************************
   *** CREATE SAFE ***
   **********************************************************/

  const [createSafe, { loading }] = useMutation(CREATE_SAFE, {
    onCompleted: (data: any) => {
      setSafeId(data.createSafe.id);
      setSuccess(`${t("Success.Message.SafeCreated")}`);
      setTimeout(() => {
        setSuccess(``);
        setStep(10);
      }, 2000);
    },
    onError: (error: any) => {
      console.log("SAFE CREATION ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });

  useEffect(() => {
    if (safeName) {
      createSafe({
        variables: {
          createSafeInput: {
            name: safeName,
            type:
              imageFile && type === AssetTypes.IMAGE
                ? SafeTypes.IMAGE
                : imageFile && type === AssetTypes.LOGO
                ? SafeTypes.LOGO
                : SafeTypes.AI,
          },
        },
      });
    }
  }, [createSafe, imageFile, safeName, type]);

  /**********************************************************
   *** ADD ASSET ***
   **********************************************************/

  const [saveImage, { loading: saveImageLoading }] = useMutation(SAVE_IMAGE, {
    onCompleted: (data: any) => {
      setSuccess(`${t("Success.Message.ImageSaved")} `);
      if (setSafeIdForSettings) setSafeIdForSettings(safeId);
      setTimeout(() => {
        onClose();
      }, 2000);
    },
    onError: (error: any) => {
      console.log("SAVE IMAGE ERROR:", JSON.parse(JSON.stringify(error)));
      setError(errorMessage(error, t));
    },
  });
  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted: (data: any) => {
        setSuccess(`${t("Success.Message.ImageSaved")} `);
        if (setSafeIdForSettings) setSafeIdForSettings(safeId);
        setTimeout(() => {
          onClose();
        }, 2000);
      },
      onError: (error: any) => {
        console.log("SAVE IMAGE ERROR:", JSON.parse(JSON.stringify(error)));
        setError(errorMessage(error, t));
      },
    }
  );

  useEffect(() => {
    if (safeId && asset && !asset.imageFile) {
      saveImage({
        variables: {
          saveImageFromUrlInput: {
            type: AssetTypes.AI_IMAGERY,
            filename: asset.imageName,
            safeId,
            jobId: imageryId,
            jobDeliveryIndex: deliveryIndex,
            url: imageUrl,
          },
        },
      });
    }
    if (safeId && asset && asset.imageFile) {
      uploadImage({
        variables: {
          uploadImageInput: {
            type,
            filename: asset.imageName,
            safeId,
            file: imageFile.file,
          },
        },
      });
    }
  }, [
    safeId,
    asset,
    saveImage,
    imageryId,
    deliveryIndex,
    imageUrl,
    uploadImage,
    imageFile,
    type,
  ]);

  /**********************************************************
   *** QUESTIONS ***
   **********************************************************/

  const allQuestions: IQuestion[] = useMemo(() => {
    const q: IQuestion[] = [
      {
        step: 0,
        images: imageUrl
          ? [imageUrl]
          : imageFile
          ? [imageFile.dataURL]
          : undefined,
        q: t("SaveImage.Question.0"),
        answerType: AnswerTypes.LIST,
        options: safesOptions,
        nextStep: 1,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            const safeId = find(question.options, { label: answer })?.value;
            setSafeId(safeId);
            setStep(getNextStep(question, answer));
          }
        },
      },
      {
        step: 1,
        q: t("SaveImage.Question.1"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setSafeName(answer);
          }
        },
      },
      {
        step: 10,
        q: t("SaveImage.Question.10"),
        answerType: AnswerTypes.TEXT,
        onAnswer: (question: IQuestion, answer: string) => {
          const errors = validate({ name: answer }, constraintName(t), {
            fullMessages: false,
          });
          if (errors) setError(errors.name[0]);
          else {
            setAsset({
              imageName: answer,
              deliveryIndex,
              imageryId,
              imageFile: imageFile,
            });
          }
        },
      },
    ];

    if (resQuestion) q.push(resQuestion);

    return q;
  }, [
    deliveryIndex,
    imageFile,
    imageUrl,
    imageryId,
    resQuestion,
    safesOptions,
    t,
  ]);

  /**********************************************************
   *** RENDER ***
   **********************************************************/

  return (
    <Conversation
      darkMode={darkMode}
      allQuestions={allQuestions}
      step={step ?? 0}
      setStep={setStep}
      error={error}
      success={success}
      info={
        loading || saveImageLoading || uploadImageLoading
          ? t("Loading.Message.Waiting")
          : undefined
      }
      resetErrors={() => setError(undefined)}
      buttons={buttons}
      resetButtons={() => setButtons([])}
    />
  );
};
