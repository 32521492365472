import gql from "graphql-tag";

/**********************************************************
 *** FRAGMENT ***
 **********************************************************/

export const MoodboardFragment = gql`
  fragment Moodboard on Moodboard {
    id
    user
    need {
      subject
      requirements
      mood
    }
    prompt
    delivery {
      type
      asset
      additionalDetails
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
